import { createSlice } from '@reduxjs/toolkit'
import { v4 as uuid } from 'uuid';
import { commonReducers, initialState, SliceStatus } from './common'
import {
  DocumentStatus,
  DocumentVersion,
  AssociatedFile,
} from '@alucio/aws-beacon-amplify/src/models'
import activeUser from 'src/state/global/ActiveUser'
import { DocumentVersionORM } from 'src/types/types'
import logger from 'src/utils/logger'

export const sliceName = 'documentVersion'
const { reducers, extraReducers } = commonReducers<DocumentVersion>(sliceName)

export const createAssociatedFile = (
  payload: Omit<
    AssociatedFile,
    'id' | 'createdAt' | 'createdBy'
  >,
): AssociatedFile => {
  if (!activeUser.user?.id) {
    throw new Error('Could not find current logged in User')
  }

  return {
    id: uuid(),
    isDefault: false,
    createdAt: new Date().toISOString(),
    createdBy: activeUser.user.id,
    ...payload,
  }
}

export const getNextSemVer = (
  documentVersionORM: DocumentVersionORM,
  updates: Partial<DocumentVersion>,
): DocumentVersion['semVer'] => {
  const documentORM = documentVersionORM.relations.documentORM
  const isFirstVersion = !documentORM.relations.version.latestPublishedDocumentVersionORM
  const isMajorChange = updates.changeType === 'MAJOR'
  const latestVersion = documentORM.relations.version.latestUsableDocumentVersionORM.model

  // versionForm semVer can be null for a new version from upload (lambda fn doesnt fill the field)
  // for those cases we try to fallback to semVer value from event payload
  const currentSemVer = documentVersionORM.model.semVer ?? latestVersion.semVer!
  const nextSemVer = isFirstVersion
    ? { major: 1, minor: 0 }
    : {
      major: currentSemVer.major + (isMajorChange ? 1 : 0),
      minor: isMajorChange ? 0 : currentSemVer.minor + 1,
    }

  return nextSemVer
}

/**
 * This a wrapper for any reducers that checks to verify that target updates for a record are valid
 * Currently, we support 1 use case
 * Generally, there should not be multiple variations of DocVerIds in a single record,
 * they should all match the parent id of the record being saved.
 * If a variant is detected, an error is logged and thrown. Use a Redux Selector to subscribe to potential errors and update in UI accordingly
 *
 * NOTE: This does only checks for variants, not other DocVerIds! For example, things in the AssociatedFiles array would not be caught
 * It will also not catch completely random IDs either
 *
 * In the future, this would be better off in the AppSync resolver level so it's not client-side only (when Amplify supports JS resolvers)
 */
const validateRecord = <T extends (...args: any[]) => any>(reducer: T) => (...args: Parameters<T>): ReturnType<T> => {
  const [state, action] = args

  const { id, documentId } = action.payload.entity
  const stringified = JSON.stringify(action.payload.updates)

  const docIdVerRegex = new RegExp(`${documentId}_[0-9]+`, 'gi')
  const uuids = [...stringified.matchAll(docIdVerRegex)]

  let logMsg: string | undefined
  let errorMsg: string | undefined

  // [TODO] - If we need to do multiple checks, don't inline the logic, refactor this into a pipe
  const isValid = uuids.every(
    ([matchingText]) => {
      if (matchingText !== id) {
        errorMsg = 'There was an error saving this Document.' +
        ' Please refresh the page, try again and contact support if the issue persists'
        logMsg = 'Attempted to save a DocumentVersion record with incorrect IDs'
        logger.versioning.update.error(logMsg, { id, documentId, updates: action.payload.updates })
        return false
      }

      return true
    },
  )

  if (!isValid) {
    return {
      ...state,
      status: SliceStatus.ERROR,
      errorStatus: {
        error: logMsg,
        message: errorMsg,
      },
    }
  }

  return reducer(...args)
}

const saveWithValidateRecord = validateRecord(reducers.save)
const batchSaveWithValidateRecord = validateRecord(reducers.batchSave)

const documentVersionSlice = createSlice({
  name: sliceName,
  initialState: initialState<DocumentVersion>(),
  reducers: {
    ...reducers,
    saveWithValidateRecord,
    batchSaveWithValidateRecord,
    prePublish: {
      prepare: (documentVersionORM: DocumentVersionORM, updates: Partial<DocumentVersion>) => {
        const nextSemVer = getNextSemVer(documentVersionORM, updates)

        return {
          payload: {
            model: DocumentVersion,
            entity: documentVersionORM.model,
            updates: {
              ...updates,
              publishedAt: new Date().toISOString(),
              // [NOTE] - Not sure why we're saving these values from the client? Looks like it's being pulled from the parent document,
              //          but assumes it's not applied to the version?
              integrationType: documentVersionORM.meta.integration.integrationType,
              integration: documentVersionORM.meta.integration.integration,
              semVer: nextSemVer,
            },
          },
        }
      },
      reducer: saveWithValidateRecord,
    },
    schedulePublish: {
      prepare: (documentVersionORM: DocumentVersionORM, updates: Partial<DocumentVersion>) => {
        const nextSemVer = getNextSemVer(documentVersionORM, updates)

        return {
          payload: {
            model: DocumentVersion,
            entity: documentVersionORM.model,
            updates: {
              ...updates,
              // [NOTE] - Not sure why we're saving these values from the client? Looks like it's being pulled from the parent document,
              //          but assumes it's not applied to the version?
              integrationType: documentVersionORM.meta.integration.integrationType,
              integration: documentVersionORM.meta.integration.integration,
              semVer: nextSemVer,
            },
          },
        }
      },
      reducer: saveWithValidateRecord,
    },
    cancelScheduledPublish: {
      prepare: (documentVersionORM: DocumentVersionORM) => {
        if (!documentVersionORM.model.scheduledPublish) {
          throw new Error('Cannot cancel a version that is not set to publish')
        }
        const updates: Partial<DocumentVersion> = {
          scheduledPublish: undefined,
          semVer: undefined,
        }

        return {
          payload: {
            model: DocumentVersion,
            entity: documentVersionORM.model,
            updates,
          },
        }
      },
      reducer: saveWithValidateRecord,
    },
    delete: {
      prepare: (documentVersionORM: DocumentVersionORM) => {
        if (documentVersionORM.model.status === 'PUBLISHED') {
          throw new Error('You cannot delete a Published Version')
        }

        return {
          payload: {
            model: DocumentVersion,
            entity: documentVersionORM.model,
            updates: { status: DocumentStatus.DELETED },
          },
        }
      },
      reducer: saveWithValidateRecord,
    },
    /** This reducer will update the document version with the provided title. If no
     * title is provided or the title is an empty string, it will reset the title to
     * the original source file name */
    rename: {
      prepare: (documentVersionORM: DocumentVersionORM, updatedTitle) => {
        const newTitle = updatedTitle || documentVersionORM.model.srcFilename
        return {
          payload: {
            model: DocumentVersion,
            entity: documentVersionORM.model,
            updates: { title: newTitle },
          },
        }
      },
      reducer: saveWithValidateRecord,
    },
  },
  extraReducers,
})

export default documentVersionSlice
export const documentVersionActions = documentVersionSlice.actions
