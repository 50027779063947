/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.

export type CreateUserInput = {
  id?: string | null,
  cognitoSubId?: string | null,
  tenantId: string,
  email: string,
  givenName: string,
  phoneNumber?: string | null,
  familyName: string,
  meetingId?: string | null,
  defaultFiltersCustomValues: Array< CustomValuesInput >,
  lockedFiltersCustomValues: Array< CustomValuesInput >,
  defaultFilterValues: Array< LabelValuesInput >,
  lockedFilters?: Array< LabelValuesInput > | null,
  shareBCC?: Array< string > | null,
  shareCC?: Array< string > | null,
  createdAt?: string | null,
  createdBy: string,
  updatedAt?: string | null,
  status?: USER_STATUS | null,
  role: USER_ROLE,
  bookmarkedDocs?: Array< BookmarkedDocInput > | null,
  isExcludedFromReporting?: boolean | null,
  additionalRoles?: Array< USER_ROLE > | null,
  altUsername?: string | null,
  _version?: number | null,
};

export type CustomValuesInput = {
  fieldId: string,
  objectRecords?: Array< ObjectRecordInput > | null,
  values: Array< string >,
};

export type ObjectRecordInput = {
  id: string,
  externalId?: string | null,
  status: OBJECT_RECORD_STATUS,
  syncStatus?: CRMSyncStatus | null,
  values: Array< ObjectRecordValueInput >,
};

export enum OBJECT_RECORD_STATUS {
  ACTIVE = "ACTIVE",
  REMOVED = "REMOVED",
}


export enum CRMSyncStatus {
  SYNCED = "SYNCED",
  NOT_SYNCED = "NOT_SYNCED",
  DELETED = "DELETED",
}


export type ObjectRecordValueInput = {
  fieldId: string,
  values: Array< string >,
};

export type LabelValuesInput = {
  key: string,
  values: Array< string >,
};

export enum USER_STATUS {
  ACTIVE = "ACTIVE",
  DEACTIVATED = "DEACTIVATED",
}


export enum USER_ROLE {
  AlucioAdmin = "AlucioAdmin",
  TenantAdmin = "TenantAdmin",
  TenantPublisher = "TenantPublisher",
  TenantViewer = "TenantViewer",
  TeamReportViewer = "TeamReportViewer",
}


export type BookmarkedDocInput = {
  docID: string,
  createdAt: string,
};

export type ModelUserConditionInput = {
  cognitoSubId?: ModelIDInput | null,
  tenantId?: ModelIDInput | null,
  email?: ModelStringInput | null,
  givenName?: ModelStringInput | null,
  phoneNumber?: ModelStringInput | null,
  familyName?: ModelStringInput | null,
  meetingId?: ModelStringInput | null,
  shareBCC?: ModelStringInput | null,
  shareCC?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  createdBy?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  status?: ModelUSER_STATUSInput | null,
  role?: ModelUSER_ROLEInput | null,
  isExcludedFromReporting?: ModelBooleanInput | null,
  additionalRoles?: ModelUSER_ROLEListInput | null,
  altUsername?: ModelStringInput | null,
  and?: Array< ModelUserConditionInput | null > | null,
  or?: Array< ModelUserConditionInput | null > | null,
  not?: ModelUserConditionInput | null,
};

export type ModelIDInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export enum ModelAttributeTypes {
  binary = "binary",
  binarySet = "binarySet",
  bool = "bool",
  list = "list",
  map = "map",
  number = "number",
  numberSet = "numberSet",
  string = "string",
  stringSet = "stringSet",
  _null = "_null",
}


export type ModelSizeInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
};

export type ModelStringInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export type ModelUSER_STATUSInput = {
  eq?: USER_STATUS | null,
  ne?: USER_STATUS | null,
};

export type ModelUSER_ROLEInput = {
  eq?: USER_ROLE | null,
  ne?: USER_ROLE | null,
};

export type ModelBooleanInput = {
  ne?: boolean | null,
  eq?: boolean | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type ModelUSER_ROLEListInput = {
  eq?: Array< USER_ROLE | null > | null,
  ne?: Array< USER_ROLE | null > | null,
  contains?: USER_ROLE | null,
  notContains?: USER_ROLE | null,
};

export type User = {
  __typename: "User",
  id: string,
  cognitoSubId?: string | null,
  tenantId: string,
  email: string,
  givenName: string,
  phoneNumber?: string | null,
  familyName: string,
  meetingId?: string | null,
  defaultFiltersCustomValues:  Array<CustomValues >,
  lockedFiltersCustomValues:  Array<CustomValues >,
  defaultFilterValues:  Array<LabelValues >,
  lockedFilters?:  Array<LabelValues > | null,
  shareBCC?: Array< string > | null,
  shareCC?: Array< string > | null,
  createdAt: string,
  createdBy: string,
  updatedAt: string,
  status?: USER_STATUS | null,
  role: USER_ROLE,
  bookmarkedDocs?:  Array<BookmarkedDoc > | null,
  isExcludedFromReporting?: boolean | null,
  additionalRoles?: Array< USER_ROLE > | null,
  altUsername?: string | null,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type CustomValues = {
  __typename: "CustomValues",
  fieldId: string,
  objectRecords?:  Array<ObjectRecord > | null,
  values: Array< string >,
};

export type ObjectRecord = {
  __typename: "ObjectRecord",
  id: string,
  externalId?: string | null,
  syncStatus?: CRMSyncStatus | null,
  status: OBJECT_RECORD_STATUS,
  values:  Array<ObjectRecordValue >,
};

export type ObjectRecordValue = {
  __typename: "ObjectRecordValue",
  fieldId: string,
  values: Array< string >,
};

export type LabelValues = {
  __typename: "LabelValues",
  key: string,
  values: Array< string >,
};

export type BookmarkedDoc = {
  __typename: "BookmarkedDoc",
  docID: string,
  createdAt: string,
};

export type UpdateUserInput = {
  id: string,
  cognitoSubId?: string | null,
  tenantId?: string | null,
  email?: string | null,
  givenName?: string | null,
  phoneNumber?: string | null,
  familyName?: string | null,
  meetingId?: string | null,
  defaultFiltersCustomValues?: Array< CustomValuesInput > | null,
  lockedFiltersCustomValues?: Array< CustomValuesInput > | null,
  defaultFilterValues?: Array< LabelValuesInput > | null,
  lockedFilters?: Array< LabelValuesInput > | null,
  shareBCC?: Array< string > | null,
  shareCC?: Array< string > | null,
  createdAt?: string | null,
  createdBy?: string | null,
  updatedAt?: string | null,
  status?: USER_STATUS | null,
  role?: USER_ROLE | null,
  bookmarkedDocs?: Array< BookmarkedDocInput > | null,
  isExcludedFromReporting?: boolean | null,
  additionalRoles?: Array< USER_ROLE > | null,
  altUsername?: string | null,
  _version?: number | null,
};

export type DeleteUserInput = {
  id: string,
  _version?: number | null,
};

export type CreateTenantInput = {
  id?: string | null,
  name: string,
  licensedUsers: number,
  status: TENANT_STATUS,
  fields: Array< FieldConfigInput >,
  folderUpdateGracePeriodDays?: number | null,
  medInfoURL?: string | null,
  publisherListConfig?: ListConfigurationInput | null,
  mslListConfig?: ListConfigurationInput | null,
  featureFlags: Array< FeatureFlagsInput >,
  createdAt?: string | null,
  createdBy: string,
  updatedAt?: string | null,
  statusChangedAt: string,
  statusChangedBy: string,
  ssoDomains?: Array< string | null > | null,
  integrations?: Array< string | null > | null,
  config: TenantConfigInput,
  _version?: number | null,
};

export enum TENANT_STATUS {
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
}


export type FieldConfigInput = {
  fieldName: string,
  description?: string | null,
  required: boolean,
  isEmailTemplateFilter?: boolean | null,
  dataType: FIELD_DATA_TYPE,
  userFilter: boolean,
  defaultSearchFilter: boolean,
  values: Array< string >,
};

export enum FIELD_DATA_TYPE {
  CATEGORICAL = "CATEGORICAL",
  MULTICATEGORICAL = "MULTICATEGORICAL",
  STRING = "STRING",
  DATE = "DATE",
  USER_LIST = "USER_LIST",
  OBJECT = "OBJECT",
  NUMBER = "NUMBER",
}


export type ListConfigurationInput = {
  fields: Array< ListConfigurationFieldInput >,
  sort?: ListConfigurationSortInput | null,
};

export type ListConfigurationFieldInput = {
  fieldName: string,
  header?: string | null,
  width?: number | null,
};

export type ListConfigurationSortInput = {
  field: string,
  isAsc: boolean,
};

export type FeatureFlagsInput = {
  flagId: string,
  value: boolean,
};

export type TenantConfigInput = {
  requiredSlidesHiddenMessage?: string | null,
  forms?: Array< TenantFormInput > | null,
  meetingFields?: Array< MeetingFieldDefinitionInput > | null,
  customFields?: Array< CustomFieldDefinitionInput > | null,
  defaultDocumentPermissions: DocumentPermissionInput,
  mslListConfig?: ListConfigInput | null,
  publisherListConfig?: ListConfigInput | null,
  crmIntegration?: CRMIntegrationInput | null,
  sessionTimeout?: number | null,
  quickFilters?: Array< QuickFilterInput > | null,
  hubsConfig: HubConfigInput,
  reports: Array< KeyValueInput >,
  emailDocumentUpdateFrequency?: FREQUENCY | null,
  sharePPTXasPDF?: boolean | null,
};

export type TenantFormInput = {
  id?: string | null,
  name: string,
  label: string,
  fields: Array< CustomFieldDefinitionInput >,
  targetRequestTemplate: string,
  targetType: TENANT_FORM_TARGET_TYPE,
  targetAddress: string,
  targetParameters?: Array< TenantFormTargetParametersInput > | null,
  status: TENANT_FORM_STATUS,
};

export type CustomFieldDefinitionInput = {
  id?: string | null,
  fieldName: string,
  fieldLabel: string,
  reportingName?: string | null,
  status: FIELD_STATUS,
  description?: string | null,
  required?: boolean | null,
  maxLength?: number | null,
  formatValidation?: FORMAT_VALIDATION | null,
  fieldType: FIELD_DATA_TYPE,
  showByDefault?: boolean | null,
  dependentFieldId?: string | null,
  controlType: CONTROL_TYPE,
  order?: number | null,
  settings?: CommonFieldSettingsInput | null,
  usage: Array< CUSTOM_FIELD_USAGE >,
  documentSettings?: DocumentFieldSettingsInput | null,
  fieldValueDefinitions: Array< CustomFieldValueDefinitionInput >,
  displayOnParentSelection?: boolean | null,
  displayOnValueSelection?: Array< string > | null,
  objectSetting?: ObjectSettingInput | null,
  isChildField?: boolean | null,
  dateRestriction?: DATE_RESTRICTION | null,
  createdAt?: string | null,
  scaleNumber?: number | null,
  precisionNumber?: number | null,
};

export enum FIELD_STATUS {
  DISABLED = "DISABLED",
  ENABLED = "ENABLED",
}


export enum FORMAT_VALIDATION {
  EMAIL = "EMAIL",
}


export enum CONTROL_TYPE {
  SELECT = "SELECT",
  RADIOLIST = "RADIOLIST",
  CHECKBOX = "CHECKBOX",
  CHECKBOXLIST = "CHECKBOXLIST",
  CRMACCOUNTSEARCHER = "CRMACCOUNTSEARCHER",
  CRMATTENDEELIST = "CRMATTENDEELIST",
  INPUT = "INPUT",
  TEXTAREA = "TEXTAREA",
  DATEPICKER = "DATEPICKER",
  USERLIST = "USERLIST",
  CUSTOM = "CUSTOM",
  OBJECT = "OBJECT",
  LABEL = "LABEL",
  DATETIMEPICKER = "DATETIMEPICKER",
}


export type CommonFieldSettingsInput = {
  isBadge?: boolean | null,
  fieldPosition: FIELD_POSITION,
};

export enum FIELD_POSITION {
  LEFT = "LEFT",
  RIGHT = "RIGHT",
}


export enum CUSTOM_FIELD_USAGE {
  USER_FILTER = "USER_FILTER",
  MEETING = "MEETING",
  DOCUMENT = "DOCUMENT",
  EMAIL_TEMPLATE_FILTER = "EMAIL_TEMPLATE_FILTER",
  CONTENT_SHARE = "CONTENT_SHARE",
  HUB = "HUB",
  TENANT_FORM = "TENANT_FORM",
}


export type DocumentFieldSettingsInput = {
  fieldPosition: FIELD_POSITION,
};

export type CustomFieldValueDefinitionInput = {
  id?: string | null,
  value: string,
  label?: string | null,
  disabled?: boolean | null,
  isDefault?: boolean | null,
  documentSettings?: DocumentFieldValueSettingsInput | null,
  badgeColor?: string | null,
  badgeLabel?: string | null,
  createdAt?: string | null,
  dependentValueIds: Array< string >,
};

export type DocumentFieldValueSettingsInput = {
  presentationWatermarkText?: string | null,
  permission?: DocumentPermissionInput | null,
};

export type DocumentPermissionInput = {
  present?: PERMISSION_EFFECT | null,
  modify?: PERMISSION_EFFECT | null,
  share?: PERMISSION_EFFECT | null,
  download?: PERMISSION_EFFECT | null,
  externalNotation?: PERMISSION_EFFECT | null,
};

export enum PERMISSION_EFFECT {
  ALLOW = "ALLOW",
  BLOCK = "BLOCK",
}


export type ObjectSettingInput = {
  childrenFieldIds: Array< string >,
  restriction?: ObjectRestrictionInput | null,
};

export type ObjectRestrictionInput = {
  syncUpdate?: boolean | null,
  syncDelete?: boolean | null,
  update?: boolean | null,
  delete?: boolean | null,
};

export enum DATE_RESTRICTION {
  ONLY_FUTURE = "ONLY_FUTURE",
  ONLY_PAST = "ONLY_PAST",
}


export enum TENANT_FORM_TARGET_TYPE {
  API = "API",
}


export type TenantFormTargetParametersInput = {
  key: string,
  value: string,
};

export enum TENANT_FORM_STATUS {
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
}


export type MeetingFieldDefinitionInput = {
  id?: string | null,
  fieldName: string,
  status: FIELD_STATUS,
  description?: string | null,
  required: boolean,
  maxLength?: number | null,
  type: FIELD_DATA_TYPE,
  controlType: CONTROL_TYPE,
  fieldValueDefinitions: Array< MeetingFieldValueDefinitionInput >,
  createdAt?: string | null,
};

export type MeetingFieldValueDefinitionInput = {
  id?: string | null,
  value: string,
  disabled: boolean,
  createdAt?: string | null,
};

export type ListConfigInput = {
  sort?: ListSortInput | null,
  fields: Array< FieldListConfigInput >,
};

export type ListSortInput = {
  field: string,
  isAsc?: boolean | null,
};

export type FieldListConfigInput = {
  field: string,
  width?: number | null,
};

export type CRMIntegrationInput = {
  id?: string | null,
  crmIntegrationType: CRM_INTEGRATION_TYPE,
  instanceUrl: string,
  clientId: string,
  redirectUri: string,
  name: string,
  accountsSettings: CRMAccountSettingsInput,
  meetingSetting?: CRMTableSettingInput | null,
  additionalSettings?: CRMAdditionalSettingsInput | null,
  loginUrl?: string | null,
  crmStandaloneForms?: Array< CRMStandaloneFormInput > | null,
};

export enum CRM_INTEGRATION_TYPE {
  SALESFORCE = "SALESFORCE",
  VEEVA = "VEEVA",
}


export type CRMAccountSettingsInput = {
  query: string,
  displaySettings: CRMDisplaySettingsInput,
  addressSettings: CRMAddressSettingsInput,
};

export type CRMDisplaySettingsInput = {
  shortened: string,
  extended?: Array< string > | null,
};

export type CRMAddressSettingsInput = {
  alias: string,
  displaySettings: CRMDisplaySettingsInput,
};

export type CRMTableSettingInput = {
  apiName: string,
  presentationsFieldName?: string | null,
  children: Array< CRMChildTableSettingInput >,
  type: CRM_INTEGRATION_TYPE,
};

export type CRMChildTableSettingInput = {
  name: string,
  apiName: string,
  relationshipName?: string | null,
  type: CRM_INTEGRATION_TYPE,
  veevaSetting?: VeevaSettingInput | null,
};

export type VeevaSettingInput = {
  markerFieldName?: string | null,
};

export type CRMAdditionalSettingsInput = {
  allowCommentsOnTasks?: boolean | null,
  detailContentTableSettings?: CRMTableDetailContentPresentedInput | null,
  enableSurveys?: boolean | null,
  crmAccountToObjectSettings?: Array< CRMAccountToObjectSettingInput > | null,
};

export type CRMTableDetailContentPresentedInput = {
  name: string,
  apiName: string,
  relationshipName: string,
  prefix?: string | null,
};

export type CRMAccountToObjectSettingInput = {
  accountFieldNames: Array< CRMAccountToObjectFieldsInput >,
  apiName: string,
};

export type CRMAccountToObjectFieldsInput = {
  accountFieldName: string,
  objectFieldName: string,
};

export type CRMStandaloneFormInput = {
  id?: string | null,
  apiName: string,
  children: Array< CRMChildTableSettingInput >,
  type: CRM_INTEGRATION_TYPE,
  parents: Array< CRMStandaloneFormParentInput >,
  usage?: Array< STANDALONE_FORM_USAGE > | null,
};

export type CRMStandaloneFormParentInput = {
  apiName: string,
  relationshipName: string,
};

export enum STANDALONE_FORM_USAGE {
  STANDALONE = "STANDALONE",
  MEETINGS = "MEETINGS",
}


export type QuickFilterInput = {
  id?: string | null,
  label: string,
  path: Array< string >,
};

export type HubConfigInput = {
  shareExpirationDays?: number | null,
  disabledSections: Array< HUB_SECTION_TYPE >,
  disableNotationDescription: boolean,
};

export enum HUB_SECTION_TYPE {
  TEXTAREA = "TEXTAREA",
  SHARED_FILES = "SHARED_FILES",
  TODO_LIST = "TODO_LIST",
  LINKS = "LINKS",
}


export type KeyValueInput = {
  key: string,
  value: boolean,
};

export enum FREQUENCY {
  DAILY = "DAILY",
  WEEKLY = "WEEKLY",
  MONTHLY = "MONTHLY",
}


export type ModelTenantConditionInput = {
  name?: ModelStringInput | null,
  licensedUsers?: ModelIntInput | null,
  status?: ModelTENANT_STATUSInput | null,
  folderUpdateGracePeriodDays?: ModelIntInput | null,
  medInfoURL?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  createdBy?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  statusChangedAt?: ModelStringInput | null,
  statusChangedBy?: ModelStringInput | null,
  ssoDomains?: ModelStringInput | null,
  integrations?: ModelStringInput | null,
  and?: Array< ModelTenantConditionInput | null > | null,
  or?: Array< ModelTenantConditionInput | null > | null,
  not?: ModelTenantConditionInput | null,
};

export type ModelIntInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type ModelTENANT_STATUSInput = {
  eq?: TENANT_STATUS | null,
  ne?: TENANT_STATUS | null,
};

export type Tenant = {
  __typename: "Tenant",
  id: string,
  name: string,
  licensedUsers: number,
  status: TENANT_STATUS,
  fields:  Array<FieldConfig >,
  folderUpdateGracePeriodDays?: number | null,
  medInfoURL?: string | null,
  publisherListConfig?: ListConfiguration | null,
  mslListConfig?: ListConfiguration | null,
  featureFlags:  Array<FeatureFlags >,
  createdAt: string,
  createdBy: string,
  updatedAt: string,
  statusChangedAt: string,
  statusChangedBy: string,
  ssoDomains?: Array< string | null > | null,
  integrations?: Array< string | null > | null,
  config: TenantConfig,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type FieldConfig = {
  __typename: "FieldConfig",
  fieldName: string,
  description?: string | null,
  required: boolean,
  isEmailTemplateFilter?: boolean | null,
  dataType: FIELD_DATA_TYPE,
  userFilter: boolean,
  defaultSearchFilter: boolean,
  values: Array< string >,
};

export type ListConfiguration = {
  __typename: "ListConfiguration",
  fields:  Array<ListConfigurationField >,
  sort?: ListConfigurationSort | null,
};

export type ListConfigurationField = {
  __typename: "ListConfigurationField",
  fieldName: string,
  header?: string | null,
  width?: number | null,
};

export type ListConfigurationSort = {
  __typename: "ListConfigurationSort",
  field: string,
  isAsc: boolean,
};

export type FeatureFlags = {
  __typename: "FeatureFlags",
  flagId: string,
  value: boolean,
};

export type TenantConfig = {
  __typename: "TenantConfig",
  requiredSlidesHiddenMessage?: string | null,
  forms?:  Array<TenantForm > | null,
  meetingFields?:  Array<MeetingFieldDefinition > | null,
  customFields?:  Array<CustomFieldDefinition > | null,
  defaultDocumentPermissions: DocumentPermission,
  mslListConfig?: ListConfig | null,
  publisherListConfig?: ListConfig | null,
  crmIntegration?: CRMIntegration | null,
  sessionTimeout?: number | null,
  quickFilters?:  Array<QuickFilter > | null,
  hubsConfig: HubConfig,
  reports:  Array<KeyValue >,
  emailDocumentUpdateFrequency?: FREQUENCY | null,
  sharePPTXasPDF?: boolean | null,
};

export type TenantForm = {
  __typename: "TenantForm",
  id: string,
  name: string,
  label: string,
  fields:  Array<CustomFieldDefinition >,
  targetRequestTemplate: string,
  targetType: TENANT_FORM_TARGET_TYPE,
  targetAddress: string,
  targetParameters?:  Array<TenantFormTargetParameters > | null,
  status: TENANT_FORM_STATUS,
};

export type CustomFieldDefinition = {
  __typename: "CustomFieldDefinition",
  id: string,
  fieldName: string,
  fieldLabel: string,
  reportingName?: string | null,
  status: FIELD_STATUS,
  description?: string | null,
  required?: boolean | null,
  maxLength?: number | null,
  formatValidation?: FORMAT_VALIDATION | null,
  fieldType: FIELD_DATA_TYPE,
  showByDefault?: boolean | null,
  dependentFieldId?: string | null,
  controlType: CONTROL_TYPE,
  order?: number | null,
  settings?: CommonFieldSettings | null,
  usage: Array< CUSTOM_FIELD_USAGE >,
  documentSettings?: DocumentFieldSettings | null,
  fieldValueDefinitions:  Array<CustomFieldValueDefinition >,
  displayOnParentSelection?: boolean | null,
  displayOnValueSelection?: Array< string > | null,
  objectSetting?: ObjectSetting | null,
  isChildField?: boolean | null,
  dateRestriction?: DATE_RESTRICTION | null,
  createdAt: string,
  scaleNumber?: number | null,
  precisionNumber?: number | null,
};

export type CommonFieldSettings = {
  __typename: "CommonFieldSettings",
  isBadge?: boolean | null,
  fieldPosition: FIELD_POSITION,
};

export type DocumentFieldSettings = {
  __typename: "DocumentFieldSettings",
  fieldPosition: FIELD_POSITION,
};

export type CustomFieldValueDefinition = {
  __typename: "CustomFieldValueDefinition",
  id: string,
  value: string,
  label?: string | null,
  disabled?: boolean | null,
  isDefault?: boolean | null,
  documentSettings?: DocumentFieldValueSettings | null,
  badgeColor?: string | null,
  badgeLabel?: string | null,
  createdAt: string,
  dependentValueIds: Array< string >,
};

export type DocumentFieldValueSettings = {
  __typename: "DocumentFieldValueSettings",
  presentationWatermarkText?: string | null,
  permission?: DocumentPermission | null,
};

export type DocumentPermission = {
  __typename: "DocumentPermission",
  present?: PERMISSION_EFFECT | null,
  modify?: PERMISSION_EFFECT | null,
  share?: PERMISSION_EFFECT | null,
  download?: PERMISSION_EFFECT | null,
  externalNotation?: PERMISSION_EFFECT | null,
};

export type ObjectSetting = {
  __typename: "ObjectSetting",
  childrenFieldIds: Array< string >,
  restriction?: ObjectRestriction | null,
};

export type ObjectRestriction = {
  __typename: "ObjectRestriction",
  syncUpdate?: boolean | null,
  syncDelete?: boolean | null,
  update?: boolean | null,
  delete?: boolean | null,
};

export type TenantFormTargetParameters = {
  __typename: "TenantFormTargetParameters",
  key: string,
  value: string,
};

export type MeetingFieldDefinition = {
  __typename: "MeetingFieldDefinition",
  id: string,
  fieldName: string,
  status: FIELD_STATUS,
  description?: string | null,
  required: boolean,
  maxLength?: number | null,
  type: FIELD_DATA_TYPE,
  controlType: CONTROL_TYPE,
  fieldValueDefinitions:  Array<MeetingFieldValueDefinition >,
  createdAt: string,
};

export type MeetingFieldValueDefinition = {
  __typename: "MeetingFieldValueDefinition",
  id: string,
  value: string,
  disabled: boolean,
  createdAt: string,
};

export type ListConfig = {
  __typename: "ListConfig",
  sort?: ListSort | null,
  fields:  Array<FieldListConfig >,
};

export type ListSort = {
  __typename: "ListSort",
  field: string,
  isAsc?: boolean | null,
};

export type FieldListConfig = {
  __typename: "FieldListConfig",
  field: string,
  width?: number | null,
};

export type CRMIntegration = {
  __typename: "CRMIntegration",
  id: string,
  crmIntegrationType: CRM_INTEGRATION_TYPE,
  instanceUrl: string,
  clientId: string,
  redirectUri: string,
  name: string,
  accountsSettings: CRMAccountSettings,
  meetingSetting?: CRMTableSetting | null,
  additionalSettings?: CRMAdditionalSettings | null,
  loginUrl?: string | null,
  crmStandaloneForms?:  Array<CRMStandaloneForm > | null,
};

export type CRMAccountSettings = {
  __typename: "CRMAccountSettings",
  query: string,
  displaySettings: CRMDisplaySettings,
  addressSettings: CRMAddressSettings,
};

export type CRMDisplaySettings = {
  __typename: "CRMDisplaySettings",
  shortened: string,
  extended?: Array< string > | null,
};

export type CRMAddressSettings = {
  __typename: "CRMAddressSettings",
  alias: string,
  displaySettings: CRMDisplaySettings,
};

export type CRMTableSetting = {
  __typename: "CRMTableSetting",
  apiName: string,
  presentationsFieldName?: string | null,
  children:  Array<CRMChildTableSetting >,
  type: CRM_INTEGRATION_TYPE,
};

export type CRMChildTableSetting = {
  __typename: "CRMChildTableSetting",
  name: string,
  apiName: string,
  relationshipName?: string | null,
  type: CRM_INTEGRATION_TYPE,
  veevaSetting?: VeevaSetting | null,
};

export type VeevaSetting = {
  __typename: "VeevaSetting",
  markerFieldName?: string | null,
};

export type CRMAdditionalSettings = {
  __typename: "CRMAdditionalSettings",
  allowCommentsOnTasks?: boolean | null,
  detailContentTableSettings?: CRMTableDetailContentPresented | null,
  enableSurveys?: boolean | null,
  crmAccountToObjectSettings?:  Array<CRMAccountToObjectSetting > | null,
};

export type CRMTableDetailContentPresented = {
  __typename: "CRMTableDetailContentPresented",
  name: string,
  apiName: string,
  relationshipName: string,
  prefix?: string | null,
};

export type CRMAccountToObjectSetting = {
  __typename: "CRMAccountToObjectSetting",
  accountFieldNames:  Array<CRMAccountToObjectFields >,
  apiName: string,
};

export type CRMAccountToObjectFields = {
  __typename: "CRMAccountToObjectFields",
  accountFieldName: string,
  objectFieldName: string,
};

export type CRMStandaloneForm = {
  __typename: "CRMStandaloneForm",
  id: string,
  apiName: string,
  children:  Array<CRMChildTableSetting >,
  type: CRM_INTEGRATION_TYPE,
  parents:  Array<CRMStandaloneFormParent >,
  usage?: Array< STANDALONE_FORM_USAGE > | null,
};

export type CRMStandaloneFormParent = {
  __typename: "CRMStandaloneFormParent",
  apiName: string,
  relationshipName: string,
};

export type QuickFilter = {
  __typename: "QuickFilter",
  id: string,
  label: string,
  path: Array< string >,
};

export type HubConfig = {
  __typename: "HubConfig",
  shareExpirationDays?: number | null,
  disabledSections: Array< HUB_SECTION_TYPE >,
  disableNotationDescription: boolean,
};

export type KeyValue = {
  __typename: "KeyValue",
  key: string,
  value: boolean,
};

export type UpdateTenantInput = {
  id: string,
  name?: string | null,
  licensedUsers?: number | null,
  status?: TENANT_STATUS | null,
  fields?: Array< FieldConfigInput > | null,
  folderUpdateGracePeriodDays?: number | null,
  medInfoURL?: string | null,
  publisherListConfig?: ListConfigurationInput | null,
  mslListConfig?: ListConfigurationInput | null,
  featureFlags?: Array< FeatureFlagsInput > | null,
  createdAt?: string | null,
  createdBy?: string | null,
  updatedAt?: string | null,
  statusChangedAt?: string | null,
  statusChangedBy?: string | null,
  ssoDomains?: Array< string | null > | null,
  integrations?: Array< string | null > | null,
  config?: TenantConfigInput | null,
  _version?: number | null,
};

export type DeleteTenantInput = {
  id: string,
  _version?: number | null,
};

export type CreateDocumentVersionInput = {
  id?: string | null,
  tenantId: string,
  documentId: string,
  versionNumber: number,
  srcFilename?: string | null,
  contentURL?: string | null,
  conversionStatus: CONVERSION_STATUS,
  status: DOCUMENT_STATUS,
  srcFile?: S3ObjectInput | null,
  srcHash?: string | null,
  srcSize?: number | null,
  numPages?: number | null,
  pageSettings: Array< PageSettingInput >,
  pageGroups?: Array< PageGroupInput > | null,
  type: FILE_TYPE,
  releaseNotes?: string | null,
  changeType?: DOCUMENT_VERSION_CHANGE_TYPE | null,
  labelValues?: Array< LabelValueInput > | null,
  customValues?: Array< CustomValuesInput > | null,
  title?: string | null,
  shortDescription?: string | null,
  longDescription?: string | null,
  owner?: string | null,
  expiresAt?: string | null,
  hasCopyright?: boolean | null,
  hasCustomThumbnail?: boolean | null,
  purpose?: string | null,
  canHideSlides?: boolean | null,
  distributable?: boolean | null,
  downloadable?: boolean | null,
  isInternalGenerated?: boolean | null,
  semVer?: SemVerInput | null,
  notificationScope?: NOTIFICATION_SCOPE | null,
  selectedThumbnail?: number | null,
  publishedAt?: string | null,
  uploadedAt?: string | null,
  uploadedBy?: string | null,
  convertedArchiveKey?: string | null,
  convertedArchiveSize?: number | null,
  convertedFolderKey?: string | null,
  associatedFiles?: Array< AssociatedFileInput > | null,
  editPermissions: Array< string >,
  converterVersion?: number | null,
  createdAt?: string | null,
  createdBy: string,
  updatedAt?: string | null,
  updatedBy: string,
  integration?: DocumentVersionIntegrationInput | null,
  integrationType?: INTEGRATION_TYPE | null,
  conversionWarningCode?: CONVERSION_WARNING_CODE | null,
  conversionErrorCode?: string | null,
  scheduledPublish?: string | null,
  mappingStatus?: MAPPING_STATUS | null,
  _version?: number | null,
};

export enum CONVERSION_STATUS {
  PENDING = "PENDING",
  PROCESSING = "PROCESSING",
  PROCESSED = "PROCESSED",
  ERROR = "ERROR",
}


export enum DOCUMENT_STATUS {
  ARCHIVED = "ARCHIVED",
  REVOKED = "REVOKED",
  NOT_PUBLISHED = "NOT_PUBLISHED",
  PUBLISHED = "PUBLISHED",
  DELETED = "DELETED",
  ARCHIVED_WITH_VERSIONS = "ARCHIVED_WITH_VERSIONS",
  REVOKED_WITH_VERSIONS = "REVOKED_WITH_VERSIONS",
  DELETED_WITH_VERSIONS = "DELETED_WITH_VERSIONS",
}


export type S3ObjectInput = {
  bucket: string,
  region: string,
  key: string,
  url: string,
};

export type PageSettingInput = {
  pageId: string,
  number: number,
  isRequired?: boolean | null,
  linkedSlides: Array< string >,
};

export type PageGroupInput = {
  id?: string | null,
  pageIds?: Array< string > | null,
  name: string,
  locked: boolean,
  sourceID?: string | null,
  source: PAGE_GROUP_SOURCE,
};

export enum PAGE_GROUP_SOURCE {
  USER = "USER",
  DOCUMENT = "DOCUMENT",
}


export enum FILE_TYPE {
  JPG = "JPG",
  JPEG = "JPEG",
  XLS = "XLS",
  XLSX = "XLSX",
  XLSM = "XLSM",
  DOC = "DOC",
  DOCX = "DOCX",
  PDF = "PDF",
  PPTX = "PPTX",
  PNG = "PNG",
  MP4 = "MP4",
  WEB = "WEB",
  HTML = "HTML",
  ZIP = "ZIP",
}


export enum DOCUMENT_VERSION_CHANGE_TYPE {
  MAJOR = "MAJOR",
  MINOR = "MINOR",
}


export type LabelValueInput = {
  key: string,
  value: string,
};

export type SemVerInput = {
  minor: number,
  major: number,
};

export enum NOTIFICATION_SCOPE {
  NONE = "NONE",
  EMAIL = "EMAIL",
  EMAIL_IMMEDIATE = "EMAIL_IMMEDIATE",
}


export type AssociatedFileInput = {
  id?: string | null,
  isDistributable?: boolean | null,
  isDefault?: boolean | null,
  type: ASSOCIATED_FILE_TYPE,
  attachmentId: string,
  status: ASSOCIATED_FILE_STATUS,
  createdAt?: string | null,
  createdBy: string,
};

export enum ASSOCIATED_FILE_TYPE {
  DOCUMENT = "DOCUMENT",
  ATTACHED_FILE = "ATTACHED_FILE",
}


export enum ASSOCIATED_FILE_STATUS {
  ACTIVE = "ACTIVE",
  DELETED = "DELETED",
}


export type DocumentVersionIntegrationInput = {
  externalVersionId?: string | null,
  version?: string | null,
  timestamp?: string | null,
  srcFileHash?: string | null,
  srcDocumentHash?: string | null,
};

export enum INTEGRATION_TYPE {
  VEEVA = "VEEVA",
  BEACON = "BEACON",
  EXPORT_TO_S3 = "EXPORT_TO_S3",
  NONE = "NONE",
}


export enum CONVERSION_WARNING_CODE {
  PASSWORD_PROTECTED = "PASSWORD_PROTECTED",
}


export enum MAPPING_STATUS {
  HAS_MAPPINGS = "HAS_MAPPINGS",
  NO_MAPPINGS = "NO_MAPPINGS",
}


export type ModelDocumentVersionConditionInput = {
  tenantId?: ModelIDInput | null,
  documentId?: ModelIDInput | null,
  versionNumber?: ModelIntInput | null,
  srcFilename?: ModelStringInput | null,
  contentURL?: ModelStringInput | null,
  conversionStatus?: ModelCONVERSION_STATUSInput | null,
  status?: ModelDOCUMENT_STATUSInput | null,
  srcHash?: ModelStringInput | null,
  srcSize?: ModelFloatInput | null,
  numPages?: ModelIntInput | null,
  type?: ModelFILE_TYPEInput | null,
  releaseNotes?: ModelStringInput | null,
  changeType?: ModelDOCUMENT_VERSION_CHANGE_TYPEInput | null,
  title?: ModelStringInput | null,
  shortDescription?: ModelStringInput | null,
  longDescription?: ModelStringInput | null,
  owner?: ModelStringInput | null,
  expiresAt?: ModelStringInput | null,
  hasCopyright?: ModelBooleanInput | null,
  hasCustomThumbnail?: ModelBooleanInput | null,
  purpose?: ModelStringInput | null,
  canHideSlides?: ModelBooleanInput | null,
  distributable?: ModelBooleanInput | null,
  downloadable?: ModelBooleanInput | null,
  isInternalGenerated?: ModelBooleanInput | null,
  notificationScope?: ModelNOTIFICATION_SCOPEInput | null,
  selectedThumbnail?: ModelIntInput | null,
  publishedAt?: ModelStringInput | null,
  uploadedAt?: ModelStringInput | null,
  uploadedBy?: ModelStringInput | null,
  convertedArchiveKey?: ModelStringInput | null,
  convertedArchiveSize?: ModelFloatInput | null,
  convertedFolderKey?: ModelStringInput | null,
  editPermissions?: ModelStringInput | null,
  converterVersion?: ModelIntInput | null,
  createdAt?: ModelStringInput | null,
  createdBy?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  updatedBy?: ModelStringInput | null,
  integrationType?: ModelINTEGRATION_TYPEInput | null,
  conversionWarningCode?: ModelCONVERSION_WARNING_CODEInput | null,
  conversionErrorCode?: ModelStringInput | null,
  scheduledPublish?: ModelStringInput | null,
  mappingStatus?: ModelMAPPING_STATUSInput | null,
  and?: Array< ModelDocumentVersionConditionInput | null > | null,
  or?: Array< ModelDocumentVersionConditionInput | null > | null,
  not?: ModelDocumentVersionConditionInput | null,
};

export type ModelCONVERSION_STATUSInput = {
  eq?: CONVERSION_STATUS | null,
  ne?: CONVERSION_STATUS | null,
};

export type ModelDOCUMENT_STATUSInput = {
  eq?: DOCUMENT_STATUS | null,
  ne?: DOCUMENT_STATUS | null,
};

export type ModelFloatInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type ModelFILE_TYPEInput = {
  eq?: FILE_TYPE | null,
  ne?: FILE_TYPE | null,
};

export type ModelDOCUMENT_VERSION_CHANGE_TYPEInput = {
  eq?: DOCUMENT_VERSION_CHANGE_TYPE | null,
  ne?: DOCUMENT_VERSION_CHANGE_TYPE | null,
};

export type ModelNOTIFICATION_SCOPEInput = {
  eq?: NOTIFICATION_SCOPE | null,
  ne?: NOTIFICATION_SCOPE | null,
};

export type ModelINTEGRATION_TYPEInput = {
  eq?: INTEGRATION_TYPE | null,
  ne?: INTEGRATION_TYPE | null,
};

export type ModelCONVERSION_WARNING_CODEInput = {
  eq?: CONVERSION_WARNING_CODE | null,
  ne?: CONVERSION_WARNING_CODE | null,
};

export type ModelMAPPING_STATUSInput = {
  eq?: MAPPING_STATUS | null,
  ne?: MAPPING_STATUS | null,
};

export type DocumentVersion = {
  __typename: "DocumentVersion",
  id: string,
  tenantId: string,
  documentId: string,
  versionNumber: number,
  srcFilename?: string | null,
  contentURL?: string | null,
  conversionStatus: CONVERSION_STATUS,
  status: DOCUMENT_STATUS,
  srcFile?: S3Object | null,
  srcHash?: string | null,
  srcSize?: number | null,
  numPages?: number | null,
  pageSettings:  Array<PageSetting >,
  pageGroups?:  Array<PageGroup > | null,
  type: FILE_TYPE,
  releaseNotes?: string | null,
  changeType?: DOCUMENT_VERSION_CHANGE_TYPE | null,
  labelValues?:  Array<LabelValue > | null,
  customValues?:  Array<CustomValues > | null,
  title?: string | null,
  shortDescription?: string | null,
  longDescription?: string | null,
  owner?: string | null,
  expiresAt?: string | null,
  hasCopyright?: boolean | null,
  hasCustomThumbnail?: boolean | null,
  purpose?: string | null,
  canHideSlides?: boolean | null,
  distributable?: boolean | null,
  downloadable?: boolean | null,
  isInternalGenerated?: boolean | null,
  semVer?: SemVer | null,
  notificationScope?: NOTIFICATION_SCOPE | null,
  selectedThumbnail?: number | null,
  publishedAt?: string | null,
  uploadedAt?: string | null,
  uploadedBy?: string | null,
  convertedArchiveKey?: string | null,
  convertedArchiveSize?: number | null,
  convertedFolderKey?: string | null,
  associatedFiles?:  Array<AssociatedFile > | null,
  editPermissions: Array< string >,
  converterVersion?: number | null,
  createdAt: string,
  createdBy: string,
  updatedAt: string,
  updatedBy: string,
  integration?: DocumentVersionIntegration | null,
  integrationType?: INTEGRATION_TYPE | null,
  conversionWarningCode?: CONVERSION_WARNING_CODE | null,
  conversionErrorCode?: string | null,
  scheduledPublish?: string | null,
  mappingStatus?: MAPPING_STATUS | null,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type S3Object = {
  __typename: "S3Object",
  bucket: string,
  region: string,
  key: string,
  url: string,
};

export type PageSetting = {
  __typename: "PageSetting",
  pageId: string,
  number: number,
  isRequired?: boolean | null,
  linkedSlides: Array< string >,
};

export type PageGroup = {
  __typename: "PageGroup",
  id: string,
  pageIds?: Array< string > | null,
  name: string,
  locked: boolean,
  sourceID?: string | null,
  source: PAGE_GROUP_SOURCE,
};

export type LabelValue = {
  __typename: "LabelValue",
  key: string,
  value: string,
};

export type SemVer = {
  __typename: "SemVer",
  minor: number,
  major: number,
};

export type AssociatedFile = {
  __typename: "AssociatedFile",
  id: string,
  isDistributable?: boolean | null,
  isDefault?: boolean | null,
  type: ASSOCIATED_FILE_TYPE,
  attachmentId: string,
  status: ASSOCIATED_FILE_STATUS,
  createdAt: string,
  createdBy: string,
};

export type DocumentVersionIntegration = {
  __typename: "DocumentVersionIntegration",
  externalVersionId?: string | null,
  version?: string | null,
  timestamp?: string | null,
  srcFileHash?: string | null,
  srcDocumentHash?: string | null,
};

export type UpdateDocumentVersionInput = {
  id: string,
  tenantId?: string | null,
  documentId?: string | null,
  versionNumber?: number | null,
  srcFilename?: string | null,
  contentURL?: string | null,
  conversionStatus?: CONVERSION_STATUS | null,
  status?: DOCUMENT_STATUS | null,
  srcFile?: S3ObjectInput | null,
  srcHash?: string | null,
  srcSize?: number | null,
  numPages?: number | null,
  pageSettings?: Array< PageSettingInput > | null,
  pageGroups?: Array< PageGroupInput > | null,
  type?: FILE_TYPE | null,
  releaseNotes?: string | null,
  changeType?: DOCUMENT_VERSION_CHANGE_TYPE | null,
  labelValues?: Array< LabelValueInput > | null,
  customValues?: Array< CustomValuesInput > | null,
  title?: string | null,
  shortDescription?: string | null,
  longDescription?: string | null,
  owner?: string | null,
  expiresAt?: string | null,
  hasCopyright?: boolean | null,
  hasCustomThumbnail?: boolean | null,
  purpose?: string | null,
  canHideSlides?: boolean | null,
  distributable?: boolean | null,
  downloadable?: boolean | null,
  isInternalGenerated?: boolean | null,
  semVer?: SemVerInput | null,
  notificationScope?: NOTIFICATION_SCOPE | null,
  selectedThumbnail?: number | null,
  publishedAt?: string | null,
  uploadedAt?: string | null,
  uploadedBy?: string | null,
  convertedArchiveKey?: string | null,
  convertedArchiveSize?: number | null,
  convertedFolderKey?: string | null,
  associatedFiles?: Array< AssociatedFileInput > | null,
  editPermissions?: Array< string > | null,
  converterVersion?: number | null,
  createdAt?: string | null,
  createdBy?: string | null,
  updatedAt?: string | null,
  updatedBy?: string | null,
  integration?: DocumentVersionIntegrationInput | null,
  integrationType?: INTEGRATION_TYPE | null,
  conversionWarningCode?: CONVERSION_WARNING_CODE | null,
  conversionErrorCode?: string | null,
  scheduledPublish?: string | null,
  mappingStatus?: MAPPING_STATUS | null,
  _version?: number | null,
};

export type DeleteDocumentVersionInput = {
  id: string,
  _version?: number | null,
};

export type CreateAttachedFileInput = {
  id?: string | null,
  title: string,
  tenantId: string,
  documentId?: string | null,
  srcFile: S3ObjectInput,
  srcFileName: string,
  srcHash?: string | null,
  srcSize: number,
  type: FILE_TYPE,
  editPermissions: Array< string >,
  createdAt?: string | null,
  createdBy: string,
  updatedAt?: string | null,
  _version?: number | null,
};

export type ModelAttachedFileConditionInput = {
  title?: ModelStringInput | null,
  tenantId?: ModelStringInput | null,
  documentId?: ModelIDInput | null,
  srcFileName?: ModelStringInput | null,
  srcHash?: ModelStringInput | null,
  srcSize?: ModelFloatInput | null,
  type?: ModelFILE_TYPEInput | null,
  editPermissions?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  createdBy?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelAttachedFileConditionInput | null > | null,
  or?: Array< ModelAttachedFileConditionInput | null > | null,
  not?: ModelAttachedFileConditionInput | null,
};

export type AttachedFile = {
  __typename: "AttachedFile",
  id: string,
  title: string,
  tenantId: string,
  documentId?: string | null,
  srcFile: S3Object,
  srcFileName: string,
  srcHash?: string | null,
  srcSize: number,
  type: FILE_TYPE,
  editPermissions: Array< string >,
  createdAt: string,
  createdBy: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type UpdateAttachedFileInput = {
  id: string,
  title?: string | null,
  tenantId?: string | null,
  documentId?: string | null,
  srcFile?: S3ObjectInput | null,
  srcFileName?: string | null,
  srcHash?: string | null,
  srcSize?: number | null,
  type?: FILE_TYPE | null,
  editPermissions?: Array< string > | null,
  createdAt?: string | null,
  createdBy?: string | null,
  updatedAt?: string | null,
  _version?: number | null,
};

export type DeleteAttachedFileInput = {
  id: string,
  _version?: number | null,
};

export type CreateDocumentInput = {
  id?: string | null,
  tenantId: string,
  accessLevel: DOCUMENT_ACCESS_LEVEL,
  status: DOCUMENT_STATUS,
  owner: string,
  expiresAt?: string | null,
  integration?: DocumentIntegrationInput | null,
  integrationType?: INTEGRATION_TYPE | null,
  type: FILE_TYPE,
  editPermissions?: Array< string > | null,
  createdAt?: string | null,
  createdBy: string,
  updatedAt?: string | null,
  updatedBy: string,
  _version?: number | null,
};

export enum DOCUMENT_ACCESS_LEVEL {
  TENANT = "TENANT",
  USER = "USER",
}


export type DocumentIntegrationInput = {
  integrationId?: string | null,
  externalId?: string | null,
  firstSync?: string | null,
  lastSync?: string | null,
};

export type ModelDocumentConditionInput = {
  tenantId?: ModelIDInput | null,
  accessLevel?: ModelDOCUMENT_ACCESS_LEVELInput | null,
  status?: ModelDOCUMENT_STATUSInput | null,
  owner?: ModelStringInput | null,
  expiresAt?: ModelStringInput | null,
  integrationType?: ModelINTEGRATION_TYPEInput | null,
  type?: ModelFILE_TYPEInput | null,
  editPermissions?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  createdBy?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  updatedBy?: ModelStringInput | null,
  and?: Array< ModelDocumentConditionInput | null > | null,
  or?: Array< ModelDocumentConditionInput | null > | null,
  not?: ModelDocumentConditionInput | null,
};

export type ModelDOCUMENT_ACCESS_LEVELInput = {
  eq?: DOCUMENT_ACCESS_LEVEL | null,
  ne?: DOCUMENT_ACCESS_LEVEL | null,
};

export type Document = {
  __typename: "Document",
  id: string,
  tenantId: string,
  accessLevel: DOCUMENT_ACCESS_LEVEL,
  status: DOCUMENT_STATUS,
  owner: string,
  expiresAt?: string | null,
  integration?: DocumentIntegration | null,
  integrationType?: INTEGRATION_TYPE | null,
  type: FILE_TYPE,
  editPermissions?: Array< string > | null,
  createdAt: string,
  createdBy: string,
  updatedAt: string,
  updatedBy: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type DocumentIntegration = {
  __typename: "DocumentIntegration",
  integrationId?: string | null,
  externalId?: string | null,
  firstSync?: string | null,
  lastSync?: string | null,
};

export type UpdateDocumentInput = {
  id: string,
  tenantId?: string | null,
  accessLevel?: DOCUMENT_ACCESS_LEVEL | null,
  status?: DOCUMENT_STATUS | null,
  owner?: string | null,
  expiresAt?: string | null,
  integration?: DocumentIntegrationInput | null,
  integrationType?: INTEGRATION_TYPE | null,
  type?: FILE_TYPE | null,
  editPermissions?: Array< string > | null,
  createdAt?: string | null,
  createdBy?: string | null,
  updatedAt?: string | null,
  updatedBy?: string | null,
  _version?: number | null,
};

export type DeleteDocumentInput = {
  id: string,
  _version?: number | null,
};

export type CreateEmailTemplateInput = {
  id?: string | null,
  tenantId: string,
  labelValues?: Array< LabelValueInput > | null,
  customFilterValues?: Array< CustomValuesInput > | null,
  subject?: string | null,
  title?: string | null,
  cc?: Array< string > | null,
  bcc?: Array< string > | null,
  body?: string | null,
  associatedFiles?: Array< AssociatedFileInput > | null,
  status: EMAIL_TEMPLATE_STATUS,
  editPermissions?: Array< string > | null,
  createdBy: string,
  createdAt?: string | null,
  updatedBy: string,
  updatedAt?: string | null,
  _version?: number | null,
};

export enum EMAIL_TEMPLATE_STATUS {
  DRAFT = "DRAFT",
  ACTIVE = "ACTIVE",
  DEACTIVATED = "DEACTIVATED",
  REMOVED = "REMOVED",
}


export type ModelEmailTemplateConditionInput = {
  tenantId?: ModelIDInput | null,
  subject?: ModelStringInput | null,
  title?: ModelStringInput | null,
  cc?: ModelStringInput | null,
  bcc?: ModelStringInput | null,
  body?: ModelStringInput | null,
  status?: ModelEMAIL_TEMPLATE_STATUSInput | null,
  editPermissions?: ModelStringInput | null,
  createdBy?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedBy?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelEmailTemplateConditionInput | null > | null,
  or?: Array< ModelEmailTemplateConditionInput | null > | null,
  not?: ModelEmailTemplateConditionInput | null,
};

export type ModelEMAIL_TEMPLATE_STATUSInput = {
  eq?: EMAIL_TEMPLATE_STATUS | null,
  ne?: EMAIL_TEMPLATE_STATUS | null,
};

export type EmailTemplate = {
  __typename: "EmailTemplate",
  id: string,
  tenantId: string,
  labelValues?:  Array<LabelValue > | null,
  customFilterValues?:  Array<CustomValues > | null,
  subject?: string | null,
  title?: string | null,
  cc?: Array< string > | null,
  bcc?: Array< string > | null,
  body?: string | null,
  associatedFiles?:  Array<AssociatedFile > | null,
  status: EMAIL_TEMPLATE_STATUS,
  editPermissions?: Array< string > | null,
  createdBy: string,
  createdAt: string,
  updatedBy: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type UpdateEmailTemplateInput = {
  id: string,
  tenantId?: string | null,
  labelValues?: Array< LabelValueInput > | null,
  customFilterValues?: Array< CustomValuesInput > | null,
  subject?: string | null,
  title?: string | null,
  cc?: Array< string > | null,
  bcc?: Array< string > | null,
  body?: string | null,
  associatedFiles?: Array< AssociatedFileInput > | null,
  status?: EMAIL_TEMPLATE_STATUS | null,
  editPermissions?: Array< string > | null,
  createdBy?: string | null,
  createdAt?: string | null,
  updatedBy?: string | null,
  updatedAt?: string | null,
  _version?: number | null,
};

export type DeleteEmailTemplateInput = {
  id: string,
  _version?: number | null,
};

export type CreateDocumentSettingInput = {
  id?: string | null,
  tenantId: string,
  createdAt?: string | null,
  createdBy: string,
  updatedAt?: string | null,
  updatedBy: string,
  documentId: string,
  documentVersionId: string,
  notation: Array< NotationInput >,
  status: USER_NOTATIONS_STATUS,
  _version?: number | null,
};

export type NotationInput = {
  id?: string | null,
  type: NOTATION_TYPE,
  description?: string | null,
  status: HUB_SECTION_ITEM_STATUS,
  pageId: string,
  coordinate?: Array< CoordinateInput > | null,
  createdAt?: string | null,
  createdBy: string,
  updatedAt?: string | null,
  updatedBy: string,
};

export enum NOTATION_TYPE {
  CALLOUT = "CALLOUT",
  PAGE_NOTE = "PAGE_NOTE",
  TEXT_INSERTION = "TEXT_INSERTION",
}


export enum HUB_SECTION_ITEM_STATUS {
  ACTIVE = "ACTIVE",
  DELETED = "DELETED",
}


export type CoordinateInput = {
  x: number,
  y: number,
};

export enum USER_NOTATIONS_STATUS {
  ACTIVE = "ACTIVE",
  DELETED = "DELETED",
}


export type ModelDocumentSettingConditionInput = {
  tenantId?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  createdBy?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  updatedBy?: ModelStringInput | null,
  documentId?: ModelIDInput | null,
  documentVersionId?: ModelIDInput | null,
  status?: ModelUSER_NOTATIONS_STATUSInput | null,
  and?: Array< ModelDocumentSettingConditionInput | null > | null,
  or?: Array< ModelDocumentSettingConditionInput | null > | null,
  not?: ModelDocumentSettingConditionInput | null,
};

export type ModelUSER_NOTATIONS_STATUSInput = {
  eq?: USER_NOTATIONS_STATUS | null,
  ne?: USER_NOTATIONS_STATUS | null,
};

export type DocumentSetting = {
  __typename: "DocumentSetting",
  id: string,
  tenantId: string,
  createdAt: string,
  createdBy: string,
  updatedAt: string,
  updatedBy: string,
  documentId: string,
  documentVersionId: string,
  notation:  Array<Notation >,
  status: USER_NOTATIONS_STATUS,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type Notation = {
  __typename: "Notation",
  id: string,
  type: NOTATION_TYPE,
  description?: string | null,
  status: HUB_SECTION_ITEM_STATUS,
  pageId: string,
  coordinate?:  Array<Coordinate > | null,
  createdAt: string,
  createdBy: string,
  updatedAt: string,
  updatedBy: string,
};

export type Coordinate = {
  __typename: "Coordinate",
  x: number,
  y: number,
};

export type UpdateDocumentSettingInput = {
  id: string,
  tenantId?: string | null,
  createdAt?: string | null,
  createdBy?: string | null,
  updatedAt?: string | null,
  updatedBy?: string | null,
  documentId?: string | null,
  documentVersionId?: string | null,
  notation?: Array< NotationInput > | null,
  status?: USER_NOTATIONS_STATUS | null,
  _version?: number | null,
};

export type DeleteDocumentSettingInput = {
  id: string,
  _version?: number | null,
};

export type CreateUserNotationsInput = {
  id?: string | null,
  tenantId: string,
  createdAt?: string | null,
  createdBy: string,
  updatedAt?: string | null,
  updatedBy: string,
  documentId?: string | null,
  documentVersionId?: string | null,
  customDeckId?: string | null,
  notation: Array< NotationInput >,
  status: USER_NOTATIONS_STATUS,
  type: USER_NOTATIONS_TYPE,
  _version?: number | null,
};

export enum USER_NOTATIONS_TYPE {
  DOCUMENT_VERSION = "DOCUMENT_VERSION",
  CUSTOM_DECK = "CUSTOM_DECK",
}


export type ModelUserNotationsConditionInput = {
  tenantId?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  createdBy?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  updatedBy?: ModelStringInput | null,
  documentId?: ModelIDInput | null,
  documentVersionId?: ModelIDInput | null,
  customDeckId?: ModelIDInput | null,
  status?: ModelUSER_NOTATIONS_STATUSInput | null,
  type?: ModelUSER_NOTATIONS_TYPEInput | null,
  and?: Array< ModelUserNotationsConditionInput | null > | null,
  or?: Array< ModelUserNotationsConditionInput | null > | null,
  not?: ModelUserNotationsConditionInput | null,
};

export type ModelUSER_NOTATIONS_TYPEInput = {
  eq?: USER_NOTATIONS_TYPE | null,
  ne?: USER_NOTATIONS_TYPE | null,
};

export type UserNotations = {
  __typename: "UserNotations",
  id: string,
  tenantId: string,
  createdAt: string,
  createdBy: string,
  updatedAt: string,
  updatedBy: string,
  documentId?: string | null,
  documentVersionId?: string | null,
  customDeckId?: string | null,
  notation:  Array<Notation >,
  status: USER_NOTATIONS_STATUS,
  type: USER_NOTATIONS_TYPE,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type UpdateUserNotationsInput = {
  id: string,
  tenantId?: string | null,
  createdAt?: string | null,
  createdBy?: string | null,
  updatedAt?: string | null,
  updatedBy?: string | null,
  documentId?: string | null,
  documentVersionId?: string | null,
  customDeckId?: string | null,
  notation?: Array< NotationInput > | null,
  status?: USER_NOTATIONS_STATUS | null,
  type?: USER_NOTATIONS_TYPE | null,
  _version?: number | null,
};

export type DeleteUserNotationsInput = {
  id: string,
  _version?: number | null,
};

export type CreateCustomDeckInput = {
  id?: string | null,
  createdAt?: string | null,
  createdBy: string,
  autoUpdateAcknowledgedAt?: string | null,
  updatedAt?: string | null,
  updatedBy: string,
  tenantId: string,
  groups: Array< CustomDeckGroupInput >,
  title: string,
  editMutex?: EditMutexInput | null,
  sourceCustomDeckId?: string | null,
  _version?: number | null,
};

export type CustomDeckGroupInput = {
  id?: string | null,
  pages: Array< CustomDeckPageInput >,
  visible: boolean,
  srcId?: string | null,
  docAccessLevel: DOCUMENT_ACCESS_LEVEL,
  name?: string | null,
  locked?: boolean | null,
};

export type CustomDeckPageInput = {
  pageId: string,
  pageNumber: number,
  documentVersionId: string,
};

export type EditMutexInput = {
  userId: string,
  timeStarted: string,
  lastSeenAt: string,
};

export type ModelCustomDeckConditionInput = {
  createdAt?: ModelStringInput | null,
  createdBy?: ModelStringInput | null,
  autoUpdateAcknowledgedAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  updatedBy?: ModelStringInput | null,
  tenantId?: ModelIDInput | null,
  title?: ModelStringInput | null,
  sourceCustomDeckId?: ModelIDInput | null,
  and?: Array< ModelCustomDeckConditionInput | null > | null,
  or?: Array< ModelCustomDeckConditionInput | null > | null,
  not?: ModelCustomDeckConditionInput | null,
};

export type CustomDeck = {
  __typename: "CustomDeck",
  id: string,
  createdAt: string,
  createdBy: string,
  autoUpdateAcknowledgedAt?: string | null,
  updatedAt: string,
  updatedBy: string,
  tenantId: string,
  groups:  Array<CustomDeckGroup >,
  title: string,
  editMutex?: EditMutex | null,
  sourceCustomDeckId?: string | null,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type CustomDeckGroup = {
  __typename: "CustomDeckGroup",
  id: string,
  pages:  Array<CustomDeckPage >,
  visible: boolean,
  srcId?: string | null,
  docAccessLevel: DOCUMENT_ACCESS_LEVEL,
  name?: string | null,
  locked?: boolean | null,
};

export type CustomDeckPage = {
  __typename: "CustomDeckPage",
  pageId: string,
  pageNumber: number,
  documentVersionId: string,
};

export type EditMutex = {
  __typename: "EditMutex",
  userId: string,
  timeStarted: string,
  lastSeenAt: string,
};

export type UpdateCustomDeckInput = {
  id: string,
  createdAt?: string | null,
  createdBy?: string | null,
  autoUpdateAcknowledgedAt?: string | null,
  updatedAt?: string | null,
  updatedBy?: string | null,
  tenantId?: string | null,
  groups?: Array< CustomDeckGroupInput > | null,
  title?: string | null,
  editMutex?: EditMutexInput | null,
  sourceCustomDeckId?: string | null,
  _version?: number | null,
};

export type DeleteCustomDeckInput = {
  id: string,
  _version?: number | null,
};

export type CreateFolderInput = {
  id?: string | null,
  tenantId: string,
  name: string,
  isPinned?: boolean | null,
  status: FOLDER_STATUS,
  items: Array< FolderItemInput >,
  createdAt?: string | null,
  createdBy: string,
  updatedAt?: string | null,
  updatedBy: string,
  sharePermissions?: Array< SharePermissionInput > | null,
  shareStatus: SHARE_STATUS,
  _version?: number | null,
};

export enum FOLDER_STATUS {
  ACTIVE = "ACTIVE",
  ARCHIVED = "ARCHIVED",
  REMOVED = "REMOVED",
}


export type FolderItemInput = {
  id: string,
  type: FOLDER_ITEM_TYPE,
  itemId: string,
  status: FOLDER_ITEM_STATUS,
  addedAt: string,
  updateAcknowledgedAt?: string | null,
  itemLastUpdatedAt: string,
  visiblePages?: Array< number > | null,
  customTitle?: string | null,
};

export enum FOLDER_ITEM_TYPE {
  FOLDER = "FOLDER",
  DOCUMENT_VERSION = "DOCUMENT_VERSION",
  CUSTOM_DECK = "CUSTOM_DECK",
}


export enum FOLDER_ITEM_STATUS {
  ACTIVE = "ACTIVE",
  REMOVED = "REMOVED",
}


export type SharePermissionInput = {
  id: string,
  isDeleted?: boolean | null,
  type: PERMISSION_TYPE,
  targetType: SHARE_TARGET_TYPE,
  targetUsername?: string | null,
  targetCustomValues?: Array< CustomValuesInput > | null,
  targetLabels?: Array< LabelValueInput > | null,
  createdAt?: string | null,
  createdBy?: string | null,
  updatedAt: string,
  updatedBy: string,
  deletedAt?: string | null,
  deletedBy?: string | null,
};

export enum PERMISSION_TYPE {
  VIEW = "VIEW",
  EDIT = "EDIT",
}


export enum SHARE_TARGET_TYPE {
  USER = "USER",
  FILTER = "FILTER",
  ALL = "ALL",
}


export enum SHARE_STATUS {
  IS_SHARED = "IS_SHARED",
  NOT_SHARED = "NOT_SHARED",
}


export type ModelFolderConditionInput = {
  tenantId?: ModelIDInput | null,
  name?: ModelStringInput | null,
  isPinned?: ModelBooleanInput | null,
  status?: ModelFOLDER_STATUSInput | null,
  createdAt?: ModelStringInput | null,
  createdBy?: ModelIDInput | null,
  updatedAt?: ModelStringInput | null,
  updatedBy?: ModelStringInput | null,
  shareStatus?: ModelSHARE_STATUSInput | null,
  and?: Array< ModelFolderConditionInput | null > | null,
  or?: Array< ModelFolderConditionInput | null > | null,
  not?: ModelFolderConditionInput | null,
};

export type ModelFOLDER_STATUSInput = {
  eq?: FOLDER_STATUS | null,
  ne?: FOLDER_STATUS | null,
};

export type ModelSHARE_STATUSInput = {
  eq?: SHARE_STATUS | null,
  ne?: SHARE_STATUS | null,
};

export type Folder = {
  __typename: "Folder",
  id: string,
  tenantId: string,
  name: string,
  isPinned?: boolean | null,
  status: FOLDER_STATUS,
  items:  Array<FolderItem >,
  createdAt: string,
  createdBy: string,
  updatedAt: string,
  updatedBy: string,
  sharePermissions?:  Array<SharePermission > | null,
  shareStatus: SHARE_STATUS,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type FolderItem = {
  __typename: "FolderItem",
  id: string,
  type: FOLDER_ITEM_TYPE,
  itemId: string,
  status?: FOLDER_ITEM_STATUS | null,
  addedAt: string,
  updateAcknowledgedAt?: string | null,
  itemLastUpdatedAt: string,
  visiblePages?: Array< number > | null,
  customTitle?: string | null,
};

export type SharePermission = {
  __typename: "SharePermission",
  id: string,
  isDeleted?: boolean | null,
  type: PERMISSION_TYPE,
  targetType: SHARE_TARGET_TYPE,
  targetUsername?: string | null,
  targetCustomValues?:  Array<CustomValues > | null,
  targetLabels?:  Array<LabelValues > | null,
  createdAt: string,
  createdBy: string,
  updatedAt: string,
  updatedBy: string,
  deletedBy?: string | null,
  deletedAt?: string | null,
};

export type UpdateFolderInput = {
  id: string,
  tenantId?: string | null,
  name?: string | null,
  isPinned?: boolean | null,
  status?: FOLDER_STATUS | null,
  items?: Array< FolderItemInput > | null,
  createdAt?: string | null,
  createdBy?: string | null,
  updatedAt?: string | null,
  updatedBy?: string | null,
  sharePermissions?: Array< SharePermissionInput > | null,
  shareStatus?: SHARE_STATUS | null,
  _version?: number | null,
};

export type DeleteFolderInput = {
  id: string,
  _version?: number | null,
};

export type CreateContentShareInput = {
  id?: string | null,
  token: string,
  tenantId: string,
  type: SHARE_TYPE,
  contentId: string,
  meetingId?: string | null,
  expiresAt: string,
  createdAt?: string | null,
  updatedAt?: string | null,
  createdBy: string,
  customValues: Array< CustomValuesInput >,
  revoked?: boolean | null,
  customDeckDependencies?: CustomDeckDependenciesInput | null,
  notificationScope?: SHARE_NOTIFICATION_SCOPE | null,
  _version?: number | null,
};

export enum SHARE_TYPE {
  DOCUMENT_VERSION = "DOCUMENT_VERSION",
  RECORDING = "RECORDING",
  ATTACHED_FILE = "ATTACHED_FILE",
  HUB = "HUB",
  CUSTOM_DECK = "CUSTOM_DECK",
}


export type CustomDeckDependenciesInput = {
  folderId: string,
  versions?: Array< string > | null,
};

export enum SHARE_NOTIFICATION_SCOPE {
  EMAIL = "EMAIL",
  NONE = "NONE",
}


export type ModelContentShareConditionInput = {
  token?: ModelIDInput | null,
  tenantId?: ModelIDInput | null,
  type?: ModelSHARE_TYPEInput | null,
  contentId?: ModelIDInput | null,
  meetingId?: ModelStringInput | null,
  expiresAt?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  createdBy?: ModelIDInput | null,
  revoked?: ModelBooleanInput | null,
  notificationScope?: ModelSHARE_NOTIFICATION_SCOPEInput | null,
  and?: Array< ModelContentShareConditionInput | null > | null,
  or?: Array< ModelContentShareConditionInput | null > | null,
  not?: ModelContentShareConditionInput | null,
};

export type ModelSHARE_TYPEInput = {
  eq?: SHARE_TYPE | null,
  ne?: SHARE_TYPE | null,
};

export type ModelSHARE_NOTIFICATION_SCOPEInput = {
  eq?: SHARE_NOTIFICATION_SCOPE | null,
  ne?: SHARE_NOTIFICATION_SCOPE | null,
};

export type ContentShare = {
  __typename: "ContentShare",
  id: string,
  token: string,
  tenantId: string,
  type: SHARE_TYPE,
  contentId: string,
  meetingId?: string | null,
  expiresAt: string,
  createdAt: string,
  updatedAt: string,
  createdBy: string,
  customValues:  Array<CustomValues >,
  revoked?: boolean | null,
  customDeckDependencies?: CustomDeckDependencies | null,
  notificationScope?: SHARE_NOTIFICATION_SCOPE | null,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type CustomDeckDependencies = {
  __typename: "CustomDeckDependencies",
  versions: Array< string >,
  folderId: string,
};

export type UpdateContentShareInput = {
  id: string,
  token?: string | null,
  tenantId?: string | null,
  type?: SHARE_TYPE | null,
  contentId?: string | null,
  meetingId?: string | null,
  expiresAt?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  createdBy?: string | null,
  customValues?: Array< CustomValuesInput > | null,
  revoked?: boolean | null,
  customDeckDependencies?: CustomDeckDependenciesInput | null,
  notificationScope?: SHARE_NOTIFICATION_SCOPE | null,
  _version?: number | null,
};

export type DeleteContentShareInput = {
  id: string,
  _version?: number | null,
};

export type CreateIntegrationLogInput = {
  integrationId: string,
  id?: string | null,
  log: Array< IntegrationLogEntryInput | null >,
  status: INTEGRATION_RUN_STATUS,
  tenantId: string,
  _version?: number | null,
};

export type IntegrationLogEntryInput = {
  level: LOG_LEVEL,
  timestamp: string,
  message: string,
  srcDocId: string,
  srcDocVersion: string,
};

export enum LOG_LEVEL {
  WARNING = "WARNING",
  ERROR = "ERROR",
  SUCCESS = "SUCCESS",
  INFO = "INFO",
}


export enum INTEGRATION_RUN_STATUS {
  RUNNING = "RUNNING",
  COMPLETED = "COMPLETED",
  WARNING = "WARNING",
  ERROR = "ERROR",
}


export type ModelIntegrationLogConditionInput = {
  integrationId?: ModelIDInput | null,
  status?: ModelINTEGRATION_RUN_STATUSInput | null,
  tenantId?: ModelIDInput | null,
  and?: Array< ModelIntegrationLogConditionInput | null > | null,
  or?: Array< ModelIntegrationLogConditionInput | null > | null,
  not?: ModelIntegrationLogConditionInput | null,
};

export type ModelINTEGRATION_RUN_STATUSInput = {
  eq?: INTEGRATION_RUN_STATUS | null,
  ne?: INTEGRATION_RUN_STATUS | null,
};

export type IntegrationLog = {
  __typename: "IntegrationLog",
  integrationId: string,
  id: string,
  log:  Array<IntegrationLogEntry | null >,
  status: INTEGRATION_RUN_STATUS,
  tenantId: string,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type IntegrationLogEntry = {
  __typename: "IntegrationLogEntry",
  level: LOG_LEVEL,
  timestamp: string,
  message: string,
  srcDocId: string,
  srcDocVersion: string,
};

export type UpdateIntegrationLogInput = {
  integrationId?: string | null,
  id: string,
  log?: Array< IntegrationLogEntryInput | null > | null,
  status?: INTEGRATION_RUN_STATUS | null,
  tenantId?: string | null,
  _version?: number | null,
};

export type DeleteIntegrationLogInput = {
  id: string,
  _version?: number | null,
};

export type CreateIntegrationSettingsInput = {
  id?: string | null,
  tenantId: string,
  syncContentEvery: number,
  name?: string | null,
  mapping: Array< IntegrationFieldMappingInput | null >,
  clientConfigurationFields: Array< IntegrationClientConfigurationTupleInput | null >,
  integrationType?: INTEGRATION_TYPE | null,
  enabled?: boolean | null,
  notificationEmail?: string | null,
  errorSyncEmail?: string | null,
  createdAt?: string | null,
  createdBy: string,
  updatedAt?: string | null,
  updatedBy: string,
  _version?: number | null,
};

export type IntegrationFieldMappingInput = {
  key: string,
  fieldType: string,
  dataType: string,
  targetFieldName: string,
  srcFieldname: string,
  valueMappings?: Array< IntegrationValueMappingInput | null > | null,
};

export type IntegrationValueMappingInput = {
  srcValue: string,
  targetValue: string,
};

export type IntegrationClientConfigurationTupleInput = {
  key: string,
  value: string,
};

export type ModelIntegrationSettingsConditionInput = {
  tenantId?: ModelIDInput | null,
  syncContentEvery?: ModelIntInput | null,
  name?: ModelStringInput | null,
  integrationType?: ModelINTEGRATION_TYPEInput | null,
  enabled?: ModelBooleanInput | null,
  notificationEmail?: ModelStringInput | null,
  errorSyncEmail?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  createdBy?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  updatedBy?: ModelStringInput | null,
  and?: Array< ModelIntegrationSettingsConditionInput | null > | null,
  or?: Array< ModelIntegrationSettingsConditionInput | null > | null,
  not?: ModelIntegrationSettingsConditionInput | null,
};

export type IntegrationSettings = {
  __typename: "IntegrationSettings",
  id: string,
  tenantId: string,
  syncContentEvery: number,
  name?: string | null,
  mapping:  Array<IntegrationFieldMapping | null >,
  clientConfigurationFields:  Array<IntegrationClientConfigurationTuple | null >,
  integrationType?: INTEGRATION_TYPE | null,
  enabled?: boolean | null,
  notificationEmail?: string | null,
  errorSyncEmail?: string | null,
  createdAt: string,
  createdBy: string,
  updatedAt: string,
  updatedBy: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type IntegrationFieldMapping = {
  __typename: "IntegrationFieldMapping",
  key: string,
  fieldType: string,
  dataType: string,
  targetFieldName: string,
  srcFieldname: string,
  valueMappings?:  Array<IntegrationValueMapping | null > | null,
};

export type IntegrationValueMapping = {
  __typename: "IntegrationValueMapping",
  srcValue: string,
  targetValue: string,
};

export type IntegrationClientConfigurationTuple = {
  __typename: "IntegrationClientConfigurationTuple",
  key: string,
  value: string,
};

export type UpdateIntegrationSettingsInput = {
  id: string,
  tenantId?: string | null,
  syncContentEvery?: number | null,
  name?: string | null,
  mapping?: Array< IntegrationFieldMappingInput | null > | null,
  clientConfigurationFields?: Array< IntegrationClientConfigurationTupleInput | null > | null,
  integrationType?: INTEGRATION_TYPE | null,
  enabled?: boolean | null,
  notificationEmail?: string | null,
  errorSyncEmail?: string | null,
  createdAt?: string | null,
  createdBy?: string | null,
  updatedAt?: string | null,
  updatedBy?: string | null,
  _version?: number | null,
};

export type DeleteIntegrationSettingsInput = {
  id: string,
  _version?: number | null,
};

export type CreateMeetingInput = {
  id?: string | null,
  tenantId: string,
  title?: string | null,
  startTime: string,
  endTime?: string | null,
  contentPresented: Array< ContentPresentedInput >,
  type: MEETING_TYPE,
  fieldValues: Array< FieldValueInput >,
  customValues?: Array< CustomValuesInput > | null,
  notes?: string | null,
  status: MEETING_STATUS,
  createdAt?: string | null,
  createdBy: string,
  updatedAt?: string | null,
  updatedBy: string,
  attendees: Array< AttendeeInput >,
  crmRecord?: CRMMeetingRecordInfoInput | null,
  _version?: number | null,
};

export type ContentPresentedInput = {
  contentId: string,
  groupId?: string | null,
  folderItemId?: string | null,
  title: string,
  groupTitle?: string | null,
  status: CONTENT_PRESENTED_STATUS,
  contentType: MEETING_CONTENT_TYPE,
  events?: Array< PagePresentedEventInput > | null,
  openedAt?: string | null,
  closedAt?: string | null,
  presentedMeta: Array< PresentedMetaInput >,
};

export enum CONTENT_PRESENTED_STATUS {
  ACTIVE = "ACTIVE",
  DELETED = "DELETED",
}


export enum MEETING_CONTENT_TYPE {
  CUSTOM_DECK = "CUSTOM_DECK",
  DOCUMENT_VERSION = "DOCUMENT_VERSION",
  DOCUMENT_VERSION_GROUP = "DOCUMENT_VERSION_GROUP",
}


export type PagePresentedEventInput = {
  pageNumber: number,
  pageId: string,
  timestamp: string,
  end?: string | null,
};

export type PresentedMetaInput = {
  pageId: string,
  presented: boolean,
  followUp?: boolean | null,
  sentiment?: SENTIMENT | null,
  note?: string | null,
  title?: string | null,
};

export enum SENTIMENT {
  POSITIVE = "POSITIVE",
  NEGATIVE = "NEGATIVE",
}


export enum MEETING_TYPE {
  IN_PERSON = "IN_PERSON",
  VIRTUAL = "VIRTUAL",
  MANUAL = "MANUAL",
}


export type FieldValueInput = {
  fieldId: string,
  valueOrId: string,
};

export enum MEETING_STATUS {
  IN_PROGRESS = "IN_PROGRESS",
  ENDED_EXIT = "ENDED_EXIT",
  ENDED_TIMEOUT = "ENDED_TIMEOUT",
  DELETED = "DELETED",
  LOCKED = "LOCKED",
  PLANNED = "PLANNED",
}


export type AttendeeInput = {
  id?: string | null,
  name: string,
  status: MEETING_ATTENDEE_STATUS,
  updatedAt?: string | null,
  email?: string | null,
  crmAccountId?: string | null,
  crmAddressId?: string | null,
  attendeeType: ATTENDEE_TYPE,
  crmRecord?: CRMMeetingRecordInfoInput | null,
  lastSyncedAt?: string | null,
};

export enum MEETING_ATTENDEE_STATUS {
  ACTIVE = "ACTIVE",
  REMOVED = "REMOVED",
}


export enum ATTENDEE_TYPE {
  PRIMARY = "PRIMARY",
  SECONDARY = "SECONDARY",
}


export type CRMMeetingRecordInfoInput = {
  crmCallId?: string | null,
  crmCustomValues: Array< CustomValuesInput >,
  crmSyncStatus?: CRMSyncStatus | null,
  lastSyncedAt?: string | null,
};

export type ModelMeetingConditionInput = {
  tenantId?: ModelIDInput | null,
  title?: ModelStringInput | null,
  startTime?: ModelStringInput | null,
  endTime?: ModelStringInput | null,
  type?: ModelMEETING_TYPEInput | null,
  notes?: ModelStringInput | null,
  status?: ModelMEETING_STATUSInput | null,
  createdAt?: ModelStringInput | null,
  createdBy?: ModelIDInput | null,
  updatedAt?: ModelStringInput | null,
  updatedBy?: ModelStringInput | null,
  and?: Array< ModelMeetingConditionInput | null > | null,
  or?: Array< ModelMeetingConditionInput | null > | null,
  not?: ModelMeetingConditionInput | null,
};

export type ModelMEETING_TYPEInput = {
  eq?: MEETING_TYPE | null,
  ne?: MEETING_TYPE | null,
};

export type ModelMEETING_STATUSInput = {
  eq?: MEETING_STATUS | null,
  ne?: MEETING_STATUS | null,
};

export type Meeting = {
  __typename: "Meeting",
  id: string,
  tenantId: string,
  title?: string | null,
  startTime: string,
  endTime?: string | null,
  contentPresented:  Array<ContentPresented >,
  type: MEETING_TYPE,
  fieldValues:  Array<FieldValue >,
  customValues?:  Array<CustomValues > | null,
  notes?: string | null,
  status: MEETING_STATUS,
  createdAt: string,
  createdBy: string,
  updatedAt: string,
  updatedBy: string,
  attendees:  Array<Attendee >,
  crmRecord?: CRMMeetingRecordInfo | null,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type ContentPresented = {
  __typename: "ContentPresented",
  contentId: string,
  groupId?: string | null,
  folderItemId?: string | null,
  title: string,
  groupTitle?: string | null,
  status: CONTENT_PRESENTED_STATUS,
  contentType: MEETING_CONTENT_TYPE,
  events?:  Array<PagePresentedEvent > | null,
  openedAt?: string | null,
  closedAt?: string | null,
  presentedMeta:  Array<PresentedMeta >,
};

export type PagePresentedEvent = {
  __typename: "PagePresentedEvent",
  pageNumber: number,
  pageId: string,
  timestamp: string,
  end?: string | null,
};

export type PresentedMeta = {
  __typename: "PresentedMeta",
  pageId: string,
  presented: boolean,
  followUp?: boolean | null,
  sentiment?: SENTIMENT | null,
  note?: string | null,
  title?: string | null,
};

export type FieldValue = {
  __typename: "FieldValue",
  fieldId: string,
  valueOrId: string,
};

export type Attendee = {
  __typename: "Attendee",
  id: string,
  name: string,
  status: MEETING_ATTENDEE_STATUS,
  updatedAt: string,
  email?: string | null,
  crmAccountId?: string | null,
  crmAddressId?: string | null,
  attendeeType: ATTENDEE_TYPE,
  crmRecord?: CRMMeetingRecordInfo | null,
  lastSyncedAt?: string | null,
};

export type CRMMeetingRecordInfo = {
  __typename: "CRMMeetingRecordInfo",
  crmCallId?: string | null,
  crmCustomValues:  Array<CustomValues >,
  crmSyncStatus?: CRMSyncStatus | null,
  lastSyncedAt?: string | null,
};

export type UpdateMeetingInput = {
  id: string,
  tenantId?: string | null,
  title?: string | null,
  startTime?: string | null,
  endTime?: string | null,
  contentPresented?: Array< ContentPresentedInput > | null,
  type?: MEETING_TYPE | null,
  fieldValues?: Array< FieldValueInput > | null,
  customValues?: Array< CustomValuesInput > | null,
  notes?: string | null,
  status?: MEETING_STATUS | null,
  createdAt?: string | null,
  createdBy?: string | null,
  updatedAt?: string | null,
  updatedBy?: string | null,
  attendees?: Array< AttendeeInput > | null,
  crmRecord?: CRMMeetingRecordInfoInput | null,
  _version?: number | null,
};

export type DeleteMeetingInput = {
  id: string,
  _version?: number | null,
};

export type CreateHubInput = {
  id?: string | null,
  tenantId: string,
  name?: string | null,
  status: HUB_STATUS,
  createdAt?: string | null,
  createdBy: string,
  updatedAt?: string | null,
  updatedBy: string,
  hubSections?: Array< HubSectionInput > | null,
  customValues?: Array< CustomValuesInput > | null,
  meetingId?: string | null,
  _version?: number | null,
};

export enum HUB_STATUS {
  ACTIVE = "ACTIVE",
  DELETED = "DELETED",
}


export type HubSectionInput = {
  id?: string | null,
  sectionHeader?: string | null,
  status: HUB_SECTION_STATUS,
  type: HUB_SECTION_TYPE,
  visible: boolean,
  order: number,
  textarea?: TextareaHubSettingsInput | null,
  sharedFiles?: Array< SharedFileHubSettingInput > | null,
  toDos?: Array< ToDoInput > | null,
  links?: Array< LinkInput > | null,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export enum HUB_SECTION_STATUS {
  ACTIVE = "ACTIVE",
  DELETED = "DELETED",
}


export type TextareaHubSettingsInput = {
  content?: string | null,
};

export type SharedFileHubSettingInput = {
  id?: string | null,
  contentId: string,
  title: string,
  contentType: HUB_SHARED_FILE_CONTENT_TYPE,
  documentVersionSettings?: DocumentVersionSettingsInput | null,
  status: HUB_SECTION_ITEM_STATUS,
  createdAt?: string | null,
  updatedAt?: string | null,
  notation?: Array< NotationInput > | null,
};

export enum HUB_SHARED_FILE_CONTENT_TYPE {
  DOCUMENT_VERSION = "DOCUMENT_VERSION",
}


export type DocumentVersionSettingsInput = {
  associatedFiles?: Array< HubSharedAssociatedFileInput > | null,
};

export type HubSharedAssociatedFileInput = {
  associatedFileId: string,
  versionId?: string | null,
  notation?: Array< NotationInput > | null,
};

export type ToDoInput = {
  id?: string | null,
  title: string,
  status: TODO_STATUS,
  completedAt?: string | null,
  resolution?: string | null,
  order: number,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export enum TODO_STATUS {
  ACTIVE = "ACTIVE",
  COMPLETED = "COMPLETED",
  DELETED = "DELETED",
}


export type LinkInput = {
  id?: string | null,
  url: string,
  title?: string | null,
  status: HUB_SECTION_ITEM_STATUS,
  order: number,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type ModelHubConditionInput = {
  tenantId?: ModelIDInput | null,
  name?: ModelStringInput | null,
  status?: ModelHUB_STATUSInput | null,
  createdAt?: ModelStringInput | null,
  createdBy?: ModelIDInput | null,
  updatedAt?: ModelStringInput | null,
  updatedBy?: ModelStringInput | null,
  meetingId?: ModelStringInput | null,
  and?: Array< ModelHubConditionInput | null > | null,
  or?: Array< ModelHubConditionInput | null > | null,
  not?: ModelHubConditionInput | null,
};

export type ModelHUB_STATUSInput = {
  eq?: HUB_STATUS | null,
  ne?: HUB_STATUS | null,
};

export type Hub = {
  __typename: "Hub",
  id: string,
  tenantId: string,
  name?: string | null,
  status: HUB_STATUS,
  createdAt: string,
  createdBy: string,
  updatedAt: string,
  updatedBy: string,
  hubSections?:  Array<HubSection > | null,
  customValues?:  Array<CustomValues > | null,
  meetingId?: string | null,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type HubSection = {
  __typename: "HubSection",
  id: string,
  sectionHeader?: string | null,
  status: HUB_SECTION_STATUS,
  type: HUB_SECTION_TYPE,
  visible: boolean,
  order: number,
  textarea?: TextareaHubSettings | null,
  sharedFiles?:  Array<SharedFileHubSetting > | null,
  toDos?:  Array<ToDo > | null,
  links?:  Array<Link > | null,
  createdAt: string,
  updatedAt: string,
};

export type TextareaHubSettings = {
  __typename: "TextareaHubSettings",
  content?: string | null,
};

export type SharedFileHubSetting = {
  __typename: "SharedFileHubSetting",
  id: string,
  contentId: string,
  title: string,
  contentType: HUB_SHARED_FILE_CONTENT_TYPE,
  documentVersionSettings?: DocumentVersionSettings | null,
  status: HUB_SECTION_ITEM_STATUS,
  createdAt: string,
  updatedAt: string,
  notation?:  Array<Notation > | null,
};

export type DocumentVersionSettings = {
  __typename: "DocumentVersionSettings",
  associatedFiles?:  Array<HubSharedAssociatedFile > | null,
};

export type HubSharedAssociatedFile = {
  __typename: "HubSharedAssociatedFile",
  associatedFileId: string,
  versionId?: string | null,
  notation?:  Array<Notation > | null,
};

export type ToDo = {
  __typename: "ToDo",
  id: string,
  title: string,
  status: TODO_STATUS,
  completedAt?: string | null,
  resolution?: string | null,
  order: number,
  createdAt: string,
  updatedAt: string,
};

export type Link = {
  __typename: "Link",
  id: string,
  url: string,
  title?: string | null,
  status: HUB_SECTION_ITEM_STATUS,
  order: number,
  createdAt: string,
  updatedAt: string,
};

export type UpdateHubInput = {
  id: string,
  tenantId?: string | null,
  name?: string | null,
  status?: HUB_STATUS | null,
  createdAt?: string | null,
  createdBy?: string | null,
  updatedAt?: string | null,
  updatedBy?: string | null,
  hubSections?: Array< HubSectionInput > | null,
  customValues?: Array< CustomValuesInput > | null,
  meetingId?: string | null,
  _version?: number | null,
};

export type DeleteHubInput = {
  id: string,
  _version?: number | null,
};

export type CreateCustomFormRecordInput = {
  id?: string | null,
  tenantId: string,
  customFormId: string,
  entity: CUSTOM_FORM_RECORD_ENTITY,
  status: CUSTOM_FORM_RECORD_STATUS,
  crmFields?: CustomFormRecordCRMFieldsInput | null,
  name?: string | null,
  parentId?: string | null,
  parentModel?: CUSTOM_FORM_RECORD_RELATIONSHIP_MODEL | null,
  values: Array< CustomValuesInput >,
  createdAt?: string | null,
  createdBy: string,
  updatedAt?: string | null,
  updatedBy: string,
  _version?: number | null,
};

export enum CUSTOM_FORM_RECORD_ENTITY {
  BEACON_TENANT_FORM = "BEACON_TENANT_FORM",
  CRM_OBJECT = "CRM_OBJECT",
  SURVEY_TARGET = "SURVEY_TARGET",
}


export enum CUSTOM_FORM_RECORD_STATUS {
  ACTIVE = "ACTIVE",
  LOCKED = "LOCKED",
  DELETED = "DELETED",
}


export type CustomFormRecordCRMFieldsInput = {
  accountId?: string | null,
  externalId?: string | null,
  apiName: string,
  recordTypeId?: string | null,
  syncStatus: CRMSyncStatus,
  lastSyncedAt?: string | null,
};

export enum CUSTOM_FORM_RECORD_RELATIONSHIP_MODEL {
  MEETING = "MEETING",
}


export type ModelCustomFormRecordConditionInput = {
  tenantId?: ModelIDInput | null,
  customFormId?: ModelIDInput | null,
  entity?: ModelCUSTOM_FORM_RECORD_ENTITYInput | null,
  status?: ModelCUSTOM_FORM_RECORD_STATUSInput | null,
  name?: ModelStringInput | null,
  parentId?: ModelStringInput | null,
  parentModel?: ModelCUSTOM_FORM_RECORD_RELATIONSHIP_MODELInput | null,
  createdAt?: ModelStringInput | null,
  createdBy?: ModelIDInput | null,
  updatedAt?: ModelStringInput | null,
  updatedBy?: ModelStringInput | null,
  and?: Array< ModelCustomFormRecordConditionInput | null > | null,
  or?: Array< ModelCustomFormRecordConditionInput | null > | null,
  not?: ModelCustomFormRecordConditionInput | null,
};

export type ModelCUSTOM_FORM_RECORD_ENTITYInput = {
  eq?: CUSTOM_FORM_RECORD_ENTITY | null,
  ne?: CUSTOM_FORM_RECORD_ENTITY | null,
};

export type ModelCUSTOM_FORM_RECORD_STATUSInput = {
  eq?: CUSTOM_FORM_RECORD_STATUS | null,
  ne?: CUSTOM_FORM_RECORD_STATUS | null,
};

export type ModelCUSTOM_FORM_RECORD_RELATIONSHIP_MODELInput = {
  eq?: CUSTOM_FORM_RECORD_RELATIONSHIP_MODEL | null,
  ne?: CUSTOM_FORM_RECORD_RELATIONSHIP_MODEL | null,
};

export type CustomFormRecord = {
  __typename: "CustomFormRecord",
  id: string,
  tenantId: string,
  customFormId: string,
  entity: CUSTOM_FORM_RECORD_ENTITY,
  status: CUSTOM_FORM_RECORD_STATUS,
  crmFields?: CustomFormRecordCRMFields | null,
  name?: string | null,
  parentId?: string | null,
  parentModel?: CUSTOM_FORM_RECORD_RELATIONSHIP_MODEL | null,
  values:  Array<CustomValues >,
  createdAt: string,
  createdBy: string,
  updatedAt: string,
  updatedBy: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type CustomFormRecordCRMFields = {
  __typename: "CustomFormRecordCRMFields",
  accountId?: string | null,
  externalId?: string | null,
  apiName: string,
  recordTypeId?: string | null,
  syncStatus: CRMSyncStatus,
  lastSyncedAt?: string | null,
};

export type UpdateCustomFormRecordInput = {
  id: string,
  tenantId?: string | null,
  customFormId?: string | null,
  entity?: CUSTOM_FORM_RECORD_ENTITY | null,
  status?: CUSTOM_FORM_RECORD_STATUS | null,
  crmFields?: CustomFormRecordCRMFieldsInput | null,
  name?: string | null,
  parentId?: string | null,
  parentModel?: CUSTOM_FORM_RECORD_RELATIONSHIP_MODEL | null,
  values?: Array< CustomValuesInput > | null,
  createdAt?: string | null,
  createdBy?: string | null,
  updatedAt?: string | null,
  updatedBy?: string | null,
  _version?: number | null,
};

export type DeleteCustomFormRecordInput = {
  id: string,
  _version?: number | null,
};

export type CreateTenantInputLambda = {
  name: string,
  fieldConfig?: Array< FieldConfigInput > | null,
};

export type CreateDocumentFromS3UploadInput = {
  srcFilename: string,
  fileS3Key: string,
};

export type UpdateDocumentThumbnailInput = {
  documentId: string,
  documentVersionId: string,
  fileS3Key?: string | null,
  srcFilename?: string | null,
};

export type CreateDocumentFromS3UploadEngineInput = {
  srcFilename: string,
  fileS3Key: string,
  tenantId: string,
};

export type CreateDocumentVersionFromS3UploadInput = {
  srcFilename: string,
  fileS3Key: string,
  version: number,
  documentId: string,
  existingVersionId: string,
};

export type CreateDocumentVersionFromS3UploadEngineInput = {
  srcFilename: string,
  documentId: string,
  fileS3Key: string,
  existingVersionId: string,
  tenantId: string,
  updateDocVersion: boolean,
};

export type CreateDocumentVersionFromExistingEngineInput = {
  email: string,
  document: UpdateDocumentInput,
  documentVersion: UpdateDocumentVersionInput,
};

export type CreateAttachedFileFromS3UploadInput = {
  entityId: string,
  entityType: ATTACHED_FILE_ENTITY_TYPE,
  srcFilename: string,
  fileS3Key: string,
};

export enum ATTACHED_FILE_ENTITY_TYPE {
  DOCUMENT_VERSION = "DOCUMENT_VERSION",
  EMAIL_TEMPLATE = "EMAIL_TEMPLATE",
}


export type UpdateUserLambdaInput = {
  id: string,
  tenantId?: string | null,
  email?: string | null,
  altUsername?: string | null,
  givenName?: string | null,
  phoneNumber?: string | null,
  familyName?: string | null,
  meetingId?: string | null,
  defaultFilterValues?: Array< LabelValuesInput > | null,
  lockedFilters?: Array< LabelValuesInput > | null,
  defaultFiltersCustomValues?: Array< CustomValuesInput > | null,
  lockedFiltersCustomValues?: Array< CustomValuesInput > | null,
  shareBCC?: Array< string > | null,
  shareCC?: Array< string > | null,
  createdAt?: string | null,
  createdBy?: string | null,
  updatedAt?: string | null,
  status?: USER_STATUS | null,
  role?: USER_ROLE | null,
  bookmarkedDocs?: Array< BookmarkedDocInput > | null,
  isExcludedFromReporting?: boolean | null,
  additionalRoles?: Array< USER_ROLE > | null,
};

export type UpdateCustomDeckLambdaInput = {
  id: string,
  createdAt?: string | null,
  createdBy?: string | null,
  autoUpdateAcknowledgedAt?: string | null,
  updatedAt?: string | null,
  updatedBy?: string | null,
  tenantId?: string | null,
  groups?: Array< CustomDeckGroupLambdaInput > | null,
  title?: string | null,
  editMutex?: EditMutexLambdaInput | null,
  sourceCustomDeckId?: string | null,
};

export type CustomDeckGroupLambdaInput = {
  id?: string | null,
  pages: Array< CustomDeckPageLambdaInput >,
  visible: boolean,
  srcId?: string | null,
  docAccessLevel: DOCUMENT_ACCESS_LEVEL,
  name?: string | null,
  locked?: boolean | null,
};

export type CustomDeckPageLambdaInput = {
  pageId: string,
  pageNumber: number,
  documentVersionId: string,
};

export type EditMutexLambdaInput = {
  userId: string,
  timeStarted: string,
  lastSeenAt: string,
};

export type DisableUserLambdaInput = {
  id: string,
  status: USER_STATUS,
};

export type CreateUserLambdaInput = {
  tenantId: string,
  email: string,
  altUsername?: string | null,
  givenName: string,
  phoneNumber?: string | null,
  familyName: string,
  defaultFilterValues?: Array< LabelValuesInput > | null,
  defaultFiltersCustomValues?: Array< CustomValuesInput > | null,
  lockedFilters?: Array< LabelValuesInput > | null,
  lockedFiltersCustomValues?: Array< CustomValuesInput > | null,
  shareBCC?: Array< string > | null,
  shareCC?: Array< string > | null,
  role: USER_ROLE,
  bookmarkedDocs?: Array< BookmarkedDocInput > | null,
  isExcludedFromReporting?: boolean | null,
  additionalRoles?: Array< USER_ROLE > | null,
};

export type RunIntegrationEngineInput = {
  integrationSettingId: string,
  forcePropertyUpdate?: FORCE_PROPERTY_UPDATE | null,
};

export enum FORCE_PROPERTY_UPDATE {
  OVERRIDE = "OVERRIDE",
  POPULATE = "POPULATE",
}


export type SubmitIntegrationResult = {
  __typename: "SubmitIntegrationResult",
  success: boolean,
  message?: string | null,
};

export type UpdateTenantLogoInput = {
  tenantId: string,
  key: string,
};

export type UpdateZendeskOrgInput = {
  tenantId: string,
  tenantName?: string | null,
  ssoDomains?: Array< string > | null,
};

export type UpdateJsonPagesLambdaInput = {
  documentId: string,
  documentVersionId: string,
  pagesData: Array< ContentPageDataInput >,
};

export type ContentPageDataInput = {
  number?: number | null,
  speakerNotes?: string | null,
  enableSlideTextInsertion?: boolean | null,
  title?: string | null,
  mapping?: string | null,
};

export type CreateSubFolderInput = {
  name: string,
  parentFolderId?: string | null,
};

export type UpsertVersionContentsResult = {
  __typename: "UpsertVersionContentsResult",
  documentVersionId?: string | null,
  tenantId?: string | null,
  result?: string | null,
};

export type UpdateDocVerPageMatchesResult = {
  __typename: "UpdateDocVerPageMatchesResult",
  tenantId: string,
  documentVersionId: string,
  status: string,
  message: string,
};

export type ModelUserFilterInput = {
  id?: ModelIDInput | null,
  cognitoSubId?: ModelIDInput | null,
  tenantId?: ModelIDInput | null,
  email?: ModelStringInput | null,
  givenName?: ModelStringInput | null,
  phoneNumber?: ModelStringInput | null,
  familyName?: ModelStringInput | null,
  meetingId?: ModelStringInput | null,
  shareBCC?: ModelStringInput | null,
  shareCC?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  createdBy?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  status?: ModelUSER_STATUSInput | null,
  role?: ModelUSER_ROLEInput | null,
  isExcludedFromReporting?: ModelBooleanInput | null,
  additionalRoles?: ModelUSER_ROLEListInput | null,
  altUsername?: ModelStringInput | null,
  and?: Array< ModelUserFilterInput | null > | null,
  or?: Array< ModelUserFilterInput | null > | null,
  not?: ModelUserFilterInput | null,
};

export type ModelUserConnection = {
  __typename: "ModelUserConnection",
  items:  Array<User | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelTenantFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  licensedUsers?: ModelIntInput | null,
  status?: ModelTENANT_STATUSInput | null,
  folderUpdateGracePeriodDays?: ModelIntInput | null,
  medInfoURL?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  createdBy?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  statusChangedAt?: ModelStringInput | null,
  statusChangedBy?: ModelStringInput | null,
  ssoDomains?: ModelStringInput | null,
  integrations?: ModelStringInput | null,
  and?: Array< ModelTenantFilterInput | null > | null,
  or?: Array< ModelTenantFilterInput | null > | null,
  not?: ModelTenantFilterInput | null,
};

export type ModelTenantConnection = {
  __typename: "ModelTenantConnection",
  items:  Array<Tenant | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelDocumentVersionFilterInput = {
  id?: ModelIDInput | null,
  tenantId?: ModelIDInput | null,
  documentId?: ModelIDInput | null,
  versionNumber?: ModelIntInput | null,
  srcFilename?: ModelStringInput | null,
  contentURL?: ModelStringInput | null,
  conversionStatus?: ModelCONVERSION_STATUSInput | null,
  status?: ModelDOCUMENT_STATUSInput | null,
  srcHash?: ModelStringInput | null,
  srcSize?: ModelFloatInput | null,
  numPages?: ModelIntInput | null,
  type?: ModelFILE_TYPEInput | null,
  releaseNotes?: ModelStringInput | null,
  changeType?: ModelDOCUMENT_VERSION_CHANGE_TYPEInput | null,
  title?: ModelStringInput | null,
  shortDescription?: ModelStringInput | null,
  longDescription?: ModelStringInput | null,
  owner?: ModelStringInput | null,
  expiresAt?: ModelStringInput | null,
  hasCopyright?: ModelBooleanInput | null,
  hasCustomThumbnail?: ModelBooleanInput | null,
  purpose?: ModelStringInput | null,
  canHideSlides?: ModelBooleanInput | null,
  distributable?: ModelBooleanInput | null,
  downloadable?: ModelBooleanInput | null,
  isInternalGenerated?: ModelBooleanInput | null,
  notificationScope?: ModelNOTIFICATION_SCOPEInput | null,
  selectedThumbnail?: ModelIntInput | null,
  publishedAt?: ModelStringInput | null,
  uploadedAt?: ModelStringInput | null,
  uploadedBy?: ModelStringInput | null,
  convertedArchiveKey?: ModelStringInput | null,
  convertedArchiveSize?: ModelFloatInput | null,
  convertedFolderKey?: ModelStringInput | null,
  editPermissions?: ModelStringInput | null,
  converterVersion?: ModelIntInput | null,
  createdAt?: ModelStringInput | null,
  createdBy?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  updatedBy?: ModelStringInput | null,
  integrationType?: ModelINTEGRATION_TYPEInput | null,
  conversionWarningCode?: ModelCONVERSION_WARNING_CODEInput | null,
  conversionErrorCode?: ModelStringInput | null,
  scheduledPublish?: ModelStringInput | null,
  mappingStatus?: ModelMAPPING_STATUSInput | null,
  and?: Array< ModelDocumentVersionFilterInput | null > | null,
  or?: Array< ModelDocumentVersionFilterInput | null > | null,
  not?: ModelDocumentVersionFilterInput | null,
};

export type ModelDocumentVersionConnection = {
  __typename: "ModelDocumentVersionConnection",
  items:  Array<DocumentVersion | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelStringKeyConditionInput = {
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
};

export enum ModelSortDirection {
  ASC = "ASC",
  DESC = "DESC",
}


export type ModelIntKeyConditionInput = {
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
};

export type ModelAttachedFileFilterInput = {
  id?: ModelIDInput | null,
  title?: ModelStringInput | null,
  tenantId?: ModelStringInput | null,
  documentId?: ModelIDInput | null,
  srcFileName?: ModelStringInput | null,
  srcHash?: ModelStringInput | null,
  srcSize?: ModelFloatInput | null,
  type?: ModelFILE_TYPEInput | null,
  editPermissions?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  createdBy?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelAttachedFileFilterInput | null > | null,
  or?: Array< ModelAttachedFileFilterInput | null > | null,
  not?: ModelAttachedFileFilterInput | null,
};

export type ModelAttachedFileConnection = {
  __typename: "ModelAttachedFileConnection",
  items:  Array<AttachedFile | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelDocumentFilterInput = {
  id?: ModelIDInput | null,
  tenantId?: ModelIDInput | null,
  accessLevel?: ModelDOCUMENT_ACCESS_LEVELInput | null,
  status?: ModelDOCUMENT_STATUSInput | null,
  owner?: ModelStringInput | null,
  expiresAt?: ModelStringInput | null,
  integrationType?: ModelINTEGRATION_TYPEInput | null,
  type?: ModelFILE_TYPEInput | null,
  editPermissions?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  createdBy?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  updatedBy?: ModelStringInput | null,
  and?: Array< ModelDocumentFilterInput | null > | null,
  or?: Array< ModelDocumentFilterInput | null > | null,
  not?: ModelDocumentFilterInput | null,
};

export type ModelDocumentConnection = {
  __typename: "ModelDocumentConnection",
  items:  Array<Document | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelEmailTemplateFilterInput = {
  id?: ModelIDInput | null,
  tenantId?: ModelIDInput | null,
  subject?: ModelStringInput | null,
  title?: ModelStringInput | null,
  cc?: ModelStringInput | null,
  bcc?: ModelStringInput | null,
  body?: ModelStringInput | null,
  status?: ModelEMAIL_TEMPLATE_STATUSInput | null,
  editPermissions?: ModelStringInput | null,
  createdBy?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedBy?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelEmailTemplateFilterInput | null > | null,
  or?: Array< ModelEmailTemplateFilterInput | null > | null,
  not?: ModelEmailTemplateFilterInput | null,
};

export type ModelEmailTemplateConnection = {
  __typename: "ModelEmailTemplateConnection",
  items:  Array<EmailTemplate | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelDocumentSettingFilterInput = {
  id?: ModelIDInput | null,
  tenantId?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  createdBy?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  updatedBy?: ModelStringInput | null,
  documentId?: ModelIDInput | null,
  documentVersionId?: ModelIDInput | null,
  status?: ModelUSER_NOTATIONS_STATUSInput | null,
  and?: Array< ModelDocumentSettingFilterInput | null > | null,
  or?: Array< ModelDocumentSettingFilterInput | null > | null,
  not?: ModelDocumentSettingFilterInput | null,
};

export type ModelDocumentSettingConnection = {
  __typename: "ModelDocumentSettingConnection",
  items:  Array<DocumentSetting | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelUserNotationsFilterInput = {
  id?: ModelIDInput | null,
  tenantId?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  createdBy?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  updatedBy?: ModelStringInput | null,
  documentId?: ModelIDInput | null,
  documentVersionId?: ModelIDInput | null,
  customDeckId?: ModelIDInput | null,
  status?: ModelUSER_NOTATIONS_STATUSInput | null,
  type?: ModelUSER_NOTATIONS_TYPEInput | null,
  and?: Array< ModelUserNotationsFilterInput | null > | null,
  or?: Array< ModelUserNotationsFilterInput | null > | null,
  not?: ModelUserNotationsFilterInput | null,
};

export type ModelUserNotationsConnection = {
  __typename: "ModelUserNotationsConnection",
  items:  Array<UserNotations | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelCustomDeckFilterInput = {
  id?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  createdBy?: ModelStringInput | null,
  autoUpdateAcknowledgedAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  updatedBy?: ModelStringInput | null,
  tenantId?: ModelIDInput | null,
  title?: ModelStringInput | null,
  sourceCustomDeckId?: ModelIDInput | null,
  and?: Array< ModelCustomDeckFilterInput | null > | null,
  or?: Array< ModelCustomDeckFilterInput | null > | null,
  not?: ModelCustomDeckFilterInput | null,
};

export type ModelCustomDeckConnection = {
  __typename: "ModelCustomDeckConnection",
  items:  Array<CustomDeck | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelFolderFilterInput = {
  id?: ModelIDInput | null,
  tenantId?: ModelIDInput | null,
  name?: ModelStringInput | null,
  isPinned?: ModelBooleanInput | null,
  status?: ModelFOLDER_STATUSInput | null,
  createdAt?: ModelStringInput | null,
  createdBy?: ModelIDInput | null,
  updatedAt?: ModelStringInput | null,
  updatedBy?: ModelStringInput | null,
  shareStatus?: ModelSHARE_STATUSInput | null,
  and?: Array< ModelFolderFilterInput | null > | null,
  or?: Array< ModelFolderFilterInput | null > | null,
  not?: ModelFolderFilterInput | null,
};

export type ModelFolderConnection = {
  __typename: "ModelFolderConnection",
  items:  Array<Folder | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelFolderByTenantStatusSharedCompositeKeyConditionInput = {
  eq?: ModelFolderByTenantStatusSharedCompositeKeyInput | null,
  le?: ModelFolderByTenantStatusSharedCompositeKeyInput | null,
  lt?: ModelFolderByTenantStatusSharedCompositeKeyInput | null,
  ge?: ModelFolderByTenantStatusSharedCompositeKeyInput | null,
  gt?: ModelFolderByTenantStatusSharedCompositeKeyInput | null,
  between?: Array< ModelFolderByTenantStatusSharedCompositeKeyInput | null > | null,
  beginsWith?: ModelFolderByTenantStatusSharedCompositeKeyInput | null,
};

export type ModelFolderByTenantStatusSharedCompositeKeyInput = {
  status?: FOLDER_STATUS | null,
  shareStatus?: SHARE_STATUS | null,
};

export type ModelContentShareFilterInput = {
  id?: ModelIDInput | null,
  token?: ModelIDInput | null,
  tenantId?: ModelIDInput | null,
  type?: ModelSHARE_TYPEInput | null,
  contentId?: ModelIDInput | null,
  meetingId?: ModelStringInput | null,
  expiresAt?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  createdBy?: ModelIDInput | null,
  revoked?: ModelBooleanInput | null,
  notificationScope?: ModelSHARE_NOTIFICATION_SCOPEInput | null,
  and?: Array< ModelContentShareFilterInput | null > | null,
  or?: Array< ModelContentShareFilterInput | null > | null,
  not?: ModelContentShareFilterInput | null,
};

export type ModelContentShareConnection = {
  __typename: "ModelContentShareConnection",
  items:  Array<ContentShare | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelIntegrationLogFilterInput = {
  integrationId?: ModelIDInput | null,
  id?: ModelIDInput | null,
  status?: ModelINTEGRATION_RUN_STATUSInput | null,
  tenantId?: ModelIDInput | null,
  and?: Array< ModelIntegrationLogFilterInput | null > | null,
  or?: Array< ModelIntegrationLogFilterInput | null > | null,
  not?: ModelIntegrationLogFilterInput | null,
};

export type ModelIntegrationLogConnection = {
  __typename: "ModelIntegrationLogConnection",
  items:  Array<IntegrationLog | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelIntegrationSettingsFilterInput = {
  id?: ModelIDInput | null,
  tenantId?: ModelIDInput | null,
  syncContentEvery?: ModelIntInput | null,
  name?: ModelStringInput | null,
  integrationType?: ModelINTEGRATION_TYPEInput | null,
  enabled?: ModelBooleanInput | null,
  notificationEmail?: ModelStringInput | null,
  errorSyncEmail?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  createdBy?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  updatedBy?: ModelStringInput | null,
  and?: Array< ModelIntegrationSettingsFilterInput | null > | null,
  or?: Array< ModelIntegrationSettingsFilterInput | null > | null,
  not?: ModelIntegrationSettingsFilterInput | null,
};

export type ModelIntegrationSettingsConnection = {
  __typename: "ModelIntegrationSettingsConnection",
  items:  Array<IntegrationSettings | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelMeetingFilterInput = {
  id?: ModelIDInput | null,
  tenantId?: ModelIDInput | null,
  title?: ModelStringInput | null,
  startTime?: ModelStringInput | null,
  endTime?: ModelStringInput | null,
  type?: ModelMEETING_TYPEInput | null,
  notes?: ModelStringInput | null,
  status?: ModelMEETING_STATUSInput | null,
  createdAt?: ModelStringInput | null,
  createdBy?: ModelIDInput | null,
  updatedAt?: ModelStringInput | null,
  updatedBy?: ModelStringInput | null,
  and?: Array< ModelMeetingFilterInput | null > | null,
  or?: Array< ModelMeetingFilterInput | null > | null,
  not?: ModelMeetingFilterInput | null,
};

export type ModelMeetingConnection = {
  __typename: "ModelMeetingConnection",
  items:  Array<Meeting | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelHubFilterInput = {
  id?: ModelIDInput | null,
  tenantId?: ModelIDInput | null,
  name?: ModelStringInput | null,
  status?: ModelHUB_STATUSInput | null,
  createdAt?: ModelStringInput | null,
  createdBy?: ModelIDInput | null,
  updatedAt?: ModelStringInput | null,
  updatedBy?: ModelStringInput | null,
  meetingId?: ModelStringInput | null,
  and?: Array< ModelHubFilterInput | null > | null,
  or?: Array< ModelHubFilterInput | null > | null,
  not?: ModelHubFilterInput | null,
};

export type ModelHubConnection = {
  __typename: "ModelHubConnection",
  items:  Array<Hub | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelCustomFormRecordFilterInput = {
  id?: ModelIDInput | null,
  tenantId?: ModelIDInput | null,
  customFormId?: ModelIDInput | null,
  entity?: ModelCUSTOM_FORM_RECORD_ENTITYInput | null,
  status?: ModelCUSTOM_FORM_RECORD_STATUSInput | null,
  name?: ModelStringInput | null,
  parentId?: ModelStringInput | null,
  parentModel?: ModelCUSTOM_FORM_RECORD_RELATIONSHIP_MODELInput | null,
  createdAt?: ModelStringInput | null,
  createdBy?: ModelIDInput | null,
  updatedAt?: ModelStringInput | null,
  updatedBy?: ModelStringInput | null,
  and?: Array< ModelCustomFormRecordFilterInput | null > | null,
  or?: Array< ModelCustomFormRecordFilterInput | null > | null,
  not?: ModelCustomFormRecordFilterInput | null,
};

export type ModelCustomFormRecordConnection = {
  __typename: "ModelCustomFormRecordConnection",
  items:  Array<CustomFormRecord | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ContentTokenOutput = {
  __typename: "ContentTokenOutput",
  token: string,
  expiresAt: string,
};

export type PresentableContentMetadata = {
  __typename: "PresentableContentMetadata",
  id: string,
  contentId: string,
  token: string,
  key: string,
  expiresAt: string,
  fileName: string,
  associatedFileId?: string | null,
  shareType: SHARE_TYPE,
  fileExtension?: string | null,
  contentURL?: string | null,
  pageGroups?:  Array<PageGroup > | null,
  title?: string | null,
  numPages?: number | null,
  notation?:  Array<Notation > | null,
  coverThumbnailPageNum?: number | null,
  sharedBy: string,
  tenantId: string,
};

export type HubAndContentDetails = {
  __typename: "HubAndContentDetails",
  hubId: string,
  tenantId: string,
  title?: string | null,
  status: HUB_STATUS,
  hubSections?:  Array<HubSection > | null,
  content?:  Array<PresentableContentMetadata > | null,
  tenantLogoUrl?: string | null,
  sharedBy: string,
  featureFlags:  Array<FeatureFlags >,
};

export type AvailableDashboards = {
  __typename: "AvailableDashboards",
  dashboards:  Array<Dashboard >,
  isTeamLeader: boolean,
};

export type Dashboard = {
  __typename: "Dashboard",
  reports:  Array<ReportItem >,
  dashboardUrl: string,
  dashboardId: string,
  name: string,
  dashboardSheets:  Array<Sheet >,
};

export type ReportItem = {
  __typename: "ReportItem",
  dashboardId: string,
  name: string,
  group: ReportGroup,
};

export type ReportGroup = {
  __typename: "ReportGroup",
  name: string,
  order: number,
};

export type Sheet = {
  __typename: "Sheet",
  sheetId: string,
  name: string,
};

export type GetSharedFoldersOutput = {
  __typename: "GetSharedFoldersOutput",
  folders:  Array<Folder >,
  permissions:  Array<FolderPermission >,
};

export type FolderPermission = {
  __typename: "FolderPermission",
  id: string,
  type: PERMISSION_TYPE,
};

export type GetDependenciesOutput = {
  __typename: "GetDependenciesOutput",
  customDecks:  Array<CustomDeck >,
  documents:  Array<Document >,
  documentVersions:  Array<DocumentVersion >,
  accessTokens:  Array<AccessTokenData >,
};

export type AccessTokenData = {
  __typename: "AccessTokenData",
  documentVersionId: string,
  accessToken: string,
  accessTokenExpire: string,
};

export type PublisherIntegration = {
  __typename: "PublisherIntegration",
  id: string,
  tenantId: string,
  name: string,
  integrationType: string,
  enabled: boolean,
};

export enum ZENDESK_FEATURE {
  CHAT = "CHAT",
  SSO = "SSO",
}


export type ZendeskTokenPayload = {
  __typename: "ZendeskTokenPayload",
  token: string,
  expiresAt: string,
};

export type AuthInformationPayload = {
  __typename: "AuthInformationPayload",
  accessToken: string,
  instanceUrl: string,
  refreshToken: string,
  userInfo: UserInfoPayload,
};

export type UserInfoPayload = {
  __typename: "UserInfoPayload",
  id: string,
  organizationId: string,
  displayName: string,
  thumbnail?: string | null,
};

export type RefreshTokenCRMPayload = {
  __typename: "RefreshTokenCRMPayload",
  accessToken: string,
  issuedAt: string,
  signature: string,
  id: string,
};

export type FullTextSearchFilterInput = {
  statuses?: Array< string > | null,
};

export type FullTextSearchOutput = {
  __typename: "FullTextSearchOutput",
  items:  Array<FullTextSearchResultItem >,
  searchServerTime?: number | null,
  searchClientTime?: number | null,
};

export type FullTextSearchResultItem = {
  __typename: "FullTextSearchResultItem",
  documentId: string,
  title: TextWithHighlights,
  documentExcerpt: TextWithHighlights,
  queryId: string,
  resultId: string,
  pageNumber?: number | null,
};

export type TextWithHighlights = {
  __typename: "TextWithHighlights",
  text?: string | null,
  highlights?:  Array<Highlights > | null,
};

export type Highlights = {
  __typename: "Highlights",
  beginOffset: number,
  endOffset: number,
  topAnswer?: boolean | null,
  type: string,
};

export type ModelSubscriptionUserFilterInput = {
  cognitoSubId?: ModelSubscriptionIDInput | null,
  email?: ModelSubscriptionStringInput | null,
  givenName?: ModelSubscriptionStringInput | null,
  phoneNumber?: ModelSubscriptionStringInput | null,
  familyName?: ModelSubscriptionStringInput | null,
  meetingId?: ModelSubscriptionStringInput | null,
  shareBCC?: ModelSubscriptionStringInput | null,
  shareCC?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  createdBy?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  status?: ModelSubscriptionStringInput | null,
  role?: ModelSubscriptionStringInput | null,
  isExcludedFromReporting?: ModelSubscriptionBooleanInput | null,
  additionalRoles?: ModelSubscriptionStringInput | null,
  altUsername?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionUserFilterInput | null > | null,
  or?: Array< ModelSubscriptionUserFilterInput | null > | null,
};

export type ModelSubscriptionIDInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  in?: Array< string | null > | null,
  notIn?: Array< string | null > | null,
};

export type ModelSubscriptionStringInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  in?: Array< string | null > | null,
  notIn?: Array< string | null > | null,
};

export type ModelSubscriptionBooleanInput = {
  ne?: boolean | null,
  eq?: boolean | null,
};

export type ModelSubscriptionTenantFilterInput = {
  name?: ModelSubscriptionStringInput | null,
  licensedUsers?: ModelSubscriptionIntInput | null,
  status?: ModelSubscriptionStringInput | null,
  folderUpdateGracePeriodDays?: ModelSubscriptionIntInput | null,
  medInfoURL?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  createdBy?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  statusChangedAt?: ModelSubscriptionStringInput | null,
  statusChangedBy?: ModelSubscriptionStringInput | null,
  ssoDomains?: ModelSubscriptionStringInput | null,
  integrations?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionTenantFilterInput | null > | null,
  or?: Array< ModelSubscriptionTenantFilterInput | null > | null,
};

export type ModelSubscriptionIntInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  in?: Array< number | null > | null,
  notIn?: Array< number | null > | null,
};

export type ModelSubscriptionDocumentVersionFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  documentId?: ModelSubscriptionIDInput | null,
  versionNumber?: ModelSubscriptionIntInput | null,
  srcFilename?: ModelSubscriptionStringInput | null,
  contentURL?: ModelSubscriptionStringInput | null,
  conversionStatus?: ModelSubscriptionStringInput | null,
  status?: ModelSubscriptionStringInput | null,
  srcHash?: ModelSubscriptionStringInput | null,
  srcSize?: ModelSubscriptionFloatInput | null,
  numPages?: ModelSubscriptionIntInput | null,
  type?: ModelSubscriptionStringInput | null,
  releaseNotes?: ModelSubscriptionStringInput | null,
  changeType?: ModelSubscriptionStringInput | null,
  title?: ModelSubscriptionStringInput | null,
  shortDescription?: ModelSubscriptionStringInput | null,
  longDescription?: ModelSubscriptionStringInput | null,
  owner?: ModelSubscriptionStringInput | null,
  expiresAt?: ModelSubscriptionStringInput | null,
  hasCopyright?: ModelSubscriptionBooleanInput | null,
  hasCustomThumbnail?: ModelSubscriptionBooleanInput | null,
  purpose?: ModelSubscriptionStringInput | null,
  canHideSlides?: ModelSubscriptionBooleanInput | null,
  distributable?: ModelSubscriptionBooleanInput | null,
  downloadable?: ModelSubscriptionBooleanInput | null,
  isInternalGenerated?: ModelSubscriptionBooleanInput | null,
  notificationScope?: ModelSubscriptionStringInput | null,
  selectedThumbnail?: ModelSubscriptionIntInput | null,
  publishedAt?: ModelSubscriptionStringInput | null,
  uploadedAt?: ModelSubscriptionStringInput | null,
  uploadedBy?: ModelSubscriptionStringInput | null,
  convertedArchiveKey?: ModelSubscriptionStringInput | null,
  convertedArchiveSize?: ModelSubscriptionFloatInput | null,
  convertedFolderKey?: ModelSubscriptionStringInput | null,
  converterVersion?: ModelSubscriptionIntInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  createdBy?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  updatedBy?: ModelSubscriptionStringInput | null,
  integrationType?: ModelSubscriptionStringInput | null,
  conversionWarningCode?: ModelSubscriptionStringInput | null,
  conversionErrorCode?: ModelSubscriptionStringInput | null,
  scheduledPublish?: ModelSubscriptionStringInput | null,
  mappingStatus?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionDocumentVersionFilterInput | null > | null,
  or?: Array< ModelSubscriptionDocumentVersionFilterInput | null > | null,
};

export type ModelSubscriptionFloatInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  in?: Array< number | null > | null,
  notIn?: Array< number | null > | null,
};

export type ModelSubscriptionAttachedFileFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  title?: ModelSubscriptionStringInput | null,
  documentId?: ModelSubscriptionIDInput | null,
  srcFileName?: ModelSubscriptionStringInput | null,
  srcHash?: ModelSubscriptionStringInput | null,
  srcSize?: ModelSubscriptionFloatInput | null,
  type?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  createdBy?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionAttachedFileFilterInput | null > | null,
  or?: Array< ModelSubscriptionAttachedFileFilterInput | null > | null,
};

export type ModelSubscriptionDocumentFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  accessLevel?: ModelSubscriptionStringInput | null,
  status?: ModelSubscriptionStringInput | null,
  owner?: ModelSubscriptionStringInput | null,
  expiresAt?: ModelSubscriptionStringInput | null,
  integrationType?: ModelSubscriptionStringInput | null,
  type?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  createdBy?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  updatedBy?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionDocumentFilterInput | null > | null,
  or?: Array< ModelSubscriptionDocumentFilterInput | null > | null,
};

export type ModelSubscriptionEmailTemplateFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  subject?: ModelSubscriptionStringInput | null,
  title?: ModelSubscriptionStringInput | null,
  cc?: ModelSubscriptionStringInput | null,
  bcc?: ModelSubscriptionStringInput | null,
  body?: ModelSubscriptionStringInput | null,
  status?: ModelSubscriptionStringInput | null,
  createdBy?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedBy?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionEmailTemplateFilterInput | null > | null,
  or?: Array< ModelSubscriptionEmailTemplateFilterInput | null > | null,
};

export type ModelSubscriptionDocumentSettingFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  tenantId?: ModelSubscriptionIDInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  updatedBy?: ModelSubscriptionStringInput | null,
  documentId?: ModelSubscriptionIDInput | null,
  documentVersionId?: ModelSubscriptionIDInput | null,
  status?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionDocumentSettingFilterInput | null > | null,
  or?: Array< ModelSubscriptionDocumentSettingFilterInput | null > | null,
};

export type ModelSubscriptionUserNotationsFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  tenantId?: ModelSubscriptionIDInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  updatedBy?: ModelSubscriptionStringInput | null,
  documentId?: ModelSubscriptionIDInput | null,
  documentVersionId?: ModelSubscriptionIDInput | null,
  customDeckId?: ModelSubscriptionIDInput | null,
  status?: ModelSubscriptionStringInput | null,
  type?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionUserNotationsFilterInput | null > | null,
  or?: Array< ModelSubscriptionUserNotationsFilterInput | null > | null,
};

export type ModelSubscriptionCustomDeckFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  autoUpdateAcknowledgedAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  updatedBy?: ModelSubscriptionStringInput | null,
  tenantId?: ModelSubscriptionIDInput | null,
  title?: ModelSubscriptionStringInput | null,
  sourceCustomDeckId?: ModelSubscriptionIDInput | null,
  and?: Array< ModelSubscriptionCustomDeckFilterInput | null > | null,
  or?: Array< ModelSubscriptionCustomDeckFilterInput | null > | null,
};

export type ModelSubscriptionFolderFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  tenantId?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  isPinned?: ModelSubscriptionBooleanInput | null,
  status?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  updatedBy?: ModelSubscriptionStringInput | null,
  shareStatus?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionFolderFilterInput | null > | null,
  or?: Array< ModelSubscriptionFolderFilterInput | null > | null,
};

export type ModelSubscriptionContentShareFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  token?: ModelSubscriptionIDInput | null,
  tenantId?: ModelSubscriptionIDInput | null,
  type?: ModelSubscriptionStringInput | null,
  contentId?: ModelSubscriptionIDInput | null,
  meetingId?: ModelSubscriptionStringInput | null,
  expiresAt?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  revoked?: ModelSubscriptionBooleanInput | null,
  notificationScope?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionContentShareFilterInput | null > | null,
  or?: Array< ModelSubscriptionContentShareFilterInput | null > | null,
};

export type ModelSubscriptionIntegrationLogFilterInput = {
  integrationId?: ModelSubscriptionIDInput | null,
  id?: ModelSubscriptionIDInput | null,
  status?: ModelSubscriptionStringInput | null,
  tenantId?: ModelSubscriptionIDInput | null,
  and?: Array< ModelSubscriptionIntegrationLogFilterInput | null > | null,
  or?: Array< ModelSubscriptionIntegrationLogFilterInput | null > | null,
};

export type ModelSubscriptionIntegrationSettingsFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  tenantId?: ModelSubscriptionIDInput | null,
  syncContentEvery?: ModelSubscriptionIntInput | null,
  name?: ModelSubscriptionStringInput | null,
  integrationType?: ModelSubscriptionStringInput | null,
  enabled?: ModelSubscriptionBooleanInput | null,
  notificationEmail?: ModelSubscriptionStringInput | null,
  errorSyncEmail?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  createdBy?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  updatedBy?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionIntegrationSettingsFilterInput | null > | null,
  or?: Array< ModelSubscriptionIntegrationSettingsFilterInput | null > | null,
};

export type ModelSubscriptionMeetingFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  tenantId?: ModelSubscriptionIDInput | null,
  title?: ModelSubscriptionStringInput | null,
  startTime?: ModelSubscriptionStringInput | null,
  endTime?: ModelSubscriptionStringInput | null,
  type?: ModelSubscriptionStringInput | null,
  notes?: ModelSubscriptionStringInput | null,
  status?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  updatedBy?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionMeetingFilterInput | null > | null,
  or?: Array< ModelSubscriptionMeetingFilterInput | null > | null,
};

export type ModelSubscriptionHubFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  tenantId?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  status?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  updatedBy?: ModelSubscriptionStringInput | null,
  meetingId?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionHubFilterInput | null > | null,
  or?: Array< ModelSubscriptionHubFilterInput | null > | null,
};

export type ModelSubscriptionCustomFormRecordFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  tenantId?: ModelSubscriptionIDInput | null,
  customFormId?: ModelSubscriptionIDInput | null,
  entity?: ModelSubscriptionStringInput | null,
  status?: ModelSubscriptionStringInput | null,
  name?: ModelSubscriptionStringInput | null,
  parentId?: ModelSubscriptionStringInput | null,
  parentModel?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  updatedBy?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionCustomFormRecordFilterInput | null > | null,
  or?: Array< ModelSubscriptionCustomFormRecordFilterInput | null > | null,
};

export type CreateUserMutationVariables = {
  input: CreateUserInput,
  condition?: ModelUserConditionInput | null,
};

export type CreateUserMutation = {
  createUser?:  {
    __typename: "User",
    id: string,
    cognitoSubId?: string | null,
    tenantId: string,
    email: string,
    givenName: string,
    phoneNumber?: string | null,
    familyName: string,
    meetingId?: string | null,
    defaultFiltersCustomValues:  Array< {
      __typename: "CustomValues",
      fieldId: string,
      objectRecords?:  Array< {
        __typename: "ObjectRecord",
        id: string,
        externalId?: string | null,
        syncStatus?: CRMSyncStatus | null,
        status: OBJECT_RECORD_STATUS,
        values:  Array< {
          __typename: "ObjectRecordValue",
          fieldId: string,
          values: Array< string >,
        } >,
      } > | null,
      values: Array< string >,
    } >,
    lockedFiltersCustomValues:  Array< {
      __typename: "CustomValues",
      fieldId: string,
      objectRecords?:  Array< {
        __typename: "ObjectRecord",
        id: string,
        externalId?: string | null,
        syncStatus?: CRMSyncStatus | null,
        status: OBJECT_RECORD_STATUS,
        values:  Array< {
          __typename: "ObjectRecordValue",
          fieldId: string,
          values: Array< string >,
        } >,
      } > | null,
      values: Array< string >,
    } >,
    defaultFilterValues:  Array< {
      __typename: "LabelValues",
      key: string,
      values: Array< string >,
    } >,
    lockedFilters?:  Array< {
      __typename: "LabelValues",
      key: string,
      values: Array< string >,
    } > | null,
    shareBCC?: Array< string > | null,
    shareCC?: Array< string > | null,
    createdAt: string,
    createdBy: string,
    updatedAt: string,
    status?: USER_STATUS | null,
    role: USER_ROLE,
    bookmarkedDocs?:  Array< {
      __typename: "BookmarkedDoc",
      docID: string,
      createdAt: string,
    } > | null,
    isExcludedFromReporting?: boolean | null,
    additionalRoles?: Array< USER_ROLE > | null,
    altUsername?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateUserMutationVariables = {
  input: UpdateUserInput,
  condition?: ModelUserConditionInput | null,
};

export type UpdateUserMutation = {
  updateUser?:  {
    __typename: "User",
    id: string,
    cognitoSubId?: string | null,
    tenantId: string,
    email: string,
    givenName: string,
    phoneNumber?: string | null,
    familyName: string,
    meetingId?: string | null,
    defaultFiltersCustomValues:  Array< {
      __typename: "CustomValues",
      fieldId: string,
      objectRecords?:  Array< {
        __typename: "ObjectRecord",
        id: string,
        externalId?: string | null,
        syncStatus?: CRMSyncStatus | null,
        status: OBJECT_RECORD_STATUS,
        values:  Array< {
          __typename: "ObjectRecordValue",
          fieldId: string,
          values: Array< string >,
        } >,
      } > | null,
      values: Array< string >,
    } >,
    lockedFiltersCustomValues:  Array< {
      __typename: "CustomValues",
      fieldId: string,
      objectRecords?:  Array< {
        __typename: "ObjectRecord",
        id: string,
        externalId?: string | null,
        syncStatus?: CRMSyncStatus | null,
        status: OBJECT_RECORD_STATUS,
        values:  Array< {
          __typename: "ObjectRecordValue",
          fieldId: string,
          values: Array< string >,
        } >,
      } > | null,
      values: Array< string >,
    } >,
    defaultFilterValues:  Array< {
      __typename: "LabelValues",
      key: string,
      values: Array< string >,
    } >,
    lockedFilters?:  Array< {
      __typename: "LabelValues",
      key: string,
      values: Array< string >,
    } > | null,
    shareBCC?: Array< string > | null,
    shareCC?: Array< string > | null,
    createdAt: string,
    createdBy: string,
    updatedAt: string,
    status?: USER_STATUS | null,
    role: USER_ROLE,
    bookmarkedDocs?:  Array< {
      __typename: "BookmarkedDoc",
      docID: string,
      createdAt: string,
    } > | null,
    isExcludedFromReporting?: boolean | null,
    additionalRoles?: Array< USER_ROLE > | null,
    altUsername?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteUserMutationVariables = {
  input: DeleteUserInput,
  condition?: ModelUserConditionInput | null,
};

export type DeleteUserMutation = {
  deleteUser?:  {
    __typename: "User",
    id: string,
    cognitoSubId?: string | null,
    tenantId: string,
    email: string,
    givenName: string,
    phoneNumber?: string | null,
    familyName: string,
    meetingId?: string | null,
    defaultFiltersCustomValues:  Array< {
      __typename: "CustomValues",
      fieldId: string,
      objectRecords?:  Array< {
        __typename: "ObjectRecord",
        id: string,
        externalId?: string | null,
        syncStatus?: CRMSyncStatus | null,
        status: OBJECT_RECORD_STATUS,
        values:  Array< {
          __typename: "ObjectRecordValue",
          fieldId: string,
          values: Array< string >,
        } >,
      } > | null,
      values: Array< string >,
    } >,
    lockedFiltersCustomValues:  Array< {
      __typename: "CustomValues",
      fieldId: string,
      objectRecords?:  Array< {
        __typename: "ObjectRecord",
        id: string,
        externalId?: string | null,
        syncStatus?: CRMSyncStatus | null,
        status: OBJECT_RECORD_STATUS,
        values:  Array< {
          __typename: "ObjectRecordValue",
          fieldId: string,
          values: Array< string >,
        } >,
      } > | null,
      values: Array< string >,
    } >,
    defaultFilterValues:  Array< {
      __typename: "LabelValues",
      key: string,
      values: Array< string >,
    } >,
    lockedFilters?:  Array< {
      __typename: "LabelValues",
      key: string,
      values: Array< string >,
    } > | null,
    shareBCC?: Array< string > | null,
    shareCC?: Array< string > | null,
    createdAt: string,
    createdBy: string,
    updatedAt: string,
    status?: USER_STATUS | null,
    role: USER_ROLE,
    bookmarkedDocs?:  Array< {
      __typename: "BookmarkedDoc",
      docID: string,
      createdAt: string,
    } > | null,
    isExcludedFromReporting?: boolean | null,
    additionalRoles?: Array< USER_ROLE > | null,
    altUsername?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateTenantMutationVariables = {
  input: CreateTenantInput,
  condition?: ModelTenantConditionInput | null,
};

export type CreateTenantMutation = {
  createTenant?:  {
    __typename: "Tenant",
    id: string,
    name: string,
    licensedUsers: number,
    status: TENANT_STATUS,
    fields:  Array< {
      __typename: "FieldConfig",
      fieldName: string,
      description?: string | null,
      required: boolean,
      isEmailTemplateFilter?: boolean | null,
      dataType: FIELD_DATA_TYPE,
      userFilter: boolean,
      defaultSearchFilter: boolean,
      values: Array< string >,
    } >,
    folderUpdateGracePeriodDays?: number | null,
    medInfoURL?: string | null,
    publisherListConfig?:  {
      __typename: "ListConfiguration",
      fields:  Array< {
        __typename: "ListConfigurationField",
        fieldName: string,
        header?: string | null,
        width?: number | null,
      } >,
      sort?:  {
        __typename: "ListConfigurationSort",
        field: string,
        isAsc: boolean,
      } | null,
    } | null,
    mslListConfig?:  {
      __typename: "ListConfiguration",
      fields:  Array< {
        __typename: "ListConfigurationField",
        fieldName: string,
        header?: string | null,
        width?: number | null,
      } >,
      sort?:  {
        __typename: "ListConfigurationSort",
        field: string,
        isAsc: boolean,
      } | null,
    } | null,
    featureFlags:  Array< {
      __typename: "FeatureFlags",
      flagId: string,
      value: boolean,
    } >,
    createdAt: string,
    createdBy: string,
    updatedAt: string,
    statusChangedAt: string,
    statusChangedBy: string,
    ssoDomains?: Array< string | null > | null,
    integrations?: Array< string | null > | null,
    config:  {
      __typename: "TenantConfig",
      requiredSlidesHiddenMessage?: string | null,
      forms?:  Array< {
        __typename: "TenantForm",
        id: string,
        name: string,
        label: string,
        fields:  Array< {
          __typename: "CustomFieldDefinition",
          id: string,
          fieldName: string,
          fieldLabel: string,
          reportingName?: string | null,
          status: FIELD_STATUS,
          description?: string | null,
          required?: boolean | null,
          maxLength?: number | null,
          formatValidation?: FORMAT_VALIDATION | null,
          fieldType: FIELD_DATA_TYPE,
          showByDefault?: boolean | null,
          dependentFieldId?: string | null,
          controlType: CONTROL_TYPE,
          order?: number | null,
          settings?:  {
            __typename: "CommonFieldSettings",
            isBadge?: boolean | null,
            fieldPosition: FIELD_POSITION,
          } | null,
          usage: Array< CUSTOM_FIELD_USAGE >,
          documentSettings?:  {
            __typename: "DocumentFieldSettings",
            fieldPosition: FIELD_POSITION,
          } | null,
          fieldValueDefinitions:  Array< {
            __typename: "CustomFieldValueDefinition",
            id: string,
            value: string,
            label?: string | null,
            disabled?: boolean | null,
            isDefault?: boolean | null,
            documentSettings?:  {
              __typename: "DocumentFieldValueSettings",
              presentationWatermarkText?: string | null,
              permission?:  {
                __typename: "DocumentPermission",
                present?: PERMISSION_EFFECT | null,
                modify?: PERMISSION_EFFECT | null,
                share?: PERMISSION_EFFECT | null,
                download?: PERMISSION_EFFECT | null,
                externalNotation?: PERMISSION_EFFECT | null,
              } | null,
            } | null,
            badgeColor?: string | null,
            badgeLabel?: string | null,
            createdAt: string,
            dependentValueIds: Array< string >,
          } >,
          displayOnParentSelection?: boolean | null,
          displayOnValueSelection?: Array< string > | null,
          objectSetting?:  {
            __typename: "ObjectSetting",
            childrenFieldIds: Array< string >,
            restriction?:  {
              __typename: "ObjectRestriction",
              syncUpdate?: boolean | null,
              syncDelete?: boolean | null,
              update?: boolean | null,
              delete?: boolean | null,
            } | null,
          } | null,
          isChildField?: boolean | null,
          dateRestriction?: DATE_RESTRICTION | null,
          createdAt: string,
          scaleNumber?: number | null,
          precisionNumber?: number | null,
        } >,
        targetRequestTemplate: string,
        targetType: TENANT_FORM_TARGET_TYPE,
        targetAddress: string,
        targetParameters?:  Array< {
          __typename: "TenantFormTargetParameters",
          key: string,
          value: string,
        } > | null,
        status: TENANT_FORM_STATUS,
      } > | null,
      meetingFields?:  Array< {
        __typename: "MeetingFieldDefinition",
        id: string,
        fieldName: string,
        status: FIELD_STATUS,
        description?: string | null,
        required: boolean,
        maxLength?: number | null,
        type: FIELD_DATA_TYPE,
        controlType: CONTROL_TYPE,
        fieldValueDefinitions:  Array< {
          __typename: "MeetingFieldValueDefinition",
          id: string,
          value: string,
          disabled: boolean,
          createdAt: string,
        } >,
        createdAt: string,
      } > | null,
      customFields?:  Array< {
        __typename: "CustomFieldDefinition",
        id: string,
        fieldName: string,
        fieldLabel: string,
        reportingName?: string | null,
        status: FIELD_STATUS,
        description?: string | null,
        required?: boolean | null,
        maxLength?: number | null,
        formatValidation?: FORMAT_VALIDATION | null,
        fieldType: FIELD_DATA_TYPE,
        showByDefault?: boolean | null,
        dependentFieldId?: string | null,
        controlType: CONTROL_TYPE,
        order?: number | null,
        settings?:  {
          __typename: "CommonFieldSettings",
          isBadge?: boolean | null,
          fieldPosition: FIELD_POSITION,
        } | null,
        usage: Array< CUSTOM_FIELD_USAGE >,
        documentSettings?:  {
          __typename: "DocumentFieldSettings",
          fieldPosition: FIELD_POSITION,
        } | null,
        fieldValueDefinitions:  Array< {
          __typename: "CustomFieldValueDefinition",
          id: string,
          value: string,
          label?: string | null,
          disabled?: boolean | null,
          isDefault?: boolean | null,
          documentSettings?:  {
            __typename: "DocumentFieldValueSettings",
            presentationWatermarkText?: string | null,
            permission?:  {
              __typename: "DocumentPermission",
              present?: PERMISSION_EFFECT | null,
              modify?: PERMISSION_EFFECT | null,
              share?: PERMISSION_EFFECT | null,
              download?: PERMISSION_EFFECT | null,
              externalNotation?: PERMISSION_EFFECT | null,
            } | null,
          } | null,
          badgeColor?: string | null,
          badgeLabel?: string | null,
          createdAt: string,
          dependentValueIds: Array< string >,
        } >,
        displayOnParentSelection?: boolean | null,
        displayOnValueSelection?: Array< string > | null,
        objectSetting?:  {
          __typename: "ObjectSetting",
          childrenFieldIds: Array< string >,
          restriction?:  {
            __typename: "ObjectRestriction",
            syncUpdate?: boolean | null,
            syncDelete?: boolean | null,
            update?: boolean | null,
            delete?: boolean | null,
          } | null,
        } | null,
        isChildField?: boolean | null,
        dateRestriction?: DATE_RESTRICTION | null,
        createdAt: string,
        scaleNumber?: number | null,
        precisionNumber?: number | null,
      } > | null,
      defaultDocumentPermissions:  {
        __typename: "DocumentPermission",
        present?: PERMISSION_EFFECT | null,
        modify?: PERMISSION_EFFECT | null,
        share?: PERMISSION_EFFECT | null,
        download?: PERMISSION_EFFECT | null,
        externalNotation?: PERMISSION_EFFECT | null,
      },
      mslListConfig?:  {
        __typename: "ListConfig",
        sort?:  {
          __typename: "ListSort",
          field: string,
          isAsc?: boolean | null,
        } | null,
        fields:  Array< {
          __typename: "FieldListConfig",
          field: string,
          width?: number | null,
        } >,
      } | null,
      publisherListConfig?:  {
        __typename: "ListConfig",
        sort?:  {
          __typename: "ListSort",
          field: string,
          isAsc?: boolean | null,
        } | null,
        fields:  Array< {
          __typename: "FieldListConfig",
          field: string,
          width?: number | null,
        } >,
      } | null,
      crmIntegration?:  {
        __typename: "CRMIntegration",
        id: string,
        crmIntegrationType: CRM_INTEGRATION_TYPE,
        instanceUrl: string,
        clientId: string,
        redirectUri: string,
        name: string,
        accountsSettings:  {
          __typename: "CRMAccountSettings",
          query: string,
          displaySettings:  {
            __typename: "CRMDisplaySettings",
            shortened: string,
            extended?: Array< string > | null,
          },
          addressSettings:  {
            __typename: "CRMAddressSettings",
            alias: string,
            displaySettings:  {
              __typename: "CRMDisplaySettings",
              shortened: string,
              extended?: Array< string > | null,
            },
          },
        },
        meetingSetting?:  {
          __typename: "CRMTableSetting",
          apiName: string,
          presentationsFieldName?: string | null,
          children:  Array< {
            __typename: "CRMChildTableSetting",
            name: string,
            apiName: string,
            relationshipName?: string | null,
            type: CRM_INTEGRATION_TYPE,
            veevaSetting?:  {
              __typename: "VeevaSetting",
              markerFieldName?: string | null,
            } | null,
          } >,
          type: CRM_INTEGRATION_TYPE,
        } | null,
        additionalSettings?:  {
          __typename: "CRMAdditionalSettings",
          allowCommentsOnTasks?: boolean | null,
          detailContentTableSettings?:  {
            __typename: "CRMTableDetailContentPresented",
            name: string,
            apiName: string,
            relationshipName: string,
            prefix?: string | null,
          } | null,
          enableSurveys?: boolean | null,
          crmAccountToObjectSettings?:  Array< {
            __typename: "CRMAccountToObjectSetting",
            accountFieldNames:  Array< {
              __typename: "CRMAccountToObjectFields",
              accountFieldName: string,
              objectFieldName: string,
            } >,
            apiName: string,
          } > | null,
        } | null,
        loginUrl?: string | null,
        crmStandaloneForms?:  Array< {
          __typename: "CRMStandaloneForm",
          id: string,
          apiName: string,
          children:  Array< {
            __typename: "CRMChildTableSetting",
            name: string,
            apiName: string,
            relationshipName?: string | null,
            type: CRM_INTEGRATION_TYPE,
            veevaSetting?:  {
              __typename: "VeevaSetting",
              markerFieldName?: string | null,
            } | null,
          } >,
          type: CRM_INTEGRATION_TYPE,
          parents:  Array< {
            __typename: "CRMStandaloneFormParent",
            apiName: string,
            relationshipName: string,
          } >,
          usage?: Array< STANDALONE_FORM_USAGE > | null,
        } > | null,
      } | null,
      sessionTimeout?: number | null,
      quickFilters?:  Array< {
        __typename: "QuickFilter",
        id: string,
        label: string,
        path: Array< string >,
      } > | null,
      hubsConfig:  {
        __typename: "HubConfig",
        shareExpirationDays?: number | null,
        disabledSections: Array< HUB_SECTION_TYPE >,
        disableNotationDescription: boolean,
      },
      reports:  Array< {
        __typename: "KeyValue",
        key: string,
        value: boolean,
      } >,
      emailDocumentUpdateFrequency?: FREQUENCY | null,
      sharePPTXasPDF?: boolean | null,
    },
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateTenantMutationVariables = {
  input: UpdateTenantInput,
  condition?: ModelTenantConditionInput | null,
};

export type UpdateTenantMutation = {
  updateTenant?:  {
    __typename: "Tenant",
    id: string,
    name: string,
    licensedUsers: number,
    status: TENANT_STATUS,
    fields:  Array< {
      __typename: "FieldConfig",
      fieldName: string,
      description?: string | null,
      required: boolean,
      isEmailTemplateFilter?: boolean | null,
      dataType: FIELD_DATA_TYPE,
      userFilter: boolean,
      defaultSearchFilter: boolean,
      values: Array< string >,
    } >,
    folderUpdateGracePeriodDays?: number | null,
    medInfoURL?: string | null,
    publisherListConfig?:  {
      __typename: "ListConfiguration",
      fields:  Array< {
        __typename: "ListConfigurationField",
        fieldName: string,
        header?: string | null,
        width?: number | null,
      } >,
      sort?:  {
        __typename: "ListConfigurationSort",
        field: string,
        isAsc: boolean,
      } | null,
    } | null,
    mslListConfig?:  {
      __typename: "ListConfiguration",
      fields:  Array< {
        __typename: "ListConfigurationField",
        fieldName: string,
        header?: string | null,
        width?: number | null,
      } >,
      sort?:  {
        __typename: "ListConfigurationSort",
        field: string,
        isAsc: boolean,
      } | null,
    } | null,
    featureFlags:  Array< {
      __typename: "FeatureFlags",
      flagId: string,
      value: boolean,
    } >,
    createdAt: string,
    createdBy: string,
    updatedAt: string,
    statusChangedAt: string,
    statusChangedBy: string,
    ssoDomains?: Array< string | null > | null,
    integrations?: Array< string | null > | null,
    config:  {
      __typename: "TenantConfig",
      requiredSlidesHiddenMessage?: string | null,
      forms?:  Array< {
        __typename: "TenantForm",
        id: string,
        name: string,
        label: string,
        fields:  Array< {
          __typename: "CustomFieldDefinition",
          id: string,
          fieldName: string,
          fieldLabel: string,
          reportingName?: string | null,
          status: FIELD_STATUS,
          description?: string | null,
          required?: boolean | null,
          maxLength?: number | null,
          formatValidation?: FORMAT_VALIDATION | null,
          fieldType: FIELD_DATA_TYPE,
          showByDefault?: boolean | null,
          dependentFieldId?: string | null,
          controlType: CONTROL_TYPE,
          order?: number | null,
          settings?:  {
            __typename: "CommonFieldSettings",
            isBadge?: boolean | null,
            fieldPosition: FIELD_POSITION,
          } | null,
          usage: Array< CUSTOM_FIELD_USAGE >,
          documentSettings?:  {
            __typename: "DocumentFieldSettings",
            fieldPosition: FIELD_POSITION,
          } | null,
          fieldValueDefinitions:  Array< {
            __typename: "CustomFieldValueDefinition",
            id: string,
            value: string,
            label?: string | null,
            disabled?: boolean | null,
            isDefault?: boolean | null,
            documentSettings?:  {
              __typename: "DocumentFieldValueSettings",
              presentationWatermarkText?: string | null,
              permission?:  {
                __typename: "DocumentPermission",
                present?: PERMISSION_EFFECT | null,
                modify?: PERMISSION_EFFECT | null,
                share?: PERMISSION_EFFECT | null,
                download?: PERMISSION_EFFECT | null,
                externalNotation?: PERMISSION_EFFECT | null,
              } | null,
            } | null,
            badgeColor?: string | null,
            badgeLabel?: string | null,
            createdAt: string,
            dependentValueIds: Array< string >,
          } >,
          displayOnParentSelection?: boolean | null,
          displayOnValueSelection?: Array< string > | null,
          objectSetting?:  {
            __typename: "ObjectSetting",
            childrenFieldIds: Array< string >,
            restriction?:  {
              __typename: "ObjectRestriction",
              syncUpdate?: boolean | null,
              syncDelete?: boolean | null,
              update?: boolean | null,
              delete?: boolean | null,
            } | null,
          } | null,
          isChildField?: boolean | null,
          dateRestriction?: DATE_RESTRICTION | null,
          createdAt: string,
          scaleNumber?: number | null,
          precisionNumber?: number | null,
        } >,
        targetRequestTemplate: string,
        targetType: TENANT_FORM_TARGET_TYPE,
        targetAddress: string,
        targetParameters?:  Array< {
          __typename: "TenantFormTargetParameters",
          key: string,
          value: string,
        } > | null,
        status: TENANT_FORM_STATUS,
      } > | null,
      meetingFields?:  Array< {
        __typename: "MeetingFieldDefinition",
        id: string,
        fieldName: string,
        status: FIELD_STATUS,
        description?: string | null,
        required: boolean,
        maxLength?: number | null,
        type: FIELD_DATA_TYPE,
        controlType: CONTROL_TYPE,
        fieldValueDefinitions:  Array< {
          __typename: "MeetingFieldValueDefinition",
          id: string,
          value: string,
          disabled: boolean,
          createdAt: string,
        } >,
        createdAt: string,
      } > | null,
      customFields?:  Array< {
        __typename: "CustomFieldDefinition",
        id: string,
        fieldName: string,
        fieldLabel: string,
        reportingName?: string | null,
        status: FIELD_STATUS,
        description?: string | null,
        required?: boolean | null,
        maxLength?: number | null,
        formatValidation?: FORMAT_VALIDATION | null,
        fieldType: FIELD_DATA_TYPE,
        showByDefault?: boolean | null,
        dependentFieldId?: string | null,
        controlType: CONTROL_TYPE,
        order?: number | null,
        settings?:  {
          __typename: "CommonFieldSettings",
          isBadge?: boolean | null,
          fieldPosition: FIELD_POSITION,
        } | null,
        usage: Array< CUSTOM_FIELD_USAGE >,
        documentSettings?:  {
          __typename: "DocumentFieldSettings",
          fieldPosition: FIELD_POSITION,
        } | null,
        fieldValueDefinitions:  Array< {
          __typename: "CustomFieldValueDefinition",
          id: string,
          value: string,
          label?: string | null,
          disabled?: boolean | null,
          isDefault?: boolean | null,
          documentSettings?:  {
            __typename: "DocumentFieldValueSettings",
            presentationWatermarkText?: string | null,
            permission?:  {
              __typename: "DocumentPermission",
              present?: PERMISSION_EFFECT | null,
              modify?: PERMISSION_EFFECT | null,
              share?: PERMISSION_EFFECT | null,
              download?: PERMISSION_EFFECT | null,
              externalNotation?: PERMISSION_EFFECT | null,
            } | null,
          } | null,
          badgeColor?: string | null,
          badgeLabel?: string | null,
          createdAt: string,
          dependentValueIds: Array< string >,
        } >,
        displayOnParentSelection?: boolean | null,
        displayOnValueSelection?: Array< string > | null,
        objectSetting?:  {
          __typename: "ObjectSetting",
          childrenFieldIds: Array< string >,
          restriction?:  {
            __typename: "ObjectRestriction",
            syncUpdate?: boolean | null,
            syncDelete?: boolean | null,
            update?: boolean | null,
            delete?: boolean | null,
          } | null,
        } | null,
        isChildField?: boolean | null,
        dateRestriction?: DATE_RESTRICTION | null,
        createdAt: string,
        scaleNumber?: number | null,
        precisionNumber?: number | null,
      } > | null,
      defaultDocumentPermissions:  {
        __typename: "DocumentPermission",
        present?: PERMISSION_EFFECT | null,
        modify?: PERMISSION_EFFECT | null,
        share?: PERMISSION_EFFECT | null,
        download?: PERMISSION_EFFECT | null,
        externalNotation?: PERMISSION_EFFECT | null,
      },
      mslListConfig?:  {
        __typename: "ListConfig",
        sort?:  {
          __typename: "ListSort",
          field: string,
          isAsc?: boolean | null,
        } | null,
        fields:  Array< {
          __typename: "FieldListConfig",
          field: string,
          width?: number | null,
        } >,
      } | null,
      publisherListConfig?:  {
        __typename: "ListConfig",
        sort?:  {
          __typename: "ListSort",
          field: string,
          isAsc?: boolean | null,
        } | null,
        fields:  Array< {
          __typename: "FieldListConfig",
          field: string,
          width?: number | null,
        } >,
      } | null,
      crmIntegration?:  {
        __typename: "CRMIntegration",
        id: string,
        crmIntegrationType: CRM_INTEGRATION_TYPE,
        instanceUrl: string,
        clientId: string,
        redirectUri: string,
        name: string,
        accountsSettings:  {
          __typename: "CRMAccountSettings",
          query: string,
          displaySettings:  {
            __typename: "CRMDisplaySettings",
            shortened: string,
            extended?: Array< string > | null,
          },
          addressSettings:  {
            __typename: "CRMAddressSettings",
            alias: string,
            displaySettings:  {
              __typename: "CRMDisplaySettings",
              shortened: string,
              extended?: Array< string > | null,
            },
          },
        },
        meetingSetting?:  {
          __typename: "CRMTableSetting",
          apiName: string,
          presentationsFieldName?: string | null,
          children:  Array< {
            __typename: "CRMChildTableSetting",
            name: string,
            apiName: string,
            relationshipName?: string | null,
            type: CRM_INTEGRATION_TYPE,
            veevaSetting?:  {
              __typename: "VeevaSetting",
              markerFieldName?: string | null,
            } | null,
          } >,
          type: CRM_INTEGRATION_TYPE,
        } | null,
        additionalSettings?:  {
          __typename: "CRMAdditionalSettings",
          allowCommentsOnTasks?: boolean | null,
          detailContentTableSettings?:  {
            __typename: "CRMTableDetailContentPresented",
            name: string,
            apiName: string,
            relationshipName: string,
            prefix?: string | null,
          } | null,
          enableSurveys?: boolean | null,
          crmAccountToObjectSettings?:  Array< {
            __typename: "CRMAccountToObjectSetting",
            accountFieldNames:  Array< {
              __typename: "CRMAccountToObjectFields",
              accountFieldName: string,
              objectFieldName: string,
            } >,
            apiName: string,
          } > | null,
        } | null,
        loginUrl?: string | null,
        crmStandaloneForms?:  Array< {
          __typename: "CRMStandaloneForm",
          id: string,
          apiName: string,
          children:  Array< {
            __typename: "CRMChildTableSetting",
            name: string,
            apiName: string,
            relationshipName?: string | null,
            type: CRM_INTEGRATION_TYPE,
            veevaSetting?:  {
              __typename: "VeevaSetting",
              markerFieldName?: string | null,
            } | null,
          } >,
          type: CRM_INTEGRATION_TYPE,
          parents:  Array< {
            __typename: "CRMStandaloneFormParent",
            apiName: string,
            relationshipName: string,
          } >,
          usage?: Array< STANDALONE_FORM_USAGE > | null,
        } > | null,
      } | null,
      sessionTimeout?: number | null,
      quickFilters?:  Array< {
        __typename: "QuickFilter",
        id: string,
        label: string,
        path: Array< string >,
      } > | null,
      hubsConfig:  {
        __typename: "HubConfig",
        shareExpirationDays?: number | null,
        disabledSections: Array< HUB_SECTION_TYPE >,
        disableNotationDescription: boolean,
      },
      reports:  Array< {
        __typename: "KeyValue",
        key: string,
        value: boolean,
      } >,
      emailDocumentUpdateFrequency?: FREQUENCY | null,
      sharePPTXasPDF?: boolean | null,
    },
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteTenantMutationVariables = {
  input: DeleteTenantInput,
  condition?: ModelTenantConditionInput | null,
};

export type DeleteTenantMutation = {
  deleteTenant?:  {
    __typename: "Tenant",
    id: string,
    name: string,
    licensedUsers: number,
    status: TENANT_STATUS,
    fields:  Array< {
      __typename: "FieldConfig",
      fieldName: string,
      description?: string | null,
      required: boolean,
      isEmailTemplateFilter?: boolean | null,
      dataType: FIELD_DATA_TYPE,
      userFilter: boolean,
      defaultSearchFilter: boolean,
      values: Array< string >,
    } >,
    folderUpdateGracePeriodDays?: number | null,
    medInfoURL?: string | null,
    publisherListConfig?:  {
      __typename: "ListConfiguration",
      fields:  Array< {
        __typename: "ListConfigurationField",
        fieldName: string,
        header?: string | null,
        width?: number | null,
      } >,
      sort?:  {
        __typename: "ListConfigurationSort",
        field: string,
        isAsc: boolean,
      } | null,
    } | null,
    mslListConfig?:  {
      __typename: "ListConfiguration",
      fields:  Array< {
        __typename: "ListConfigurationField",
        fieldName: string,
        header?: string | null,
        width?: number | null,
      } >,
      sort?:  {
        __typename: "ListConfigurationSort",
        field: string,
        isAsc: boolean,
      } | null,
    } | null,
    featureFlags:  Array< {
      __typename: "FeatureFlags",
      flagId: string,
      value: boolean,
    } >,
    createdAt: string,
    createdBy: string,
    updatedAt: string,
    statusChangedAt: string,
    statusChangedBy: string,
    ssoDomains?: Array< string | null > | null,
    integrations?: Array< string | null > | null,
    config:  {
      __typename: "TenantConfig",
      requiredSlidesHiddenMessage?: string | null,
      forms?:  Array< {
        __typename: "TenantForm",
        id: string,
        name: string,
        label: string,
        fields:  Array< {
          __typename: "CustomFieldDefinition",
          id: string,
          fieldName: string,
          fieldLabel: string,
          reportingName?: string | null,
          status: FIELD_STATUS,
          description?: string | null,
          required?: boolean | null,
          maxLength?: number | null,
          formatValidation?: FORMAT_VALIDATION | null,
          fieldType: FIELD_DATA_TYPE,
          showByDefault?: boolean | null,
          dependentFieldId?: string | null,
          controlType: CONTROL_TYPE,
          order?: number | null,
          settings?:  {
            __typename: "CommonFieldSettings",
            isBadge?: boolean | null,
            fieldPosition: FIELD_POSITION,
          } | null,
          usage: Array< CUSTOM_FIELD_USAGE >,
          documentSettings?:  {
            __typename: "DocumentFieldSettings",
            fieldPosition: FIELD_POSITION,
          } | null,
          fieldValueDefinitions:  Array< {
            __typename: "CustomFieldValueDefinition",
            id: string,
            value: string,
            label?: string | null,
            disabled?: boolean | null,
            isDefault?: boolean | null,
            documentSettings?:  {
              __typename: "DocumentFieldValueSettings",
              presentationWatermarkText?: string | null,
              permission?:  {
                __typename: "DocumentPermission",
                present?: PERMISSION_EFFECT | null,
                modify?: PERMISSION_EFFECT | null,
                share?: PERMISSION_EFFECT | null,
                download?: PERMISSION_EFFECT | null,
                externalNotation?: PERMISSION_EFFECT | null,
              } | null,
            } | null,
            badgeColor?: string | null,
            badgeLabel?: string | null,
            createdAt: string,
            dependentValueIds: Array< string >,
          } >,
          displayOnParentSelection?: boolean | null,
          displayOnValueSelection?: Array< string > | null,
          objectSetting?:  {
            __typename: "ObjectSetting",
            childrenFieldIds: Array< string >,
            restriction?:  {
              __typename: "ObjectRestriction",
              syncUpdate?: boolean | null,
              syncDelete?: boolean | null,
              update?: boolean | null,
              delete?: boolean | null,
            } | null,
          } | null,
          isChildField?: boolean | null,
          dateRestriction?: DATE_RESTRICTION | null,
          createdAt: string,
          scaleNumber?: number | null,
          precisionNumber?: number | null,
        } >,
        targetRequestTemplate: string,
        targetType: TENANT_FORM_TARGET_TYPE,
        targetAddress: string,
        targetParameters?:  Array< {
          __typename: "TenantFormTargetParameters",
          key: string,
          value: string,
        } > | null,
        status: TENANT_FORM_STATUS,
      } > | null,
      meetingFields?:  Array< {
        __typename: "MeetingFieldDefinition",
        id: string,
        fieldName: string,
        status: FIELD_STATUS,
        description?: string | null,
        required: boolean,
        maxLength?: number | null,
        type: FIELD_DATA_TYPE,
        controlType: CONTROL_TYPE,
        fieldValueDefinitions:  Array< {
          __typename: "MeetingFieldValueDefinition",
          id: string,
          value: string,
          disabled: boolean,
          createdAt: string,
        } >,
        createdAt: string,
      } > | null,
      customFields?:  Array< {
        __typename: "CustomFieldDefinition",
        id: string,
        fieldName: string,
        fieldLabel: string,
        reportingName?: string | null,
        status: FIELD_STATUS,
        description?: string | null,
        required?: boolean | null,
        maxLength?: number | null,
        formatValidation?: FORMAT_VALIDATION | null,
        fieldType: FIELD_DATA_TYPE,
        showByDefault?: boolean | null,
        dependentFieldId?: string | null,
        controlType: CONTROL_TYPE,
        order?: number | null,
        settings?:  {
          __typename: "CommonFieldSettings",
          isBadge?: boolean | null,
          fieldPosition: FIELD_POSITION,
        } | null,
        usage: Array< CUSTOM_FIELD_USAGE >,
        documentSettings?:  {
          __typename: "DocumentFieldSettings",
          fieldPosition: FIELD_POSITION,
        } | null,
        fieldValueDefinitions:  Array< {
          __typename: "CustomFieldValueDefinition",
          id: string,
          value: string,
          label?: string | null,
          disabled?: boolean | null,
          isDefault?: boolean | null,
          documentSettings?:  {
            __typename: "DocumentFieldValueSettings",
            presentationWatermarkText?: string | null,
            permission?:  {
              __typename: "DocumentPermission",
              present?: PERMISSION_EFFECT | null,
              modify?: PERMISSION_EFFECT | null,
              share?: PERMISSION_EFFECT | null,
              download?: PERMISSION_EFFECT | null,
              externalNotation?: PERMISSION_EFFECT | null,
            } | null,
          } | null,
          badgeColor?: string | null,
          badgeLabel?: string | null,
          createdAt: string,
          dependentValueIds: Array< string >,
        } >,
        displayOnParentSelection?: boolean | null,
        displayOnValueSelection?: Array< string > | null,
        objectSetting?:  {
          __typename: "ObjectSetting",
          childrenFieldIds: Array< string >,
          restriction?:  {
            __typename: "ObjectRestriction",
            syncUpdate?: boolean | null,
            syncDelete?: boolean | null,
            update?: boolean | null,
            delete?: boolean | null,
          } | null,
        } | null,
        isChildField?: boolean | null,
        dateRestriction?: DATE_RESTRICTION | null,
        createdAt: string,
        scaleNumber?: number | null,
        precisionNumber?: number | null,
      } > | null,
      defaultDocumentPermissions:  {
        __typename: "DocumentPermission",
        present?: PERMISSION_EFFECT | null,
        modify?: PERMISSION_EFFECT | null,
        share?: PERMISSION_EFFECT | null,
        download?: PERMISSION_EFFECT | null,
        externalNotation?: PERMISSION_EFFECT | null,
      },
      mslListConfig?:  {
        __typename: "ListConfig",
        sort?:  {
          __typename: "ListSort",
          field: string,
          isAsc?: boolean | null,
        } | null,
        fields:  Array< {
          __typename: "FieldListConfig",
          field: string,
          width?: number | null,
        } >,
      } | null,
      publisherListConfig?:  {
        __typename: "ListConfig",
        sort?:  {
          __typename: "ListSort",
          field: string,
          isAsc?: boolean | null,
        } | null,
        fields:  Array< {
          __typename: "FieldListConfig",
          field: string,
          width?: number | null,
        } >,
      } | null,
      crmIntegration?:  {
        __typename: "CRMIntegration",
        id: string,
        crmIntegrationType: CRM_INTEGRATION_TYPE,
        instanceUrl: string,
        clientId: string,
        redirectUri: string,
        name: string,
        accountsSettings:  {
          __typename: "CRMAccountSettings",
          query: string,
          displaySettings:  {
            __typename: "CRMDisplaySettings",
            shortened: string,
            extended?: Array< string > | null,
          },
          addressSettings:  {
            __typename: "CRMAddressSettings",
            alias: string,
            displaySettings:  {
              __typename: "CRMDisplaySettings",
              shortened: string,
              extended?: Array< string > | null,
            },
          },
        },
        meetingSetting?:  {
          __typename: "CRMTableSetting",
          apiName: string,
          presentationsFieldName?: string | null,
          children:  Array< {
            __typename: "CRMChildTableSetting",
            name: string,
            apiName: string,
            relationshipName?: string | null,
            type: CRM_INTEGRATION_TYPE,
            veevaSetting?:  {
              __typename: "VeevaSetting",
              markerFieldName?: string | null,
            } | null,
          } >,
          type: CRM_INTEGRATION_TYPE,
        } | null,
        additionalSettings?:  {
          __typename: "CRMAdditionalSettings",
          allowCommentsOnTasks?: boolean | null,
          detailContentTableSettings?:  {
            __typename: "CRMTableDetailContentPresented",
            name: string,
            apiName: string,
            relationshipName: string,
            prefix?: string | null,
          } | null,
          enableSurveys?: boolean | null,
          crmAccountToObjectSettings?:  Array< {
            __typename: "CRMAccountToObjectSetting",
            accountFieldNames:  Array< {
              __typename: "CRMAccountToObjectFields",
              accountFieldName: string,
              objectFieldName: string,
            } >,
            apiName: string,
          } > | null,
        } | null,
        loginUrl?: string | null,
        crmStandaloneForms?:  Array< {
          __typename: "CRMStandaloneForm",
          id: string,
          apiName: string,
          children:  Array< {
            __typename: "CRMChildTableSetting",
            name: string,
            apiName: string,
            relationshipName?: string | null,
            type: CRM_INTEGRATION_TYPE,
            veevaSetting?:  {
              __typename: "VeevaSetting",
              markerFieldName?: string | null,
            } | null,
          } >,
          type: CRM_INTEGRATION_TYPE,
          parents:  Array< {
            __typename: "CRMStandaloneFormParent",
            apiName: string,
            relationshipName: string,
          } >,
          usage?: Array< STANDALONE_FORM_USAGE > | null,
        } > | null,
      } | null,
      sessionTimeout?: number | null,
      quickFilters?:  Array< {
        __typename: "QuickFilter",
        id: string,
        label: string,
        path: Array< string >,
      } > | null,
      hubsConfig:  {
        __typename: "HubConfig",
        shareExpirationDays?: number | null,
        disabledSections: Array< HUB_SECTION_TYPE >,
        disableNotationDescription: boolean,
      },
      reports:  Array< {
        __typename: "KeyValue",
        key: string,
        value: boolean,
      } >,
      emailDocumentUpdateFrequency?: FREQUENCY | null,
      sharePPTXasPDF?: boolean | null,
    },
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateDocumentVersionMutationVariables = {
  input: CreateDocumentVersionInput,
  condition?: ModelDocumentVersionConditionInput | null,
};

export type CreateDocumentVersionMutation = {
  createDocumentVersion?:  {
    __typename: "DocumentVersion",
    id: string,
    tenantId: string,
    documentId: string,
    versionNumber: number,
    srcFilename?: string | null,
    contentURL?: string | null,
    conversionStatus: CONVERSION_STATUS,
    status: DOCUMENT_STATUS,
    srcFile?:  {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
      url: string,
    } | null,
    srcHash?: string | null,
    srcSize?: number | null,
    numPages?: number | null,
    pageSettings:  Array< {
      __typename: "PageSetting",
      pageId: string,
      number: number,
      isRequired?: boolean | null,
      linkedSlides: Array< string >,
    } >,
    pageGroups?:  Array< {
      __typename: "PageGroup",
      id: string,
      pageIds?: Array< string > | null,
      name: string,
      locked: boolean,
      sourceID?: string | null,
      source: PAGE_GROUP_SOURCE,
    } > | null,
    type: FILE_TYPE,
    releaseNotes?: string | null,
    changeType?: DOCUMENT_VERSION_CHANGE_TYPE | null,
    labelValues?:  Array< {
      __typename: "LabelValue",
      key: string,
      value: string,
    } > | null,
    customValues?:  Array< {
      __typename: "CustomValues",
      fieldId: string,
      objectRecords?:  Array< {
        __typename: "ObjectRecord",
        id: string,
        externalId?: string | null,
        syncStatus?: CRMSyncStatus | null,
        status: OBJECT_RECORD_STATUS,
        values:  Array< {
          __typename: "ObjectRecordValue",
          fieldId: string,
          values: Array< string >,
        } >,
      } > | null,
      values: Array< string >,
    } > | null,
    title?: string | null,
    shortDescription?: string | null,
    longDescription?: string | null,
    owner?: string | null,
    expiresAt?: string | null,
    hasCopyright?: boolean | null,
    hasCustomThumbnail?: boolean | null,
    purpose?: string | null,
    canHideSlides?: boolean | null,
    distributable?: boolean | null,
    downloadable?: boolean | null,
    isInternalGenerated?: boolean | null,
    semVer?:  {
      __typename: "SemVer",
      minor: number,
      major: number,
    } | null,
    notificationScope?: NOTIFICATION_SCOPE | null,
    selectedThumbnail?: number | null,
    publishedAt?: string | null,
    uploadedAt?: string | null,
    uploadedBy?: string | null,
    convertedArchiveKey?: string | null,
    convertedArchiveSize?: number | null,
    convertedFolderKey?: string | null,
    associatedFiles?:  Array< {
      __typename: "AssociatedFile",
      id: string,
      isDistributable?: boolean | null,
      isDefault?: boolean | null,
      type: ASSOCIATED_FILE_TYPE,
      attachmentId: string,
      status: ASSOCIATED_FILE_STATUS,
      createdAt: string,
      createdBy: string,
    } > | null,
    editPermissions: Array< string >,
    converterVersion?: number | null,
    createdAt: string,
    createdBy: string,
    updatedAt: string,
    updatedBy: string,
    integration?:  {
      __typename: "DocumentVersionIntegration",
      externalVersionId?: string | null,
      version?: string | null,
      timestamp?: string | null,
      srcFileHash?: string | null,
      srcDocumentHash?: string | null,
    } | null,
    integrationType?: INTEGRATION_TYPE | null,
    conversionWarningCode?: CONVERSION_WARNING_CODE | null,
    conversionErrorCode?: string | null,
    scheduledPublish?: string | null,
    mappingStatus?: MAPPING_STATUS | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateDocumentVersionMutationVariables = {
  input: UpdateDocumentVersionInput,
  condition?: ModelDocumentVersionConditionInput | null,
};

export type UpdateDocumentVersionMutation = {
  updateDocumentVersion?:  {
    __typename: "DocumentVersion",
    id: string,
    tenantId: string,
    documentId: string,
    versionNumber: number,
    srcFilename?: string | null,
    contentURL?: string | null,
    conversionStatus: CONVERSION_STATUS,
    status: DOCUMENT_STATUS,
    srcFile?:  {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
      url: string,
    } | null,
    srcHash?: string | null,
    srcSize?: number | null,
    numPages?: number | null,
    pageSettings:  Array< {
      __typename: "PageSetting",
      pageId: string,
      number: number,
      isRequired?: boolean | null,
      linkedSlides: Array< string >,
    } >,
    pageGroups?:  Array< {
      __typename: "PageGroup",
      id: string,
      pageIds?: Array< string > | null,
      name: string,
      locked: boolean,
      sourceID?: string | null,
      source: PAGE_GROUP_SOURCE,
    } > | null,
    type: FILE_TYPE,
    releaseNotes?: string | null,
    changeType?: DOCUMENT_VERSION_CHANGE_TYPE | null,
    labelValues?:  Array< {
      __typename: "LabelValue",
      key: string,
      value: string,
    } > | null,
    customValues?:  Array< {
      __typename: "CustomValues",
      fieldId: string,
      objectRecords?:  Array< {
        __typename: "ObjectRecord",
        id: string,
        externalId?: string | null,
        syncStatus?: CRMSyncStatus | null,
        status: OBJECT_RECORD_STATUS,
        values:  Array< {
          __typename: "ObjectRecordValue",
          fieldId: string,
          values: Array< string >,
        } >,
      } > | null,
      values: Array< string >,
    } > | null,
    title?: string | null,
    shortDescription?: string | null,
    longDescription?: string | null,
    owner?: string | null,
    expiresAt?: string | null,
    hasCopyright?: boolean | null,
    hasCustomThumbnail?: boolean | null,
    purpose?: string | null,
    canHideSlides?: boolean | null,
    distributable?: boolean | null,
    downloadable?: boolean | null,
    isInternalGenerated?: boolean | null,
    semVer?:  {
      __typename: "SemVer",
      minor: number,
      major: number,
    } | null,
    notificationScope?: NOTIFICATION_SCOPE | null,
    selectedThumbnail?: number | null,
    publishedAt?: string | null,
    uploadedAt?: string | null,
    uploadedBy?: string | null,
    convertedArchiveKey?: string | null,
    convertedArchiveSize?: number | null,
    convertedFolderKey?: string | null,
    associatedFiles?:  Array< {
      __typename: "AssociatedFile",
      id: string,
      isDistributable?: boolean | null,
      isDefault?: boolean | null,
      type: ASSOCIATED_FILE_TYPE,
      attachmentId: string,
      status: ASSOCIATED_FILE_STATUS,
      createdAt: string,
      createdBy: string,
    } > | null,
    editPermissions: Array< string >,
    converterVersion?: number | null,
    createdAt: string,
    createdBy: string,
    updatedAt: string,
    updatedBy: string,
    integration?:  {
      __typename: "DocumentVersionIntegration",
      externalVersionId?: string | null,
      version?: string | null,
      timestamp?: string | null,
      srcFileHash?: string | null,
      srcDocumentHash?: string | null,
    } | null,
    integrationType?: INTEGRATION_TYPE | null,
    conversionWarningCode?: CONVERSION_WARNING_CODE | null,
    conversionErrorCode?: string | null,
    scheduledPublish?: string | null,
    mappingStatus?: MAPPING_STATUS | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteDocumentVersionMutationVariables = {
  input: DeleteDocumentVersionInput,
  condition?: ModelDocumentVersionConditionInput | null,
};

export type DeleteDocumentVersionMutation = {
  deleteDocumentVersion?:  {
    __typename: "DocumentVersion",
    id: string,
    tenantId: string,
    documentId: string,
    versionNumber: number,
    srcFilename?: string | null,
    contentURL?: string | null,
    conversionStatus: CONVERSION_STATUS,
    status: DOCUMENT_STATUS,
    srcFile?:  {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
      url: string,
    } | null,
    srcHash?: string | null,
    srcSize?: number | null,
    numPages?: number | null,
    pageSettings:  Array< {
      __typename: "PageSetting",
      pageId: string,
      number: number,
      isRequired?: boolean | null,
      linkedSlides: Array< string >,
    } >,
    pageGroups?:  Array< {
      __typename: "PageGroup",
      id: string,
      pageIds?: Array< string > | null,
      name: string,
      locked: boolean,
      sourceID?: string | null,
      source: PAGE_GROUP_SOURCE,
    } > | null,
    type: FILE_TYPE,
    releaseNotes?: string | null,
    changeType?: DOCUMENT_VERSION_CHANGE_TYPE | null,
    labelValues?:  Array< {
      __typename: "LabelValue",
      key: string,
      value: string,
    } > | null,
    customValues?:  Array< {
      __typename: "CustomValues",
      fieldId: string,
      objectRecords?:  Array< {
        __typename: "ObjectRecord",
        id: string,
        externalId?: string | null,
        syncStatus?: CRMSyncStatus | null,
        status: OBJECT_RECORD_STATUS,
        values:  Array< {
          __typename: "ObjectRecordValue",
          fieldId: string,
          values: Array< string >,
        } >,
      } > | null,
      values: Array< string >,
    } > | null,
    title?: string | null,
    shortDescription?: string | null,
    longDescription?: string | null,
    owner?: string | null,
    expiresAt?: string | null,
    hasCopyright?: boolean | null,
    hasCustomThumbnail?: boolean | null,
    purpose?: string | null,
    canHideSlides?: boolean | null,
    distributable?: boolean | null,
    downloadable?: boolean | null,
    isInternalGenerated?: boolean | null,
    semVer?:  {
      __typename: "SemVer",
      minor: number,
      major: number,
    } | null,
    notificationScope?: NOTIFICATION_SCOPE | null,
    selectedThumbnail?: number | null,
    publishedAt?: string | null,
    uploadedAt?: string | null,
    uploadedBy?: string | null,
    convertedArchiveKey?: string | null,
    convertedArchiveSize?: number | null,
    convertedFolderKey?: string | null,
    associatedFiles?:  Array< {
      __typename: "AssociatedFile",
      id: string,
      isDistributable?: boolean | null,
      isDefault?: boolean | null,
      type: ASSOCIATED_FILE_TYPE,
      attachmentId: string,
      status: ASSOCIATED_FILE_STATUS,
      createdAt: string,
      createdBy: string,
    } > | null,
    editPermissions: Array< string >,
    converterVersion?: number | null,
    createdAt: string,
    createdBy: string,
    updatedAt: string,
    updatedBy: string,
    integration?:  {
      __typename: "DocumentVersionIntegration",
      externalVersionId?: string | null,
      version?: string | null,
      timestamp?: string | null,
      srcFileHash?: string | null,
      srcDocumentHash?: string | null,
    } | null,
    integrationType?: INTEGRATION_TYPE | null,
    conversionWarningCode?: CONVERSION_WARNING_CODE | null,
    conversionErrorCode?: string | null,
    scheduledPublish?: string | null,
    mappingStatus?: MAPPING_STATUS | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateAttachedFileMutationVariables = {
  input: CreateAttachedFileInput,
  condition?: ModelAttachedFileConditionInput | null,
};

export type CreateAttachedFileMutation = {
  createAttachedFile?:  {
    __typename: "AttachedFile",
    id: string,
    title: string,
    tenantId: string,
    documentId?: string | null,
    srcFile:  {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
      url: string,
    },
    srcFileName: string,
    srcHash?: string | null,
    srcSize: number,
    type: FILE_TYPE,
    editPermissions: Array< string >,
    createdAt: string,
    createdBy: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateAttachedFileMutationVariables = {
  input: UpdateAttachedFileInput,
  condition?: ModelAttachedFileConditionInput | null,
};

export type UpdateAttachedFileMutation = {
  updateAttachedFile?:  {
    __typename: "AttachedFile",
    id: string,
    title: string,
    tenantId: string,
    documentId?: string | null,
    srcFile:  {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
      url: string,
    },
    srcFileName: string,
    srcHash?: string | null,
    srcSize: number,
    type: FILE_TYPE,
    editPermissions: Array< string >,
    createdAt: string,
    createdBy: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteAttachedFileMutationVariables = {
  input: DeleteAttachedFileInput,
  condition?: ModelAttachedFileConditionInput | null,
};

export type DeleteAttachedFileMutation = {
  deleteAttachedFile?:  {
    __typename: "AttachedFile",
    id: string,
    title: string,
    tenantId: string,
    documentId?: string | null,
    srcFile:  {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
      url: string,
    },
    srcFileName: string,
    srcHash?: string | null,
    srcSize: number,
    type: FILE_TYPE,
    editPermissions: Array< string >,
    createdAt: string,
    createdBy: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateDocumentMutationVariables = {
  input: CreateDocumentInput,
  condition?: ModelDocumentConditionInput | null,
};

export type CreateDocumentMutation = {
  createDocument?:  {
    __typename: "Document",
    id: string,
    tenantId: string,
    accessLevel: DOCUMENT_ACCESS_LEVEL,
    status: DOCUMENT_STATUS,
    owner: string,
    expiresAt?: string | null,
    integration?:  {
      __typename: "DocumentIntegration",
      integrationId?: string | null,
      externalId?: string | null,
      firstSync?: string | null,
      lastSync?: string | null,
    } | null,
    integrationType?: INTEGRATION_TYPE | null,
    type: FILE_TYPE,
    editPermissions?: Array< string > | null,
    createdAt: string,
    createdBy: string,
    updatedAt: string,
    updatedBy: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateDocumentMutationVariables = {
  input: UpdateDocumentInput,
  condition?: ModelDocumentConditionInput | null,
};

export type UpdateDocumentMutation = {
  updateDocument?:  {
    __typename: "Document",
    id: string,
    tenantId: string,
    accessLevel: DOCUMENT_ACCESS_LEVEL,
    status: DOCUMENT_STATUS,
    owner: string,
    expiresAt?: string | null,
    integration?:  {
      __typename: "DocumentIntegration",
      integrationId?: string | null,
      externalId?: string | null,
      firstSync?: string | null,
      lastSync?: string | null,
    } | null,
    integrationType?: INTEGRATION_TYPE | null,
    type: FILE_TYPE,
    editPermissions?: Array< string > | null,
    createdAt: string,
    createdBy: string,
    updatedAt: string,
    updatedBy: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteDocumentMutationVariables = {
  input: DeleteDocumentInput,
  condition?: ModelDocumentConditionInput | null,
};

export type DeleteDocumentMutation = {
  deleteDocument?:  {
    __typename: "Document",
    id: string,
    tenantId: string,
    accessLevel: DOCUMENT_ACCESS_LEVEL,
    status: DOCUMENT_STATUS,
    owner: string,
    expiresAt?: string | null,
    integration?:  {
      __typename: "DocumentIntegration",
      integrationId?: string | null,
      externalId?: string | null,
      firstSync?: string | null,
      lastSync?: string | null,
    } | null,
    integrationType?: INTEGRATION_TYPE | null,
    type: FILE_TYPE,
    editPermissions?: Array< string > | null,
    createdAt: string,
    createdBy: string,
    updatedAt: string,
    updatedBy: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateEmailTemplateMutationVariables = {
  input: CreateEmailTemplateInput,
  condition?: ModelEmailTemplateConditionInput | null,
};

export type CreateEmailTemplateMutation = {
  createEmailTemplate?:  {
    __typename: "EmailTemplate",
    id: string,
    tenantId: string,
    labelValues?:  Array< {
      __typename: "LabelValue",
      key: string,
      value: string,
    } > | null,
    customFilterValues?:  Array< {
      __typename: "CustomValues",
      fieldId: string,
      objectRecords?:  Array< {
        __typename: "ObjectRecord",
        id: string,
        externalId?: string | null,
        syncStatus?: CRMSyncStatus | null,
        status: OBJECT_RECORD_STATUS,
        values:  Array< {
          __typename: "ObjectRecordValue",
          fieldId: string,
          values: Array< string >,
        } >,
      } > | null,
      values: Array< string >,
    } > | null,
    subject?: string | null,
    title?: string | null,
    cc?: Array< string > | null,
    bcc?: Array< string > | null,
    body?: string | null,
    associatedFiles?:  Array< {
      __typename: "AssociatedFile",
      id: string,
      isDistributable?: boolean | null,
      isDefault?: boolean | null,
      type: ASSOCIATED_FILE_TYPE,
      attachmentId: string,
      status: ASSOCIATED_FILE_STATUS,
      createdAt: string,
      createdBy: string,
    } > | null,
    status: EMAIL_TEMPLATE_STATUS,
    editPermissions?: Array< string > | null,
    createdBy: string,
    createdAt: string,
    updatedBy: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateEmailTemplateMutationVariables = {
  input: UpdateEmailTemplateInput,
  condition?: ModelEmailTemplateConditionInput | null,
};

export type UpdateEmailTemplateMutation = {
  updateEmailTemplate?:  {
    __typename: "EmailTemplate",
    id: string,
    tenantId: string,
    labelValues?:  Array< {
      __typename: "LabelValue",
      key: string,
      value: string,
    } > | null,
    customFilterValues?:  Array< {
      __typename: "CustomValues",
      fieldId: string,
      objectRecords?:  Array< {
        __typename: "ObjectRecord",
        id: string,
        externalId?: string | null,
        syncStatus?: CRMSyncStatus | null,
        status: OBJECT_RECORD_STATUS,
        values:  Array< {
          __typename: "ObjectRecordValue",
          fieldId: string,
          values: Array< string >,
        } >,
      } > | null,
      values: Array< string >,
    } > | null,
    subject?: string | null,
    title?: string | null,
    cc?: Array< string > | null,
    bcc?: Array< string > | null,
    body?: string | null,
    associatedFiles?:  Array< {
      __typename: "AssociatedFile",
      id: string,
      isDistributable?: boolean | null,
      isDefault?: boolean | null,
      type: ASSOCIATED_FILE_TYPE,
      attachmentId: string,
      status: ASSOCIATED_FILE_STATUS,
      createdAt: string,
      createdBy: string,
    } > | null,
    status: EMAIL_TEMPLATE_STATUS,
    editPermissions?: Array< string > | null,
    createdBy: string,
    createdAt: string,
    updatedBy: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteEmailTemplateMutationVariables = {
  input: DeleteEmailTemplateInput,
  condition?: ModelEmailTemplateConditionInput | null,
};

export type DeleteEmailTemplateMutation = {
  deleteEmailTemplate?:  {
    __typename: "EmailTemplate",
    id: string,
    tenantId: string,
    labelValues?:  Array< {
      __typename: "LabelValue",
      key: string,
      value: string,
    } > | null,
    customFilterValues?:  Array< {
      __typename: "CustomValues",
      fieldId: string,
      objectRecords?:  Array< {
        __typename: "ObjectRecord",
        id: string,
        externalId?: string | null,
        syncStatus?: CRMSyncStatus | null,
        status: OBJECT_RECORD_STATUS,
        values:  Array< {
          __typename: "ObjectRecordValue",
          fieldId: string,
          values: Array< string >,
        } >,
      } > | null,
      values: Array< string >,
    } > | null,
    subject?: string | null,
    title?: string | null,
    cc?: Array< string > | null,
    bcc?: Array< string > | null,
    body?: string | null,
    associatedFiles?:  Array< {
      __typename: "AssociatedFile",
      id: string,
      isDistributable?: boolean | null,
      isDefault?: boolean | null,
      type: ASSOCIATED_FILE_TYPE,
      attachmentId: string,
      status: ASSOCIATED_FILE_STATUS,
      createdAt: string,
      createdBy: string,
    } > | null,
    status: EMAIL_TEMPLATE_STATUS,
    editPermissions?: Array< string > | null,
    createdBy: string,
    createdAt: string,
    updatedBy: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateDocumentSettingMutationVariables = {
  input: CreateDocumentSettingInput,
  condition?: ModelDocumentSettingConditionInput | null,
};

export type CreateDocumentSettingMutation = {
  createDocumentSetting?:  {
    __typename: "DocumentSetting",
    id: string,
    tenantId: string,
    createdAt: string,
    createdBy: string,
    updatedAt: string,
    updatedBy: string,
    documentId: string,
    documentVersionId: string,
    notation:  Array< {
      __typename: "Notation",
      id: string,
      type: NOTATION_TYPE,
      description?: string | null,
      status: HUB_SECTION_ITEM_STATUS,
      pageId: string,
      coordinate?:  Array< {
        __typename: "Coordinate",
        x: number,
        y: number,
      } > | null,
      createdAt: string,
      createdBy: string,
      updatedAt: string,
      updatedBy: string,
    } >,
    status: USER_NOTATIONS_STATUS,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateDocumentSettingMutationVariables = {
  input: UpdateDocumentSettingInput,
  condition?: ModelDocumentSettingConditionInput | null,
};

export type UpdateDocumentSettingMutation = {
  updateDocumentSetting?:  {
    __typename: "DocumentSetting",
    id: string,
    tenantId: string,
    createdAt: string,
    createdBy: string,
    updatedAt: string,
    updatedBy: string,
    documentId: string,
    documentVersionId: string,
    notation:  Array< {
      __typename: "Notation",
      id: string,
      type: NOTATION_TYPE,
      description?: string | null,
      status: HUB_SECTION_ITEM_STATUS,
      pageId: string,
      coordinate?:  Array< {
        __typename: "Coordinate",
        x: number,
        y: number,
      } > | null,
      createdAt: string,
      createdBy: string,
      updatedAt: string,
      updatedBy: string,
    } >,
    status: USER_NOTATIONS_STATUS,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteDocumentSettingMutationVariables = {
  input: DeleteDocumentSettingInput,
  condition?: ModelDocumentSettingConditionInput | null,
};

export type DeleteDocumentSettingMutation = {
  deleteDocumentSetting?:  {
    __typename: "DocumentSetting",
    id: string,
    tenantId: string,
    createdAt: string,
    createdBy: string,
    updatedAt: string,
    updatedBy: string,
    documentId: string,
    documentVersionId: string,
    notation:  Array< {
      __typename: "Notation",
      id: string,
      type: NOTATION_TYPE,
      description?: string | null,
      status: HUB_SECTION_ITEM_STATUS,
      pageId: string,
      coordinate?:  Array< {
        __typename: "Coordinate",
        x: number,
        y: number,
      } > | null,
      createdAt: string,
      createdBy: string,
      updatedAt: string,
      updatedBy: string,
    } >,
    status: USER_NOTATIONS_STATUS,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateUserNotationsMutationVariables = {
  input: CreateUserNotationsInput,
  condition?: ModelUserNotationsConditionInput | null,
};

export type CreateUserNotationsMutation = {
  createUserNotations?:  {
    __typename: "UserNotations",
    id: string,
    tenantId: string,
    createdAt: string,
    createdBy: string,
    updatedAt: string,
    updatedBy: string,
    documentId?: string | null,
    documentVersionId?: string | null,
    customDeckId?: string | null,
    notation:  Array< {
      __typename: "Notation",
      id: string,
      type: NOTATION_TYPE,
      description?: string | null,
      status: HUB_SECTION_ITEM_STATUS,
      pageId: string,
      coordinate?:  Array< {
        __typename: "Coordinate",
        x: number,
        y: number,
      } > | null,
      createdAt: string,
      createdBy: string,
      updatedAt: string,
      updatedBy: string,
    } >,
    status: USER_NOTATIONS_STATUS,
    type: USER_NOTATIONS_TYPE,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateUserNotationsMutationVariables = {
  input: UpdateUserNotationsInput,
  condition?: ModelUserNotationsConditionInput | null,
};

export type UpdateUserNotationsMutation = {
  updateUserNotations?:  {
    __typename: "UserNotations",
    id: string,
    tenantId: string,
    createdAt: string,
    createdBy: string,
    updatedAt: string,
    updatedBy: string,
    documentId?: string | null,
    documentVersionId?: string | null,
    customDeckId?: string | null,
    notation:  Array< {
      __typename: "Notation",
      id: string,
      type: NOTATION_TYPE,
      description?: string | null,
      status: HUB_SECTION_ITEM_STATUS,
      pageId: string,
      coordinate?:  Array< {
        __typename: "Coordinate",
        x: number,
        y: number,
      } > | null,
      createdAt: string,
      createdBy: string,
      updatedAt: string,
      updatedBy: string,
    } >,
    status: USER_NOTATIONS_STATUS,
    type: USER_NOTATIONS_TYPE,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteUserNotationsMutationVariables = {
  input: DeleteUserNotationsInput,
  condition?: ModelUserNotationsConditionInput | null,
};

export type DeleteUserNotationsMutation = {
  deleteUserNotations?:  {
    __typename: "UserNotations",
    id: string,
    tenantId: string,
    createdAt: string,
    createdBy: string,
    updatedAt: string,
    updatedBy: string,
    documentId?: string | null,
    documentVersionId?: string | null,
    customDeckId?: string | null,
    notation:  Array< {
      __typename: "Notation",
      id: string,
      type: NOTATION_TYPE,
      description?: string | null,
      status: HUB_SECTION_ITEM_STATUS,
      pageId: string,
      coordinate?:  Array< {
        __typename: "Coordinate",
        x: number,
        y: number,
      } > | null,
      createdAt: string,
      createdBy: string,
      updatedAt: string,
      updatedBy: string,
    } >,
    status: USER_NOTATIONS_STATUS,
    type: USER_NOTATIONS_TYPE,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateCustomDeckMutationVariables = {
  input: CreateCustomDeckInput,
  condition?: ModelCustomDeckConditionInput | null,
};

export type CreateCustomDeckMutation = {
  createCustomDeck?:  {
    __typename: "CustomDeck",
    id: string,
    createdAt: string,
    createdBy: string,
    autoUpdateAcknowledgedAt?: string | null,
    updatedAt: string,
    updatedBy: string,
    tenantId: string,
    groups:  Array< {
      __typename: "CustomDeckGroup",
      id: string,
      pages:  Array< {
        __typename: "CustomDeckPage",
        pageId: string,
        pageNumber: number,
        documentVersionId: string,
      } >,
      visible: boolean,
      srcId?: string | null,
      docAccessLevel: DOCUMENT_ACCESS_LEVEL,
      name?: string | null,
      locked?: boolean | null,
    } >,
    title: string,
    editMutex?:  {
      __typename: "EditMutex",
      userId: string,
      timeStarted: string,
      lastSeenAt: string,
    } | null,
    sourceCustomDeckId?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateCustomDeckMutationVariables = {
  input: UpdateCustomDeckInput,
  condition?: ModelCustomDeckConditionInput | null,
};

export type UpdateCustomDeckMutation = {
  updateCustomDeck?:  {
    __typename: "CustomDeck",
    id: string,
    createdAt: string,
    createdBy: string,
    autoUpdateAcknowledgedAt?: string | null,
    updatedAt: string,
    updatedBy: string,
    tenantId: string,
    groups:  Array< {
      __typename: "CustomDeckGroup",
      id: string,
      pages:  Array< {
        __typename: "CustomDeckPage",
        pageId: string,
        pageNumber: number,
        documentVersionId: string,
      } >,
      visible: boolean,
      srcId?: string | null,
      docAccessLevel: DOCUMENT_ACCESS_LEVEL,
      name?: string | null,
      locked?: boolean | null,
    } >,
    title: string,
    editMutex?:  {
      __typename: "EditMutex",
      userId: string,
      timeStarted: string,
      lastSeenAt: string,
    } | null,
    sourceCustomDeckId?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteCustomDeckMutationVariables = {
  input: DeleteCustomDeckInput,
  condition?: ModelCustomDeckConditionInput | null,
};

export type DeleteCustomDeckMutation = {
  deleteCustomDeck?:  {
    __typename: "CustomDeck",
    id: string,
    createdAt: string,
    createdBy: string,
    autoUpdateAcknowledgedAt?: string | null,
    updatedAt: string,
    updatedBy: string,
    tenantId: string,
    groups:  Array< {
      __typename: "CustomDeckGroup",
      id: string,
      pages:  Array< {
        __typename: "CustomDeckPage",
        pageId: string,
        pageNumber: number,
        documentVersionId: string,
      } >,
      visible: boolean,
      srcId?: string | null,
      docAccessLevel: DOCUMENT_ACCESS_LEVEL,
      name?: string | null,
      locked?: boolean | null,
    } >,
    title: string,
    editMutex?:  {
      __typename: "EditMutex",
      userId: string,
      timeStarted: string,
      lastSeenAt: string,
    } | null,
    sourceCustomDeckId?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateFolderMutationVariables = {
  input: CreateFolderInput,
  condition?: ModelFolderConditionInput | null,
};

export type CreateFolderMutation = {
  createFolder?:  {
    __typename: "Folder",
    id: string,
    tenantId: string,
    name: string,
    isPinned?: boolean | null,
    status: FOLDER_STATUS,
    items:  Array< {
      __typename: "FolderItem",
      id: string,
      type: FOLDER_ITEM_TYPE,
      itemId: string,
      status?: FOLDER_ITEM_STATUS | null,
      addedAt: string,
      updateAcknowledgedAt?: string | null,
      itemLastUpdatedAt: string,
      visiblePages?: Array< number > | null,
      customTitle?: string | null,
    } >,
    createdAt: string,
    createdBy: string,
    updatedAt: string,
    updatedBy: string,
    sharePermissions?:  Array< {
      __typename: "SharePermission",
      id: string,
      isDeleted?: boolean | null,
      type: PERMISSION_TYPE,
      targetType: SHARE_TARGET_TYPE,
      targetUsername?: string | null,
      targetCustomValues?:  Array< {
        __typename: "CustomValues",
        fieldId: string,
        objectRecords?:  Array< {
          __typename: "ObjectRecord",
          id: string,
          externalId?: string | null,
          syncStatus?: CRMSyncStatus | null,
          status: OBJECT_RECORD_STATUS,
          values:  Array< {
            __typename: "ObjectRecordValue",
            fieldId: string,
            values: Array< string >,
          } >,
        } > | null,
        values: Array< string >,
      } > | null,
      targetLabels?:  Array< {
        __typename: "LabelValues",
        key: string,
        values: Array< string >,
      } > | null,
      createdAt: string,
      createdBy: string,
      updatedAt: string,
      updatedBy: string,
      deletedBy?: string | null,
      deletedAt?: string | null,
    } > | null,
    shareStatus: SHARE_STATUS,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateFolderMutationVariables = {
  input: UpdateFolderInput,
  condition?: ModelFolderConditionInput | null,
};

export type UpdateFolderMutation = {
  updateFolder?:  {
    __typename: "Folder",
    id: string,
    tenantId: string,
    name: string,
    isPinned?: boolean | null,
    status: FOLDER_STATUS,
    items:  Array< {
      __typename: "FolderItem",
      id: string,
      type: FOLDER_ITEM_TYPE,
      itemId: string,
      status?: FOLDER_ITEM_STATUS | null,
      addedAt: string,
      updateAcknowledgedAt?: string | null,
      itemLastUpdatedAt: string,
      visiblePages?: Array< number > | null,
      customTitle?: string | null,
    } >,
    createdAt: string,
    createdBy: string,
    updatedAt: string,
    updatedBy: string,
    sharePermissions?:  Array< {
      __typename: "SharePermission",
      id: string,
      isDeleted?: boolean | null,
      type: PERMISSION_TYPE,
      targetType: SHARE_TARGET_TYPE,
      targetUsername?: string | null,
      targetCustomValues?:  Array< {
        __typename: "CustomValues",
        fieldId: string,
        objectRecords?:  Array< {
          __typename: "ObjectRecord",
          id: string,
          externalId?: string | null,
          syncStatus?: CRMSyncStatus | null,
          status: OBJECT_RECORD_STATUS,
          values:  Array< {
            __typename: "ObjectRecordValue",
            fieldId: string,
            values: Array< string >,
          } >,
        } > | null,
        values: Array< string >,
      } > | null,
      targetLabels?:  Array< {
        __typename: "LabelValues",
        key: string,
        values: Array< string >,
      } > | null,
      createdAt: string,
      createdBy: string,
      updatedAt: string,
      updatedBy: string,
      deletedBy?: string | null,
      deletedAt?: string | null,
    } > | null,
    shareStatus: SHARE_STATUS,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteFolderMutationVariables = {
  input: DeleteFolderInput,
  condition?: ModelFolderConditionInput | null,
};

export type DeleteFolderMutation = {
  deleteFolder?:  {
    __typename: "Folder",
    id: string,
    tenantId: string,
    name: string,
    isPinned?: boolean | null,
    status: FOLDER_STATUS,
    items:  Array< {
      __typename: "FolderItem",
      id: string,
      type: FOLDER_ITEM_TYPE,
      itemId: string,
      status?: FOLDER_ITEM_STATUS | null,
      addedAt: string,
      updateAcknowledgedAt?: string | null,
      itemLastUpdatedAt: string,
      visiblePages?: Array< number > | null,
      customTitle?: string | null,
    } >,
    createdAt: string,
    createdBy: string,
    updatedAt: string,
    updatedBy: string,
    sharePermissions?:  Array< {
      __typename: "SharePermission",
      id: string,
      isDeleted?: boolean | null,
      type: PERMISSION_TYPE,
      targetType: SHARE_TARGET_TYPE,
      targetUsername?: string | null,
      targetCustomValues?:  Array< {
        __typename: "CustomValues",
        fieldId: string,
        objectRecords?:  Array< {
          __typename: "ObjectRecord",
          id: string,
          externalId?: string | null,
          syncStatus?: CRMSyncStatus | null,
          status: OBJECT_RECORD_STATUS,
          values:  Array< {
            __typename: "ObjectRecordValue",
            fieldId: string,
            values: Array< string >,
          } >,
        } > | null,
        values: Array< string >,
      } > | null,
      targetLabels?:  Array< {
        __typename: "LabelValues",
        key: string,
        values: Array< string >,
      } > | null,
      createdAt: string,
      createdBy: string,
      updatedAt: string,
      updatedBy: string,
      deletedBy?: string | null,
      deletedAt?: string | null,
    } > | null,
    shareStatus: SHARE_STATUS,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateContentShareMutationVariables = {
  input: CreateContentShareInput,
  condition?: ModelContentShareConditionInput | null,
};

export type CreateContentShareMutation = {
  createContentShare?:  {
    __typename: "ContentShare",
    id: string,
    token: string,
    tenantId: string,
    type: SHARE_TYPE,
    contentId: string,
    meetingId?: string | null,
    expiresAt: string,
    createdAt: string,
    updatedAt: string,
    createdBy: string,
    customValues:  Array< {
      __typename: "CustomValues",
      fieldId: string,
      objectRecords?:  Array< {
        __typename: "ObjectRecord",
        id: string,
        externalId?: string | null,
        syncStatus?: CRMSyncStatus | null,
        status: OBJECT_RECORD_STATUS,
        values:  Array< {
          __typename: "ObjectRecordValue",
          fieldId: string,
          values: Array< string >,
        } >,
      } > | null,
      values: Array< string >,
    } >,
    revoked?: boolean | null,
    customDeckDependencies?:  {
      __typename: "CustomDeckDependencies",
      versions: Array< string >,
      folderId: string,
    } | null,
    notificationScope?: SHARE_NOTIFICATION_SCOPE | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateContentShareMutationVariables = {
  input: UpdateContentShareInput,
  condition?: ModelContentShareConditionInput | null,
};

export type UpdateContentShareMutation = {
  updateContentShare?:  {
    __typename: "ContentShare",
    id: string,
    token: string,
    tenantId: string,
    type: SHARE_TYPE,
    contentId: string,
    meetingId?: string | null,
    expiresAt: string,
    createdAt: string,
    updatedAt: string,
    createdBy: string,
    customValues:  Array< {
      __typename: "CustomValues",
      fieldId: string,
      objectRecords?:  Array< {
        __typename: "ObjectRecord",
        id: string,
        externalId?: string | null,
        syncStatus?: CRMSyncStatus | null,
        status: OBJECT_RECORD_STATUS,
        values:  Array< {
          __typename: "ObjectRecordValue",
          fieldId: string,
          values: Array< string >,
        } >,
      } > | null,
      values: Array< string >,
    } >,
    revoked?: boolean | null,
    customDeckDependencies?:  {
      __typename: "CustomDeckDependencies",
      versions: Array< string >,
      folderId: string,
    } | null,
    notificationScope?: SHARE_NOTIFICATION_SCOPE | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteContentShareMutationVariables = {
  input: DeleteContentShareInput,
  condition?: ModelContentShareConditionInput | null,
};

export type DeleteContentShareMutation = {
  deleteContentShare?:  {
    __typename: "ContentShare",
    id: string,
    token: string,
    tenantId: string,
    type: SHARE_TYPE,
    contentId: string,
    meetingId?: string | null,
    expiresAt: string,
    createdAt: string,
    updatedAt: string,
    createdBy: string,
    customValues:  Array< {
      __typename: "CustomValues",
      fieldId: string,
      objectRecords?:  Array< {
        __typename: "ObjectRecord",
        id: string,
        externalId?: string | null,
        syncStatus?: CRMSyncStatus | null,
        status: OBJECT_RECORD_STATUS,
        values:  Array< {
          __typename: "ObjectRecordValue",
          fieldId: string,
          values: Array< string >,
        } >,
      } > | null,
      values: Array< string >,
    } >,
    revoked?: boolean | null,
    customDeckDependencies?:  {
      __typename: "CustomDeckDependencies",
      versions: Array< string >,
      folderId: string,
    } | null,
    notificationScope?: SHARE_NOTIFICATION_SCOPE | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateIntegrationLogMutationVariables = {
  input: CreateIntegrationLogInput,
  condition?: ModelIntegrationLogConditionInput | null,
};

export type CreateIntegrationLogMutation = {
  createIntegrationLog?:  {
    __typename: "IntegrationLog",
    integrationId: string,
    id: string,
    log:  Array< {
      __typename: "IntegrationLogEntry",
      level: LOG_LEVEL,
      timestamp: string,
      message: string,
      srcDocId: string,
      srcDocVersion: string,
    } | null >,
    status: INTEGRATION_RUN_STATUS,
    tenantId: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateIntegrationLogMutationVariables = {
  input: UpdateIntegrationLogInput,
  condition?: ModelIntegrationLogConditionInput | null,
};

export type UpdateIntegrationLogMutation = {
  updateIntegrationLog?:  {
    __typename: "IntegrationLog",
    integrationId: string,
    id: string,
    log:  Array< {
      __typename: "IntegrationLogEntry",
      level: LOG_LEVEL,
      timestamp: string,
      message: string,
      srcDocId: string,
      srcDocVersion: string,
    } | null >,
    status: INTEGRATION_RUN_STATUS,
    tenantId: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteIntegrationLogMutationVariables = {
  input: DeleteIntegrationLogInput,
  condition?: ModelIntegrationLogConditionInput | null,
};

export type DeleteIntegrationLogMutation = {
  deleteIntegrationLog?:  {
    __typename: "IntegrationLog",
    integrationId: string,
    id: string,
    log:  Array< {
      __typename: "IntegrationLogEntry",
      level: LOG_LEVEL,
      timestamp: string,
      message: string,
      srcDocId: string,
      srcDocVersion: string,
    } | null >,
    status: INTEGRATION_RUN_STATUS,
    tenantId: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateIntegrationSettingsMutationVariables = {
  input: CreateIntegrationSettingsInput,
  condition?: ModelIntegrationSettingsConditionInput | null,
};

export type CreateIntegrationSettingsMutation = {
  createIntegrationSettings?:  {
    __typename: "IntegrationSettings",
    id: string,
    tenantId: string,
    syncContentEvery: number,
    name?: string | null,
    mapping:  Array< {
      __typename: "IntegrationFieldMapping",
      key: string,
      fieldType: string,
      dataType: string,
      targetFieldName: string,
      srcFieldname: string,
      valueMappings?:  Array< {
        __typename: "IntegrationValueMapping",
        srcValue: string,
        targetValue: string,
      } | null > | null,
    } | null >,
    clientConfigurationFields:  Array< {
      __typename: "IntegrationClientConfigurationTuple",
      key: string,
      value: string,
    } | null >,
    integrationType?: INTEGRATION_TYPE | null,
    enabled?: boolean | null,
    notificationEmail?: string | null,
    errorSyncEmail?: string | null,
    createdAt: string,
    createdBy: string,
    updatedAt: string,
    updatedBy: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateIntegrationSettingsMutationVariables = {
  input: UpdateIntegrationSettingsInput,
  condition?: ModelIntegrationSettingsConditionInput | null,
};

export type UpdateIntegrationSettingsMutation = {
  updateIntegrationSettings?:  {
    __typename: "IntegrationSettings",
    id: string,
    tenantId: string,
    syncContentEvery: number,
    name?: string | null,
    mapping:  Array< {
      __typename: "IntegrationFieldMapping",
      key: string,
      fieldType: string,
      dataType: string,
      targetFieldName: string,
      srcFieldname: string,
      valueMappings?:  Array< {
        __typename: "IntegrationValueMapping",
        srcValue: string,
        targetValue: string,
      } | null > | null,
    } | null >,
    clientConfigurationFields:  Array< {
      __typename: "IntegrationClientConfigurationTuple",
      key: string,
      value: string,
    } | null >,
    integrationType?: INTEGRATION_TYPE | null,
    enabled?: boolean | null,
    notificationEmail?: string | null,
    errorSyncEmail?: string | null,
    createdAt: string,
    createdBy: string,
    updatedAt: string,
    updatedBy: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteIntegrationSettingsMutationVariables = {
  input: DeleteIntegrationSettingsInput,
  condition?: ModelIntegrationSettingsConditionInput | null,
};

export type DeleteIntegrationSettingsMutation = {
  deleteIntegrationSettings?:  {
    __typename: "IntegrationSettings",
    id: string,
    tenantId: string,
    syncContentEvery: number,
    name?: string | null,
    mapping:  Array< {
      __typename: "IntegrationFieldMapping",
      key: string,
      fieldType: string,
      dataType: string,
      targetFieldName: string,
      srcFieldname: string,
      valueMappings?:  Array< {
        __typename: "IntegrationValueMapping",
        srcValue: string,
        targetValue: string,
      } | null > | null,
    } | null >,
    clientConfigurationFields:  Array< {
      __typename: "IntegrationClientConfigurationTuple",
      key: string,
      value: string,
    } | null >,
    integrationType?: INTEGRATION_TYPE | null,
    enabled?: boolean | null,
    notificationEmail?: string | null,
    errorSyncEmail?: string | null,
    createdAt: string,
    createdBy: string,
    updatedAt: string,
    updatedBy: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateMeetingMutationVariables = {
  input: CreateMeetingInput,
  condition?: ModelMeetingConditionInput | null,
};

export type CreateMeetingMutation = {
  createMeeting?:  {
    __typename: "Meeting",
    id: string,
    tenantId: string,
    title?: string | null,
    startTime: string,
    endTime?: string | null,
    contentPresented:  Array< {
      __typename: "ContentPresented",
      contentId: string,
      groupId?: string | null,
      folderItemId?: string | null,
      title: string,
      groupTitle?: string | null,
      status: CONTENT_PRESENTED_STATUS,
      contentType: MEETING_CONTENT_TYPE,
      events?:  Array< {
        __typename: "PagePresentedEvent",
        pageNumber: number,
        pageId: string,
        timestamp: string,
        end?: string | null,
      } > | null,
      openedAt?: string | null,
      closedAt?: string | null,
      presentedMeta:  Array< {
        __typename: "PresentedMeta",
        pageId: string,
        presented: boolean,
        followUp?: boolean | null,
        sentiment?: SENTIMENT | null,
        note?: string | null,
        title?: string | null,
      } >,
    } >,
    type: MEETING_TYPE,
    fieldValues:  Array< {
      __typename: "FieldValue",
      fieldId: string,
      valueOrId: string,
    } >,
    customValues?:  Array< {
      __typename: "CustomValues",
      fieldId: string,
      objectRecords?:  Array< {
        __typename: "ObjectRecord",
        id: string,
        externalId?: string | null,
        syncStatus?: CRMSyncStatus | null,
        status: OBJECT_RECORD_STATUS,
        values:  Array< {
          __typename: "ObjectRecordValue",
          fieldId: string,
          values: Array< string >,
        } >,
      } > | null,
      values: Array< string >,
    } > | null,
    notes?: string | null,
    status: MEETING_STATUS,
    createdAt: string,
    createdBy: string,
    updatedAt: string,
    updatedBy: string,
    attendees:  Array< {
      __typename: "Attendee",
      id: string,
      name: string,
      status: MEETING_ATTENDEE_STATUS,
      updatedAt: string,
      email?: string | null,
      crmAccountId?: string | null,
      crmAddressId?: string | null,
      attendeeType: ATTENDEE_TYPE,
      crmRecord?:  {
        __typename: "CRMMeetingRecordInfo",
        crmCallId?: string | null,
        crmCustomValues:  Array< {
          __typename: "CustomValues",
          fieldId: string,
          objectRecords?:  Array< {
            __typename: "ObjectRecord",
            id: string,
            externalId?: string | null,
            syncStatus?: CRMSyncStatus | null,
            status: OBJECT_RECORD_STATUS,
            values:  Array< {
              __typename: "ObjectRecordValue",
              fieldId: string,
              values: Array< string >,
            } >,
          } > | null,
          values: Array< string >,
        } >,
        crmSyncStatus?: CRMSyncStatus | null,
        lastSyncedAt?: string | null,
      } | null,
      lastSyncedAt?: string | null,
    } >,
    crmRecord?:  {
      __typename: "CRMMeetingRecordInfo",
      crmCallId?: string | null,
      crmCustomValues:  Array< {
        __typename: "CustomValues",
        fieldId: string,
        objectRecords?:  Array< {
          __typename: "ObjectRecord",
          id: string,
          externalId?: string | null,
          syncStatus?: CRMSyncStatus | null,
          status: OBJECT_RECORD_STATUS,
          values:  Array< {
            __typename: "ObjectRecordValue",
            fieldId: string,
            values: Array< string >,
          } >,
        } > | null,
        values: Array< string >,
      } >,
      crmSyncStatus?: CRMSyncStatus | null,
      lastSyncedAt?: string | null,
    } | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateMeetingMutationVariables = {
  input: UpdateMeetingInput,
  condition?: ModelMeetingConditionInput | null,
};

export type UpdateMeetingMutation = {
  updateMeeting?:  {
    __typename: "Meeting",
    id: string,
    tenantId: string,
    title?: string | null,
    startTime: string,
    endTime?: string | null,
    contentPresented:  Array< {
      __typename: "ContentPresented",
      contentId: string,
      groupId?: string | null,
      folderItemId?: string | null,
      title: string,
      groupTitle?: string | null,
      status: CONTENT_PRESENTED_STATUS,
      contentType: MEETING_CONTENT_TYPE,
      events?:  Array< {
        __typename: "PagePresentedEvent",
        pageNumber: number,
        pageId: string,
        timestamp: string,
        end?: string | null,
      } > | null,
      openedAt?: string | null,
      closedAt?: string | null,
      presentedMeta:  Array< {
        __typename: "PresentedMeta",
        pageId: string,
        presented: boolean,
        followUp?: boolean | null,
        sentiment?: SENTIMENT | null,
        note?: string | null,
        title?: string | null,
      } >,
    } >,
    type: MEETING_TYPE,
    fieldValues:  Array< {
      __typename: "FieldValue",
      fieldId: string,
      valueOrId: string,
    } >,
    customValues?:  Array< {
      __typename: "CustomValues",
      fieldId: string,
      objectRecords?:  Array< {
        __typename: "ObjectRecord",
        id: string,
        externalId?: string | null,
        syncStatus?: CRMSyncStatus | null,
        status: OBJECT_RECORD_STATUS,
        values:  Array< {
          __typename: "ObjectRecordValue",
          fieldId: string,
          values: Array< string >,
        } >,
      } > | null,
      values: Array< string >,
    } > | null,
    notes?: string | null,
    status: MEETING_STATUS,
    createdAt: string,
    createdBy: string,
    updatedAt: string,
    updatedBy: string,
    attendees:  Array< {
      __typename: "Attendee",
      id: string,
      name: string,
      status: MEETING_ATTENDEE_STATUS,
      updatedAt: string,
      email?: string | null,
      crmAccountId?: string | null,
      crmAddressId?: string | null,
      attendeeType: ATTENDEE_TYPE,
      crmRecord?:  {
        __typename: "CRMMeetingRecordInfo",
        crmCallId?: string | null,
        crmCustomValues:  Array< {
          __typename: "CustomValues",
          fieldId: string,
          objectRecords?:  Array< {
            __typename: "ObjectRecord",
            id: string,
            externalId?: string | null,
            syncStatus?: CRMSyncStatus | null,
            status: OBJECT_RECORD_STATUS,
            values:  Array< {
              __typename: "ObjectRecordValue",
              fieldId: string,
              values: Array< string >,
            } >,
          } > | null,
          values: Array< string >,
        } >,
        crmSyncStatus?: CRMSyncStatus | null,
        lastSyncedAt?: string | null,
      } | null,
      lastSyncedAt?: string | null,
    } >,
    crmRecord?:  {
      __typename: "CRMMeetingRecordInfo",
      crmCallId?: string | null,
      crmCustomValues:  Array< {
        __typename: "CustomValues",
        fieldId: string,
        objectRecords?:  Array< {
          __typename: "ObjectRecord",
          id: string,
          externalId?: string | null,
          syncStatus?: CRMSyncStatus | null,
          status: OBJECT_RECORD_STATUS,
          values:  Array< {
            __typename: "ObjectRecordValue",
            fieldId: string,
            values: Array< string >,
          } >,
        } > | null,
        values: Array< string >,
      } >,
      crmSyncStatus?: CRMSyncStatus | null,
      lastSyncedAt?: string | null,
    } | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteMeetingMutationVariables = {
  input: DeleteMeetingInput,
  condition?: ModelMeetingConditionInput | null,
};

export type DeleteMeetingMutation = {
  deleteMeeting?:  {
    __typename: "Meeting",
    id: string,
    tenantId: string,
    title?: string | null,
    startTime: string,
    endTime?: string | null,
    contentPresented:  Array< {
      __typename: "ContentPresented",
      contentId: string,
      groupId?: string | null,
      folderItemId?: string | null,
      title: string,
      groupTitle?: string | null,
      status: CONTENT_PRESENTED_STATUS,
      contentType: MEETING_CONTENT_TYPE,
      events?:  Array< {
        __typename: "PagePresentedEvent",
        pageNumber: number,
        pageId: string,
        timestamp: string,
        end?: string | null,
      } > | null,
      openedAt?: string | null,
      closedAt?: string | null,
      presentedMeta:  Array< {
        __typename: "PresentedMeta",
        pageId: string,
        presented: boolean,
        followUp?: boolean | null,
        sentiment?: SENTIMENT | null,
        note?: string | null,
        title?: string | null,
      } >,
    } >,
    type: MEETING_TYPE,
    fieldValues:  Array< {
      __typename: "FieldValue",
      fieldId: string,
      valueOrId: string,
    } >,
    customValues?:  Array< {
      __typename: "CustomValues",
      fieldId: string,
      objectRecords?:  Array< {
        __typename: "ObjectRecord",
        id: string,
        externalId?: string | null,
        syncStatus?: CRMSyncStatus | null,
        status: OBJECT_RECORD_STATUS,
        values:  Array< {
          __typename: "ObjectRecordValue",
          fieldId: string,
          values: Array< string >,
        } >,
      } > | null,
      values: Array< string >,
    } > | null,
    notes?: string | null,
    status: MEETING_STATUS,
    createdAt: string,
    createdBy: string,
    updatedAt: string,
    updatedBy: string,
    attendees:  Array< {
      __typename: "Attendee",
      id: string,
      name: string,
      status: MEETING_ATTENDEE_STATUS,
      updatedAt: string,
      email?: string | null,
      crmAccountId?: string | null,
      crmAddressId?: string | null,
      attendeeType: ATTENDEE_TYPE,
      crmRecord?:  {
        __typename: "CRMMeetingRecordInfo",
        crmCallId?: string | null,
        crmCustomValues:  Array< {
          __typename: "CustomValues",
          fieldId: string,
          objectRecords?:  Array< {
            __typename: "ObjectRecord",
            id: string,
            externalId?: string | null,
            syncStatus?: CRMSyncStatus | null,
            status: OBJECT_RECORD_STATUS,
            values:  Array< {
              __typename: "ObjectRecordValue",
              fieldId: string,
              values: Array< string >,
            } >,
          } > | null,
          values: Array< string >,
        } >,
        crmSyncStatus?: CRMSyncStatus | null,
        lastSyncedAt?: string | null,
      } | null,
      lastSyncedAt?: string | null,
    } >,
    crmRecord?:  {
      __typename: "CRMMeetingRecordInfo",
      crmCallId?: string | null,
      crmCustomValues:  Array< {
        __typename: "CustomValues",
        fieldId: string,
        objectRecords?:  Array< {
          __typename: "ObjectRecord",
          id: string,
          externalId?: string | null,
          syncStatus?: CRMSyncStatus | null,
          status: OBJECT_RECORD_STATUS,
          values:  Array< {
            __typename: "ObjectRecordValue",
            fieldId: string,
            values: Array< string >,
          } >,
        } > | null,
        values: Array< string >,
      } >,
      crmSyncStatus?: CRMSyncStatus | null,
      lastSyncedAt?: string | null,
    } | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateHubMutationVariables = {
  input: CreateHubInput,
  condition?: ModelHubConditionInput | null,
};

export type CreateHubMutation = {
  createHub?:  {
    __typename: "Hub",
    id: string,
    tenantId: string,
    name?: string | null,
    status: HUB_STATUS,
    createdAt: string,
    createdBy: string,
    updatedAt: string,
    updatedBy: string,
    hubSections?:  Array< {
      __typename: "HubSection",
      id: string,
      sectionHeader?: string | null,
      status: HUB_SECTION_STATUS,
      type: HUB_SECTION_TYPE,
      visible: boolean,
      order: number,
      textarea?:  {
        __typename: "TextareaHubSettings",
        content?: string | null,
      } | null,
      sharedFiles?:  Array< {
        __typename: "SharedFileHubSetting",
        id: string,
        contentId: string,
        title: string,
        contentType: HUB_SHARED_FILE_CONTENT_TYPE,
        documentVersionSettings?:  {
          __typename: "DocumentVersionSettings",
          associatedFiles?:  Array< {
            __typename: "HubSharedAssociatedFile",
            associatedFileId: string,
            versionId?: string | null,
            notation?:  Array< {
              __typename: "Notation",
              id: string,
              type: NOTATION_TYPE,
              description?: string | null,
              status: HUB_SECTION_ITEM_STATUS,
              pageId: string,
              coordinate?:  Array< {
                __typename: "Coordinate",
                x: number,
                y: number,
              } > | null,
              createdAt: string,
              createdBy: string,
              updatedAt: string,
              updatedBy: string,
            } > | null,
          } > | null,
        } | null,
        status: HUB_SECTION_ITEM_STATUS,
        createdAt: string,
        updatedAt: string,
        notation?:  Array< {
          __typename: "Notation",
          id: string,
          type: NOTATION_TYPE,
          description?: string | null,
          status: HUB_SECTION_ITEM_STATUS,
          pageId: string,
          coordinate?:  Array< {
            __typename: "Coordinate",
            x: number,
            y: number,
          } > | null,
          createdAt: string,
          createdBy: string,
          updatedAt: string,
          updatedBy: string,
        } > | null,
      } > | null,
      toDos?:  Array< {
        __typename: "ToDo",
        id: string,
        title: string,
        status: TODO_STATUS,
        completedAt?: string | null,
        resolution?: string | null,
        order: number,
        createdAt: string,
        updatedAt: string,
      } > | null,
      links?:  Array< {
        __typename: "Link",
        id: string,
        url: string,
        title?: string | null,
        status: HUB_SECTION_ITEM_STATUS,
        order: number,
        createdAt: string,
        updatedAt: string,
      } > | null,
      createdAt: string,
      updatedAt: string,
    } > | null,
    customValues?:  Array< {
      __typename: "CustomValues",
      fieldId: string,
      objectRecords?:  Array< {
        __typename: "ObjectRecord",
        id: string,
        externalId?: string | null,
        syncStatus?: CRMSyncStatus | null,
        status: OBJECT_RECORD_STATUS,
        values:  Array< {
          __typename: "ObjectRecordValue",
          fieldId: string,
          values: Array< string >,
        } >,
      } > | null,
      values: Array< string >,
    } > | null,
    meetingId?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateHubMutationVariables = {
  input: UpdateHubInput,
  condition?: ModelHubConditionInput | null,
};

export type UpdateHubMutation = {
  updateHub?:  {
    __typename: "Hub",
    id: string,
    tenantId: string,
    name?: string | null,
    status: HUB_STATUS,
    createdAt: string,
    createdBy: string,
    updatedAt: string,
    updatedBy: string,
    hubSections?:  Array< {
      __typename: "HubSection",
      id: string,
      sectionHeader?: string | null,
      status: HUB_SECTION_STATUS,
      type: HUB_SECTION_TYPE,
      visible: boolean,
      order: number,
      textarea?:  {
        __typename: "TextareaHubSettings",
        content?: string | null,
      } | null,
      sharedFiles?:  Array< {
        __typename: "SharedFileHubSetting",
        id: string,
        contentId: string,
        title: string,
        contentType: HUB_SHARED_FILE_CONTENT_TYPE,
        documentVersionSettings?:  {
          __typename: "DocumentVersionSettings",
          associatedFiles?:  Array< {
            __typename: "HubSharedAssociatedFile",
            associatedFileId: string,
            versionId?: string | null,
            notation?:  Array< {
              __typename: "Notation",
              id: string,
              type: NOTATION_TYPE,
              description?: string | null,
              status: HUB_SECTION_ITEM_STATUS,
              pageId: string,
              coordinate?:  Array< {
                __typename: "Coordinate",
                x: number,
                y: number,
              } > | null,
              createdAt: string,
              createdBy: string,
              updatedAt: string,
              updatedBy: string,
            } > | null,
          } > | null,
        } | null,
        status: HUB_SECTION_ITEM_STATUS,
        createdAt: string,
        updatedAt: string,
        notation?:  Array< {
          __typename: "Notation",
          id: string,
          type: NOTATION_TYPE,
          description?: string | null,
          status: HUB_SECTION_ITEM_STATUS,
          pageId: string,
          coordinate?:  Array< {
            __typename: "Coordinate",
            x: number,
            y: number,
          } > | null,
          createdAt: string,
          createdBy: string,
          updatedAt: string,
          updatedBy: string,
        } > | null,
      } > | null,
      toDos?:  Array< {
        __typename: "ToDo",
        id: string,
        title: string,
        status: TODO_STATUS,
        completedAt?: string | null,
        resolution?: string | null,
        order: number,
        createdAt: string,
        updatedAt: string,
      } > | null,
      links?:  Array< {
        __typename: "Link",
        id: string,
        url: string,
        title?: string | null,
        status: HUB_SECTION_ITEM_STATUS,
        order: number,
        createdAt: string,
        updatedAt: string,
      } > | null,
      createdAt: string,
      updatedAt: string,
    } > | null,
    customValues?:  Array< {
      __typename: "CustomValues",
      fieldId: string,
      objectRecords?:  Array< {
        __typename: "ObjectRecord",
        id: string,
        externalId?: string | null,
        syncStatus?: CRMSyncStatus | null,
        status: OBJECT_RECORD_STATUS,
        values:  Array< {
          __typename: "ObjectRecordValue",
          fieldId: string,
          values: Array< string >,
        } >,
      } > | null,
      values: Array< string >,
    } > | null,
    meetingId?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteHubMutationVariables = {
  input: DeleteHubInput,
  condition?: ModelHubConditionInput | null,
};

export type DeleteHubMutation = {
  deleteHub?:  {
    __typename: "Hub",
    id: string,
    tenantId: string,
    name?: string | null,
    status: HUB_STATUS,
    createdAt: string,
    createdBy: string,
    updatedAt: string,
    updatedBy: string,
    hubSections?:  Array< {
      __typename: "HubSection",
      id: string,
      sectionHeader?: string | null,
      status: HUB_SECTION_STATUS,
      type: HUB_SECTION_TYPE,
      visible: boolean,
      order: number,
      textarea?:  {
        __typename: "TextareaHubSettings",
        content?: string | null,
      } | null,
      sharedFiles?:  Array< {
        __typename: "SharedFileHubSetting",
        id: string,
        contentId: string,
        title: string,
        contentType: HUB_SHARED_FILE_CONTENT_TYPE,
        documentVersionSettings?:  {
          __typename: "DocumentVersionSettings",
          associatedFiles?:  Array< {
            __typename: "HubSharedAssociatedFile",
            associatedFileId: string,
            versionId?: string | null,
            notation?:  Array< {
              __typename: "Notation",
              id: string,
              type: NOTATION_TYPE,
              description?: string | null,
              status: HUB_SECTION_ITEM_STATUS,
              pageId: string,
              coordinate?:  Array< {
                __typename: "Coordinate",
                x: number,
                y: number,
              } > | null,
              createdAt: string,
              createdBy: string,
              updatedAt: string,
              updatedBy: string,
            } > | null,
          } > | null,
        } | null,
        status: HUB_SECTION_ITEM_STATUS,
        createdAt: string,
        updatedAt: string,
        notation?:  Array< {
          __typename: "Notation",
          id: string,
          type: NOTATION_TYPE,
          description?: string | null,
          status: HUB_SECTION_ITEM_STATUS,
          pageId: string,
          coordinate?:  Array< {
            __typename: "Coordinate",
            x: number,
            y: number,
          } > | null,
          createdAt: string,
          createdBy: string,
          updatedAt: string,
          updatedBy: string,
        } > | null,
      } > | null,
      toDos?:  Array< {
        __typename: "ToDo",
        id: string,
        title: string,
        status: TODO_STATUS,
        completedAt?: string | null,
        resolution?: string | null,
        order: number,
        createdAt: string,
        updatedAt: string,
      } > | null,
      links?:  Array< {
        __typename: "Link",
        id: string,
        url: string,
        title?: string | null,
        status: HUB_SECTION_ITEM_STATUS,
        order: number,
        createdAt: string,
        updatedAt: string,
      } > | null,
      createdAt: string,
      updatedAt: string,
    } > | null,
    customValues?:  Array< {
      __typename: "CustomValues",
      fieldId: string,
      objectRecords?:  Array< {
        __typename: "ObjectRecord",
        id: string,
        externalId?: string | null,
        syncStatus?: CRMSyncStatus | null,
        status: OBJECT_RECORD_STATUS,
        values:  Array< {
          __typename: "ObjectRecordValue",
          fieldId: string,
          values: Array< string >,
        } >,
      } > | null,
      values: Array< string >,
    } > | null,
    meetingId?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateCustomFormRecordMutationVariables = {
  input: CreateCustomFormRecordInput,
  condition?: ModelCustomFormRecordConditionInput | null,
};

export type CreateCustomFormRecordMutation = {
  createCustomFormRecord?:  {
    __typename: "CustomFormRecord",
    id: string,
    tenantId: string,
    customFormId: string,
    entity: CUSTOM_FORM_RECORD_ENTITY,
    status: CUSTOM_FORM_RECORD_STATUS,
    crmFields?:  {
      __typename: "CustomFormRecordCRMFields",
      accountId?: string | null,
      externalId?: string | null,
      apiName: string,
      recordTypeId?: string | null,
      syncStatus: CRMSyncStatus,
      lastSyncedAt?: string | null,
    } | null,
    name?: string | null,
    parentId?: string | null,
    parentModel?: CUSTOM_FORM_RECORD_RELATIONSHIP_MODEL | null,
    values:  Array< {
      __typename: "CustomValues",
      fieldId: string,
      objectRecords?:  Array< {
        __typename: "ObjectRecord",
        id: string,
        externalId?: string | null,
        syncStatus?: CRMSyncStatus | null,
        status: OBJECT_RECORD_STATUS,
        values:  Array< {
          __typename: "ObjectRecordValue",
          fieldId: string,
          values: Array< string >,
        } >,
      } > | null,
      values: Array< string >,
    } >,
    createdAt: string,
    createdBy: string,
    updatedAt: string,
    updatedBy: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateCustomFormRecordMutationVariables = {
  input: UpdateCustomFormRecordInput,
  condition?: ModelCustomFormRecordConditionInput | null,
};

export type UpdateCustomFormRecordMutation = {
  updateCustomFormRecord?:  {
    __typename: "CustomFormRecord",
    id: string,
    tenantId: string,
    customFormId: string,
    entity: CUSTOM_FORM_RECORD_ENTITY,
    status: CUSTOM_FORM_RECORD_STATUS,
    crmFields?:  {
      __typename: "CustomFormRecordCRMFields",
      accountId?: string | null,
      externalId?: string | null,
      apiName: string,
      recordTypeId?: string | null,
      syncStatus: CRMSyncStatus,
      lastSyncedAt?: string | null,
    } | null,
    name?: string | null,
    parentId?: string | null,
    parentModel?: CUSTOM_FORM_RECORD_RELATIONSHIP_MODEL | null,
    values:  Array< {
      __typename: "CustomValues",
      fieldId: string,
      objectRecords?:  Array< {
        __typename: "ObjectRecord",
        id: string,
        externalId?: string | null,
        syncStatus?: CRMSyncStatus | null,
        status: OBJECT_RECORD_STATUS,
        values:  Array< {
          __typename: "ObjectRecordValue",
          fieldId: string,
          values: Array< string >,
        } >,
      } > | null,
      values: Array< string >,
    } >,
    createdAt: string,
    createdBy: string,
    updatedAt: string,
    updatedBy: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteCustomFormRecordMutationVariables = {
  input: DeleteCustomFormRecordInput,
  condition?: ModelCustomFormRecordConditionInput | null,
};

export type DeleteCustomFormRecordMutation = {
  deleteCustomFormRecord?:  {
    __typename: "CustomFormRecord",
    id: string,
    tenantId: string,
    customFormId: string,
    entity: CUSTOM_FORM_RECORD_ENTITY,
    status: CUSTOM_FORM_RECORD_STATUS,
    crmFields?:  {
      __typename: "CustomFormRecordCRMFields",
      accountId?: string | null,
      externalId?: string | null,
      apiName: string,
      recordTypeId?: string | null,
      syncStatus: CRMSyncStatus,
      lastSyncedAt?: string | null,
    } | null,
    name?: string | null,
    parentId?: string | null,
    parentModel?: CUSTOM_FORM_RECORD_RELATIONSHIP_MODEL | null,
    values:  Array< {
      __typename: "CustomValues",
      fieldId: string,
      objectRecords?:  Array< {
        __typename: "ObjectRecord",
        id: string,
        externalId?: string | null,
        syncStatus?: CRMSyncStatus | null,
        status: OBJECT_RECORD_STATUS,
        values:  Array< {
          __typename: "ObjectRecordValue",
          fieldId: string,
          values: Array< string >,
        } >,
      } > | null,
      values: Array< string >,
    } >,
    createdAt: string,
    createdBy: string,
    updatedAt: string,
    updatedBy: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateTenantLambdaMutationVariables = {
  tenant?: CreateTenantInputLambda | null,
};

export type CreateTenantLambdaMutation = {
  createTenantLambda?:  {
    __typename: "Tenant",
    id: string,
    name: string,
    licensedUsers: number,
    status: TENANT_STATUS,
    fields:  Array< {
      __typename: "FieldConfig",
      fieldName: string,
      description?: string | null,
      required: boolean,
      isEmailTemplateFilter?: boolean | null,
      dataType: FIELD_DATA_TYPE,
      userFilter: boolean,
      defaultSearchFilter: boolean,
      values: Array< string >,
    } >,
    folderUpdateGracePeriodDays?: number | null,
    medInfoURL?: string | null,
    publisherListConfig?:  {
      __typename: "ListConfiguration",
      fields:  Array< {
        __typename: "ListConfigurationField",
        fieldName: string,
        header?: string | null,
        width?: number | null,
      } >,
      sort?:  {
        __typename: "ListConfigurationSort",
        field: string,
        isAsc: boolean,
      } | null,
    } | null,
    mslListConfig?:  {
      __typename: "ListConfiguration",
      fields:  Array< {
        __typename: "ListConfigurationField",
        fieldName: string,
        header?: string | null,
        width?: number | null,
      } >,
      sort?:  {
        __typename: "ListConfigurationSort",
        field: string,
        isAsc: boolean,
      } | null,
    } | null,
    featureFlags:  Array< {
      __typename: "FeatureFlags",
      flagId: string,
      value: boolean,
    } >,
    createdAt: string,
    createdBy: string,
    updatedAt: string,
    statusChangedAt: string,
    statusChangedBy: string,
    ssoDomains?: Array< string | null > | null,
    integrations?: Array< string | null > | null,
    config:  {
      __typename: "TenantConfig",
      requiredSlidesHiddenMessage?: string | null,
      forms?:  Array< {
        __typename: "TenantForm",
        id: string,
        name: string,
        label: string,
        fields:  Array< {
          __typename: "CustomFieldDefinition",
          id: string,
          fieldName: string,
          fieldLabel: string,
          reportingName?: string | null,
          status: FIELD_STATUS,
          description?: string | null,
          required?: boolean | null,
          maxLength?: number | null,
          formatValidation?: FORMAT_VALIDATION | null,
          fieldType: FIELD_DATA_TYPE,
          showByDefault?: boolean | null,
          dependentFieldId?: string | null,
          controlType: CONTROL_TYPE,
          order?: number | null,
          settings?:  {
            __typename: "CommonFieldSettings",
            isBadge?: boolean | null,
            fieldPosition: FIELD_POSITION,
          } | null,
          usage: Array< CUSTOM_FIELD_USAGE >,
          documentSettings?:  {
            __typename: "DocumentFieldSettings",
            fieldPosition: FIELD_POSITION,
          } | null,
          fieldValueDefinitions:  Array< {
            __typename: "CustomFieldValueDefinition",
            id: string,
            value: string,
            label?: string | null,
            disabled?: boolean | null,
            isDefault?: boolean | null,
            documentSettings?:  {
              __typename: "DocumentFieldValueSettings",
              presentationWatermarkText?: string | null,
              permission?:  {
                __typename: "DocumentPermission",
                present?: PERMISSION_EFFECT | null,
                modify?: PERMISSION_EFFECT | null,
                share?: PERMISSION_EFFECT | null,
                download?: PERMISSION_EFFECT | null,
                externalNotation?: PERMISSION_EFFECT | null,
              } | null,
            } | null,
            badgeColor?: string | null,
            badgeLabel?: string | null,
            createdAt: string,
            dependentValueIds: Array< string >,
          } >,
          displayOnParentSelection?: boolean | null,
          displayOnValueSelection?: Array< string > | null,
          objectSetting?:  {
            __typename: "ObjectSetting",
            childrenFieldIds: Array< string >,
            restriction?:  {
              __typename: "ObjectRestriction",
              syncUpdate?: boolean | null,
              syncDelete?: boolean | null,
              update?: boolean | null,
              delete?: boolean | null,
            } | null,
          } | null,
          isChildField?: boolean | null,
          dateRestriction?: DATE_RESTRICTION | null,
          createdAt: string,
          scaleNumber?: number | null,
          precisionNumber?: number | null,
        } >,
        targetRequestTemplate: string,
        targetType: TENANT_FORM_TARGET_TYPE,
        targetAddress: string,
        targetParameters?:  Array< {
          __typename: "TenantFormTargetParameters",
          key: string,
          value: string,
        } > | null,
        status: TENANT_FORM_STATUS,
      } > | null,
      meetingFields?:  Array< {
        __typename: "MeetingFieldDefinition",
        id: string,
        fieldName: string,
        status: FIELD_STATUS,
        description?: string | null,
        required: boolean,
        maxLength?: number | null,
        type: FIELD_DATA_TYPE,
        controlType: CONTROL_TYPE,
        fieldValueDefinitions:  Array< {
          __typename: "MeetingFieldValueDefinition",
          id: string,
          value: string,
          disabled: boolean,
          createdAt: string,
        } >,
        createdAt: string,
      } > | null,
      customFields?:  Array< {
        __typename: "CustomFieldDefinition",
        id: string,
        fieldName: string,
        fieldLabel: string,
        reportingName?: string | null,
        status: FIELD_STATUS,
        description?: string | null,
        required?: boolean | null,
        maxLength?: number | null,
        formatValidation?: FORMAT_VALIDATION | null,
        fieldType: FIELD_DATA_TYPE,
        showByDefault?: boolean | null,
        dependentFieldId?: string | null,
        controlType: CONTROL_TYPE,
        order?: number | null,
        settings?:  {
          __typename: "CommonFieldSettings",
          isBadge?: boolean | null,
          fieldPosition: FIELD_POSITION,
        } | null,
        usage: Array< CUSTOM_FIELD_USAGE >,
        documentSettings?:  {
          __typename: "DocumentFieldSettings",
          fieldPosition: FIELD_POSITION,
        } | null,
        fieldValueDefinitions:  Array< {
          __typename: "CustomFieldValueDefinition",
          id: string,
          value: string,
          label?: string | null,
          disabled?: boolean | null,
          isDefault?: boolean | null,
          documentSettings?:  {
            __typename: "DocumentFieldValueSettings",
            presentationWatermarkText?: string | null,
            permission?:  {
              __typename: "DocumentPermission",
              present?: PERMISSION_EFFECT | null,
              modify?: PERMISSION_EFFECT | null,
              share?: PERMISSION_EFFECT | null,
              download?: PERMISSION_EFFECT | null,
              externalNotation?: PERMISSION_EFFECT | null,
            } | null,
          } | null,
          badgeColor?: string | null,
          badgeLabel?: string | null,
          createdAt: string,
          dependentValueIds: Array< string >,
        } >,
        displayOnParentSelection?: boolean | null,
        displayOnValueSelection?: Array< string > | null,
        objectSetting?:  {
          __typename: "ObjectSetting",
          childrenFieldIds: Array< string >,
          restriction?:  {
            __typename: "ObjectRestriction",
            syncUpdate?: boolean | null,
            syncDelete?: boolean | null,
            update?: boolean | null,
            delete?: boolean | null,
          } | null,
        } | null,
        isChildField?: boolean | null,
        dateRestriction?: DATE_RESTRICTION | null,
        createdAt: string,
        scaleNumber?: number | null,
        precisionNumber?: number | null,
      } > | null,
      defaultDocumentPermissions:  {
        __typename: "DocumentPermission",
        present?: PERMISSION_EFFECT | null,
        modify?: PERMISSION_EFFECT | null,
        share?: PERMISSION_EFFECT | null,
        download?: PERMISSION_EFFECT | null,
        externalNotation?: PERMISSION_EFFECT | null,
      },
      mslListConfig?:  {
        __typename: "ListConfig",
        sort?:  {
          __typename: "ListSort",
          field: string,
          isAsc?: boolean | null,
        } | null,
        fields:  Array< {
          __typename: "FieldListConfig",
          field: string,
          width?: number | null,
        } >,
      } | null,
      publisherListConfig?:  {
        __typename: "ListConfig",
        sort?:  {
          __typename: "ListSort",
          field: string,
          isAsc?: boolean | null,
        } | null,
        fields:  Array< {
          __typename: "FieldListConfig",
          field: string,
          width?: number | null,
        } >,
      } | null,
      crmIntegration?:  {
        __typename: "CRMIntegration",
        id: string,
        crmIntegrationType: CRM_INTEGRATION_TYPE,
        instanceUrl: string,
        clientId: string,
        redirectUri: string,
        name: string,
        accountsSettings:  {
          __typename: "CRMAccountSettings",
          query: string,
          displaySettings:  {
            __typename: "CRMDisplaySettings",
            shortened: string,
            extended?: Array< string > | null,
          },
          addressSettings:  {
            __typename: "CRMAddressSettings",
            alias: string,
            displaySettings:  {
              __typename: "CRMDisplaySettings",
              shortened: string,
              extended?: Array< string > | null,
            },
          },
        },
        meetingSetting?:  {
          __typename: "CRMTableSetting",
          apiName: string,
          presentationsFieldName?: string | null,
          children:  Array< {
            __typename: "CRMChildTableSetting",
            name: string,
            apiName: string,
            relationshipName?: string | null,
            type: CRM_INTEGRATION_TYPE,
            veevaSetting?:  {
              __typename: "VeevaSetting",
              markerFieldName?: string | null,
            } | null,
          } >,
          type: CRM_INTEGRATION_TYPE,
        } | null,
        additionalSettings?:  {
          __typename: "CRMAdditionalSettings",
          allowCommentsOnTasks?: boolean | null,
          detailContentTableSettings?:  {
            __typename: "CRMTableDetailContentPresented",
            name: string,
            apiName: string,
            relationshipName: string,
            prefix?: string | null,
          } | null,
          enableSurveys?: boolean | null,
          crmAccountToObjectSettings?:  Array< {
            __typename: "CRMAccountToObjectSetting",
            accountFieldNames:  Array< {
              __typename: "CRMAccountToObjectFields",
              accountFieldName: string,
              objectFieldName: string,
            } >,
            apiName: string,
          } > | null,
        } | null,
        loginUrl?: string | null,
        crmStandaloneForms?:  Array< {
          __typename: "CRMStandaloneForm",
          id: string,
          apiName: string,
          children:  Array< {
            __typename: "CRMChildTableSetting",
            name: string,
            apiName: string,
            relationshipName?: string | null,
            type: CRM_INTEGRATION_TYPE,
            veevaSetting?:  {
              __typename: "VeevaSetting",
              markerFieldName?: string | null,
            } | null,
          } >,
          type: CRM_INTEGRATION_TYPE,
          parents:  Array< {
            __typename: "CRMStandaloneFormParent",
            apiName: string,
            relationshipName: string,
          } >,
          usage?: Array< STANDALONE_FORM_USAGE > | null,
        } > | null,
      } | null,
      sessionTimeout?: number | null,
      quickFilters?:  Array< {
        __typename: "QuickFilter",
        id: string,
        label: string,
        path: Array< string >,
      } > | null,
      hubsConfig:  {
        __typename: "HubConfig",
        shareExpirationDays?: number | null,
        disabledSections: Array< HUB_SECTION_TYPE >,
        disableNotationDescription: boolean,
      },
      reports:  Array< {
        __typename: "KeyValue",
        key: string,
        value: boolean,
      } >,
      emailDocumentUpdateFrequency?: FREQUENCY | null,
      sharePPTXasPDF?: boolean | null,
    },
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateDocumentFromS3UploadMutationVariables = {
  inputDoc: CreateDocumentFromS3UploadInput,
};

export type CreateDocumentFromS3UploadMutation = {
  createDocumentFromS3Upload?:  {
    __typename: "DocumentVersion",
    id: string,
    tenantId: string,
    documentId: string,
    versionNumber: number,
    srcFilename?: string | null,
    contentURL?: string | null,
    conversionStatus: CONVERSION_STATUS,
    status: DOCUMENT_STATUS,
    srcFile?:  {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
      url: string,
    } | null,
    srcHash?: string | null,
    srcSize?: number | null,
    numPages?: number | null,
    pageSettings:  Array< {
      __typename: "PageSetting",
      pageId: string,
      number: number,
      isRequired?: boolean | null,
      linkedSlides: Array< string >,
    } >,
    pageGroups?:  Array< {
      __typename: "PageGroup",
      id: string,
      pageIds?: Array< string > | null,
      name: string,
      locked: boolean,
      sourceID?: string | null,
      source: PAGE_GROUP_SOURCE,
    } > | null,
    type: FILE_TYPE,
    releaseNotes?: string | null,
    changeType?: DOCUMENT_VERSION_CHANGE_TYPE | null,
    labelValues?:  Array< {
      __typename: "LabelValue",
      key: string,
      value: string,
    } > | null,
    customValues?:  Array< {
      __typename: "CustomValues",
      fieldId: string,
      objectRecords?:  Array< {
        __typename: "ObjectRecord",
        id: string,
        externalId?: string | null,
        syncStatus?: CRMSyncStatus | null,
        status: OBJECT_RECORD_STATUS,
        values:  Array< {
          __typename: "ObjectRecordValue",
          fieldId: string,
          values: Array< string >,
        } >,
      } > | null,
      values: Array< string >,
    } > | null,
    title?: string | null,
    shortDescription?: string | null,
    longDescription?: string | null,
    owner?: string | null,
    expiresAt?: string | null,
    hasCopyright?: boolean | null,
    hasCustomThumbnail?: boolean | null,
    purpose?: string | null,
    canHideSlides?: boolean | null,
    distributable?: boolean | null,
    downloadable?: boolean | null,
    isInternalGenerated?: boolean | null,
    semVer?:  {
      __typename: "SemVer",
      minor: number,
      major: number,
    } | null,
    notificationScope?: NOTIFICATION_SCOPE | null,
    selectedThumbnail?: number | null,
    publishedAt?: string | null,
    uploadedAt?: string | null,
    uploadedBy?: string | null,
    convertedArchiveKey?: string | null,
    convertedArchiveSize?: number | null,
    convertedFolderKey?: string | null,
    associatedFiles?:  Array< {
      __typename: "AssociatedFile",
      id: string,
      isDistributable?: boolean | null,
      isDefault?: boolean | null,
      type: ASSOCIATED_FILE_TYPE,
      attachmentId: string,
      status: ASSOCIATED_FILE_STATUS,
      createdAt: string,
      createdBy: string,
    } > | null,
    editPermissions: Array< string >,
    converterVersion?: number | null,
    createdAt: string,
    createdBy: string,
    updatedAt: string,
    updatedBy: string,
    integration?:  {
      __typename: "DocumentVersionIntegration",
      externalVersionId?: string | null,
      version?: string | null,
      timestamp?: string | null,
      srcFileHash?: string | null,
      srcDocumentHash?: string | null,
    } | null,
    integrationType?: INTEGRATION_TYPE | null,
    conversionWarningCode?: CONVERSION_WARNING_CODE | null,
    conversionErrorCode?: string | null,
    scheduledPublish?: string | null,
    mappingStatus?: MAPPING_STATUS | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateUserDocumentFromS3UploadMutationVariables = {
  inputDoc: CreateDocumentFromS3UploadInput,
};

export type CreateUserDocumentFromS3UploadMutation = {
  createUserDocumentFromS3Upload?:  {
    __typename: "DocumentVersion",
    id: string,
    tenantId: string,
    documentId: string,
    versionNumber: number,
    srcFilename?: string | null,
    contentURL?: string | null,
    conversionStatus: CONVERSION_STATUS,
    status: DOCUMENT_STATUS,
    srcFile?:  {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
      url: string,
    } | null,
    srcHash?: string | null,
    srcSize?: number | null,
    numPages?: number | null,
    pageSettings:  Array< {
      __typename: "PageSetting",
      pageId: string,
      number: number,
      isRequired?: boolean | null,
      linkedSlides: Array< string >,
    } >,
    pageGroups?:  Array< {
      __typename: "PageGroup",
      id: string,
      pageIds?: Array< string > | null,
      name: string,
      locked: boolean,
      sourceID?: string | null,
      source: PAGE_GROUP_SOURCE,
    } > | null,
    type: FILE_TYPE,
    releaseNotes?: string | null,
    changeType?: DOCUMENT_VERSION_CHANGE_TYPE | null,
    labelValues?:  Array< {
      __typename: "LabelValue",
      key: string,
      value: string,
    } > | null,
    customValues?:  Array< {
      __typename: "CustomValues",
      fieldId: string,
      objectRecords?:  Array< {
        __typename: "ObjectRecord",
        id: string,
        externalId?: string | null,
        syncStatus?: CRMSyncStatus | null,
        status: OBJECT_RECORD_STATUS,
        values:  Array< {
          __typename: "ObjectRecordValue",
          fieldId: string,
          values: Array< string >,
        } >,
      } > | null,
      values: Array< string >,
    } > | null,
    title?: string | null,
    shortDescription?: string | null,
    longDescription?: string | null,
    owner?: string | null,
    expiresAt?: string | null,
    hasCopyright?: boolean | null,
    hasCustomThumbnail?: boolean | null,
    purpose?: string | null,
    canHideSlides?: boolean | null,
    distributable?: boolean | null,
    downloadable?: boolean | null,
    isInternalGenerated?: boolean | null,
    semVer?:  {
      __typename: "SemVer",
      minor: number,
      major: number,
    } | null,
    notificationScope?: NOTIFICATION_SCOPE | null,
    selectedThumbnail?: number | null,
    publishedAt?: string | null,
    uploadedAt?: string | null,
    uploadedBy?: string | null,
    convertedArchiveKey?: string | null,
    convertedArchiveSize?: number | null,
    convertedFolderKey?: string | null,
    associatedFiles?:  Array< {
      __typename: "AssociatedFile",
      id: string,
      isDistributable?: boolean | null,
      isDefault?: boolean | null,
      type: ASSOCIATED_FILE_TYPE,
      attachmentId: string,
      status: ASSOCIATED_FILE_STATUS,
      createdAt: string,
      createdBy: string,
    } > | null,
    editPermissions: Array< string >,
    converterVersion?: number | null,
    createdAt: string,
    createdBy: string,
    updatedAt: string,
    updatedBy: string,
    integration?:  {
      __typename: "DocumentVersionIntegration",
      externalVersionId?: string | null,
      version?: string | null,
      timestamp?: string | null,
      srcFileHash?: string | null,
      srcDocumentHash?: string | null,
    } | null,
    integrationType?: INTEGRATION_TYPE | null,
    conversionWarningCode?: CONVERSION_WARNING_CODE | null,
    conversionErrorCode?: string | null,
    scheduledPublish?: string | null,
    mappingStatus?: MAPPING_STATUS | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateWebDocumentMutation = {
  createWebDocument?:  {
    __typename: "DocumentVersion",
    id: string,
    tenantId: string,
    documentId: string,
    versionNumber: number,
    srcFilename?: string | null,
    contentURL?: string | null,
    conversionStatus: CONVERSION_STATUS,
    status: DOCUMENT_STATUS,
    srcFile?:  {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
      url: string,
    } | null,
    srcHash?: string | null,
    srcSize?: number | null,
    numPages?: number | null,
    pageSettings:  Array< {
      __typename: "PageSetting",
      pageId: string,
      number: number,
      isRequired?: boolean | null,
      linkedSlides: Array< string >,
    } >,
    pageGroups?:  Array< {
      __typename: "PageGroup",
      id: string,
      pageIds?: Array< string > | null,
      name: string,
      locked: boolean,
      sourceID?: string | null,
      source: PAGE_GROUP_SOURCE,
    } > | null,
    type: FILE_TYPE,
    releaseNotes?: string | null,
    changeType?: DOCUMENT_VERSION_CHANGE_TYPE | null,
    labelValues?:  Array< {
      __typename: "LabelValue",
      key: string,
      value: string,
    } > | null,
    customValues?:  Array< {
      __typename: "CustomValues",
      fieldId: string,
      objectRecords?:  Array< {
        __typename: "ObjectRecord",
        id: string,
        externalId?: string | null,
        syncStatus?: CRMSyncStatus | null,
        status: OBJECT_RECORD_STATUS,
        values:  Array< {
          __typename: "ObjectRecordValue",
          fieldId: string,
          values: Array< string >,
        } >,
      } > | null,
      values: Array< string >,
    } > | null,
    title?: string | null,
    shortDescription?: string | null,
    longDescription?: string | null,
    owner?: string | null,
    expiresAt?: string | null,
    hasCopyright?: boolean | null,
    hasCustomThumbnail?: boolean | null,
    purpose?: string | null,
    canHideSlides?: boolean | null,
    distributable?: boolean | null,
    downloadable?: boolean | null,
    isInternalGenerated?: boolean | null,
    semVer?:  {
      __typename: "SemVer",
      minor: number,
      major: number,
    } | null,
    notificationScope?: NOTIFICATION_SCOPE | null,
    selectedThumbnail?: number | null,
    publishedAt?: string | null,
    uploadedAt?: string | null,
    uploadedBy?: string | null,
    convertedArchiveKey?: string | null,
    convertedArchiveSize?: number | null,
    convertedFolderKey?: string | null,
    associatedFiles?:  Array< {
      __typename: "AssociatedFile",
      id: string,
      isDistributable?: boolean | null,
      isDefault?: boolean | null,
      type: ASSOCIATED_FILE_TYPE,
      attachmentId: string,
      status: ASSOCIATED_FILE_STATUS,
      createdAt: string,
      createdBy: string,
    } > | null,
    editPermissions: Array< string >,
    converterVersion?: number | null,
    createdAt: string,
    createdBy: string,
    updatedAt: string,
    updatedBy: string,
    integration?:  {
      __typename: "DocumentVersionIntegration",
      externalVersionId?: string | null,
      version?: string | null,
      timestamp?: string | null,
      srcFileHash?: string | null,
      srcDocumentHash?: string | null,
    } | null,
    integrationType?: INTEGRATION_TYPE | null,
    conversionWarningCode?: CONVERSION_WARNING_CODE | null,
    conversionErrorCode?: string | null,
    scheduledPublish?: string | null,
    mappingStatus?: MAPPING_STATUS | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateDocumentThumbnailMutationVariables = {
  inputDocumentThumbnail: UpdateDocumentThumbnailInput,
};

export type UpdateDocumentThumbnailMutation = {
  updateDocumentThumbnail?:  {
    __typename: "DocumentVersion",
    id: string,
    tenantId: string,
    documentId: string,
    versionNumber: number,
    srcFilename?: string | null,
    contentURL?: string | null,
    conversionStatus: CONVERSION_STATUS,
    status: DOCUMENT_STATUS,
    srcFile?:  {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
      url: string,
    } | null,
    srcHash?: string | null,
    srcSize?: number | null,
    numPages?: number | null,
    pageSettings:  Array< {
      __typename: "PageSetting",
      pageId: string,
      number: number,
      isRequired?: boolean | null,
      linkedSlides: Array< string >,
    } >,
    pageGroups?:  Array< {
      __typename: "PageGroup",
      id: string,
      pageIds?: Array< string > | null,
      name: string,
      locked: boolean,
      sourceID?: string | null,
      source: PAGE_GROUP_SOURCE,
    } > | null,
    type: FILE_TYPE,
    releaseNotes?: string | null,
    changeType?: DOCUMENT_VERSION_CHANGE_TYPE | null,
    labelValues?:  Array< {
      __typename: "LabelValue",
      key: string,
      value: string,
    } > | null,
    customValues?:  Array< {
      __typename: "CustomValues",
      fieldId: string,
      objectRecords?:  Array< {
        __typename: "ObjectRecord",
        id: string,
        externalId?: string | null,
        syncStatus?: CRMSyncStatus | null,
        status: OBJECT_RECORD_STATUS,
        values:  Array< {
          __typename: "ObjectRecordValue",
          fieldId: string,
          values: Array< string >,
        } >,
      } > | null,
      values: Array< string >,
    } > | null,
    title?: string | null,
    shortDescription?: string | null,
    longDescription?: string | null,
    owner?: string | null,
    expiresAt?: string | null,
    hasCopyright?: boolean | null,
    hasCustomThumbnail?: boolean | null,
    purpose?: string | null,
    canHideSlides?: boolean | null,
    distributable?: boolean | null,
    downloadable?: boolean | null,
    isInternalGenerated?: boolean | null,
    semVer?:  {
      __typename: "SemVer",
      minor: number,
      major: number,
    } | null,
    notificationScope?: NOTIFICATION_SCOPE | null,
    selectedThumbnail?: number | null,
    publishedAt?: string | null,
    uploadedAt?: string | null,
    uploadedBy?: string | null,
    convertedArchiveKey?: string | null,
    convertedArchiveSize?: number | null,
    convertedFolderKey?: string | null,
    associatedFiles?:  Array< {
      __typename: "AssociatedFile",
      id: string,
      isDistributable?: boolean | null,
      isDefault?: boolean | null,
      type: ASSOCIATED_FILE_TYPE,
      attachmentId: string,
      status: ASSOCIATED_FILE_STATUS,
      createdAt: string,
      createdBy: string,
    } > | null,
    editPermissions: Array< string >,
    converterVersion?: number | null,
    createdAt: string,
    createdBy: string,
    updatedAt: string,
    updatedBy: string,
    integration?:  {
      __typename: "DocumentVersionIntegration",
      externalVersionId?: string | null,
      version?: string | null,
      timestamp?: string | null,
      srcFileHash?: string | null,
      srcDocumentHash?: string | null,
    } | null,
    integrationType?: INTEGRATION_TYPE | null,
    conversionWarningCode?: CONVERSION_WARNING_CODE | null,
    conversionErrorCode?: string | null,
    scheduledPublish?: string | null,
    mappingStatus?: MAPPING_STATUS | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateDocumentFromS3UploadIntegrationEngineMutationVariables = {
  inputDoc: CreateDocumentFromS3UploadEngineInput,
};

export type CreateDocumentFromS3UploadIntegrationEngineMutation = {
  createDocumentFromS3UploadIntegrationEngine?:  {
    __typename: "DocumentVersion",
    id: string,
    tenantId: string,
    documentId: string,
    versionNumber: number,
    srcFilename?: string | null,
    contentURL?: string | null,
    conversionStatus: CONVERSION_STATUS,
    status: DOCUMENT_STATUS,
    srcFile?:  {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
      url: string,
    } | null,
    srcHash?: string | null,
    srcSize?: number | null,
    numPages?: number | null,
    pageSettings:  Array< {
      __typename: "PageSetting",
      pageId: string,
      number: number,
      isRequired?: boolean | null,
      linkedSlides: Array< string >,
    } >,
    pageGroups?:  Array< {
      __typename: "PageGroup",
      id: string,
      pageIds?: Array< string > | null,
      name: string,
      locked: boolean,
      sourceID?: string | null,
      source: PAGE_GROUP_SOURCE,
    } > | null,
    type: FILE_TYPE,
    releaseNotes?: string | null,
    changeType?: DOCUMENT_VERSION_CHANGE_TYPE | null,
    labelValues?:  Array< {
      __typename: "LabelValue",
      key: string,
      value: string,
    } > | null,
    customValues?:  Array< {
      __typename: "CustomValues",
      fieldId: string,
      objectRecords?:  Array< {
        __typename: "ObjectRecord",
        id: string,
        externalId?: string | null,
        syncStatus?: CRMSyncStatus | null,
        status: OBJECT_RECORD_STATUS,
        values:  Array< {
          __typename: "ObjectRecordValue",
          fieldId: string,
          values: Array< string >,
        } >,
      } > | null,
      values: Array< string >,
    } > | null,
    title?: string | null,
    shortDescription?: string | null,
    longDescription?: string | null,
    owner?: string | null,
    expiresAt?: string | null,
    hasCopyright?: boolean | null,
    hasCustomThumbnail?: boolean | null,
    purpose?: string | null,
    canHideSlides?: boolean | null,
    distributable?: boolean | null,
    downloadable?: boolean | null,
    isInternalGenerated?: boolean | null,
    semVer?:  {
      __typename: "SemVer",
      minor: number,
      major: number,
    } | null,
    notificationScope?: NOTIFICATION_SCOPE | null,
    selectedThumbnail?: number | null,
    publishedAt?: string | null,
    uploadedAt?: string | null,
    uploadedBy?: string | null,
    convertedArchiveKey?: string | null,
    convertedArchiveSize?: number | null,
    convertedFolderKey?: string | null,
    associatedFiles?:  Array< {
      __typename: "AssociatedFile",
      id: string,
      isDistributable?: boolean | null,
      isDefault?: boolean | null,
      type: ASSOCIATED_FILE_TYPE,
      attachmentId: string,
      status: ASSOCIATED_FILE_STATUS,
      createdAt: string,
      createdBy: string,
    } > | null,
    editPermissions: Array< string >,
    converterVersion?: number | null,
    createdAt: string,
    createdBy: string,
    updatedAt: string,
    updatedBy: string,
    integration?:  {
      __typename: "DocumentVersionIntegration",
      externalVersionId?: string | null,
      version?: string | null,
      timestamp?: string | null,
      srcFileHash?: string | null,
      srcDocumentHash?: string | null,
    } | null,
    integrationType?: INTEGRATION_TYPE | null,
    conversionWarningCode?: CONVERSION_WARNING_CODE | null,
    conversionErrorCode?: string | null,
    scheduledPublish?: string | null,
    mappingStatus?: MAPPING_STATUS | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateDocumentVersionFromS3UploadMutationVariables = {
  inputVersion: CreateDocumentVersionFromS3UploadInput,
};

export type CreateDocumentVersionFromS3UploadMutation = {
  createDocumentVersionFromS3Upload?:  {
    __typename: "DocumentVersion",
    id: string,
    tenantId: string,
    documentId: string,
    versionNumber: number,
    srcFilename?: string | null,
    contentURL?: string | null,
    conversionStatus: CONVERSION_STATUS,
    status: DOCUMENT_STATUS,
    srcFile?:  {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
      url: string,
    } | null,
    srcHash?: string | null,
    srcSize?: number | null,
    numPages?: number | null,
    pageSettings:  Array< {
      __typename: "PageSetting",
      pageId: string,
      number: number,
      isRequired?: boolean | null,
      linkedSlides: Array< string >,
    } >,
    pageGroups?:  Array< {
      __typename: "PageGroup",
      id: string,
      pageIds?: Array< string > | null,
      name: string,
      locked: boolean,
      sourceID?: string | null,
      source: PAGE_GROUP_SOURCE,
    } > | null,
    type: FILE_TYPE,
    releaseNotes?: string | null,
    changeType?: DOCUMENT_VERSION_CHANGE_TYPE | null,
    labelValues?:  Array< {
      __typename: "LabelValue",
      key: string,
      value: string,
    } > | null,
    customValues?:  Array< {
      __typename: "CustomValues",
      fieldId: string,
      objectRecords?:  Array< {
        __typename: "ObjectRecord",
        id: string,
        externalId?: string | null,
        syncStatus?: CRMSyncStatus | null,
        status: OBJECT_RECORD_STATUS,
        values:  Array< {
          __typename: "ObjectRecordValue",
          fieldId: string,
          values: Array< string >,
        } >,
      } > | null,
      values: Array< string >,
    } > | null,
    title?: string | null,
    shortDescription?: string | null,
    longDescription?: string | null,
    owner?: string | null,
    expiresAt?: string | null,
    hasCopyright?: boolean | null,
    hasCustomThumbnail?: boolean | null,
    purpose?: string | null,
    canHideSlides?: boolean | null,
    distributable?: boolean | null,
    downloadable?: boolean | null,
    isInternalGenerated?: boolean | null,
    semVer?:  {
      __typename: "SemVer",
      minor: number,
      major: number,
    } | null,
    notificationScope?: NOTIFICATION_SCOPE | null,
    selectedThumbnail?: number | null,
    publishedAt?: string | null,
    uploadedAt?: string | null,
    uploadedBy?: string | null,
    convertedArchiveKey?: string | null,
    convertedArchiveSize?: number | null,
    convertedFolderKey?: string | null,
    associatedFiles?:  Array< {
      __typename: "AssociatedFile",
      id: string,
      isDistributable?: boolean | null,
      isDefault?: boolean | null,
      type: ASSOCIATED_FILE_TYPE,
      attachmentId: string,
      status: ASSOCIATED_FILE_STATUS,
      createdAt: string,
      createdBy: string,
    } > | null,
    editPermissions: Array< string >,
    converterVersion?: number | null,
    createdAt: string,
    createdBy: string,
    updatedAt: string,
    updatedBy: string,
    integration?:  {
      __typename: "DocumentVersionIntegration",
      externalVersionId?: string | null,
      version?: string | null,
      timestamp?: string | null,
      srcFileHash?: string | null,
      srcDocumentHash?: string | null,
    } | null,
    integrationType?: INTEGRATION_TYPE | null,
    conversionWarningCode?: CONVERSION_WARNING_CODE | null,
    conversionErrorCode?: string | null,
    scheduledPublish?: string | null,
    mappingStatus?: MAPPING_STATUS | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateDocumentVersionFromS3UploadIntegrationEngineMutationVariables = {
  inputVersion: CreateDocumentVersionFromS3UploadEngineInput,
};

export type CreateDocumentVersionFromS3UploadIntegrationEngineMutation = {
  createDocumentVersionFromS3UploadIntegrationEngine?:  {
    __typename: "DocumentVersion",
    id: string,
    tenantId: string,
    documentId: string,
    versionNumber: number,
    srcFilename?: string | null,
    contentURL?: string | null,
    conversionStatus: CONVERSION_STATUS,
    status: DOCUMENT_STATUS,
    srcFile?:  {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
      url: string,
    } | null,
    srcHash?: string | null,
    srcSize?: number | null,
    numPages?: number | null,
    pageSettings:  Array< {
      __typename: "PageSetting",
      pageId: string,
      number: number,
      isRequired?: boolean | null,
      linkedSlides: Array< string >,
    } >,
    pageGroups?:  Array< {
      __typename: "PageGroup",
      id: string,
      pageIds?: Array< string > | null,
      name: string,
      locked: boolean,
      sourceID?: string | null,
      source: PAGE_GROUP_SOURCE,
    } > | null,
    type: FILE_TYPE,
    releaseNotes?: string | null,
    changeType?: DOCUMENT_VERSION_CHANGE_TYPE | null,
    labelValues?:  Array< {
      __typename: "LabelValue",
      key: string,
      value: string,
    } > | null,
    customValues?:  Array< {
      __typename: "CustomValues",
      fieldId: string,
      objectRecords?:  Array< {
        __typename: "ObjectRecord",
        id: string,
        externalId?: string | null,
        syncStatus?: CRMSyncStatus | null,
        status: OBJECT_RECORD_STATUS,
        values:  Array< {
          __typename: "ObjectRecordValue",
          fieldId: string,
          values: Array< string >,
        } >,
      } > | null,
      values: Array< string >,
    } > | null,
    title?: string | null,
    shortDescription?: string | null,
    longDescription?: string | null,
    owner?: string | null,
    expiresAt?: string | null,
    hasCopyright?: boolean | null,
    hasCustomThumbnail?: boolean | null,
    purpose?: string | null,
    canHideSlides?: boolean | null,
    distributable?: boolean | null,
    downloadable?: boolean | null,
    isInternalGenerated?: boolean | null,
    semVer?:  {
      __typename: "SemVer",
      minor: number,
      major: number,
    } | null,
    notificationScope?: NOTIFICATION_SCOPE | null,
    selectedThumbnail?: number | null,
    publishedAt?: string | null,
    uploadedAt?: string | null,
    uploadedBy?: string | null,
    convertedArchiveKey?: string | null,
    convertedArchiveSize?: number | null,
    convertedFolderKey?: string | null,
    associatedFiles?:  Array< {
      __typename: "AssociatedFile",
      id: string,
      isDistributable?: boolean | null,
      isDefault?: boolean | null,
      type: ASSOCIATED_FILE_TYPE,
      attachmentId: string,
      status: ASSOCIATED_FILE_STATUS,
      createdAt: string,
      createdBy: string,
    } > | null,
    editPermissions: Array< string >,
    converterVersion?: number | null,
    createdAt: string,
    createdBy: string,
    updatedAt: string,
    updatedBy: string,
    integration?:  {
      __typename: "DocumentVersionIntegration",
      externalVersionId?: string | null,
      version?: string | null,
      timestamp?: string | null,
      srcFileHash?: string | null,
      srcDocumentHash?: string | null,
    } | null,
    integrationType?: INTEGRATION_TYPE | null,
    conversionWarningCode?: CONVERSION_WARNING_CODE | null,
    conversionErrorCode?: string | null,
    scheduledPublish?: string | null,
    mappingStatus?: MAPPING_STATUS | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateDocumentVersionFromExistingMutationVariables = {
  documentId: string,
  newVersionNumber: number,
  existingVersionId: string,
};

export type CreateDocumentVersionFromExistingMutation = {
  createDocumentVersionFromExisting?:  {
    __typename: "DocumentVersion",
    id: string,
    tenantId: string,
    documentId: string,
    versionNumber: number,
    srcFilename?: string | null,
    contentURL?: string | null,
    conversionStatus: CONVERSION_STATUS,
    status: DOCUMENT_STATUS,
    srcFile?:  {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
      url: string,
    } | null,
    srcHash?: string | null,
    srcSize?: number | null,
    numPages?: number | null,
    pageSettings:  Array< {
      __typename: "PageSetting",
      pageId: string,
      number: number,
      isRequired?: boolean | null,
      linkedSlides: Array< string >,
    } >,
    pageGroups?:  Array< {
      __typename: "PageGroup",
      id: string,
      pageIds?: Array< string > | null,
      name: string,
      locked: boolean,
      sourceID?: string | null,
      source: PAGE_GROUP_SOURCE,
    } > | null,
    type: FILE_TYPE,
    releaseNotes?: string | null,
    changeType?: DOCUMENT_VERSION_CHANGE_TYPE | null,
    labelValues?:  Array< {
      __typename: "LabelValue",
      key: string,
      value: string,
    } > | null,
    customValues?:  Array< {
      __typename: "CustomValues",
      fieldId: string,
      objectRecords?:  Array< {
        __typename: "ObjectRecord",
        id: string,
        externalId?: string | null,
        syncStatus?: CRMSyncStatus | null,
        status: OBJECT_RECORD_STATUS,
        values:  Array< {
          __typename: "ObjectRecordValue",
          fieldId: string,
          values: Array< string >,
        } >,
      } > | null,
      values: Array< string >,
    } > | null,
    title?: string | null,
    shortDescription?: string | null,
    longDescription?: string | null,
    owner?: string | null,
    expiresAt?: string | null,
    hasCopyright?: boolean | null,
    hasCustomThumbnail?: boolean | null,
    purpose?: string | null,
    canHideSlides?: boolean | null,
    distributable?: boolean | null,
    downloadable?: boolean | null,
    isInternalGenerated?: boolean | null,
    semVer?:  {
      __typename: "SemVer",
      minor: number,
      major: number,
    } | null,
    notificationScope?: NOTIFICATION_SCOPE | null,
    selectedThumbnail?: number | null,
    publishedAt?: string | null,
    uploadedAt?: string | null,
    uploadedBy?: string | null,
    convertedArchiveKey?: string | null,
    convertedArchiveSize?: number | null,
    convertedFolderKey?: string | null,
    associatedFiles?:  Array< {
      __typename: "AssociatedFile",
      id: string,
      isDistributable?: boolean | null,
      isDefault?: boolean | null,
      type: ASSOCIATED_FILE_TYPE,
      attachmentId: string,
      status: ASSOCIATED_FILE_STATUS,
      createdAt: string,
      createdBy: string,
    } > | null,
    editPermissions: Array< string >,
    converterVersion?: number | null,
    createdAt: string,
    createdBy: string,
    updatedAt: string,
    updatedBy: string,
    integration?:  {
      __typename: "DocumentVersionIntegration",
      externalVersionId?: string | null,
      version?: string | null,
      timestamp?: string | null,
      srcFileHash?: string | null,
      srcDocumentHash?: string | null,
    } | null,
    integrationType?: INTEGRATION_TYPE | null,
    conversionWarningCode?: CONVERSION_WARNING_CODE | null,
    conversionErrorCode?: string | null,
    scheduledPublish?: string | null,
    mappingStatus?: MAPPING_STATUS | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateDocumentVersionFromExistingIntegrationEngineMutationVariables = {
  input?: CreateDocumentVersionFromExistingEngineInput | null,
};

export type CreateDocumentVersionFromExistingIntegrationEngineMutation = {
  createDocumentVersionFromExistingIntegrationEngine?:  {
    __typename: "DocumentVersion",
    id: string,
    tenantId: string,
    documentId: string,
    versionNumber: number,
    srcFilename?: string | null,
    contentURL?: string | null,
    conversionStatus: CONVERSION_STATUS,
    status: DOCUMENT_STATUS,
    srcFile?:  {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
      url: string,
    } | null,
    srcHash?: string | null,
    srcSize?: number | null,
    numPages?: number | null,
    pageSettings:  Array< {
      __typename: "PageSetting",
      pageId: string,
      number: number,
      isRequired?: boolean | null,
      linkedSlides: Array< string >,
    } >,
    pageGroups?:  Array< {
      __typename: "PageGroup",
      id: string,
      pageIds?: Array< string > | null,
      name: string,
      locked: boolean,
      sourceID?: string | null,
      source: PAGE_GROUP_SOURCE,
    } > | null,
    type: FILE_TYPE,
    releaseNotes?: string | null,
    changeType?: DOCUMENT_VERSION_CHANGE_TYPE | null,
    labelValues?:  Array< {
      __typename: "LabelValue",
      key: string,
      value: string,
    } > | null,
    customValues?:  Array< {
      __typename: "CustomValues",
      fieldId: string,
      objectRecords?:  Array< {
        __typename: "ObjectRecord",
        id: string,
        externalId?: string | null,
        syncStatus?: CRMSyncStatus | null,
        status: OBJECT_RECORD_STATUS,
        values:  Array< {
          __typename: "ObjectRecordValue",
          fieldId: string,
          values: Array< string >,
        } >,
      } > | null,
      values: Array< string >,
    } > | null,
    title?: string | null,
    shortDescription?: string | null,
    longDescription?: string | null,
    owner?: string | null,
    expiresAt?: string | null,
    hasCopyright?: boolean | null,
    hasCustomThumbnail?: boolean | null,
    purpose?: string | null,
    canHideSlides?: boolean | null,
    distributable?: boolean | null,
    downloadable?: boolean | null,
    isInternalGenerated?: boolean | null,
    semVer?:  {
      __typename: "SemVer",
      minor: number,
      major: number,
    } | null,
    notificationScope?: NOTIFICATION_SCOPE | null,
    selectedThumbnail?: number | null,
    publishedAt?: string | null,
    uploadedAt?: string | null,
    uploadedBy?: string | null,
    convertedArchiveKey?: string | null,
    convertedArchiveSize?: number | null,
    convertedFolderKey?: string | null,
    associatedFiles?:  Array< {
      __typename: "AssociatedFile",
      id: string,
      isDistributable?: boolean | null,
      isDefault?: boolean | null,
      type: ASSOCIATED_FILE_TYPE,
      attachmentId: string,
      status: ASSOCIATED_FILE_STATUS,
      createdAt: string,
      createdBy: string,
    } > | null,
    editPermissions: Array< string >,
    converterVersion?: number | null,
    createdAt: string,
    createdBy: string,
    updatedAt: string,
    updatedBy: string,
    integration?:  {
      __typename: "DocumentVersionIntegration",
      externalVersionId?: string | null,
      version?: string | null,
      timestamp?: string | null,
      srcFileHash?: string | null,
      srcDocumentHash?: string | null,
    } | null,
    integrationType?: INTEGRATION_TYPE | null,
    conversionWarningCode?: CONVERSION_WARNING_CODE | null,
    conversionErrorCode?: string | null,
    scheduledPublish?: string | null,
    mappingStatus?: MAPPING_STATUS | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateAttachedFileFromS3UploadMutationVariables = {
  inputAttached: CreateAttachedFileFromS3UploadInput,
};

export type CreateAttachedFileFromS3UploadMutation = {
  createAttachedFileFromS3Upload?:  {
    __typename: "AttachedFile",
    id: string,
    title: string,
    tenantId: string,
    documentId?: string | null,
    srcFile:  {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
      url: string,
    },
    srcFileName: string,
    srcHash?: string | null,
    srcSize: number,
    type: FILE_TYPE,
    editPermissions: Array< string >,
    createdAt: string,
    createdBy: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DownloadDocumentVersionAsPdfFileMutationVariables = {
  documentVersionId: string,
};

export type DownloadDocumentVersionAsPdfFileMutation = {
  downloadDocumentVersionAsPdfFile?: string | null,
};

export type DownloadCustomDeckAsPdfFileMutationVariables = {
  customDeckId: string,
};

export type DownloadCustomDeckAsPdfFileMutation = {
  downloadCustomDeckAsPdfFile?: string | null,
};

export type GenerateContentShareMutationVariables = {
  type: SHARE_TYPE,
  contentId: string,
  expiresInMins: number,
  customValues: Array< CustomValuesInput >,
  notificationScope?: SHARE_NOTIFICATION_SCOPE | null,
  meetingId?: string | null,
  customDeckDependencies?: CustomDeckDependenciesInput | null,
};

export type GenerateContentShareMutation = {
  generateContentShare?:  {
    __typename: "ContentShare",
    id: string,
    token: string,
    tenantId: string,
    type: SHARE_TYPE,
    contentId: string,
    meetingId?: string | null,
    expiresAt: string,
    createdAt: string,
    updatedAt: string,
    createdBy: string,
    customValues:  Array< {
      __typename: "CustomValues",
      fieldId: string,
      objectRecords?:  Array< {
        __typename: "ObjectRecord",
        id: string,
        externalId?: string | null,
        syncStatus?: CRMSyncStatus | null,
        status: OBJECT_RECORD_STATUS,
        values:  Array< {
          __typename: "ObjectRecordValue",
          fieldId: string,
          values: Array< string >,
        } >,
      } > | null,
      values: Array< string >,
    } >,
    revoked?: boolean | null,
    customDeckDependencies?:  {
      __typename: "CustomDeckDependencies",
      versions: Array< string >,
      folderId: string,
    } | null,
    notificationScope?: SHARE_NOTIFICATION_SCOPE | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateUserLambdaMutationVariables = {
  user?: UpdateUserLambdaInput | null,
};

export type UpdateUserLambdaMutation = {
  updateUserLambda?:  {
    __typename: "User",
    id: string,
    cognitoSubId?: string | null,
    tenantId: string,
    email: string,
    givenName: string,
    phoneNumber?: string | null,
    familyName: string,
    meetingId?: string | null,
    defaultFiltersCustomValues:  Array< {
      __typename: "CustomValues",
      fieldId: string,
      objectRecords?:  Array< {
        __typename: "ObjectRecord",
        id: string,
        externalId?: string | null,
        syncStatus?: CRMSyncStatus | null,
        status: OBJECT_RECORD_STATUS,
        values:  Array< {
          __typename: "ObjectRecordValue",
          fieldId: string,
          values: Array< string >,
        } >,
      } > | null,
      values: Array< string >,
    } >,
    lockedFiltersCustomValues:  Array< {
      __typename: "CustomValues",
      fieldId: string,
      objectRecords?:  Array< {
        __typename: "ObjectRecord",
        id: string,
        externalId?: string | null,
        syncStatus?: CRMSyncStatus | null,
        status: OBJECT_RECORD_STATUS,
        values:  Array< {
          __typename: "ObjectRecordValue",
          fieldId: string,
          values: Array< string >,
        } >,
      } > | null,
      values: Array< string >,
    } >,
    defaultFilterValues:  Array< {
      __typename: "LabelValues",
      key: string,
      values: Array< string >,
    } >,
    lockedFilters?:  Array< {
      __typename: "LabelValues",
      key: string,
      values: Array< string >,
    } > | null,
    shareBCC?: Array< string > | null,
    shareCC?: Array< string > | null,
    createdAt: string,
    createdBy: string,
    updatedAt: string,
    status?: USER_STATUS | null,
    role: USER_ROLE,
    bookmarkedDocs?:  Array< {
      __typename: "BookmarkedDoc",
      docID: string,
      createdAt: string,
    } > | null,
    isExcludedFromReporting?: boolean | null,
    additionalRoles?: Array< USER_ROLE > | null,
    altUsername?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateCustomDeckLambdaMutationVariables = {
  customDeck: UpdateCustomDeckLambdaInput,
  folderId: string,
  rootFolderId: string,
};

export type UpdateCustomDeckLambdaMutation = {
  updateCustomDeckLambda?:  {
    __typename: "CustomDeck",
    id: string,
    createdAt: string,
    createdBy: string,
    autoUpdateAcknowledgedAt?: string | null,
    updatedAt: string,
    updatedBy: string,
    tenantId: string,
    groups:  Array< {
      __typename: "CustomDeckGroup",
      id: string,
      pages:  Array< {
        __typename: "CustomDeckPage",
        pageId: string,
        pageNumber: number,
        documentVersionId: string,
      } >,
      visible: boolean,
      srcId?: string | null,
      docAccessLevel: DOCUMENT_ACCESS_LEVEL,
      name?: string | null,
      locked?: boolean | null,
    } >,
    title: string,
    editMutex?:  {
      __typename: "EditMutex",
      userId: string,
      timeStarted: string,
      lastSeenAt: string,
    } | null,
    sourceCustomDeckId?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateUserStatusLambdaMutationVariables = {
  user?: DisableUserLambdaInput | null,
};

export type UpdateUserStatusLambdaMutation = {
  updateUserStatusLambda?:  {
    __typename: "User",
    id: string,
    cognitoSubId?: string | null,
    tenantId: string,
    email: string,
    givenName: string,
    phoneNumber?: string | null,
    familyName: string,
    meetingId?: string | null,
    defaultFiltersCustomValues:  Array< {
      __typename: "CustomValues",
      fieldId: string,
      objectRecords?:  Array< {
        __typename: "ObjectRecord",
        id: string,
        externalId?: string | null,
        syncStatus?: CRMSyncStatus | null,
        status: OBJECT_RECORD_STATUS,
        values:  Array< {
          __typename: "ObjectRecordValue",
          fieldId: string,
          values: Array< string >,
        } >,
      } > | null,
      values: Array< string >,
    } >,
    lockedFiltersCustomValues:  Array< {
      __typename: "CustomValues",
      fieldId: string,
      objectRecords?:  Array< {
        __typename: "ObjectRecord",
        id: string,
        externalId?: string | null,
        syncStatus?: CRMSyncStatus | null,
        status: OBJECT_RECORD_STATUS,
        values:  Array< {
          __typename: "ObjectRecordValue",
          fieldId: string,
          values: Array< string >,
        } >,
      } > | null,
      values: Array< string >,
    } >,
    defaultFilterValues:  Array< {
      __typename: "LabelValues",
      key: string,
      values: Array< string >,
    } >,
    lockedFilters?:  Array< {
      __typename: "LabelValues",
      key: string,
      values: Array< string >,
    } > | null,
    shareBCC?: Array< string > | null,
    shareCC?: Array< string > | null,
    createdAt: string,
    createdBy: string,
    updatedAt: string,
    status?: USER_STATUS | null,
    role: USER_ROLE,
    bookmarkedDocs?:  Array< {
      __typename: "BookmarkedDoc",
      docID: string,
      createdAt: string,
    } > | null,
    isExcludedFromReporting?: boolean | null,
    additionalRoles?: Array< USER_ROLE > | null,
    altUsername?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateTenantUserMutationVariables = {
  user?: CreateUserLambdaInput | null,
  sendInvite?: boolean | null,
};

export type CreateTenantUserMutation = {
  createTenantUser?:  {
    __typename: "User",
    id: string,
    cognitoSubId?: string | null,
    tenantId: string,
    email: string,
    givenName: string,
    phoneNumber?: string | null,
    familyName: string,
    meetingId?: string | null,
    defaultFiltersCustomValues:  Array< {
      __typename: "CustomValues",
      fieldId: string,
      objectRecords?:  Array< {
        __typename: "ObjectRecord",
        id: string,
        externalId?: string | null,
        syncStatus?: CRMSyncStatus | null,
        status: OBJECT_RECORD_STATUS,
        values:  Array< {
          __typename: "ObjectRecordValue",
          fieldId: string,
          values: Array< string >,
        } >,
      } > | null,
      values: Array< string >,
    } >,
    lockedFiltersCustomValues:  Array< {
      __typename: "CustomValues",
      fieldId: string,
      objectRecords?:  Array< {
        __typename: "ObjectRecord",
        id: string,
        externalId?: string | null,
        syncStatus?: CRMSyncStatus | null,
        status: OBJECT_RECORD_STATUS,
        values:  Array< {
          __typename: "ObjectRecordValue",
          fieldId: string,
          values: Array< string >,
        } >,
      } > | null,
      values: Array< string >,
    } >,
    defaultFilterValues:  Array< {
      __typename: "LabelValues",
      key: string,
      values: Array< string >,
    } >,
    lockedFilters?:  Array< {
      __typename: "LabelValues",
      key: string,
      values: Array< string >,
    } > | null,
    shareBCC?: Array< string > | null,
    shareCC?: Array< string > | null,
    createdAt: string,
    createdBy: string,
    updatedAt: string,
    status?: USER_STATUS | null,
    role: USER_ROLE,
    bookmarkedDocs?:  Array< {
      __typename: "BookmarkedDoc",
      docID: string,
      createdAt: string,
    } > | null,
    isExcludedFromReporting?: boolean | null,
    additionalRoles?: Array< USER_ROLE > | null,
    altUsername?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type SubmitIntegrationRunMutationVariables = {
  input?: RunIntegrationEngineInput | null,
};

export type SubmitIntegrationRunMutation = {
  submitIntegrationRun?:  {
    __typename: "SubmitIntegrationResult",
    success: boolean,
    message?: string | null,
  } | null,
};

export type SubmitUserIntegrationRunMutationVariables = {
  input?: RunIntegrationEngineInput | null,
};

export type SubmitUserIntegrationRunMutation = {
  submitUserIntegrationRun?:  {
    __typename: "SubmitIntegrationResult",
    success: boolean,
    message?: string | null,
  } | null,
};

export type UpdateTenantLogoMutationVariables = {
  input?: UpdateTenantLogoInput | null,
};

export type UpdateTenantLogoMutation = {
  updateTenantLogo?: boolean | null,
};

export type LockCustomDeckMutationVariables = {
  customDeckId: string,
  timestarted: string,
  lock: boolean,
};

export type LockCustomDeckMutation = {
  lockCustomDeck?:  {
    __typename: "CustomDeck",
    id: string,
    createdAt: string,
    createdBy: string,
    autoUpdateAcknowledgedAt?: string | null,
    updatedAt: string,
    updatedBy: string,
    tenantId: string,
    groups:  Array< {
      __typename: "CustomDeckGroup",
      id: string,
      pages:  Array< {
        __typename: "CustomDeckPage",
        pageId: string,
        pageNumber: number,
        documentVersionId: string,
      } >,
      visible: boolean,
      srcId?: string | null,
      docAccessLevel: DOCUMENT_ACCESS_LEVEL,
      name?: string | null,
      locked?: boolean | null,
    } >,
    title: string,
    editMutex?:  {
      __typename: "EditMutex",
      userId: string,
      timeStarted: string,
      lastSeenAt: string,
    } | null,
    sourceCustomDeckId?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateZendeskOrgMutationVariables = {
  input: UpdateZendeskOrgInput,
};

export type UpdateZendeskOrgMutation = {
  updateZendeskOrg?: boolean | null,
};

export type PublishDocumentMutationVariables = {
  documentId: string,
};

export type PublishDocumentMutation = {
  publishDocument?:  {
    __typename: "DocumentVersion",
    id: string,
    tenantId: string,
    documentId: string,
    versionNumber: number,
    srcFilename?: string | null,
    contentURL?: string | null,
    conversionStatus: CONVERSION_STATUS,
    status: DOCUMENT_STATUS,
    srcFile?:  {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
      url: string,
    } | null,
    srcHash?: string | null,
    srcSize?: number | null,
    numPages?: number | null,
    pageSettings:  Array< {
      __typename: "PageSetting",
      pageId: string,
      number: number,
      isRequired?: boolean | null,
      linkedSlides: Array< string >,
    } >,
    pageGroups?:  Array< {
      __typename: "PageGroup",
      id: string,
      pageIds?: Array< string > | null,
      name: string,
      locked: boolean,
      sourceID?: string | null,
      source: PAGE_GROUP_SOURCE,
    } > | null,
    type: FILE_TYPE,
    releaseNotes?: string | null,
    changeType?: DOCUMENT_VERSION_CHANGE_TYPE | null,
    labelValues?:  Array< {
      __typename: "LabelValue",
      key: string,
      value: string,
    } > | null,
    customValues?:  Array< {
      __typename: "CustomValues",
      fieldId: string,
      objectRecords?:  Array< {
        __typename: "ObjectRecord",
        id: string,
        externalId?: string | null,
        syncStatus?: CRMSyncStatus | null,
        status: OBJECT_RECORD_STATUS,
        values:  Array< {
          __typename: "ObjectRecordValue",
          fieldId: string,
          values: Array< string >,
        } >,
      } > | null,
      values: Array< string >,
    } > | null,
    title?: string | null,
    shortDescription?: string | null,
    longDescription?: string | null,
    owner?: string | null,
    expiresAt?: string | null,
    hasCopyright?: boolean | null,
    hasCustomThumbnail?: boolean | null,
    purpose?: string | null,
    canHideSlides?: boolean | null,
    distributable?: boolean | null,
    downloadable?: boolean | null,
    isInternalGenerated?: boolean | null,
    semVer?:  {
      __typename: "SemVer",
      minor: number,
      major: number,
    } | null,
    notificationScope?: NOTIFICATION_SCOPE | null,
    selectedThumbnail?: number | null,
    publishedAt?: string | null,
    uploadedAt?: string | null,
    uploadedBy?: string | null,
    convertedArchiveKey?: string | null,
    convertedArchiveSize?: number | null,
    convertedFolderKey?: string | null,
    associatedFiles?:  Array< {
      __typename: "AssociatedFile",
      id: string,
      isDistributable?: boolean | null,
      isDefault?: boolean | null,
      type: ASSOCIATED_FILE_TYPE,
      attachmentId: string,
      status: ASSOCIATED_FILE_STATUS,
      createdAt: string,
      createdBy: string,
    } > | null,
    editPermissions: Array< string >,
    converterVersion?: number | null,
    createdAt: string,
    createdBy: string,
    updatedAt: string,
    updatedBy: string,
    integration?:  {
      __typename: "DocumentVersionIntegration",
      externalVersionId?: string | null,
      version?: string | null,
      timestamp?: string | null,
      srcFileHash?: string | null,
      srcDocumentHash?: string | null,
    } | null,
    integrationType?: INTEGRATION_TYPE | null,
    conversionWarningCode?: CONVERSION_WARNING_CODE | null,
    conversionErrorCode?: string | null,
    scheduledPublish?: string | null,
    mappingStatus?: MAPPING_STATUS | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateDocumentPageDataMutationVariables = {
  input: UpdateJsonPagesLambdaInput,
};

export type UpdateDocumentPageDataMutation = {
  updateDocumentPageData?: boolean | null,
};

export type GenerateDownloadKeyMutationVariables = {
  token: string,
  contentId?: string | null,
  contentType?: ASSOCIATED_FILE_TYPE | null,
};

export type GenerateDownloadKeyMutation = {
  generateDownloadKey?: string | null,
};

export type GenerateDownloadKeyByTenantMutationVariables = {
  hubId: string,
  contentId?: string | null,
  contentType?: ASSOCIATED_FILE_TYPE | null,
};

export type GenerateDownloadKeyByTenantMutation = {
  generateDownloadKeyByTenant?: string | null,
};

export type PushAnalyticsMutationVariables = {
  input: string,
  userToken?: string | null,
};

export type PushAnalyticsMutation = {
  pushAnalytics?: boolean | null,
};

export type PushLogsMutationVariables = {
  input: Array< string >,
  shouldSendEmail?: boolean | null,
};

export type PushLogsMutation = {
  pushLogs?: boolean | null,
};

export type GetLatestDocumentVersionIdMutationVariables = {
  input: string,
};

export type GetLatestDocumentVersionIdMutation = {
  getLatestDocumentVersionId?: string | null,
};

export type SubmitTenantFormMutationVariables = {
  formId: string,
  customValues?: Array< CustomValuesInput > | null,
};

export type SubmitTenantFormMutation = {
  submitTenantForm?: boolean | null,
};

export type UpdateFolderSharePermissionsMutationVariables = {
  folderId: string,
  sharePermissions?: Array< SharePermissionInput > | null,
  sendEmailNotification?: boolean | null,
};

export type UpdateFolderSharePermissionsMutation = {
  updateFolderSharePermissions?:  {
    __typename: "Folder",
    id: string,
    tenantId: string,
    name: string,
    isPinned?: boolean | null,
    status: FOLDER_STATUS,
    items:  Array< {
      __typename: "FolderItem",
      id: string,
      type: FOLDER_ITEM_TYPE,
      itemId: string,
      status?: FOLDER_ITEM_STATUS | null,
      addedAt: string,
      updateAcknowledgedAt?: string | null,
      itemLastUpdatedAt: string,
      visiblePages?: Array< number > | null,
      customTitle?: string | null,
    } >,
    createdAt: string,
    createdBy: string,
    updatedAt: string,
    updatedBy: string,
    sharePermissions?:  Array< {
      __typename: "SharePermission",
      id: string,
      isDeleted?: boolean | null,
      type: PERMISSION_TYPE,
      targetType: SHARE_TARGET_TYPE,
      targetUsername?: string | null,
      targetCustomValues?:  Array< {
        __typename: "CustomValues",
        fieldId: string,
        objectRecords?:  Array< {
          __typename: "ObjectRecord",
          id: string,
          externalId?: string | null,
          syncStatus?: CRMSyncStatus | null,
          status: OBJECT_RECORD_STATUS,
          values:  Array< {
            __typename: "ObjectRecordValue",
            fieldId: string,
            values: Array< string >,
          } >,
        } > | null,
        values: Array< string >,
      } > | null,
      targetLabels?:  Array< {
        __typename: "LabelValues",
        key: string,
        values: Array< string >,
      } > | null,
      createdAt: string,
      createdBy: string,
      updatedAt: string,
      updatedBy: string,
      deletedBy?: string | null,
      deletedAt?: string | null,
    } > | null,
    shareStatus: SHARE_STATUS,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateFolderItemsMutationVariables = {
  foldersUpdates: Array< UpdateFolderInput >,
  rootFolderId?: string | null,
};

export type UpdateFolderItemsMutation = {
  updateFolderItems?:  Array< {
    __typename: "Folder",
    id: string,
    tenantId: string,
    name: string,
    isPinned?: boolean | null,
    status: FOLDER_STATUS,
    items:  Array< {
      __typename: "FolderItem",
      id: string,
      type: FOLDER_ITEM_TYPE,
      itemId: string,
      status?: FOLDER_ITEM_STATUS | null,
      addedAt: string,
      updateAcknowledgedAt?: string | null,
      itemLastUpdatedAt: string,
      visiblePages?: Array< number > | null,
      customTitle?: string | null,
    } >,
    createdAt: string,
    createdBy: string,
    updatedAt: string,
    updatedBy: string,
    sharePermissions?:  Array< {
      __typename: "SharePermission",
      id: string,
      isDeleted?: boolean | null,
      type: PERMISSION_TYPE,
      targetType: SHARE_TARGET_TYPE,
      targetUsername?: string | null,
      targetCustomValues?:  Array< {
        __typename: "CustomValues",
        fieldId: string,
        objectRecords?:  Array< {
          __typename: "ObjectRecord",
          id: string,
          externalId?: string | null,
          syncStatus?: CRMSyncStatus | null,
          status: OBJECT_RECORD_STATUS,
          values:  Array< {
            __typename: "ObjectRecordValue",
            fieldId: string,
            values: Array< string >,
          } >,
        } > | null,
        values: Array< string >,
      } > | null,
      targetLabels?:  Array< {
        __typename: "LabelValues",
        key: string,
        values: Array< string >,
      } > | null,
      createdAt: string,
      createdBy: string,
      updatedAt: string,
      updatedBy: string,
      deletedBy?: string | null,
      deletedAt?: string | null,
    } > | null,
    shareStatus: SHARE_STATUS,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null > | null,
};

export type CreateSubFolderMutationVariables = {
  newSubFolder: CreateSubFolderInput,
};

export type CreateSubFolderMutation = {
  createSubFolder?:  Array< {
    __typename: "Folder",
    id: string,
    tenantId: string,
    name: string,
    isPinned?: boolean | null,
    status: FOLDER_STATUS,
    items:  Array< {
      __typename: "FolderItem",
      id: string,
      type: FOLDER_ITEM_TYPE,
      itemId: string,
      status?: FOLDER_ITEM_STATUS | null,
      addedAt: string,
      updateAcknowledgedAt?: string | null,
      itemLastUpdatedAt: string,
      visiblePages?: Array< number > | null,
      customTitle?: string | null,
    } >,
    createdAt: string,
    createdBy: string,
    updatedAt: string,
    updatedBy: string,
    sharePermissions?:  Array< {
      __typename: "SharePermission",
      id: string,
      isDeleted?: boolean | null,
      type: PERMISSION_TYPE,
      targetType: SHARE_TARGET_TYPE,
      targetUsername?: string | null,
      targetCustomValues?:  Array< {
        __typename: "CustomValues",
        fieldId: string,
        objectRecords?:  Array< {
          __typename: "ObjectRecord",
          id: string,
          externalId?: string | null,
          syncStatus?: CRMSyncStatus | null,
          status: OBJECT_RECORD_STATUS,
          values:  Array< {
            __typename: "ObjectRecordValue",
            fieldId: string,
            values: Array< string >,
          } >,
        } > | null,
        values: Array< string >,
      } > | null,
      targetLabels?:  Array< {
        __typename: "LabelValues",
        key: string,
        values: Array< string >,
      } > | null,
      createdAt: string,
      createdBy: string,
      updatedAt: string,
      updatedBy: string,
      deletedBy?: string | null,
      deletedAt?: string | null,
    } > | null,
    shareStatus: SHARE_STATUS,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null > | null,
};

export type UpsertVersionContentsMutationVariables = {
  documentVersionId: string,
  tenantId: string,
  configName?: string | null,
};

export type UpsertVersionContentsMutation = {
  upsertVersionContents?:  Array< {
    __typename: "UpsertVersionContentsResult",
    documentVersionId?: string | null,
    tenantId?: string | null,
    result?: string | null,
  } | null > | null,
};

export type UpdateDocVerPageMatchesMutationVariables = {
  docVerId: string,
};

export type UpdateDocVerPageMatchesMutation = {
  updateDocVerPageMatches?:  {
    __typename: "UpdateDocVerPageMatchesResult",
    tenantId: string,
    documentVersionId: string,
    status: string,
    message: string,
  } | null,
};

export type GetUserQueryVariables = {
  id: string,
};

export type GetUserQuery = {
  getUser?:  {
    __typename: "User",
    id: string,
    cognitoSubId?: string | null,
    tenantId: string,
    email: string,
    givenName: string,
    phoneNumber?: string | null,
    familyName: string,
    meetingId?: string | null,
    defaultFiltersCustomValues:  Array< {
      __typename: "CustomValues",
      fieldId: string,
      objectRecords?:  Array< {
        __typename: "ObjectRecord",
        id: string,
        externalId?: string | null,
        syncStatus?: CRMSyncStatus | null,
        status: OBJECT_RECORD_STATUS,
        values:  Array< {
          __typename: "ObjectRecordValue",
          fieldId: string,
          values: Array< string >,
        } >,
      } > | null,
      values: Array< string >,
    } >,
    lockedFiltersCustomValues:  Array< {
      __typename: "CustomValues",
      fieldId: string,
      objectRecords?:  Array< {
        __typename: "ObjectRecord",
        id: string,
        externalId?: string | null,
        syncStatus?: CRMSyncStatus | null,
        status: OBJECT_RECORD_STATUS,
        values:  Array< {
          __typename: "ObjectRecordValue",
          fieldId: string,
          values: Array< string >,
        } >,
      } > | null,
      values: Array< string >,
    } >,
    defaultFilterValues:  Array< {
      __typename: "LabelValues",
      key: string,
      values: Array< string >,
    } >,
    lockedFilters?:  Array< {
      __typename: "LabelValues",
      key: string,
      values: Array< string >,
    } > | null,
    shareBCC?: Array< string > | null,
    shareCC?: Array< string > | null,
    createdAt: string,
    createdBy: string,
    updatedAt: string,
    status?: USER_STATUS | null,
    role: USER_ROLE,
    bookmarkedDocs?:  Array< {
      __typename: "BookmarkedDoc",
      docID: string,
      createdAt: string,
    } > | null,
    isExcludedFromReporting?: boolean | null,
    additionalRoles?: Array< USER_ROLE > | null,
    altUsername?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListUsersQueryVariables = {
  filter?: ModelUserFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListUsersQuery = {
  listUsers?:  {
    __typename: "ModelUserConnection",
    items:  Array< {
      __typename: "User",
      id: string,
      cognitoSubId?: string | null,
      tenantId: string,
      email: string,
      givenName: string,
      phoneNumber?: string | null,
      familyName: string,
      meetingId?: string | null,
      defaultFiltersCustomValues:  Array< {
        __typename: "CustomValues",
        fieldId: string,
        objectRecords?:  Array< {
          __typename: "ObjectRecord",
          id: string,
          externalId?: string | null,
          syncStatus?: CRMSyncStatus | null,
          status: OBJECT_RECORD_STATUS,
          values:  Array< {
            __typename: "ObjectRecordValue",
            fieldId: string,
            values: Array< string >,
          } >,
        } > | null,
        values: Array< string >,
      } >,
      lockedFiltersCustomValues:  Array< {
        __typename: "CustomValues",
        fieldId: string,
        objectRecords?:  Array< {
          __typename: "ObjectRecord",
          id: string,
          externalId?: string | null,
          syncStatus?: CRMSyncStatus | null,
          status: OBJECT_RECORD_STATUS,
          values:  Array< {
            __typename: "ObjectRecordValue",
            fieldId: string,
            values: Array< string >,
          } >,
        } > | null,
        values: Array< string >,
      } >,
      defaultFilterValues:  Array< {
        __typename: "LabelValues",
        key: string,
        values: Array< string >,
      } >,
      lockedFilters?:  Array< {
        __typename: "LabelValues",
        key: string,
        values: Array< string >,
      } > | null,
      shareBCC?: Array< string > | null,
      shareCC?: Array< string > | null,
      createdAt: string,
      createdBy: string,
      updatedAt: string,
      status?: USER_STATUS | null,
      role: USER_ROLE,
      bookmarkedDocs?:  Array< {
        __typename: "BookmarkedDoc",
        docID: string,
        createdAt: string,
      } > | null,
      isExcludedFromReporting?: boolean | null,
      additionalRoles?: Array< USER_ROLE > | null,
      altUsername?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncUsersQueryVariables = {
  filter?: ModelUserFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncUsersQuery = {
  syncUsers?:  {
    __typename: "ModelUserConnection",
    items:  Array< {
      __typename: "User",
      id: string,
      cognitoSubId?: string | null,
      tenantId: string,
      email: string,
      givenName: string,
      phoneNumber?: string | null,
      familyName: string,
      meetingId?: string | null,
      defaultFiltersCustomValues:  Array< {
        __typename: "CustomValues",
        fieldId: string,
        objectRecords?:  Array< {
          __typename: "ObjectRecord",
          id: string,
          externalId?: string | null,
          syncStatus?: CRMSyncStatus | null,
          status: OBJECT_RECORD_STATUS,
          values:  Array< {
            __typename: "ObjectRecordValue",
            fieldId: string,
            values: Array< string >,
          } >,
        } > | null,
        values: Array< string >,
      } >,
      lockedFiltersCustomValues:  Array< {
        __typename: "CustomValues",
        fieldId: string,
        objectRecords?:  Array< {
          __typename: "ObjectRecord",
          id: string,
          externalId?: string | null,
          syncStatus?: CRMSyncStatus | null,
          status: OBJECT_RECORD_STATUS,
          values:  Array< {
            __typename: "ObjectRecordValue",
            fieldId: string,
            values: Array< string >,
          } >,
        } > | null,
        values: Array< string >,
      } >,
      defaultFilterValues:  Array< {
        __typename: "LabelValues",
        key: string,
        values: Array< string >,
      } >,
      lockedFilters?:  Array< {
        __typename: "LabelValues",
        key: string,
        values: Array< string >,
      } > | null,
      shareBCC?: Array< string > | null,
      shareCC?: Array< string > | null,
      createdAt: string,
      createdBy: string,
      updatedAt: string,
      status?: USER_STATUS | null,
      role: USER_ROLE,
      bookmarkedDocs?:  Array< {
        __typename: "BookmarkedDoc",
        docID: string,
        createdAt: string,
      } > | null,
      isExcludedFromReporting?: boolean | null,
      additionalRoles?: Array< USER_ROLE > | null,
      altUsername?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetTenantQueryVariables = {
  id: string,
};

export type GetTenantQuery = {
  getTenant?:  {
    __typename: "Tenant",
    id: string,
    name: string,
    licensedUsers: number,
    status: TENANT_STATUS,
    fields:  Array< {
      __typename: "FieldConfig",
      fieldName: string,
      description?: string | null,
      required: boolean,
      isEmailTemplateFilter?: boolean | null,
      dataType: FIELD_DATA_TYPE,
      userFilter: boolean,
      defaultSearchFilter: boolean,
      values: Array< string >,
    } >,
    folderUpdateGracePeriodDays?: number | null,
    medInfoURL?: string | null,
    publisherListConfig?:  {
      __typename: "ListConfiguration",
      fields:  Array< {
        __typename: "ListConfigurationField",
        fieldName: string,
        header?: string | null,
        width?: number | null,
      } >,
      sort?:  {
        __typename: "ListConfigurationSort",
        field: string,
        isAsc: boolean,
      } | null,
    } | null,
    mslListConfig?:  {
      __typename: "ListConfiguration",
      fields:  Array< {
        __typename: "ListConfigurationField",
        fieldName: string,
        header?: string | null,
        width?: number | null,
      } >,
      sort?:  {
        __typename: "ListConfigurationSort",
        field: string,
        isAsc: boolean,
      } | null,
    } | null,
    featureFlags:  Array< {
      __typename: "FeatureFlags",
      flagId: string,
      value: boolean,
    } >,
    createdAt: string,
    createdBy: string,
    updatedAt: string,
    statusChangedAt: string,
    statusChangedBy: string,
    ssoDomains?: Array< string | null > | null,
    integrations?: Array< string | null > | null,
    config:  {
      __typename: "TenantConfig",
      requiredSlidesHiddenMessage?: string | null,
      forms?:  Array< {
        __typename: "TenantForm",
        id: string,
        name: string,
        label: string,
        fields:  Array< {
          __typename: "CustomFieldDefinition",
          id: string,
          fieldName: string,
          fieldLabel: string,
          reportingName?: string | null,
          status: FIELD_STATUS,
          description?: string | null,
          required?: boolean | null,
          maxLength?: number | null,
          formatValidation?: FORMAT_VALIDATION | null,
          fieldType: FIELD_DATA_TYPE,
          showByDefault?: boolean | null,
          dependentFieldId?: string | null,
          controlType: CONTROL_TYPE,
          order?: number | null,
          settings?:  {
            __typename: "CommonFieldSettings",
            isBadge?: boolean | null,
            fieldPosition: FIELD_POSITION,
          } | null,
          usage: Array< CUSTOM_FIELD_USAGE >,
          documentSettings?:  {
            __typename: "DocumentFieldSettings",
            fieldPosition: FIELD_POSITION,
          } | null,
          fieldValueDefinitions:  Array< {
            __typename: "CustomFieldValueDefinition",
            id: string,
            value: string,
            label?: string | null,
            disabled?: boolean | null,
            isDefault?: boolean | null,
            documentSettings?:  {
              __typename: "DocumentFieldValueSettings",
              presentationWatermarkText?: string | null,
              permission?:  {
                __typename: "DocumentPermission",
                present?: PERMISSION_EFFECT | null,
                modify?: PERMISSION_EFFECT | null,
                share?: PERMISSION_EFFECT | null,
                download?: PERMISSION_EFFECT | null,
                externalNotation?: PERMISSION_EFFECT | null,
              } | null,
            } | null,
            badgeColor?: string | null,
            badgeLabel?: string | null,
            createdAt: string,
            dependentValueIds: Array< string >,
          } >,
          displayOnParentSelection?: boolean | null,
          displayOnValueSelection?: Array< string > | null,
          objectSetting?:  {
            __typename: "ObjectSetting",
            childrenFieldIds: Array< string >,
            restriction?:  {
              __typename: "ObjectRestriction",
              syncUpdate?: boolean | null,
              syncDelete?: boolean | null,
              update?: boolean | null,
              delete?: boolean | null,
            } | null,
          } | null,
          isChildField?: boolean | null,
          dateRestriction?: DATE_RESTRICTION | null,
          createdAt: string,
          scaleNumber?: number | null,
          precisionNumber?: number | null,
        } >,
        targetRequestTemplate: string,
        targetType: TENANT_FORM_TARGET_TYPE,
        targetAddress: string,
        targetParameters?:  Array< {
          __typename: "TenantFormTargetParameters",
          key: string,
          value: string,
        } > | null,
        status: TENANT_FORM_STATUS,
      } > | null,
      meetingFields?:  Array< {
        __typename: "MeetingFieldDefinition",
        id: string,
        fieldName: string,
        status: FIELD_STATUS,
        description?: string | null,
        required: boolean,
        maxLength?: number | null,
        type: FIELD_DATA_TYPE,
        controlType: CONTROL_TYPE,
        fieldValueDefinitions:  Array< {
          __typename: "MeetingFieldValueDefinition",
          id: string,
          value: string,
          disabled: boolean,
          createdAt: string,
        } >,
        createdAt: string,
      } > | null,
      customFields?:  Array< {
        __typename: "CustomFieldDefinition",
        id: string,
        fieldName: string,
        fieldLabel: string,
        reportingName?: string | null,
        status: FIELD_STATUS,
        description?: string | null,
        required?: boolean | null,
        maxLength?: number | null,
        formatValidation?: FORMAT_VALIDATION | null,
        fieldType: FIELD_DATA_TYPE,
        showByDefault?: boolean | null,
        dependentFieldId?: string | null,
        controlType: CONTROL_TYPE,
        order?: number | null,
        settings?:  {
          __typename: "CommonFieldSettings",
          isBadge?: boolean | null,
          fieldPosition: FIELD_POSITION,
        } | null,
        usage: Array< CUSTOM_FIELD_USAGE >,
        documentSettings?:  {
          __typename: "DocumentFieldSettings",
          fieldPosition: FIELD_POSITION,
        } | null,
        fieldValueDefinitions:  Array< {
          __typename: "CustomFieldValueDefinition",
          id: string,
          value: string,
          label?: string | null,
          disabled?: boolean | null,
          isDefault?: boolean | null,
          documentSettings?:  {
            __typename: "DocumentFieldValueSettings",
            presentationWatermarkText?: string | null,
            permission?:  {
              __typename: "DocumentPermission",
              present?: PERMISSION_EFFECT | null,
              modify?: PERMISSION_EFFECT | null,
              share?: PERMISSION_EFFECT | null,
              download?: PERMISSION_EFFECT | null,
              externalNotation?: PERMISSION_EFFECT | null,
            } | null,
          } | null,
          badgeColor?: string | null,
          badgeLabel?: string | null,
          createdAt: string,
          dependentValueIds: Array< string >,
        } >,
        displayOnParentSelection?: boolean | null,
        displayOnValueSelection?: Array< string > | null,
        objectSetting?:  {
          __typename: "ObjectSetting",
          childrenFieldIds: Array< string >,
          restriction?:  {
            __typename: "ObjectRestriction",
            syncUpdate?: boolean | null,
            syncDelete?: boolean | null,
            update?: boolean | null,
            delete?: boolean | null,
          } | null,
        } | null,
        isChildField?: boolean | null,
        dateRestriction?: DATE_RESTRICTION | null,
        createdAt: string,
        scaleNumber?: number | null,
        precisionNumber?: number | null,
      } > | null,
      defaultDocumentPermissions:  {
        __typename: "DocumentPermission",
        present?: PERMISSION_EFFECT | null,
        modify?: PERMISSION_EFFECT | null,
        share?: PERMISSION_EFFECT | null,
        download?: PERMISSION_EFFECT | null,
        externalNotation?: PERMISSION_EFFECT | null,
      },
      mslListConfig?:  {
        __typename: "ListConfig",
        sort?:  {
          __typename: "ListSort",
          field: string,
          isAsc?: boolean | null,
        } | null,
        fields:  Array< {
          __typename: "FieldListConfig",
          field: string,
          width?: number | null,
        } >,
      } | null,
      publisherListConfig?:  {
        __typename: "ListConfig",
        sort?:  {
          __typename: "ListSort",
          field: string,
          isAsc?: boolean | null,
        } | null,
        fields:  Array< {
          __typename: "FieldListConfig",
          field: string,
          width?: number | null,
        } >,
      } | null,
      crmIntegration?:  {
        __typename: "CRMIntegration",
        id: string,
        crmIntegrationType: CRM_INTEGRATION_TYPE,
        instanceUrl: string,
        clientId: string,
        redirectUri: string,
        name: string,
        accountsSettings:  {
          __typename: "CRMAccountSettings",
          query: string,
          displaySettings:  {
            __typename: "CRMDisplaySettings",
            shortened: string,
            extended?: Array< string > | null,
          },
          addressSettings:  {
            __typename: "CRMAddressSettings",
            alias: string,
            displaySettings:  {
              __typename: "CRMDisplaySettings",
              shortened: string,
              extended?: Array< string > | null,
            },
          },
        },
        meetingSetting?:  {
          __typename: "CRMTableSetting",
          apiName: string,
          presentationsFieldName?: string | null,
          children:  Array< {
            __typename: "CRMChildTableSetting",
            name: string,
            apiName: string,
            relationshipName?: string | null,
            type: CRM_INTEGRATION_TYPE,
            veevaSetting?:  {
              __typename: "VeevaSetting",
              markerFieldName?: string | null,
            } | null,
          } >,
          type: CRM_INTEGRATION_TYPE,
        } | null,
        additionalSettings?:  {
          __typename: "CRMAdditionalSettings",
          allowCommentsOnTasks?: boolean | null,
          detailContentTableSettings?:  {
            __typename: "CRMTableDetailContentPresented",
            name: string,
            apiName: string,
            relationshipName: string,
            prefix?: string | null,
          } | null,
          enableSurveys?: boolean | null,
          crmAccountToObjectSettings?:  Array< {
            __typename: "CRMAccountToObjectSetting",
            accountFieldNames:  Array< {
              __typename: "CRMAccountToObjectFields",
              accountFieldName: string,
              objectFieldName: string,
            } >,
            apiName: string,
          } > | null,
        } | null,
        loginUrl?: string | null,
        crmStandaloneForms?:  Array< {
          __typename: "CRMStandaloneForm",
          id: string,
          apiName: string,
          children:  Array< {
            __typename: "CRMChildTableSetting",
            name: string,
            apiName: string,
            relationshipName?: string | null,
            type: CRM_INTEGRATION_TYPE,
            veevaSetting?:  {
              __typename: "VeevaSetting",
              markerFieldName?: string | null,
            } | null,
          } >,
          type: CRM_INTEGRATION_TYPE,
          parents:  Array< {
            __typename: "CRMStandaloneFormParent",
            apiName: string,
            relationshipName: string,
          } >,
          usage?: Array< STANDALONE_FORM_USAGE > | null,
        } > | null,
      } | null,
      sessionTimeout?: number | null,
      quickFilters?:  Array< {
        __typename: "QuickFilter",
        id: string,
        label: string,
        path: Array< string >,
      } > | null,
      hubsConfig:  {
        __typename: "HubConfig",
        shareExpirationDays?: number | null,
        disabledSections: Array< HUB_SECTION_TYPE >,
        disableNotationDescription: boolean,
      },
      reports:  Array< {
        __typename: "KeyValue",
        key: string,
        value: boolean,
      } >,
      emailDocumentUpdateFrequency?: FREQUENCY | null,
      sharePPTXasPDF?: boolean | null,
    },
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListTenantsQueryVariables = {
  filter?: ModelTenantFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListTenantsQuery = {
  listTenants?:  {
    __typename: "ModelTenantConnection",
    items:  Array< {
      __typename: "Tenant",
      id: string,
      name: string,
      licensedUsers: number,
      status: TENANT_STATUS,
      fields:  Array< {
        __typename: "FieldConfig",
        fieldName: string,
        description?: string | null,
        required: boolean,
        isEmailTemplateFilter?: boolean | null,
        dataType: FIELD_DATA_TYPE,
        userFilter: boolean,
        defaultSearchFilter: boolean,
        values: Array< string >,
      } >,
      folderUpdateGracePeriodDays?: number | null,
      medInfoURL?: string | null,
      publisherListConfig?:  {
        __typename: "ListConfiguration",
        fields:  Array< {
          __typename: "ListConfigurationField",
          fieldName: string,
          header?: string | null,
          width?: number | null,
        } >,
        sort?:  {
          __typename: "ListConfigurationSort",
          field: string,
          isAsc: boolean,
        } | null,
      } | null,
      mslListConfig?:  {
        __typename: "ListConfiguration",
        fields:  Array< {
          __typename: "ListConfigurationField",
          fieldName: string,
          header?: string | null,
          width?: number | null,
        } >,
        sort?:  {
          __typename: "ListConfigurationSort",
          field: string,
          isAsc: boolean,
        } | null,
      } | null,
      featureFlags:  Array< {
        __typename: "FeatureFlags",
        flagId: string,
        value: boolean,
      } >,
      createdAt: string,
      createdBy: string,
      updatedAt: string,
      statusChangedAt: string,
      statusChangedBy: string,
      ssoDomains?: Array< string | null > | null,
      integrations?: Array< string | null > | null,
      config:  {
        __typename: "TenantConfig",
        requiredSlidesHiddenMessage?: string | null,
        forms?:  Array< {
          __typename: "TenantForm",
          id: string,
          name: string,
          label: string,
          fields:  Array< {
            __typename: "CustomFieldDefinition",
            id: string,
            fieldName: string,
            fieldLabel: string,
            reportingName?: string | null,
            status: FIELD_STATUS,
            description?: string | null,
            required?: boolean | null,
            maxLength?: number | null,
            formatValidation?: FORMAT_VALIDATION | null,
            fieldType: FIELD_DATA_TYPE,
            showByDefault?: boolean | null,
            dependentFieldId?: string | null,
            controlType: CONTROL_TYPE,
            order?: number | null,
            settings?:  {
              __typename: "CommonFieldSettings",
              isBadge?: boolean | null,
              fieldPosition: FIELD_POSITION,
            } | null,
            usage: Array< CUSTOM_FIELD_USAGE >,
            documentSettings?:  {
              __typename: "DocumentFieldSettings",
              fieldPosition: FIELD_POSITION,
            } | null,
            fieldValueDefinitions:  Array< {
              __typename: "CustomFieldValueDefinition",
              id: string,
              value: string,
              label?: string | null,
              disabled?: boolean | null,
              isDefault?: boolean | null,
              documentSettings?:  {
                __typename: "DocumentFieldValueSettings",
                presentationWatermarkText?: string | null,
                permission?:  {
                  __typename: "DocumentPermission",
                  present?: PERMISSION_EFFECT | null,
                  modify?: PERMISSION_EFFECT | null,
                  share?: PERMISSION_EFFECT | null,
                  download?: PERMISSION_EFFECT | null,
                  externalNotation?: PERMISSION_EFFECT | null,
                } | null,
              } | null,
              badgeColor?: string | null,
              badgeLabel?: string | null,
              createdAt: string,
              dependentValueIds: Array< string >,
            } >,
            displayOnParentSelection?: boolean | null,
            displayOnValueSelection?: Array< string > | null,
            objectSetting?:  {
              __typename: "ObjectSetting",
              childrenFieldIds: Array< string >,
              restriction?:  {
                __typename: "ObjectRestriction",
                syncUpdate?: boolean | null,
                syncDelete?: boolean | null,
                update?: boolean | null,
                delete?: boolean | null,
              } | null,
            } | null,
            isChildField?: boolean | null,
            dateRestriction?: DATE_RESTRICTION | null,
            createdAt: string,
            scaleNumber?: number | null,
            precisionNumber?: number | null,
          } >,
          targetRequestTemplate: string,
          targetType: TENANT_FORM_TARGET_TYPE,
          targetAddress: string,
          targetParameters?:  Array< {
            __typename: "TenantFormTargetParameters",
            key: string,
            value: string,
          } > | null,
          status: TENANT_FORM_STATUS,
        } > | null,
        meetingFields?:  Array< {
          __typename: "MeetingFieldDefinition",
          id: string,
          fieldName: string,
          status: FIELD_STATUS,
          description?: string | null,
          required: boolean,
          maxLength?: number | null,
          type: FIELD_DATA_TYPE,
          controlType: CONTROL_TYPE,
          fieldValueDefinitions:  Array< {
            __typename: "MeetingFieldValueDefinition",
            id: string,
            value: string,
            disabled: boolean,
            createdAt: string,
          } >,
          createdAt: string,
        } > | null,
        customFields?:  Array< {
          __typename: "CustomFieldDefinition",
          id: string,
          fieldName: string,
          fieldLabel: string,
          reportingName?: string | null,
          status: FIELD_STATUS,
          description?: string | null,
          required?: boolean | null,
          maxLength?: number | null,
          formatValidation?: FORMAT_VALIDATION | null,
          fieldType: FIELD_DATA_TYPE,
          showByDefault?: boolean | null,
          dependentFieldId?: string | null,
          controlType: CONTROL_TYPE,
          order?: number | null,
          settings?:  {
            __typename: "CommonFieldSettings",
            isBadge?: boolean | null,
            fieldPosition: FIELD_POSITION,
          } | null,
          usage: Array< CUSTOM_FIELD_USAGE >,
          documentSettings?:  {
            __typename: "DocumentFieldSettings",
            fieldPosition: FIELD_POSITION,
          } | null,
          fieldValueDefinitions:  Array< {
            __typename: "CustomFieldValueDefinition",
            id: string,
            value: string,
            label?: string | null,
            disabled?: boolean | null,
            isDefault?: boolean | null,
            documentSettings?:  {
              __typename: "DocumentFieldValueSettings",
              presentationWatermarkText?: string | null,
              permission?:  {
                __typename: "DocumentPermission",
                present?: PERMISSION_EFFECT | null,
                modify?: PERMISSION_EFFECT | null,
                share?: PERMISSION_EFFECT | null,
                download?: PERMISSION_EFFECT | null,
                externalNotation?: PERMISSION_EFFECT | null,
              } | null,
            } | null,
            badgeColor?: string | null,
            badgeLabel?: string | null,
            createdAt: string,
            dependentValueIds: Array< string >,
          } >,
          displayOnParentSelection?: boolean | null,
          displayOnValueSelection?: Array< string > | null,
          objectSetting?:  {
            __typename: "ObjectSetting",
            childrenFieldIds: Array< string >,
            restriction?:  {
              __typename: "ObjectRestriction",
              syncUpdate?: boolean | null,
              syncDelete?: boolean | null,
              update?: boolean | null,
              delete?: boolean | null,
            } | null,
          } | null,
          isChildField?: boolean | null,
          dateRestriction?: DATE_RESTRICTION | null,
          createdAt: string,
          scaleNumber?: number | null,
          precisionNumber?: number | null,
        } > | null,
        defaultDocumentPermissions:  {
          __typename: "DocumentPermission",
          present?: PERMISSION_EFFECT | null,
          modify?: PERMISSION_EFFECT | null,
          share?: PERMISSION_EFFECT | null,
          download?: PERMISSION_EFFECT | null,
          externalNotation?: PERMISSION_EFFECT | null,
        },
        mslListConfig?:  {
          __typename: "ListConfig",
          sort?:  {
            __typename: "ListSort",
            field: string,
            isAsc?: boolean | null,
          } | null,
          fields:  Array< {
            __typename: "FieldListConfig",
            field: string,
            width?: number | null,
          } >,
        } | null,
        publisherListConfig?:  {
          __typename: "ListConfig",
          sort?:  {
            __typename: "ListSort",
            field: string,
            isAsc?: boolean | null,
          } | null,
          fields:  Array< {
            __typename: "FieldListConfig",
            field: string,
            width?: number | null,
          } >,
        } | null,
        crmIntegration?:  {
          __typename: "CRMIntegration",
          id: string,
          crmIntegrationType: CRM_INTEGRATION_TYPE,
          instanceUrl: string,
          clientId: string,
          redirectUri: string,
          name: string,
          accountsSettings:  {
            __typename: "CRMAccountSettings",
            query: string,
            displaySettings:  {
              __typename: "CRMDisplaySettings",
              shortened: string,
              extended?: Array< string > | null,
            },
            addressSettings:  {
              __typename: "CRMAddressSettings",
              alias: string,
              displaySettings:  {
                __typename: "CRMDisplaySettings",
                shortened: string,
                extended?: Array< string > | null,
              },
            },
          },
          meetingSetting?:  {
            __typename: "CRMTableSetting",
            apiName: string,
            presentationsFieldName?: string | null,
            children:  Array< {
              __typename: "CRMChildTableSetting",
              name: string,
              apiName: string,
              relationshipName?: string | null,
              type: CRM_INTEGRATION_TYPE,
              veevaSetting?:  {
                __typename: "VeevaSetting",
                markerFieldName?: string | null,
              } | null,
            } >,
            type: CRM_INTEGRATION_TYPE,
          } | null,
          additionalSettings?:  {
            __typename: "CRMAdditionalSettings",
            allowCommentsOnTasks?: boolean | null,
            detailContentTableSettings?:  {
              __typename: "CRMTableDetailContentPresented",
              name: string,
              apiName: string,
              relationshipName: string,
              prefix?: string | null,
            } | null,
            enableSurveys?: boolean | null,
            crmAccountToObjectSettings?:  Array< {
              __typename: "CRMAccountToObjectSetting",
              accountFieldNames:  Array< {
                __typename: "CRMAccountToObjectFields",
                accountFieldName: string,
                objectFieldName: string,
              } >,
              apiName: string,
            } > | null,
          } | null,
          loginUrl?: string | null,
          crmStandaloneForms?:  Array< {
            __typename: "CRMStandaloneForm",
            id: string,
            apiName: string,
            children:  Array< {
              __typename: "CRMChildTableSetting",
              name: string,
              apiName: string,
              relationshipName?: string | null,
              type: CRM_INTEGRATION_TYPE,
              veevaSetting?:  {
                __typename: "VeevaSetting",
                markerFieldName?: string | null,
              } | null,
            } >,
            type: CRM_INTEGRATION_TYPE,
            parents:  Array< {
              __typename: "CRMStandaloneFormParent",
              apiName: string,
              relationshipName: string,
            } >,
            usage?: Array< STANDALONE_FORM_USAGE > | null,
          } > | null,
        } | null,
        sessionTimeout?: number | null,
        quickFilters?:  Array< {
          __typename: "QuickFilter",
          id: string,
          label: string,
          path: Array< string >,
        } > | null,
        hubsConfig:  {
          __typename: "HubConfig",
          shareExpirationDays?: number | null,
          disabledSections: Array< HUB_SECTION_TYPE >,
          disableNotationDescription: boolean,
        },
        reports:  Array< {
          __typename: "KeyValue",
          key: string,
          value: boolean,
        } >,
        emailDocumentUpdateFrequency?: FREQUENCY | null,
        sharePPTXasPDF?: boolean | null,
      },
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncTenantsQueryVariables = {
  filter?: ModelTenantFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncTenantsQuery = {
  syncTenants?:  {
    __typename: "ModelTenantConnection",
    items:  Array< {
      __typename: "Tenant",
      id: string,
      name: string,
      licensedUsers: number,
      status: TENANT_STATUS,
      fields:  Array< {
        __typename: "FieldConfig",
        fieldName: string,
        description?: string | null,
        required: boolean,
        isEmailTemplateFilter?: boolean | null,
        dataType: FIELD_DATA_TYPE,
        userFilter: boolean,
        defaultSearchFilter: boolean,
        values: Array< string >,
      } >,
      folderUpdateGracePeriodDays?: number | null,
      medInfoURL?: string | null,
      publisherListConfig?:  {
        __typename: "ListConfiguration",
        fields:  Array< {
          __typename: "ListConfigurationField",
          fieldName: string,
          header?: string | null,
          width?: number | null,
        } >,
        sort?:  {
          __typename: "ListConfigurationSort",
          field: string,
          isAsc: boolean,
        } | null,
      } | null,
      mslListConfig?:  {
        __typename: "ListConfiguration",
        fields:  Array< {
          __typename: "ListConfigurationField",
          fieldName: string,
          header?: string | null,
          width?: number | null,
        } >,
        sort?:  {
          __typename: "ListConfigurationSort",
          field: string,
          isAsc: boolean,
        } | null,
      } | null,
      featureFlags:  Array< {
        __typename: "FeatureFlags",
        flagId: string,
        value: boolean,
      } >,
      createdAt: string,
      createdBy: string,
      updatedAt: string,
      statusChangedAt: string,
      statusChangedBy: string,
      ssoDomains?: Array< string | null > | null,
      integrations?: Array< string | null > | null,
      config:  {
        __typename: "TenantConfig",
        requiredSlidesHiddenMessage?: string | null,
        forms?:  Array< {
          __typename: "TenantForm",
          id: string,
          name: string,
          label: string,
          fields:  Array< {
            __typename: "CustomFieldDefinition",
            id: string,
            fieldName: string,
            fieldLabel: string,
            reportingName?: string | null,
            status: FIELD_STATUS,
            description?: string | null,
            required?: boolean | null,
            maxLength?: number | null,
            formatValidation?: FORMAT_VALIDATION | null,
            fieldType: FIELD_DATA_TYPE,
            showByDefault?: boolean | null,
            dependentFieldId?: string | null,
            controlType: CONTROL_TYPE,
            order?: number | null,
            settings?:  {
              __typename: "CommonFieldSettings",
              isBadge?: boolean | null,
              fieldPosition: FIELD_POSITION,
            } | null,
            usage: Array< CUSTOM_FIELD_USAGE >,
            documentSettings?:  {
              __typename: "DocumentFieldSettings",
              fieldPosition: FIELD_POSITION,
            } | null,
            fieldValueDefinitions:  Array< {
              __typename: "CustomFieldValueDefinition",
              id: string,
              value: string,
              label?: string | null,
              disabled?: boolean | null,
              isDefault?: boolean | null,
              documentSettings?:  {
                __typename: "DocumentFieldValueSettings",
                presentationWatermarkText?: string | null,
                permission?:  {
                  __typename: "DocumentPermission",
                  present?: PERMISSION_EFFECT | null,
                  modify?: PERMISSION_EFFECT | null,
                  share?: PERMISSION_EFFECT | null,
                  download?: PERMISSION_EFFECT | null,
                  externalNotation?: PERMISSION_EFFECT | null,
                } | null,
              } | null,
              badgeColor?: string | null,
              badgeLabel?: string | null,
              createdAt: string,
              dependentValueIds: Array< string >,
            } >,
            displayOnParentSelection?: boolean | null,
            displayOnValueSelection?: Array< string > | null,
            objectSetting?:  {
              __typename: "ObjectSetting",
              childrenFieldIds: Array< string >,
              restriction?:  {
                __typename: "ObjectRestriction",
                syncUpdate?: boolean | null,
                syncDelete?: boolean | null,
                update?: boolean | null,
                delete?: boolean | null,
              } | null,
            } | null,
            isChildField?: boolean | null,
            dateRestriction?: DATE_RESTRICTION | null,
            createdAt: string,
            scaleNumber?: number | null,
            precisionNumber?: number | null,
          } >,
          targetRequestTemplate: string,
          targetType: TENANT_FORM_TARGET_TYPE,
          targetAddress: string,
          targetParameters?:  Array< {
            __typename: "TenantFormTargetParameters",
            key: string,
            value: string,
          } > | null,
          status: TENANT_FORM_STATUS,
        } > | null,
        meetingFields?:  Array< {
          __typename: "MeetingFieldDefinition",
          id: string,
          fieldName: string,
          status: FIELD_STATUS,
          description?: string | null,
          required: boolean,
          maxLength?: number | null,
          type: FIELD_DATA_TYPE,
          controlType: CONTROL_TYPE,
          fieldValueDefinitions:  Array< {
            __typename: "MeetingFieldValueDefinition",
            id: string,
            value: string,
            disabled: boolean,
            createdAt: string,
          } >,
          createdAt: string,
        } > | null,
        customFields?:  Array< {
          __typename: "CustomFieldDefinition",
          id: string,
          fieldName: string,
          fieldLabel: string,
          reportingName?: string | null,
          status: FIELD_STATUS,
          description?: string | null,
          required?: boolean | null,
          maxLength?: number | null,
          formatValidation?: FORMAT_VALIDATION | null,
          fieldType: FIELD_DATA_TYPE,
          showByDefault?: boolean | null,
          dependentFieldId?: string | null,
          controlType: CONTROL_TYPE,
          order?: number | null,
          settings?:  {
            __typename: "CommonFieldSettings",
            isBadge?: boolean | null,
            fieldPosition: FIELD_POSITION,
          } | null,
          usage: Array< CUSTOM_FIELD_USAGE >,
          documentSettings?:  {
            __typename: "DocumentFieldSettings",
            fieldPosition: FIELD_POSITION,
          } | null,
          fieldValueDefinitions:  Array< {
            __typename: "CustomFieldValueDefinition",
            id: string,
            value: string,
            label?: string | null,
            disabled?: boolean | null,
            isDefault?: boolean | null,
            documentSettings?:  {
              __typename: "DocumentFieldValueSettings",
              presentationWatermarkText?: string | null,
              permission?:  {
                __typename: "DocumentPermission",
                present?: PERMISSION_EFFECT | null,
                modify?: PERMISSION_EFFECT | null,
                share?: PERMISSION_EFFECT | null,
                download?: PERMISSION_EFFECT | null,
                externalNotation?: PERMISSION_EFFECT | null,
              } | null,
            } | null,
            badgeColor?: string | null,
            badgeLabel?: string | null,
            createdAt: string,
            dependentValueIds: Array< string >,
          } >,
          displayOnParentSelection?: boolean | null,
          displayOnValueSelection?: Array< string > | null,
          objectSetting?:  {
            __typename: "ObjectSetting",
            childrenFieldIds: Array< string >,
            restriction?:  {
              __typename: "ObjectRestriction",
              syncUpdate?: boolean | null,
              syncDelete?: boolean | null,
              update?: boolean | null,
              delete?: boolean | null,
            } | null,
          } | null,
          isChildField?: boolean | null,
          dateRestriction?: DATE_RESTRICTION | null,
          createdAt: string,
          scaleNumber?: number | null,
          precisionNumber?: number | null,
        } > | null,
        defaultDocumentPermissions:  {
          __typename: "DocumentPermission",
          present?: PERMISSION_EFFECT | null,
          modify?: PERMISSION_EFFECT | null,
          share?: PERMISSION_EFFECT | null,
          download?: PERMISSION_EFFECT | null,
          externalNotation?: PERMISSION_EFFECT | null,
        },
        mslListConfig?:  {
          __typename: "ListConfig",
          sort?:  {
            __typename: "ListSort",
            field: string,
            isAsc?: boolean | null,
          } | null,
          fields:  Array< {
            __typename: "FieldListConfig",
            field: string,
            width?: number | null,
          } >,
        } | null,
        publisherListConfig?:  {
          __typename: "ListConfig",
          sort?:  {
            __typename: "ListSort",
            field: string,
            isAsc?: boolean | null,
          } | null,
          fields:  Array< {
            __typename: "FieldListConfig",
            field: string,
            width?: number | null,
          } >,
        } | null,
        crmIntegration?:  {
          __typename: "CRMIntegration",
          id: string,
          crmIntegrationType: CRM_INTEGRATION_TYPE,
          instanceUrl: string,
          clientId: string,
          redirectUri: string,
          name: string,
          accountsSettings:  {
            __typename: "CRMAccountSettings",
            query: string,
            displaySettings:  {
              __typename: "CRMDisplaySettings",
              shortened: string,
              extended?: Array< string > | null,
            },
            addressSettings:  {
              __typename: "CRMAddressSettings",
              alias: string,
              displaySettings:  {
                __typename: "CRMDisplaySettings",
                shortened: string,
                extended?: Array< string > | null,
              },
            },
          },
          meetingSetting?:  {
            __typename: "CRMTableSetting",
            apiName: string,
            presentationsFieldName?: string | null,
            children:  Array< {
              __typename: "CRMChildTableSetting",
              name: string,
              apiName: string,
              relationshipName?: string | null,
              type: CRM_INTEGRATION_TYPE,
              veevaSetting?:  {
                __typename: "VeevaSetting",
                markerFieldName?: string | null,
              } | null,
            } >,
            type: CRM_INTEGRATION_TYPE,
          } | null,
          additionalSettings?:  {
            __typename: "CRMAdditionalSettings",
            allowCommentsOnTasks?: boolean | null,
            detailContentTableSettings?:  {
              __typename: "CRMTableDetailContentPresented",
              name: string,
              apiName: string,
              relationshipName: string,
              prefix?: string | null,
            } | null,
            enableSurveys?: boolean | null,
            crmAccountToObjectSettings?:  Array< {
              __typename: "CRMAccountToObjectSetting",
              accountFieldNames:  Array< {
                __typename: "CRMAccountToObjectFields",
                accountFieldName: string,
                objectFieldName: string,
              } >,
              apiName: string,
            } > | null,
          } | null,
          loginUrl?: string | null,
          crmStandaloneForms?:  Array< {
            __typename: "CRMStandaloneForm",
            id: string,
            apiName: string,
            children:  Array< {
              __typename: "CRMChildTableSetting",
              name: string,
              apiName: string,
              relationshipName?: string | null,
              type: CRM_INTEGRATION_TYPE,
              veevaSetting?:  {
                __typename: "VeevaSetting",
                markerFieldName?: string | null,
              } | null,
            } >,
            type: CRM_INTEGRATION_TYPE,
            parents:  Array< {
              __typename: "CRMStandaloneFormParent",
              apiName: string,
              relationshipName: string,
            } >,
            usage?: Array< STANDALONE_FORM_USAGE > | null,
          } > | null,
        } | null,
        sessionTimeout?: number | null,
        quickFilters?:  Array< {
          __typename: "QuickFilter",
          id: string,
          label: string,
          path: Array< string >,
        } > | null,
        hubsConfig:  {
          __typename: "HubConfig",
          shareExpirationDays?: number | null,
          disabledSections: Array< HUB_SECTION_TYPE >,
          disableNotationDescription: boolean,
        },
        reports:  Array< {
          __typename: "KeyValue",
          key: string,
          value: boolean,
        } >,
        emailDocumentUpdateFrequency?: FREQUENCY | null,
        sharePPTXasPDF?: boolean | null,
      },
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetDocumentVersionQueryVariables = {
  id: string,
};

export type GetDocumentVersionQuery = {
  getDocumentVersion?:  {
    __typename: "DocumentVersion",
    id: string,
    tenantId: string,
    documentId: string,
    versionNumber: number,
    srcFilename?: string | null,
    contentURL?: string | null,
    conversionStatus: CONVERSION_STATUS,
    status: DOCUMENT_STATUS,
    srcFile?:  {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
      url: string,
    } | null,
    srcHash?: string | null,
    srcSize?: number | null,
    numPages?: number | null,
    pageSettings:  Array< {
      __typename: "PageSetting",
      pageId: string,
      number: number,
      isRequired?: boolean | null,
      linkedSlides: Array< string >,
    } >,
    pageGroups?:  Array< {
      __typename: "PageGroup",
      id: string,
      pageIds?: Array< string > | null,
      name: string,
      locked: boolean,
      sourceID?: string | null,
      source: PAGE_GROUP_SOURCE,
    } > | null,
    type: FILE_TYPE,
    releaseNotes?: string | null,
    changeType?: DOCUMENT_VERSION_CHANGE_TYPE | null,
    labelValues?:  Array< {
      __typename: "LabelValue",
      key: string,
      value: string,
    } > | null,
    customValues?:  Array< {
      __typename: "CustomValues",
      fieldId: string,
      objectRecords?:  Array< {
        __typename: "ObjectRecord",
        id: string,
        externalId?: string | null,
        syncStatus?: CRMSyncStatus | null,
        status: OBJECT_RECORD_STATUS,
        values:  Array< {
          __typename: "ObjectRecordValue",
          fieldId: string,
          values: Array< string >,
        } >,
      } > | null,
      values: Array< string >,
    } > | null,
    title?: string | null,
    shortDescription?: string | null,
    longDescription?: string | null,
    owner?: string | null,
    expiresAt?: string | null,
    hasCopyright?: boolean | null,
    hasCustomThumbnail?: boolean | null,
    purpose?: string | null,
    canHideSlides?: boolean | null,
    distributable?: boolean | null,
    downloadable?: boolean | null,
    isInternalGenerated?: boolean | null,
    semVer?:  {
      __typename: "SemVer",
      minor: number,
      major: number,
    } | null,
    notificationScope?: NOTIFICATION_SCOPE | null,
    selectedThumbnail?: number | null,
    publishedAt?: string | null,
    uploadedAt?: string | null,
    uploadedBy?: string | null,
    convertedArchiveKey?: string | null,
    convertedArchiveSize?: number | null,
    convertedFolderKey?: string | null,
    associatedFiles?:  Array< {
      __typename: "AssociatedFile",
      id: string,
      isDistributable?: boolean | null,
      isDefault?: boolean | null,
      type: ASSOCIATED_FILE_TYPE,
      attachmentId: string,
      status: ASSOCIATED_FILE_STATUS,
      createdAt: string,
      createdBy: string,
    } > | null,
    editPermissions: Array< string >,
    converterVersion?: number | null,
    createdAt: string,
    createdBy: string,
    updatedAt: string,
    updatedBy: string,
    integration?:  {
      __typename: "DocumentVersionIntegration",
      externalVersionId?: string | null,
      version?: string | null,
      timestamp?: string | null,
      srcFileHash?: string | null,
      srcDocumentHash?: string | null,
    } | null,
    integrationType?: INTEGRATION_TYPE | null,
    conversionWarningCode?: CONVERSION_WARNING_CODE | null,
    conversionErrorCode?: string | null,
    scheduledPublish?: string | null,
    mappingStatus?: MAPPING_STATUS | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListDocumentVersionsQueryVariables = {
  filter?: ModelDocumentVersionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListDocumentVersionsQuery = {
  listDocumentVersions?:  {
    __typename: "ModelDocumentVersionConnection",
    items:  Array< {
      __typename: "DocumentVersion",
      id: string,
      tenantId: string,
      documentId: string,
      versionNumber: number,
      srcFilename?: string | null,
      contentURL?: string | null,
      conversionStatus: CONVERSION_STATUS,
      status: DOCUMENT_STATUS,
      srcFile?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
        url: string,
      } | null,
      srcHash?: string | null,
      srcSize?: number | null,
      numPages?: number | null,
      pageSettings:  Array< {
        __typename: "PageSetting",
        pageId: string,
        number: number,
        isRequired?: boolean | null,
        linkedSlides: Array< string >,
      } >,
      pageGroups?:  Array< {
        __typename: "PageGroup",
        id: string,
        pageIds?: Array< string > | null,
        name: string,
        locked: boolean,
        sourceID?: string | null,
        source: PAGE_GROUP_SOURCE,
      } > | null,
      type: FILE_TYPE,
      releaseNotes?: string | null,
      changeType?: DOCUMENT_VERSION_CHANGE_TYPE | null,
      labelValues?:  Array< {
        __typename: "LabelValue",
        key: string,
        value: string,
      } > | null,
      customValues?:  Array< {
        __typename: "CustomValues",
        fieldId: string,
        objectRecords?:  Array< {
          __typename: "ObjectRecord",
          id: string,
          externalId?: string | null,
          syncStatus?: CRMSyncStatus | null,
          status: OBJECT_RECORD_STATUS,
          values:  Array< {
            __typename: "ObjectRecordValue",
            fieldId: string,
            values: Array< string >,
          } >,
        } > | null,
        values: Array< string >,
      } > | null,
      title?: string | null,
      shortDescription?: string | null,
      longDescription?: string | null,
      owner?: string | null,
      expiresAt?: string | null,
      hasCopyright?: boolean | null,
      hasCustomThumbnail?: boolean | null,
      purpose?: string | null,
      canHideSlides?: boolean | null,
      distributable?: boolean | null,
      downloadable?: boolean | null,
      isInternalGenerated?: boolean | null,
      semVer?:  {
        __typename: "SemVer",
        minor: number,
        major: number,
      } | null,
      notificationScope?: NOTIFICATION_SCOPE | null,
      selectedThumbnail?: number | null,
      publishedAt?: string | null,
      uploadedAt?: string | null,
      uploadedBy?: string | null,
      convertedArchiveKey?: string | null,
      convertedArchiveSize?: number | null,
      convertedFolderKey?: string | null,
      associatedFiles?:  Array< {
        __typename: "AssociatedFile",
        id: string,
        isDistributable?: boolean | null,
        isDefault?: boolean | null,
        type: ASSOCIATED_FILE_TYPE,
        attachmentId: string,
        status: ASSOCIATED_FILE_STATUS,
        createdAt: string,
        createdBy: string,
      } > | null,
      editPermissions: Array< string >,
      converterVersion?: number | null,
      createdAt: string,
      createdBy: string,
      updatedAt: string,
      updatedBy: string,
      integration?:  {
        __typename: "DocumentVersionIntegration",
        externalVersionId?: string | null,
        version?: string | null,
        timestamp?: string | null,
        srcFileHash?: string | null,
        srcDocumentHash?: string | null,
      } | null,
      integrationType?: INTEGRATION_TYPE | null,
      conversionWarningCode?: CONVERSION_WARNING_CODE | null,
      conversionErrorCode?: string | null,
      scheduledPublish?: string | null,
      mappingStatus?: MAPPING_STATUS | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncDocumentVersionsQueryVariables = {
  filter?: ModelDocumentVersionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncDocumentVersionsQuery = {
  syncDocumentVersions?:  {
    __typename: "ModelDocumentVersionConnection",
    items:  Array< {
      __typename: "DocumentVersion",
      id: string,
      tenantId: string,
      documentId: string,
      versionNumber: number,
      srcFilename?: string | null,
      contentURL?: string | null,
      conversionStatus: CONVERSION_STATUS,
      status: DOCUMENT_STATUS,
      srcFile?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
        url: string,
      } | null,
      srcHash?: string | null,
      srcSize?: number | null,
      numPages?: number | null,
      pageSettings:  Array< {
        __typename: "PageSetting",
        pageId: string,
        number: number,
        isRequired?: boolean | null,
        linkedSlides: Array< string >,
      } >,
      pageGroups?:  Array< {
        __typename: "PageGroup",
        id: string,
        pageIds?: Array< string > | null,
        name: string,
        locked: boolean,
        sourceID?: string | null,
        source: PAGE_GROUP_SOURCE,
      } > | null,
      type: FILE_TYPE,
      releaseNotes?: string | null,
      changeType?: DOCUMENT_VERSION_CHANGE_TYPE | null,
      labelValues?:  Array< {
        __typename: "LabelValue",
        key: string,
        value: string,
      } > | null,
      customValues?:  Array< {
        __typename: "CustomValues",
        fieldId: string,
        objectRecords?:  Array< {
          __typename: "ObjectRecord",
          id: string,
          externalId?: string | null,
          syncStatus?: CRMSyncStatus | null,
          status: OBJECT_RECORD_STATUS,
          values:  Array< {
            __typename: "ObjectRecordValue",
            fieldId: string,
            values: Array< string >,
          } >,
        } > | null,
        values: Array< string >,
      } > | null,
      title?: string | null,
      shortDescription?: string | null,
      longDescription?: string | null,
      owner?: string | null,
      expiresAt?: string | null,
      hasCopyright?: boolean | null,
      hasCustomThumbnail?: boolean | null,
      purpose?: string | null,
      canHideSlides?: boolean | null,
      distributable?: boolean | null,
      downloadable?: boolean | null,
      isInternalGenerated?: boolean | null,
      semVer?:  {
        __typename: "SemVer",
        minor: number,
        major: number,
      } | null,
      notificationScope?: NOTIFICATION_SCOPE | null,
      selectedThumbnail?: number | null,
      publishedAt?: string | null,
      uploadedAt?: string | null,
      uploadedBy?: string | null,
      convertedArchiveKey?: string | null,
      convertedArchiveSize?: number | null,
      convertedFolderKey?: string | null,
      associatedFiles?:  Array< {
        __typename: "AssociatedFile",
        id: string,
        isDistributable?: boolean | null,
        isDefault?: boolean | null,
        type: ASSOCIATED_FILE_TYPE,
        attachmentId: string,
        status: ASSOCIATED_FILE_STATUS,
        createdAt: string,
        createdBy: string,
      } > | null,
      editPermissions: Array< string >,
      converterVersion?: number | null,
      createdAt: string,
      createdBy: string,
      updatedAt: string,
      updatedBy: string,
      integration?:  {
        __typename: "DocumentVersionIntegration",
        externalVersionId?: string | null,
        version?: string | null,
        timestamp?: string | null,
        srcFileHash?: string | null,
        srcDocumentHash?: string | null,
      } | null,
      integrationType?: INTEGRATION_TYPE | null,
      conversionWarningCode?: CONVERSION_WARNING_CODE | null,
      conversionErrorCode?: string | null,
      scheduledPublish?: string | null,
      mappingStatus?: MAPPING_STATUS | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type DocumentVersionsByTenantIdStatusQueryVariables = {
  tenantId: string,
  status?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelDocumentVersionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type DocumentVersionsByTenantIdStatusQuery = {
  documentVersionsByTenantIdStatus?:  {
    __typename: "ModelDocumentVersionConnection",
    items:  Array< {
      __typename: "DocumentVersion",
      id: string,
      tenantId: string,
      documentId: string,
      versionNumber: number,
      srcFilename?: string | null,
      contentURL?: string | null,
      conversionStatus: CONVERSION_STATUS,
      status: DOCUMENT_STATUS,
      srcFile?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
        url: string,
      } | null,
      srcHash?: string | null,
      srcSize?: number | null,
      numPages?: number | null,
      pageSettings:  Array< {
        __typename: "PageSetting",
        pageId: string,
        number: number,
        isRequired?: boolean | null,
        linkedSlides: Array< string >,
      } >,
      pageGroups?:  Array< {
        __typename: "PageGroup",
        id: string,
        pageIds?: Array< string > | null,
        name: string,
        locked: boolean,
        sourceID?: string | null,
        source: PAGE_GROUP_SOURCE,
      } > | null,
      type: FILE_TYPE,
      releaseNotes?: string | null,
      changeType?: DOCUMENT_VERSION_CHANGE_TYPE | null,
      labelValues?:  Array< {
        __typename: "LabelValue",
        key: string,
        value: string,
      } > | null,
      customValues?:  Array< {
        __typename: "CustomValues",
        fieldId: string,
        objectRecords?:  Array< {
          __typename: "ObjectRecord",
          id: string,
          externalId?: string | null,
          syncStatus?: CRMSyncStatus | null,
          status: OBJECT_RECORD_STATUS,
          values:  Array< {
            __typename: "ObjectRecordValue",
            fieldId: string,
            values: Array< string >,
          } >,
        } > | null,
        values: Array< string >,
      } > | null,
      title?: string | null,
      shortDescription?: string | null,
      longDescription?: string | null,
      owner?: string | null,
      expiresAt?: string | null,
      hasCopyright?: boolean | null,
      hasCustomThumbnail?: boolean | null,
      purpose?: string | null,
      canHideSlides?: boolean | null,
      distributable?: boolean | null,
      downloadable?: boolean | null,
      isInternalGenerated?: boolean | null,
      semVer?:  {
        __typename: "SemVer",
        minor: number,
        major: number,
      } | null,
      notificationScope?: NOTIFICATION_SCOPE | null,
      selectedThumbnail?: number | null,
      publishedAt?: string | null,
      uploadedAt?: string | null,
      uploadedBy?: string | null,
      convertedArchiveKey?: string | null,
      convertedArchiveSize?: number | null,
      convertedFolderKey?: string | null,
      associatedFiles?:  Array< {
        __typename: "AssociatedFile",
        id: string,
        isDistributable?: boolean | null,
        isDefault?: boolean | null,
        type: ASSOCIATED_FILE_TYPE,
        attachmentId: string,
        status: ASSOCIATED_FILE_STATUS,
        createdAt: string,
        createdBy: string,
      } > | null,
      editPermissions: Array< string >,
      converterVersion?: number | null,
      createdAt: string,
      createdBy: string,
      updatedAt: string,
      updatedBy: string,
      integration?:  {
        __typename: "DocumentVersionIntegration",
        externalVersionId?: string | null,
        version?: string | null,
        timestamp?: string | null,
        srcFileHash?: string | null,
        srcDocumentHash?: string | null,
      } | null,
      integrationType?: INTEGRATION_TYPE | null,
      conversionWarningCode?: CONVERSION_WARNING_CODE | null,
      conversionErrorCode?: string | null,
      scheduledPublish?: string | null,
      mappingStatus?: MAPPING_STATUS | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type DocumentVersionsByDocumentIdVersionNumberQueryVariables = {
  documentId: string,
  versionNumber?: ModelIntKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelDocumentVersionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type DocumentVersionsByDocumentIdVersionNumberQuery = {
  documentVersionsByDocumentIdVersionNumber?:  {
    __typename: "ModelDocumentVersionConnection",
    items:  Array< {
      __typename: "DocumentVersion",
      id: string,
      tenantId: string,
      documentId: string,
      versionNumber: number,
      srcFilename?: string | null,
      contentURL?: string | null,
      conversionStatus: CONVERSION_STATUS,
      status: DOCUMENT_STATUS,
      srcFile?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
        url: string,
      } | null,
      srcHash?: string | null,
      srcSize?: number | null,
      numPages?: number | null,
      pageSettings:  Array< {
        __typename: "PageSetting",
        pageId: string,
        number: number,
        isRequired?: boolean | null,
        linkedSlides: Array< string >,
      } >,
      pageGroups?:  Array< {
        __typename: "PageGroup",
        id: string,
        pageIds?: Array< string > | null,
        name: string,
        locked: boolean,
        sourceID?: string | null,
        source: PAGE_GROUP_SOURCE,
      } > | null,
      type: FILE_TYPE,
      releaseNotes?: string | null,
      changeType?: DOCUMENT_VERSION_CHANGE_TYPE | null,
      labelValues?:  Array< {
        __typename: "LabelValue",
        key: string,
        value: string,
      } > | null,
      customValues?:  Array< {
        __typename: "CustomValues",
        fieldId: string,
        objectRecords?:  Array< {
          __typename: "ObjectRecord",
          id: string,
          externalId?: string | null,
          syncStatus?: CRMSyncStatus | null,
          status: OBJECT_RECORD_STATUS,
          values:  Array< {
            __typename: "ObjectRecordValue",
            fieldId: string,
            values: Array< string >,
          } >,
        } > | null,
        values: Array< string >,
      } > | null,
      title?: string | null,
      shortDescription?: string | null,
      longDescription?: string | null,
      owner?: string | null,
      expiresAt?: string | null,
      hasCopyright?: boolean | null,
      hasCustomThumbnail?: boolean | null,
      purpose?: string | null,
      canHideSlides?: boolean | null,
      distributable?: boolean | null,
      downloadable?: boolean | null,
      isInternalGenerated?: boolean | null,
      semVer?:  {
        __typename: "SemVer",
        minor: number,
        major: number,
      } | null,
      notificationScope?: NOTIFICATION_SCOPE | null,
      selectedThumbnail?: number | null,
      publishedAt?: string | null,
      uploadedAt?: string | null,
      uploadedBy?: string | null,
      convertedArchiveKey?: string | null,
      convertedArchiveSize?: number | null,
      convertedFolderKey?: string | null,
      associatedFiles?:  Array< {
        __typename: "AssociatedFile",
        id: string,
        isDistributable?: boolean | null,
        isDefault?: boolean | null,
        type: ASSOCIATED_FILE_TYPE,
        attachmentId: string,
        status: ASSOCIATED_FILE_STATUS,
        createdAt: string,
        createdBy: string,
      } > | null,
      editPermissions: Array< string >,
      converterVersion?: number | null,
      createdAt: string,
      createdBy: string,
      updatedAt: string,
      updatedBy: string,
      integration?:  {
        __typename: "DocumentVersionIntegration",
        externalVersionId?: string | null,
        version?: string | null,
        timestamp?: string | null,
        srcFileHash?: string | null,
        srcDocumentHash?: string | null,
      } | null,
      integrationType?: INTEGRATION_TYPE | null,
      conversionWarningCode?: CONVERSION_WARNING_CODE | null,
      conversionErrorCode?: string | null,
      scheduledPublish?: string | null,
      mappingStatus?: MAPPING_STATUS | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetAttachedFileQueryVariables = {
  id: string,
};

export type GetAttachedFileQuery = {
  getAttachedFile?:  {
    __typename: "AttachedFile",
    id: string,
    title: string,
    tenantId: string,
    documentId?: string | null,
    srcFile:  {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
      url: string,
    },
    srcFileName: string,
    srcHash?: string | null,
    srcSize: number,
    type: FILE_TYPE,
    editPermissions: Array< string >,
    createdAt: string,
    createdBy: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListAttachedFilesQueryVariables = {
  filter?: ModelAttachedFileFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListAttachedFilesQuery = {
  listAttachedFiles?:  {
    __typename: "ModelAttachedFileConnection",
    items:  Array< {
      __typename: "AttachedFile",
      id: string,
      title: string,
      tenantId: string,
      documentId?: string | null,
      srcFile:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
        url: string,
      },
      srcFileName: string,
      srcHash?: string | null,
      srcSize: number,
      type: FILE_TYPE,
      editPermissions: Array< string >,
      createdAt: string,
      createdBy: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncAttachedFilesQueryVariables = {
  filter?: ModelAttachedFileFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncAttachedFilesQuery = {
  syncAttachedFiles?:  {
    __typename: "ModelAttachedFileConnection",
    items:  Array< {
      __typename: "AttachedFile",
      id: string,
      title: string,
      tenantId: string,
      documentId?: string | null,
      srcFile:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
        url: string,
      },
      srcFileName: string,
      srcHash?: string | null,
      srcSize: number,
      type: FILE_TYPE,
      editPermissions: Array< string >,
      createdAt: string,
      createdBy: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetDocumentQueryVariables = {
  id: string,
};

export type GetDocumentQuery = {
  getDocument?:  {
    __typename: "Document",
    id: string,
    tenantId: string,
    accessLevel: DOCUMENT_ACCESS_LEVEL,
    status: DOCUMENT_STATUS,
    owner: string,
    expiresAt?: string | null,
    integration?:  {
      __typename: "DocumentIntegration",
      integrationId?: string | null,
      externalId?: string | null,
      firstSync?: string | null,
      lastSync?: string | null,
    } | null,
    integrationType?: INTEGRATION_TYPE | null,
    type: FILE_TYPE,
    editPermissions?: Array< string > | null,
    createdAt: string,
    createdBy: string,
    updatedAt: string,
    updatedBy: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListDocumentsQueryVariables = {
  filter?: ModelDocumentFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListDocumentsQuery = {
  listDocuments?:  {
    __typename: "ModelDocumentConnection",
    items:  Array< {
      __typename: "Document",
      id: string,
      tenantId: string,
      accessLevel: DOCUMENT_ACCESS_LEVEL,
      status: DOCUMENT_STATUS,
      owner: string,
      expiresAt?: string | null,
      integration?:  {
        __typename: "DocumentIntegration",
        integrationId?: string | null,
        externalId?: string | null,
        firstSync?: string | null,
        lastSync?: string | null,
      } | null,
      integrationType?: INTEGRATION_TYPE | null,
      type: FILE_TYPE,
      editPermissions?: Array< string > | null,
      createdAt: string,
      createdBy: string,
      updatedAt: string,
      updatedBy: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncDocumentsQueryVariables = {
  filter?: ModelDocumentFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncDocumentsQuery = {
  syncDocuments?:  {
    __typename: "ModelDocumentConnection",
    items:  Array< {
      __typename: "Document",
      id: string,
      tenantId: string,
      accessLevel: DOCUMENT_ACCESS_LEVEL,
      status: DOCUMENT_STATUS,
      owner: string,
      expiresAt?: string | null,
      integration?:  {
        __typename: "DocumentIntegration",
        integrationId?: string | null,
        externalId?: string | null,
        firstSync?: string | null,
        lastSync?: string | null,
      } | null,
      integrationType?: INTEGRATION_TYPE | null,
      type: FILE_TYPE,
      editPermissions?: Array< string > | null,
      createdAt: string,
      createdBy: string,
      updatedAt: string,
      updatedBy: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetEmailTemplateQueryVariables = {
  id: string,
};

export type GetEmailTemplateQuery = {
  getEmailTemplate?:  {
    __typename: "EmailTemplate",
    id: string,
    tenantId: string,
    labelValues?:  Array< {
      __typename: "LabelValue",
      key: string,
      value: string,
    } > | null,
    customFilterValues?:  Array< {
      __typename: "CustomValues",
      fieldId: string,
      objectRecords?:  Array< {
        __typename: "ObjectRecord",
        id: string,
        externalId?: string | null,
        syncStatus?: CRMSyncStatus | null,
        status: OBJECT_RECORD_STATUS,
        values:  Array< {
          __typename: "ObjectRecordValue",
          fieldId: string,
          values: Array< string >,
        } >,
      } > | null,
      values: Array< string >,
    } > | null,
    subject?: string | null,
    title?: string | null,
    cc?: Array< string > | null,
    bcc?: Array< string > | null,
    body?: string | null,
    associatedFiles?:  Array< {
      __typename: "AssociatedFile",
      id: string,
      isDistributable?: boolean | null,
      isDefault?: boolean | null,
      type: ASSOCIATED_FILE_TYPE,
      attachmentId: string,
      status: ASSOCIATED_FILE_STATUS,
      createdAt: string,
      createdBy: string,
    } > | null,
    status: EMAIL_TEMPLATE_STATUS,
    editPermissions?: Array< string > | null,
    createdBy: string,
    createdAt: string,
    updatedBy: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListEmailTemplatesQueryVariables = {
  filter?: ModelEmailTemplateFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListEmailTemplatesQuery = {
  listEmailTemplates?:  {
    __typename: "ModelEmailTemplateConnection",
    items:  Array< {
      __typename: "EmailTemplate",
      id: string,
      tenantId: string,
      labelValues?:  Array< {
        __typename: "LabelValue",
        key: string,
        value: string,
      } > | null,
      customFilterValues?:  Array< {
        __typename: "CustomValues",
        fieldId: string,
        objectRecords?:  Array< {
          __typename: "ObjectRecord",
          id: string,
          externalId?: string | null,
          syncStatus?: CRMSyncStatus | null,
          status: OBJECT_RECORD_STATUS,
          values:  Array< {
            __typename: "ObjectRecordValue",
            fieldId: string,
            values: Array< string >,
          } >,
        } > | null,
        values: Array< string >,
      } > | null,
      subject?: string | null,
      title?: string | null,
      cc?: Array< string > | null,
      bcc?: Array< string > | null,
      body?: string | null,
      associatedFiles?:  Array< {
        __typename: "AssociatedFile",
        id: string,
        isDistributable?: boolean | null,
        isDefault?: boolean | null,
        type: ASSOCIATED_FILE_TYPE,
        attachmentId: string,
        status: ASSOCIATED_FILE_STATUS,
        createdAt: string,
        createdBy: string,
      } > | null,
      status: EMAIL_TEMPLATE_STATUS,
      editPermissions?: Array< string > | null,
      createdBy: string,
      createdAt: string,
      updatedBy: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncEmailTemplatesQueryVariables = {
  filter?: ModelEmailTemplateFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncEmailTemplatesQuery = {
  syncEmailTemplates?:  {
    __typename: "ModelEmailTemplateConnection",
    items:  Array< {
      __typename: "EmailTemplate",
      id: string,
      tenantId: string,
      labelValues?:  Array< {
        __typename: "LabelValue",
        key: string,
        value: string,
      } > | null,
      customFilterValues?:  Array< {
        __typename: "CustomValues",
        fieldId: string,
        objectRecords?:  Array< {
          __typename: "ObjectRecord",
          id: string,
          externalId?: string | null,
          syncStatus?: CRMSyncStatus | null,
          status: OBJECT_RECORD_STATUS,
          values:  Array< {
            __typename: "ObjectRecordValue",
            fieldId: string,
            values: Array< string >,
          } >,
        } > | null,
        values: Array< string >,
      } > | null,
      subject?: string | null,
      title?: string | null,
      cc?: Array< string > | null,
      bcc?: Array< string > | null,
      body?: string | null,
      associatedFiles?:  Array< {
        __typename: "AssociatedFile",
        id: string,
        isDistributable?: boolean | null,
        isDefault?: boolean | null,
        type: ASSOCIATED_FILE_TYPE,
        attachmentId: string,
        status: ASSOCIATED_FILE_STATUS,
        createdAt: string,
        createdBy: string,
      } > | null,
      status: EMAIL_TEMPLATE_STATUS,
      editPermissions?: Array< string > | null,
      createdBy: string,
      createdAt: string,
      updatedBy: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetDocumentSettingQueryVariables = {
  id: string,
};

export type GetDocumentSettingQuery = {
  getDocumentSetting?:  {
    __typename: "DocumentSetting",
    id: string,
    tenantId: string,
    createdAt: string,
    createdBy: string,
    updatedAt: string,
    updatedBy: string,
    documentId: string,
    documentVersionId: string,
    notation:  Array< {
      __typename: "Notation",
      id: string,
      type: NOTATION_TYPE,
      description?: string | null,
      status: HUB_SECTION_ITEM_STATUS,
      pageId: string,
      coordinate?:  Array< {
        __typename: "Coordinate",
        x: number,
        y: number,
      } > | null,
      createdAt: string,
      createdBy: string,
      updatedAt: string,
      updatedBy: string,
    } >,
    status: USER_NOTATIONS_STATUS,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListDocumentSettingsQueryVariables = {
  filter?: ModelDocumentSettingFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListDocumentSettingsQuery = {
  listDocumentSettings?:  {
    __typename: "ModelDocumentSettingConnection",
    items:  Array< {
      __typename: "DocumentSetting",
      id: string,
      tenantId: string,
      createdAt: string,
      createdBy: string,
      updatedAt: string,
      updatedBy: string,
      documentId: string,
      documentVersionId: string,
      notation:  Array< {
        __typename: "Notation",
        id: string,
        type: NOTATION_TYPE,
        description?: string | null,
        status: HUB_SECTION_ITEM_STATUS,
        pageId: string,
        coordinate?:  Array< {
          __typename: "Coordinate",
          x: number,
          y: number,
        } > | null,
        createdAt: string,
        createdBy: string,
        updatedAt: string,
        updatedBy: string,
      } >,
      status: USER_NOTATIONS_STATUS,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncDocumentSettingsQueryVariables = {
  filter?: ModelDocumentSettingFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncDocumentSettingsQuery = {
  syncDocumentSettings?:  {
    __typename: "ModelDocumentSettingConnection",
    items:  Array< {
      __typename: "DocumentSetting",
      id: string,
      tenantId: string,
      createdAt: string,
      createdBy: string,
      updatedAt: string,
      updatedBy: string,
      documentId: string,
      documentVersionId: string,
      notation:  Array< {
        __typename: "Notation",
        id: string,
        type: NOTATION_TYPE,
        description?: string | null,
        status: HUB_SECTION_ITEM_STATUS,
        pageId: string,
        coordinate?:  Array< {
          __typename: "Coordinate",
          x: number,
          y: number,
        } > | null,
        createdAt: string,
        createdBy: string,
        updatedAt: string,
        updatedBy: string,
      } >,
      status: USER_NOTATIONS_STATUS,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetUserNotationsQueryVariables = {
  id: string,
};

export type GetUserNotationsQuery = {
  getUserNotations?:  {
    __typename: "UserNotations",
    id: string,
    tenantId: string,
    createdAt: string,
    createdBy: string,
    updatedAt: string,
    updatedBy: string,
    documentId?: string | null,
    documentVersionId?: string | null,
    customDeckId?: string | null,
    notation:  Array< {
      __typename: "Notation",
      id: string,
      type: NOTATION_TYPE,
      description?: string | null,
      status: HUB_SECTION_ITEM_STATUS,
      pageId: string,
      coordinate?:  Array< {
        __typename: "Coordinate",
        x: number,
        y: number,
      } > | null,
      createdAt: string,
      createdBy: string,
      updatedAt: string,
      updatedBy: string,
    } >,
    status: USER_NOTATIONS_STATUS,
    type: USER_NOTATIONS_TYPE,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListUserNotationsQueryVariables = {
  filter?: ModelUserNotationsFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListUserNotationsQuery = {
  listUserNotations?:  {
    __typename: "ModelUserNotationsConnection",
    items:  Array< {
      __typename: "UserNotations",
      id: string,
      tenantId: string,
      createdAt: string,
      createdBy: string,
      updatedAt: string,
      updatedBy: string,
      documentId?: string | null,
      documentVersionId?: string | null,
      customDeckId?: string | null,
      notation:  Array< {
        __typename: "Notation",
        id: string,
        type: NOTATION_TYPE,
        description?: string | null,
        status: HUB_SECTION_ITEM_STATUS,
        pageId: string,
        coordinate?:  Array< {
          __typename: "Coordinate",
          x: number,
          y: number,
        } > | null,
        createdAt: string,
        createdBy: string,
        updatedAt: string,
        updatedBy: string,
      } >,
      status: USER_NOTATIONS_STATUS,
      type: USER_NOTATIONS_TYPE,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncUserNotationsQueryVariables = {
  filter?: ModelUserNotationsFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncUserNotationsQuery = {
  syncUserNotations?:  {
    __typename: "ModelUserNotationsConnection",
    items:  Array< {
      __typename: "UserNotations",
      id: string,
      tenantId: string,
      createdAt: string,
      createdBy: string,
      updatedAt: string,
      updatedBy: string,
      documentId?: string | null,
      documentVersionId?: string | null,
      customDeckId?: string | null,
      notation:  Array< {
        __typename: "Notation",
        id: string,
        type: NOTATION_TYPE,
        description?: string | null,
        status: HUB_SECTION_ITEM_STATUS,
        pageId: string,
        coordinate?:  Array< {
          __typename: "Coordinate",
          x: number,
          y: number,
        } > | null,
        createdAt: string,
        createdBy: string,
        updatedAt: string,
        updatedBy: string,
      } >,
      status: USER_NOTATIONS_STATUS,
      type: USER_NOTATIONS_TYPE,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetCustomDeckQueryVariables = {
  id: string,
};

export type GetCustomDeckQuery = {
  getCustomDeck?:  {
    __typename: "CustomDeck",
    id: string,
    createdAt: string,
    createdBy: string,
    autoUpdateAcknowledgedAt?: string | null,
    updatedAt: string,
    updatedBy: string,
    tenantId: string,
    groups:  Array< {
      __typename: "CustomDeckGroup",
      id: string,
      pages:  Array< {
        __typename: "CustomDeckPage",
        pageId: string,
        pageNumber: number,
        documentVersionId: string,
      } >,
      visible: boolean,
      srcId?: string | null,
      docAccessLevel: DOCUMENT_ACCESS_LEVEL,
      name?: string | null,
      locked?: boolean | null,
    } >,
    title: string,
    editMutex?:  {
      __typename: "EditMutex",
      userId: string,
      timeStarted: string,
      lastSeenAt: string,
    } | null,
    sourceCustomDeckId?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListCustomDecksQueryVariables = {
  filter?: ModelCustomDeckFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListCustomDecksQuery = {
  listCustomDecks?:  {
    __typename: "ModelCustomDeckConnection",
    items:  Array< {
      __typename: "CustomDeck",
      id: string,
      createdAt: string,
      createdBy: string,
      autoUpdateAcknowledgedAt?: string | null,
      updatedAt: string,
      updatedBy: string,
      tenantId: string,
      groups:  Array< {
        __typename: "CustomDeckGroup",
        id: string,
        pages:  Array< {
          __typename: "CustomDeckPage",
          pageId: string,
          pageNumber: number,
          documentVersionId: string,
        } >,
        visible: boolean,
        srcId?: string | null,
        docAccessLevel: DOCUMENT_ACCESS_LEVEL,
        name?: string | null,
        locked?: boolean | null,
      } >,
      title: string,
      editMutex?:  {
        __typename: "EditMutex",
        userId: string,
        timeStarted: string,
        lastSeenAt: string,
      } | null,
      sourceCustomDeckId?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncCustomDecksQueryVariables = {
  filter?: ModelCustomDeckFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncCustomDecksQuery = {
  syncCustomDecks?:  {
    __typename: "ModelCustomDeckConnection",
    items:  Array< {
      __typename: "CustomDeck",
      id: string,
      createdAt: string,
      createdBy: string,
      autoUpdateAcknowledgedAt?: string | null,
      updatedAt: string,
      updatedBy: string,
      tenantId: string,
      groups:  Array< {
        __typename: "CustomDeckGroup",
        id: string,
        pages:  Array< {
          __typename: "CustomDeckPage",
          pageId: string,
          pageNumber: number,
          documentVersionId: string,
        } >,
        visible: boolean,
        srcId?: string | null,
        docAccessLevel: DOCUMENT_ACCESS_LEVEL,
        name?: string | null,
        locked?: boolean | null,
      } >,
      title: string,
      editMutex?:  {
        __typename: "EditMutex",
        userId: string,
        timeStarted: string,
        lastSeenAt: string,
      } | null,
      sourceCustomDeckId?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetFolderQueryVariables = {
  id: string,
};

export type GetFolderQuery = {
  getFolder?:  {
    __typename: "Folder",
    id: string,
    tenantId: string,
    name: string,
    isPinned?: boolean | null,
    status: FOLDER_STATUS,
    items:  Array< {
      __typename: "FolderItem",
      id: string,
      type: FOLDER_ITEM_TYPE,
      itemId: string,
      status?: FOLDER_ITEM_STATUS | null,
      addedAt: string,
      updateAcknowledgedAt?: string | null,
      itemLastUpdatedAt: string,
      visiblePages?: Array< number > | null,
      customTitle?: string | null,
    } >,
    createdAt: string,
    createdBy: string,
    updatedAt: string,
    updatedBy: string,
    sharePermissions?:  Array< {
      __typename: "SharePermission",
      id: string,
      isDeleted?: boolean | null,
      type: PERMISSION_TYPE,
      targetType: SHARE_TARGET_TYPE,
      targetUsername?: string | null,
      targetCustomValues?:  Array< {
        __typename: "CustomValues",
        fieldId: string,
        objectRecords?:  Array< {
          __typename: "ObjectRecord",
          id: string,
          externalId?: string | null,
          syncStatus?: CRMSyncStatus | null,
          status: OBJECT_RECORD_STATUS,
          values:  Array< {
            __typename: "ObjectRecordValue",
            fieldId: string,
            values: Array< string >,
          } >,
        } > | null,
        values: Array< string >,
      } > | null,
      targetLabels?:  Array< {
        __typename: "LabelValues",
        key: string,
        values: Array< string >,
      } > | null,
      createdAt: string,
      createdBy: string,
      updatedAt: string,
      updatedBy: string,
      deletedBy?: string | null,
      deletedAt?: string | null,
    } > | null,
    shareStatus: SHARE_STATUS,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListFoldersQueryVariables = {
  filter?: ModelFolderFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListFoldersQuery = {
  listFolders?:  {
    __typename: "ModelFolderConnection",
    items:  Array< {
      __typename: "Folder",
      id: string,
      tenantId: string,
      name: string,
      isPinned?: boolean | null,
      status: FOLDER_STATUS,
      items:  Array< {
        __typename: "FolderItem",
        id: string,
        type: FOLDER_ITEM_TYPE,
        itemId: string,
        status?: FOLDER_ITEM_STATUS | null,
        addedAt: string,
        updateAcknowledgedAt?: string | null,
        itemLastUpdatedAt: string,
        visiblePages?: Array< number > | null,
        customTitle?: string | null,
      } >,
      createdAt: string,
      createdBy: string,
      updatedAt: string,
      updatedBy: string,
      sharePermissions?:  Array< {
        __typename: "SharePermission",
        id: string,
        isDeleted?: boolean | null,
        type: PERMISSION_TYPE,
        targetType: SHARE_TARGET_TYPE,
        targetUsername?: string | null,
        targetCustomValues?:  Array< {
          __typename: "CustomValues",
          fieldId: string,
          objectRecords?:  Array< {
            __typename: "ObjectRecord",
            id: string,
            externalId?: string | null,
            syncStatus?: CRMSyncStatus | null,
            status: OBJECT_RECORD_STATUS,
            values:  Array< {
              __typename: "ObjectRecordValue",
              fieldId: string,
              values: Array< string >,
            } >,
          } > | null,
          values: Array< string >,
        } > | null,
        targetLabels?:  Array< {
          __typename: "LabelValues",
          key: string,
          values: Array< string >,
        } > | null,
        createdAt: string,
        createdBy: string,
        updatedAt: string,
        updatedBy: string,
        deletedBy?: string | null,
        deletedAt?: string | null,
      } > | null,
      shareStatus: SHARE_STATUS,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncFoldersQueryVariables = {
  filter?: ModelFolderFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncFoldersQuery = {
  syncFolders?:  {
    __typename: "ModelFolderConnection",
    items:  Array< {
      __typename: "Folder",
      id: string,
      tenantId: string,
      name: string,
      isPinned?: boolean | null,
      status: FOLDER_STATUS,
      items:  Array< {
        __typename: "FolderItem",
        id: string,
        type: FOLDER_ITEM_TYPE,
        itemId: string,
        status?: FOLDER_ITEM_STATUS | null,
        addedAt: string,
        updateAcknowledgedAt?: string | null,
        itemLastUpdatedAt: string,
        visiblePages?: Array< number > | null,
        customTitle?: string | null,
      } >,
      createdAt: string,
      createdBy: string,
      updatedAt: string,
      updatedBy: string,
      sharePermissions?:  Array< {
        __typename: "SharePermission",
        id: string,
        isDeleted?: boolean | null,
        type: PERMISSION_TYPE,
        targetType: SHARE_TARGET_TYPE,
        targetUsername?: string | null,
        targetCustomValues?:  Array< {
          __typename: "CustomValues",
          fieldId: string,
          objectRecords?:  Array< {
            __typename: "ObjectRecord",
            id: string,
            externalId?: string | null,
            syncStatus?: CRMSyncStatus | null,
            status: OBJECT_RECORD_STATUS,
            values:  Array< {
              __typename: "ObjectRecordValue",
              fieldId: string,
              values: Array< string >,
            } >,
          } > | null,
          values: Array< string >,
        } > | null,
        targetLabels?:  Array< {
          __typename: "LabelValues",
          key: string,
          values: Array< string >,
        } > | null,
        createdAt: string,
        createdBy: string,
        updatedAt: string,
        updatedBy: string,
        deletedBy?: string | null,
        deletedAt?: string | null,
      } > | null,
      shareStatus: SHARE_STATUS,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type FoldersByTenantStatusSharedQueryVariables = {
  tenantId: string,
  statusShareStatus?: ModelFolderByTenantStatusSharedCompositeKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelFolderFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type FoldersByTenantStatusSharedQuery = {
  foldersByTenantStatusShared?:  {
    __typename: "ModelFolderConnection",
    items:  Array< {
      __typename: "Folder",
      id: string,
      tenantId: string,
      name: string,
      isPinned?: boolean | null,
      status: FOLDER_STATUS,
      items:  Array< {
        __typename: "FolderItem",
        id: string,
        type: FOLDER_ITEM_TYPE,
        itemId: string,
        status?: FOLDER_ITEM_STATUS | null,
        addedAt: string,
        updateAcknowledgedAt?: string | null,
        itemLastUpdatedAt: string,
        visiblePages?: Array< number > | null,
        customTitle?: string | null,
      } >,
      createdAt: string,
      createdBy: string,
      updatedAt: string,
      updatedBy: string,
      sharePermissions?:  Array< {
        __typename: "SharePermission",
        id: string,
        isDeleted?: boolean | null,
        type: PERMISSION_TYPE,
        targetType: SHARE_TARGET_TYPE,
        targetUsername?: string | null,
        targetCustomValues?:  Array< {
          __typename: "CustomValues",
          fieldId: string,
          objectRecords?:  Array< {
            __typename: "ObjectRecord",
            id: string,
            externalId?: string | null,
            syncStatus?: CRMSyncStatus | null,
            status: OBJECT_RECORD_STATUS,
            values:  Array< {
              __typename: "ObjectRecordValue",
              fieldId: string,
              values: Array< string >,
            } >,
          } > | null,
          values: Array< string >,
        } > | null,
        targetLabels?:  Array< {
          __typename: "LabelValues",
          key: string,
          values: Array< string >,
        } > | null,
        createdAt: string,
        createdBy: string,
        updatedAt: string,
        updatedBy: string,
        deletedBy?: string | null,
        deletedAt?: string | null,
      } > | null,
      shareStatus: SHARE_STATUS,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetContentShareQueryVariables = {
  id: string,
};

export type GetContentShareQuery = {
  getContentShare?:  {
    __typename: "ContentShare",
    id: string,
    token: string,
    tenantId: string,
    type: SHARE_TYPE,
    contentId: string,
    meetingId?: string | null,
    expiresAt: string,
    createdAt: string,
    updatedAt: string,
    createdBy: string,
    customValues:  Array< {
      __typename: "CustomValues",
      fieldId: string,
      objectRecords?:  Array< {
        __typename: "ObjectRecord",
        id: string,
        externalId?: string | null,
        syncStatus?: CRMSyncStatus | null,
        status: OBJECT_RECORD_STATUS,
        values:  Array< {
          __typename: "ObjectRecordValue",
          fieldId: string,
          values: Array< string >,
        } >,
      } > | null,
      values: Array< string >,
    } >,
    revoked?: boolean | null,
    customDeckDependencies?:  {
      __typename: "CustomDeckDependencies",
      versions: Array< string >,
      folderId: string,
    } | null,
    notificationScope?: SHARE_NOTIFICATION_SCOPE | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListContentSharesQueryVariables = {
  filter?: ModelContentShareFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListContentSharesQuery = {
  listContentShares?:  {
    __typename: "ModelContentShareConnection",
    items:  Array< {
      __typename: "ContentShare",
      id: string,
      token: string,
      tenantId: string,
      type: SHARE_TYPE,
      contentId: string,
      meetingId?: string | null,
      expiresAt: string,
      createdAt: string,
      updatedAt: string,
      createdBy: string,
      customValues:  Array< {
        __typename: "CustomValues",
        fieldId: string,
        objectRecords?:  Array< {
          __typename: "ObjectRecord",
          id: string,
          externalId?: string | null,
          syncStatus?: CRMSyncStatus | null,
          status: OBJECT_RECORD_STATUS,
          values:  Array< {
            __typename: "ObjectRecordValue",
            fieldId: string,
            values: Array< string >,
          } >,
        } > | null,
        values: Array< string >,
      } >,
      revoked?: boolean | null,
      customDeckDependencies?:  {
        __typename: "CustomDeckDependencies",
        versions: Array< string >,
        folderId: string,
      } | null,
      notificationScope?: SHARE_NOTIFICATION_SCOPE | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncContentSharesQueryVariables = {
  filter?: ModelContentShareFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncContentSharesQuery = {
  syncContentShares?:  {
    __typename: "ModelContentShareConnection",
    items:  Array< {
      __typename: "ContentShare",
      id: string,
      token: string,
      tenantId: string,
      type: SHARE_TYPE,
      contentId: string,
      meetingId?: string | null,
      expiresAt: string,
      createdAt: string,
      updatedAt: string,
      createdBy: string,
      customValues:  Array< {
        __typename: "CustomValues",
        fieldId: string,
        objectRecords?:  Array< {
          __typename: "ObjectRecord",
          id: string,
          externalId?: string | null,
          syncStatus?: CRMSyncStatus | null,
          status: OBJECT_RECORD_STATUS,
          values:  Array< {
            __typename: "ObjectRecordValue",
            fieldId: string,
            values: Array< string >,
          } >,
        } > | null,
        values: Array< string >,
      } >,
      revoked?: boolean | null,
      customDeckDependencies?:  {
        __typename: "CustomDeckDependencies",
        versions: Array< string >,
        folderId: string,
      } | null,
      notificationScope?: SHARE_NOTIFICATION_SCOPE | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ContentShareByTokenQueryVariables = {
  token: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelContentShareFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ContentShareByTokenQuery = {
  contentShareByToken?:  {
    __typename: "ModelContentShareConnection",
    items:  Array< {
      __typename: "ContentShare",
      id: string,
      token: string,
      tenantId: string,
      type: SHARE_TYPE,
      contentId: string,
      meetingId?: string | null,
      expiresAt: string,
      createdAt: string,
      updatedAt: string,
      createdBy: string,
      customValues:  Array< {
        __typename: "CustomValues",
        fieldId: string,
        objectRecords?:  Array< {
          __typename: "ObjectRecord",
          id: string,
          externalId?: string | null,
          syncStatus?: CRMSyncStatus | null,
          status: OBJECT_RECORD_STATUS,
          values:  Array< {
            __typename: "ObjectRecordValue",
            fieldId: string,
            values: Array< string >,
          } >,
        } > | null,
        values: Array< string >,
      } >,
      revoked?: boolean | null,
      customDeckDependencies?:  {
        __typename: "CustomDeckDependencies",
        versions: Array< string >,
        folderId: string,
      } | null,
      notificationScope?: SHARE_NOTIFICATION_SCOPE | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ContentShareByContentIDQueryVariables = {
  contentId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelContentShareFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ContentShareByContentIDQuery = {
  contentShareByContentID?:  {
    __typename: "ModelContentShareConnection",
    items:  Array< {
      __typename: "ContentShare",
      id: string,
      token: string,
      tenantId: string,
      type: SHARE_TYPE,
      contentId: string,
      meetingId?: string | null,
      expiresAt: string,
      createdAt: string,
      updatedAt: string,
      createdBy: string,
      customValues:  Array< {
        __typename: "CustomValues",
        fieldId: string,
        objectRecords?:  Array< {
          __typename: "ObjectRecord",
          id: string,
          externalId?: string | null,
          syncStatus?: CRMSyncStatus | null,
          status: OBJECT_RECORD_STATUS,
          values:  Array< {
            __typename: "ObjectRecordValue",
            fieldId: string,
            values: Array< string >,
          } >,
        } > | null,
        values: Array< string >,
      } >,
      revoked?: boolean | null,
      customDeckDependencies?:  {
        __typename: "CustomDeckDependencies",
        versions: Array< string >,
        folderId: string,
      } | null,
      notificationScope?: SHARE_NOTIFICATION_SCOPE | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetIntegrationLogQueryVariables = {
  id: string,
};

export type GetIntegrationLogQuery = {
  getIntegrationLog?:  {
    __typename: "IntegrationLog",
    integrationId: string,
    id: string,
    log:  Array< {
      __typename: "IntegrationLogEntry",
      level: LOG_LEVEL,
      timestamp: string,
      message: string,
      srcDocId: string,
      srcDocVersion: string,
    } | null >,
    status: INTEGRATION_RUN_STATUS,
    tenantId: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListIntegrationLogsQueryVariables = {
  filter?: ModelIntegrationLogFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListIntegrationLogsQuery = {
  listIntegrationLogs?:  {
    __typename: "ModelIntegrationLogConnection",
    items:  Array< {
      __typename: "IntegrationLog",
      integrationId: string,
      id: string,
      log:  Array< {
        __typename: "IntegrationLogEntry",
        level: LOG_LEVEL,
        timestamp: string,
        message: string,
        srcDocId: string,
        srcDocVersion: string,
      } | null >,
      status: INTEGRATION_RUN_STATUS,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncIntegrationLogsQueryVariables = {
  filter?: ModelIntegrationLogFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncIntegrationLogsQuery = {
  syncIntegrationLogs?:  {
    __typename: "ModelIntegrationLogConnection",
    items:  Array< {
      __typename: "IntegrationLog",
      integrationId: string,
      id: string,
      log:  Array< {
        __typename: "IntegrationLogEntry",
        level: LOG_LEVEL,
        timestamp: string,
        message: string,
        srcDocId: string,
        srcDocVersion: string,
      } | null >,
      status: INTEGRATION_RUN_STATUS,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetIntegrationSettingsQueryVariables = {
  id: string,
};

export type GetIntegrationSettingsQuery = {
  getIntegrationSettings?:  {
    __typename: "IntegrationSettings",
    id: string,
    tenantId: string,
    syncContentEvery: number,
    name?: string | null,
    mapping:  Array< {
      __typename: "IntegrationFieldMapping",
      key: string,
      fieldType: string,
      dataType: string,
      targetFieldName: string,
      srcFieldname: string,
      valueMappings?:  Array< {
        __typename: "IntegrationValueMapping",
        srcValue: string,
        targetValue: string,
      } | null > | null,
    } | null >,
    clientConfigurationFields:  Array< {
      __typename: "IntegrationClientConfigurationTuple",
      key: string,
      value: string,
    } | null >,
    integrationType?: INTEGRATION_TYPE | null,
    enabled?: boolean | null,
    notificationEmail?: string | null,
    errorSyncEmail?: string | null,
    createdAt: string,
    createdBy: string,
    updatedAt: string,
    updatedBy: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListIntegrationSettingsQueryVariables = {
  filter?: ModelIntegrationSettingsFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListIntegrationSettingsQuery = {
  listIntegrationSettings?:  {
    __typename: "ModelIntegrationSettingsConnection",
    items:  Array< {
      __typename: "IntegrationSettings",
      id: string,
      tenantId: string,
      syncContentEvery: number,
      name?: string | null,
      mapping:  Array< {
        __typename: "IntegrationFieldMapping",
        key: string,
        fieldType: string,
        dataType: string,
        targetFieldName: string,
        srcFieldname: string,
        valueMappings?:  Array< {
          __typename: "IntegrationValueMapping",
          srcValue: string,
          targetValue: string,
        } | null > | null,
      } | null >,
      clientConfigurationFields:  Array< {
        __typename: "IntegrationClientConfigurationTuple",
        key: string,
        value: string,
      } | null >,
      integrationType?: INTEGRATION_TYPE | null,
      enabled?: boolean | null,
      notificationEmail?: string | null,
      errorSyncEmail?: string | null,
      createdAt: string,
      createdBy: string,
      updatedAt: string,
      updatedBy: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncIntegrationSettingsQueryVariables = {
  filter?: ModelIntegrationSettingsFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncIntegrationSettingsQuery = {
  syncIntegrationSettings?:  {
    __typename: "ModelIntegrationSettingsConnection",
    items:  Array< {
      __typename: "IntegrationSettings",
      id: string,
      tenantId: string,
      syncContentEvery: number,
      name?: string | null,
      mapping:  Array< {
        __typename: "IntegrationFieldMapping",
        key: string,
        fieldType: string,
        dataType: string,
        targetFieldName: string,
        srcFieldname: string,
        valueMappings?:  Array< {
          __typename: "IntegrationValueMapping",
          srcValue: string,
          targetValue: string,
        } | null > | null,
      } | null >,
      clientConfigurationFields:  Array< {
        __typename: "IntegrationClientConfigurationTuple",
        key: string,
        value: string,
      } | null >,
      integrationType?: INTEGRATION_TYPE | null,
      enabled?: boolean | null,
      notificationEmail?: string | null,
      errorSyncEmail?: string | null,
      createdAt: string,
      createdBy: string,
      updatedAt: string,
      updatedBy: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetMeetingQueryVariables = {
  id: string,
};

export type GetMeetingQuery = {
  getMeeting?:  {
    __typename: "Meeting",
    id: string,
    tenantId: string,
    title?: string | null,
    startTime: string,
    endTime?: string | null,
    contentPresented:  Array< {
      __typename: "ContentPresented",
      contentId: string,
      groupId?: string | null,
      folderItemId?: string | null,
      title: string,
      groupTitle?: string | null,
      status: CONTENT_PRESENTED_STATUS,
      contentType: MEETING_CONTENT_TYPE,
      events?:  Array< {
        __typename: "PagePresentedEvent",
        pageNumber: number,
        pageId: string,
        timestamp: string,
        end?: string | null,
      } > | null,
      openedAt?: string | null,
      closedAt?: string | null,
      presentedMeta:  Array< {
        __typename: "PresentedMeta",
        pageId: string,
        presented: boolean,
        followUp?: boolean | null,
        sentiment?: SENTIMENT | null,
        note?: string | null,
        title?: string | null,
      } >,
    } >,
    type: MEETING_TYPE,
    fieldValues:  Array< {
      __typename: "FieldValue",
      fieldId: string,
      valueOrId: string,
    } >,
    customValues?:  Array< {
      __typename: "CustomValues",
      fieldId: string,
      objectRecords?:  Array< {
        __typename: "ObjectRecord",
        id: string,
        externalId?: string | null,
        syncStatus?: CRMSyncStatus | null,
        status: OBJECT_RECORD_STATUS,
        values:  Array< {
          __typename: "ObjectRecordValue",
          fieldId: string,
          values: Array< string >,
        } >,
      } > | null,
      values: Array< string >,
    } > | null,
    notes?: string | null,
    status: MEETING_STATUS,
    createdAt: string,
    createdBy: string,
    updatedAt: string,
    updatedBy: string,
    attendees:  Array< {
      __typename: "Attendee",
      id: string,
      name: string,
      status: MEETING_ATTENDEE_STATUS,
      updatedAt: string,
      email?: string | null,
      crmAccountId?: string | null,
      crmAddressId?: string | null,
      attendeeType: ATTENDEE_TYPE,
      crmRecord?:  {
        __typename: "CRMMeetingRecordInfo",
        crmCallId?: string | null,
        crmCustomValues:  Array< {
          __typename: "CustomValues",
          fieldId: string,
          objectRecords?:  Array< {
            __typename: "ObjectRecord",
            id: string,
            externalId?: string | null,
            syncStatus?: CRMSyncStatus | null,
            status: OBJECT_RECORD_STATUS,
            values:  Array< {
              __typename: "ObjectRecordValue",
              fieldId: string,
              values: Array< string >,
            } >,
          } > | null,
          values: Array< string >,
        } >,
        crmSyncStatus?: CRMSyncStatus | null,
        lastSyncedAt?: string | null,
      } | null,
      lastSyncedAt?: string | null,
    } >,
    crmRecord?:  {
      __typename: "CRMMeetingRecordInfo",
      crmCallId?: string | null,
      crmCustomValues:  Array< {
        __typename: "CustomValues",
        fieldId: string,
        objectRecords?:  Array< {
          __typename: "ObjectRecord",
          id: string,
          externalId?: string | null,
          syncStatus?: CRMSyncStatus | null,
          status: OBJECT_RECORD_STATUS,
          values:  Array< {
            __typename: "ObjectRecordValue",
            fieldId: string,
            values: Array< string >,
          } >,
        } > | null,
        values: Array< string >,
      } >,
      crmSyncStatus?: CRMSyncStatus | null,
      lastSyncedAt?: string | null,
    } | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListMeetingsQueryVariables = {
  filter?: ModelMeetingFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListMeetingsQuery = {
  listMeetings?:  {
    __typename: "ModelMeetingConnection",
    items:  Array< {
      __typename: "Meeting",
      id: string,
      tenantId: string,
      title?: string | null,
      startTime: string,
      endTime?: string | null,
      contentPresented:  Array< {
        __typename: "ContentPresented",
        contentId: string,
        groupId?: string | null,
        folderItemId?: string | null,
        title: string,
        groupTitle?: string | null,
        status: CONTENT_PRESENTED_STATUS,
        contentType: MEETING_CONTENT_TYPE,
        events?:  Array< {
          __typename: "PagePresentedEvent",
          pageNumber: number,
          pageId: string,
          timestamp: string,
          end?: string | null,
        } > | null,
        openedAt?: string | null,
        closedAt?: string | null,
        presentedMeta:  Array< {
          __typename: "PresentedMeta",
          pageId: string,
          presented: boolean,
          followUp?: boolean | null,
          sentiment?: SENTIMENT | null,
          note?: string | null,
          title?: string | null,
        } >,
      } >,
      type: MEETING_TYPE,
      fieldValues:  Array< {
        __typename: "FieldValue",
        fieldId: string,
        valueOrId: string,
      } >,
      customValues?:  Array< {
        __typename: "CustomValues",
        fieldId: string,
        objectRecords?:  Array< {
          __typename: "ObjectRecord",
          id: string,
          externalId?: string | null,
          syncStatus?: CRMSyncStatus | null,
          status: OBJECT_RECORD_STATUS,
          values:  Array< {
            __typename: "ObjectRecordValue",
            fieldId: string,
            values: Array< string >,
          } >,
        } > | null,
        values: Array< string >,
      } > | null,
      notes?: string | null,
      status: MEETING_STATUS,
      createdAt: string,
      createdBy: string,
      updatedAt: string,
      updatedBy: string,
      attendees:  Array< {
        __typename: "Attendee",
        id: string,
        name: string,
        status: MEETING_ATTENDEE_STATUS,
        updatedAt: string,
        email?: string | null,
        crmAccountId?: string | null,
        crmAddressId?: string | null,
        attendeeType: ATTENDEE_TYPE,
        crmRecord?:  {
          __typename: "CRMMeetingRecordInfo",
          crmCallId?: string | null,
          crmCustomValues:  Array< {
            __typename: "CustomValues",
            fieldId: string,
            objectRecords?:  Array< {
              __typename: "ObjectRecord",
              id: string,
              externalId?: string | null,
              syncStatus?: CRMSyncStatus | null,
              status: OBJECT_RECORD_STATUS,
              values:  Array< {
                __typename: "ObjectRecordValue",
                fieldId: string,
                values: Array< string >,
              } >,
            } > | null,
            values: Array< string >,
          } >,
          crmSyncStatus?: CRMSyncStatus | null,
          lastSyncedAt?: string | null,
        } | null,
        lastSyncedAt?: string | null,
      } >,
      crmRecord?:  {
        __typename: "CRMMeetingRecordInfo",
        crmCallId?: string | null,
        crmCustomValues:  Array< {
          __typename: "CustomValues",
          fieldId: string,
          objectRecords?:  Array< {
            __typename: "ObjectRecord",
            id: string,
            externalId?: string | null,
            syncStatus?: CRMSyncStatus | null,
            status: OBJECT_RECORD_STATUS,
            values:  Array< {
              __typename: "ObjectRecordValue",
              fieldId: string,
              values: Array< string >,
            } >,
          } > | null,
          values: Array< string >,
        } >,
        crmSyncStatus?: CRMSyncStatus | null,
        lastSyncedAt?: string | null,
      } | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncMeetingsQueryVariables = {
  filter?: ModelMeetingFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncMeetingsQuery = {
  syncMeetings?:  {
    __typename: "ModelMeetingConnection",
    items:  Array< {
      __typename: "Meeting",
      id: string,
      tenantId: string,
      title?: string | null,
      startTime: string,
      endTime?: string | null,
      contentPresented:  Array< {
        __typename: "ContentPresented",
        contentId: string,
        groupId?: string | null,
        folderItemId?: string | null,
        title: string,
        groupTitle?: string | null,
        status: CONTENT_PRESENTED_STATUS,
        contentType: MEETING_CONTENT_TYPE,
        events?:  Array< {
          __typename: "PagePresentedEvent",
          pageNumber: number,
          pageId: string,
          timestamp: string,
          end?: string | null,
        } > | null,
        openedAt?: string | null,
        closedAt?: string | null,
        presentedMeta:  Array< {
          __typename: "PresentedMeta",
          pageId: string,
          presented: boolean,
          followUp?: boolean | null,
          sentiment?: SENTIMENT | null,
          note?: string | null,
          title?: string | null,
        } >,
      } >,
      type: MEETING_TYPE,
      fieldValues:  Array< {
        __typename: "FieldValue",
        fieldId: string,
        valueOrId: string,
      } >,
      customValues?:  Array< {
        __typename: "CustomValues",
        fieldId: string,
        objectRecords?:  Array< {
          __typename: "ObjectRecord",
          id: string,
          externalId?: string | null,
          syncStatus?: CRMSyncStatus | null,
          status: OBJECT_RECORD_STATUS,
          values:  Array< {
            __typename: "ObjectRecordValue",
            fieldId: string,
            values: Array< string >,
          } >,
        } > | null,
        values: Array< string >,
      } > | null,
      notes?: string | null,
      status: MEETING_STATUS,
      createdAt: string,
      createdBy: string,
      updatedAt: string,
      updatedBy: string,
      attendees:  Array< {
        __typename: "Attendee",
        id: string,
        name: string,
        status: MEETING_ATTENDEE_STATUS,
        updatedAt: string,
        email?: string | null,
        crmAccountId?: string | null,
        crmAddressId?: string | null,
        attendeeType: ATTENDEE_TYPE,
        crmRecord?:  {
          __typename: "CRMMeetingRecordInfo",
          crmCallId?: string | null,
          crmCustomValues:  Array< {
            __typename: "CustomValues",
            fieldId: string,
            objectRecords?:  Array< {
              __typename: "ObjectRecord",
              id: string,
              externalId?: string | null,
              syncStatus?: CRMSyncStatus | null,
              status: OBJECT_RECORD_STATUS,
              values:  Array< {
                __typename: "ObjectRecordValue",
                fieldId: string,
                values: Array< string >,
              } >,
            } > | null,
            values: Array< string >,
          } >,
          crmSyncStatus?: CRMSyncStatus | null,
          lastSyncedAt?: string | null,
        } | null,
        lastSyncedAt?: string | null,
      } >,
      crmRecord?:  {
        __typename: "CRMMeetingRecordInfo",
        crmCallId?: string | null,
        crmCustomValues:  Array< {
          __typename: "CustomValues",
          fieldId: string,
          objectRecords?:  Array< {
            __typename: "ObjectRecord",
            id: string,
            externalId?: string | null,
            syncStatus?: CRMSyncStatus | null,
            status: OBJECT_RECORD_STATUS,
            values:  Array< {
              __typename: "ObjectRecordValue",
              fieldId: string,
              values: Array< string >,
            } >,
          } > | null,
          values: Array< string >,
        } >,
        crmSyncStatus?: CRMSyncStatus | null,
        lastSyncedAt?: string | null,
      } | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type AttendemeetingsByCreatedByStartTimeesByHostIdStatusQueryVariables = {
  createdBy: string,
  startTime?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelMeetingFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type AttendemeetingsByCreatedByStartTimeesByHostIdStatusQuery = {
  attendemeetingsByCreatedByStartTimeesByHostIdStatus?:  {
    __typename: "ModelMeetingConnection",
    items:  Array< {
      __typename: "Meeting",
      id: string,
      tenantId: string,
      title?: string | null,
      startTime: string,
      endTime?: string | null,
      contentPresented:  Array< {
        __typename: "ContentPresented",
        contentId: string,
        groupId?: string | null,
        folderItemId?: string | null,
        title: string,
        groupTitle?: string | null,
        status: CONTENT_PRESENTED_STATUS,
        contentType: MEETING_CONTENT_TYPE,
        events?:  Array< {
          __typename: "PagePresentedEvent",
          pageNumber: number,
          pageId: string,
          timestamp: string,
          end?: string | null,
        } > | null,
        openedAt?: string | null,
        closedAt?: string | null,
        presentedMeta:  Array< {
          __typename: "PresentedMeta",
          pageId: string,
          presented: boolean,
          followUp?: boolean | null,
          sentiment?: SENTIMENT | null,
          note?: string | null,
          title?: string | null,
        } >,
      } >,
      type: MEETING_TYPE,
      fieldValues:  Array< {
        __typename: "FieldValue",
        fieldId: string,
        valueOrId: string,
      } >,
      customValues?:  Array< {
        __typename: "CustomValues",
        fieldId: string,
        objectRecords?:  Array< {
          __typename: "ObjectRecord",
          id: string,
          externalId?: string | null,
          syncStatus?: CRMSyncStatus | null,
          status: OBJECT_RECORD_STATUS,
          values:  Array< {
            __typename: "ObjectRecordValue",
            fieldId: string,
            values: Array< string >,
          } >,
        } > | null,
        values: Array< string >,
      } > | null,
      notes?: string | null,
      status: MEETING_STATUS,
      createdAt: string,
      createdBy: string,
      updatedAt: string,
      updatedBy: string,
      attendees:  Array< {
        __typename: "Attendee",
        id: string,
        name: string,
        status: MEETING_ATTENDEE_STATUS,
        updatedAt: string,
        email?: string | null,
        crmAccountId?: string | null,
        crmAddressId?: string | null,
        attendeeType: ATTENDEE_TYPE,
        crmRecord?:  {
          __typename: "CRMMeetingRecordInfo",
          crmCallId?: string | null,
          crmCustomValues:  Array< {
            __typename: "CustomValues",
            fieldId: string,
            objectRecords?:  Array< {
              __typename: "ObjectRecord",
              id: string,
              externalId?: string | null,
              syncStatus?: CRMSyncStatus | null,
              status: OBJECT_RECORD_STATUS,
              values:  Array< {
                __typename: "ObjectRecordValue",
                fieldId: string,
                values: Array< string >,
              } >,
            } > | null,
            values: Array< string >,
          } >,
          crmSyncStatus?: CRMSyncStatus | null,
          lastSyncedAt?: string | null,
        } | null,
        lastSyncedAt?: string | null,
      } >,
      crmRecord?:  {
        __typename: "CRMMeetingRecordInfo",
        crmCallId?: string | null,
        crmCustomValues:  Array< {
          __typename: "CustomValues",
          fieldId: string,
          objectRecords?:  Array< {
            __typename: "ObjectRecord",
            id: string,
            externalId?: string | null,
            syncStatus?: CRMSyncStatus | null,
            status: OBJECT_RECORD_STATUS,
            values:  Array< {
              __typename: "ObjectRecordValue",
              fieldId: string,
              values: Array< string >,
            } >,
          } > | null,
          values: Array< string >,
        } >,
        crmSyncStatus?: CRMSyncStatus | null,
        lastSyncedAt?: string | null,
      } | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetHubQueryVariables = {
  id: string,
};

export type GetHubQuery = {
  getHub?:  {
    __typename: "Hub",
    id: string,
    tenantId: string,
    name?: string | null,
    status: HUB_STATUS,
    createdAt: string,
    createdBy: string,
    updatedAt: string,
    updatedBy: string,
    hubSections?:  Array< {
      __typename: "HubSection",
      id: string,
      sectionHeader?: string | null,
      status: HUB_SECTION_STATUS,
      type: HUB_SECTION_TYPE,
      visible: boolean,
      order: number,
      textarea?:  {
        __typename: "TextareaHubSettings",
        content?: string | null,
      } | null,
      sharedFiles?:  Array< {
        __typename: "SharedFileHubSetting",
        id: string,
        contentId: string,
        title: string,
        contentType: HUB_SHARED_FILE_CONTENT_TYPE,
        documentVersionSettings?:  {
          __typename: "DocumentVersionSettings",
          associatedFiles?:  Array< {
            __typename: "HubSharedAssociatedFile",
            associatedFileId: string,
            versionId?: string | null,
            notation?:  Array< {
              __typename: "Notation",
              id: string,
              type: NOTATION_TYPE,
              description?: string | null,
              status: HUB_SECTION_ITEM_STATUS,
              pageId: string,
              coordinate?:  Array< {
                __typename: "Coordinate",
                x: number,
                y: number,
              } > | null,
              createdAt: string,
              createdBy: string,
              updatedAt: string,
              updatedBy: string,
            } > | null,
          } > | null,
        } | null,
        status: HUB_SECTION_ITEM_STATUS,
        createdAt: string,
        updatedAt: string,
        notation?:  Array< {
          __typename: "Notation",
          id: string,
          type: NOTATION_TYPE,
          description?: string | null,
          status: HUB_SECTION_ITEM_STATUS,
          pageId: string,
          coordinate?:  Array< {
            __typename: "Coordinate",
            x: number,
            y: number,
          } > | null,
          createdAt: string,
          createdBy: string,
          updatedAt: string,
          updatedBy: string,
        } > | null,
      } > | null,
      toDos?:  Array< {
        __typename: "ToDo",
        id: string,
        title: string,
        status: TODO_STATUS,
        completedAt?: string | null,
        resolution?: string | null,
        order: number,
        createdAt: string,
        updatedAt: string,
      } > | null,
      links?:  Array< {
        __typename: "Link",
        id: string,
        url: string,
        title?: string | null,
        status: HUB_SECTION_ITEM_STATUS,
        order: number,
        createdAt: string,
        updatedAt: string,
      } > | null,
      createdAt: string,
      updatedAt: string,
    } > | null,
    customValues?:  Array< {
      __typename: "CustomValues",
      fieldId: string,
      objectRecords?:  Array< {
        __typename: "ObjectRecord",
        id: string,
        externalId?: string | null,
        syncStatus?: CRMSyncStatus | null,
        status: OBJECT_RECORD_STATUS,
        values:  Array< {
          __typename: "ObjectRecordValue",
          fieldId: string,
          values: Array< string >,
        } >,
      } > | null,
      values: Array< string >,
    } > | null,
    meetingId?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListHubsQueryVariables = {
  filter?: ModelHubFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListHubsQuery = {
  listHubs?:  {
    __typename: "ModelHubConnection",
    items:  Array< {
      __typename: "Hub",
      id: string,
      tenantId: string,
      name?: string | null,
      status: HUB_STATUS,
      createdAt: string,
      createdBy: string,
      updatedAt: string,
      updatedBy: string,
      hubSections?:  Array< {
        __typename: "HubSection",
        id: string,
        sectionHeader?: string | null,
        status: HUB_SECTION_STATUS,
        type: HUB_SECTION_TYPE,
        visible: boolean,
        order: number,
        textarea?:  {
          __typename: "TextareaHubSettings",
          content?: string | null,
        } | null,
        sharedFiles?:  Array< {
          __typename: "SharedFileHubSetting",
          id: string,
          contentId: string,
          title: string,
          contentType: HUB_SHARED_FILE_CONTENT_TYPE,
          documentVersionSettings?:  {
            __typename: "DocumentVersionSettings",
            associatedFiles?:  Array< {
              __typename: "HubSharedAssociatedFile",
              associatedFileId: string,
              versionId?: string | null,
              notation?:  Array< {
                __typename: "Notation",
                id: string,
                type: NOTATION_TYPE,
                description?: string | null,
                status: HUB_SECTION_ITEM_STATUS,
                pageId: string,
                coordinate?:  Array< {
                  __typename: "Coordinate",
                  x: number,
                  y: number,
                } > | null,
                createdAt: string,
                createdBy: string,
                updatedAt: string,
                updatedBy: string,
              } > | null,
            } > | null,
          } | null,
          status: HUB_SECTION_ITEM_STATUS,
          createdAt: string,
          updatedAt: string,
          notation?:  Array< {
            __typename: "Notation",
            id: string,
            type: NOTATION_TYPE,
            description?: string | null,
            status: HUB_SECTION_ITEM_STATUS,
            pageId: string,
            coordinate?:  Array< {
              __typename: "Coordinate",
              x: number,
              y: number,
            } > | null,
            createdAt: string,
            createdBy: string,
            updatedAt: string,
            updatedBy: string,
          } > | null,
        } > | null,
        toDos?:  Array< {
          __typename: "ToDo",
          id: string,
          title: string,
          status: TODO_STATUS,
          completedAt?: string | null,
          resolution?: string | null,
          order: number,
          createdAt: string,
          updatedAt: string,
        } > | null,
        links?:  Array< {
          __typename: "Link",
          id: string,
          url: string,
          title?: string | null,
          status: HUB_SECTION_ITEM_STATUS,
          order: number,
          createdAt: string,
          updatedAt: string,
        } > | null,
        createdAt: string,
        updatedAt: string,
      } > | null,
      customValues?:  Array< {
        __typename: "CustomValues",
        fieldId: string,
        objectRecords?:  Array< {
          __typename: "ObjectRecord",
          id: string,
          externalId?: string | null,
          syncStatus?: CRMSyncStatus | null,
          status: OBJECT_RECORD_STATUS,
          values:  Array< {
            __typename: "ObjectRecordValue",
            fieldId: string,
            values: Array< string >,
          } >,
        } > | null,
        values: Array< string >,
      } > | null,
      meetingId?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncHubsQueryVariables = {
  filter?: ModelHubFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncHubsQuery = {
  syncHubs?:  {
    __typename: "ModelHubConnection",
    items:  Array< {
      __typename: "Hub",
      id: string,
      tenantId: string,
      name?: string | null,
      status: HUB_STATUS,
      createdAt: string,
      createdBy: string,
      updatedAt: string,
      updatedBy: string,
      hubSections?:  Array< {
        __typename: "HubSection",
        id: string,
        sectionHeader?: string | null,
        status: HUB_SECTION_STATUS,
        type: HUB_SECTION_TYPE,
        visible: boolean,
        order: number,
        textarea?:  {
          __typename: "TextareaHubSettings",
          content?: string | null,
        } | null,
        sharedFiles?:  Array< {
          __typename: "SharedFileHubSetting",
          id: string,
          contentId: string,
          title: string,
          contentType: HUB_SHARED_FILE_CONTENT_TYPE,
          documentVersionSettings?:  {
            __typename: "DocumentVersionSettings",
            associatedFiles?:  Array< {
              __typename: "HubSharedAssociatedFile",
              associatedFileId: string,
              versionId?: string | null,
              notation?:  Array< {
                __typename: "Notation",
                id: string,
                type: NOTATION_TYPE,
                description?: string | null,
                status: HUB_SECTION_ITEM_STATUS,
                pageId: string,
                coordinate?:  Array< {
                  __typename: "Coordinate",
                  x: number,
                  y: number,
                } > | null,
                createdAt: string,
                createdBy: string,
                updatedAt: string,
                updatedBy: string,
              } > | null,
            } > | null,
          } | null,
          status: HUB_SECTION_ITEM_STATUS,
          createdAt: string,
          updatedAt: string,
          notation?:  Array< {
            __typename: "Notation",
            id: string,
            type: NOTATION_TYPE,
            description?: string | null,
            status: HUB_SECTION_ITEM_STATUS,
            pageId: string,
            coordinate?:  Array< {
              __typename: "Coordinate",
              x: number,
              y: number,
            } > | null,
            createdAt: string,
            createdBy: string,
            updatedAt: string,
            updatedBy: string,
          } > | null,
        } > | null,
        toDos?:  Array< {
          __typename: "ToDo",
          id: string,
          title: string,
          status: TODO_STATUS,
          completedAt?: string | null,
          resolution?: string | null,
          order: number,
          createdAt: string,
          updatedAt: string,
        } > | null,
        links?:  Array< {
          __typename: "Link",
          id: string,
          url: string,
          title?: string | null,
          status: HUB_SECTION_ITEM_STATUS,
          order: number,
          createdAt: string,
          updatedAt: string,
        } > | null,
        createdAt: string,
        updatedAt: string,
      } > | null,
      customValues?:  Array< {
        __typename: "CustomValues",
        fieldId: string,
        objectRecords?:  Array< {
          __typename: "ObjectRecord",
          id: string,
          externalId?: string | null,
          syncStatus?: CRMSyncStatus | null,
          status: OBJECT_RECORD_STATUS,
          values:  Array< {
            __typename: "ObjectRecordValue",
            fieldId: string,
            values: Array< string >,
          } >,
        } > | null,
        values: Array< string >,
      } > | null,
      meetingId?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type HubsByOwnerByStatusQueryVariables = {
  createdBy: string,
  status?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelHubFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type HubsByOwnerByStatusQuery = {
  hubsByOwnerByStatus?:  {
    __typename: "ModelHubConnection",
    items:  Array< {
      __typename: "Hub",
      id: string,
      tenantId: string,
      name?: string | null,
      status: HUB_STATUS,
      createdAt: string,
      createdBy: string,
      updatedAt: string,
      updatedBy: string,
      hubSections?:  Array< {
        __typename: "HubSection",
        id: string,
        sectionHeader?: string | null,
        status: HUB_SECTION_STATUS,
        type: HUB_SECTION_TYPE,
        visible: boolean,
        order: number,
        textarea?:  {
          __typename: "TextareaHubSettings",
          content?: string | null,
        } | null,
        sharedFiles?:  Array< {
          __typename: "SharedFileHubSetting",
          id: string,
          contentId: string,
          title: string,
          contentType: HUB_SHARED_FILE_CONTENT_TYPE,
          documentVersionSettings?:  {
            __typename: "DocumentVersionSettings",
            associatedFiles?:  Array< {
              __typename: "HubSharedAssociatedFile",
              associatedFileId: string,
              versionId?: string | null,
              notation?:  Array< {
                __typename: "Notation",
                id: string,
                type: NOTATION_TYPE,
                description?: string | null,
                status: HUB_SECTION_ITEM_STATUS,
                pageId: string,
                coordinate?:  Array< {
                  __typename: "Coordinate",
                  x: number,
                  y: number,
                } > | null,
                createdAt: string,
                createdBy: string,
                updatedAt: string,
                updatedBy: string,
              } > | null,
            } > | null,
          } | null,
          status: HUB_SECTION_ITEM_STATUS,
          createdAt: string,
          updatedAt: string,
          notation?:  Array< {
            __typename: "Notation",
            id: string,
            type: NOTATION_TYPE,
            description?: string | null,
            status: HUB_SECTION_ITEM_STATUS,
            pageId: string,
            coordinate?:  Array< {
              __typename: "Coordinate",
              x: number,
              y: number,
            } > | null,
            createdAt: string,
            createdBy: string,
            updatedAt: string,
            updatedBy: string,
          } > | null,
        } > | null,
        toDos?:  Array< {
          __typename: "ToDo",
          id: string,
          title: string,
          status: TODO_STATUS,
          completedAt?: string | null,
          resolution?: string | null,
          order: number,
          createdAt: string,
          updatedAt: string,
        } > | null,
        links?:  Array< {
          __typename: "Link",
          id: string,
          url: string,
          title?: string | null,
          status: HUB_SECTION_ITEM_STATUS,
          order: number,
          createdAt: string,
          updatedAt: string,
        } > | null,
        createdAt: string,
        updatedAt: string,
      } > | null,
      customValues?:  Array< {
        __typename: "CustomValues",
        fieldId: string,
        objectRecords?:  Array< {
          __typename: "ObjectRecord",
          id: string,
          externalId?: string | null,
          syncStatus?: CRMSyncStatus | null,
          status: OBJECT_RECORD_STATUS,
          values:  Array< {
            __typename: "ObjectRecordValue",
            fieldId: string,
            values: Array< string >,
          } >,
        } > | null,
        values: Array< string >,
      } > | null,
      meetingId?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetCustomFormRecordQueryVariables = {
  id: string,
};

export type GetCustomFormRecordQuery = {
  getCustomFormRecord?:  {
    __typename: "CustomFormRecord",
    id: string,
    tenantId: string,
    customFormId: string,
    entity: CUSTOM_FORM_RECORD_ENTITY,
    status: CUSTOM_FORM_RECORD_STATUS,
    crmFields?:  {
      __typename: "CustomFormRecordCRMFields",
      accountId?: string | null,
      externalId?: string | null,
      apiName: string,
      recordTypeId?: string | null,
      syncStatus: CRMSyncStatus,
      lastSyncedAt?: string | null,
    } | null,
    name?: string | null,
    parentId?: string | null,
    parentModel?: CUSTOM_FORM_RECORD_RELATIONSHIP_MODEL | null,
    values:  Array< {
      __typename: "CustomValues",
      fieldId: string,
      objectRecords?:  Array< {
        __typename: "ObjectRecord",
        id: string,
        externalId?: string | null,
        syncStatus?: CRMSyncStatus | null,
        status: OBJECT_RECORD_STATUS,
        values:  Array< {
          __typename: "ObjectRecordValue",
          fieldId: string,
          values: Array< string >,
        } >,
      } > | null,
      values: Array< string >,
    } >,
    createdAt: string,
    createdBy: string,
    updatedAt: string,
    updatedBy: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListCustomFormRecordsQueryVariables = {
  filter?: ModelCustomFormRecordFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListCustomFormRecordsQuery = {
  listCustomFormRecords?:  {
    __typename: "ModelCustomFormRecordConnection",
    items:  Array< {
      __typename: "CustomFormRecord",
      id: string,
      tenantId: string,
      customFormId: string,
      entity: CUSTOM_FORM_RECORD_ENTITY,
      status: CUSTOM_FORM_RECORD_STATUS,
      crmFields?:  {
        __typename: "CustomFormRecordCRMFields",
        accountId?: string | null,
        externalId?: string | null,
        apiName: string,
        recordTypeId?: string | null,
        syncStatus: CRMSyncStatus,
        lastSyncedAt?: string | null,
      } | null,
      name?: string | null,
      parentId?: string | null,
      parentModel?: CUSTOM_FORM_RECORD_RELATIONSHIP_MODEL | null,
      values:  Array< {
        __typename: "CustomValues",
        fieldId: string,
        objectRecords?:  Array< {
          __typename: "ObjectRecord",
          id: string,
          externalId?: string | null,
          syncStatus?: CRMSyncStatus | null,
          status: OBJECT_RECORD_STATUS,
          values:  Array< {
            __typename: "ObjectRecordValue",
            fieldId: string,
            values: Array< string >,
          } >,
        } > | null,
        values: Array< string >,
      } >,
      createdAt: string,
      createdBy: string,
      updatedAt: string,
      updatedBy: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncCustomFormRecordsQueryVariables = {
  filter?: ModelCustomFormRecordFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncCustomFormRecordsQuery = {
  syncCustomFormRecords?:  {
    __typename: "ModelCustomFormRecordConnection",
    items:  Array< {
      __typename: "CustomFormRecord",
      id: string,
      tenantId: string,
      customFormId: string,
      entity: CUSTOM_FORM_RECORD_ENTITY,
      status: CUSTOM_FORM_RECORD_STATUS,
      crmFields?:  {
        __typename: "CustomFormRecordCRMFields",
        accountId?: string | null,
        externalId?: string | null,
        apiName: string,
        recordTypeId?: string | null,
        syncStatus: CRMSyncStatus,
        lastSyncedAt?: string | null,
      } | null,
      name?: string | null,
      parentId?: string | null,
      parentModel?: CUSTOM_FORM_RECORD_RELATIONSHIP_MODEL | null,
      values:  Array< {
        __typename: "CustomValues",
        fieldId: string,
        objectRecords?:  Array< {
          __typename: "ObjectRecord",
          id: string,
          externalId?: string | null,
          syncStatus?: CRMSyncStatus | null,
          status: OBJECT_RECORD_STATUS,
          values:  Array< {
            __typename: "ObjectRecordValue",
            fieldId: string,
            values: Array< string >,
          } >,
        } > | null,
        values: Array< string >,
      } >,
      createdAt: string,
      createdBy: string,
      updatedAt: string,
      updatedBy: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type CustomFormRecordByOwnerByStatusQueryVariables = {
  createdBy: string,
  status?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCustomFormRecordFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type CustomFormRecordByOwnerByStatusQuery = {
  customFormRecordByOwnerByStatus?:  {
    __typename: "ModelCustomFormRecordConnection",
    items:  Array< {
      __typename: "CustomFormRecord",
      id: string,
      tenantId: string,
      customFormId: string,
      entity: CUSTOM_FORM_RECORD_ENTITY,
      status: CUSTOM_FORM_RECORD_STATUS,
      crmFields?:  {
        __typename: "CustomFormRecordCRMFields",
        accountId?: string | null,
        externalId?: string | null,
        apiName: string,
        recordTypeId?: string | null,
        syncStatus: CRMSyncStatus,
        lastSyncedAt?: string | null,
      } | null,
      name?: string | null,
      parentId?: string | null,
      parentModel?: CUSTOM_FORM_RECORD_RELATIONSHIP_MODEL | null,
      values:  Array< {
        __typename: "CustomValues",
        fieldId: string,
        objectRecords?:  Array< {
          __typename: "ObjectRecord",
          id: string,
          externalId?: string | null,
          syncStatus?: CRMSyncStatus | null,
          status: OBJECT_RECORD_STATUS,
          values:  Array< {
            __typename: "ObjectRecordValue",
            fieldId: string,
            values: Array< string >,
          } >,
        } > | null,
        values: Array< string >,
      } >,
      createdAt: string,
      createdBy: string,
      updatedAt: string,
      updatedBy: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GenerateTokenForContentAccessQueryVariables = {
  documentId: string,
  documentVersionId: string,
  authorizedPath: string,
  durationSeconds: number,
};

export type GenerateTokenForContentAccessQuery = {
  generateTokenForContentAccess?:  {
    __typename: "ContentTokenOutput",
    token: string,
    expiresAt: string,
  } | null,
};

export type ExchangeContentShareTokenForAccessTokenQueryVariables = {
  token: string,
};

export type ExchangeContentShareTokenForAccessTokenQuery = {
  exchangeContentShareTokenForAccessToken?:  {
    __typename: "PresentableContentMetadata",
    id: string,
    contentId: string,
    token: string,
    key: string,
    expiresAt: string,
    fileName: string,
    associatedFileId?: string | null,
    shareType: SHARE_TYPE,
    fileExtension?: string | null,
    contentURL?: string | null,
    pageGroups?:  Array< {
      __typename: "PageGroup",
      id: string,
      pageIds?: Array< string > | null,
      name: string,
      locked: boolean,
      sourceID?: string | null,
      source: PAGE_GROUP_SOURCE,
    } > | null,
    title?: string | null,
    numPages?: number | null,
    notation?:  Array< {
      __typename: "Notation",
      id: string,
      type: NOTATION_TYPE,
      description?: string | null,
      status: HUB_SECTION_ITEM_STATUS,
      pageId: string,
      coordinate?:  Array< {
        __typename: "Coordinate",
        x: number,
        y: number,
      } > | null,
      createdAt: string,
      createdBy: string,
      updatedAt: string,
      updatedBy: string,
    } > | null,
    coverThumbnailPageNum?: number | null,
    sharedBy: string,
    tenantId: string,
  } | null,
};

export type GetHubAndContentDetailsByTokenQueryVariables = {
  token: string,
};

export type GetHubAndContentDetailsByTokenQuery = {
  getHubAndContentDetailsByToken?:  {
    __typename: "HubAndContentDetails",
    hubId: string,
    tenantId: string,
    title?: string | null,
    status: HUB_STATUS,
    hubSections?:  Array< {
      __typename: "HubSection",
      id: string,
      sectionHeader?: string | null,
      status: HUB_SECTION_STATUS,
      type: HUB_SECTION_TYPE,
      visible: boolean,
      order: number,
      textarea?:  {
        __typename: "TextareaHubSettings",
        content?: string | null,
      } | null,
      sharedFiles?:  Array< {
        __typename: "SharedFileHubSetting",
        id: string,
        contentId: string,
        title: string,
        contentType: HUB_SHARED_FILE_CONTENT_TYPE,
        documentVersionSettings?:  {
          __typename: "DocumentVersionSettings",
          associatedFiles?:  Array< {
            __typename: "HubSharedAssociatedFile",
            associatedFileId: string,
            versionId?: string | null,
            notation?:  Array< {
              __typename: "Notation",
              id: string,
              type: NOTATION_TYPE,
              description?: string | null,
              status: HUB_SECTION_ITEM_STATUS,
              pageId: string,
              coordinate?:  Array< {
                __typename: "Coordinate",
                x: number,
                y: number,
              } > | null,
              createdAt: string,
              createdBy: string,
              updatedAt: string,
              updatedBy: string,
            } > | null,
          } > | null,
        } | null,
        status: HUB_SECTION_ITEM_STATUS,
        createdAt: string,
        updatedAt: string,
        notation?:  Array< {
          __typename: "Notation",
          id: string,
          type: NOTATION_TYPE,
          description?: string | null,
          status: HUB_SECTION_ITEM_STATUS,
          pageId: string,
          coordinate?:  Array< {
            __typename: "Coordinate",
            x: number,
            y: number,
          } > | null,
          createdAt: string,
          createdBy: string,
          updatedAt: string,
          updatedBy: string,
        } > | null,
      } > | null,
      toDos?:  Array< {
        __typename: "ToDo",
        id: string,
        title: string,
        status: TODO_STATUS,
        completedAt?: string | null,
        resolution?: string | null,
        order: number,
        createdAt: string,
        updatedAt: string,
      } > | null,
      links?:  Array< {
        __typename: "Link",
        id: string,
        url: string,
        title?: string | null,
        status: HUB_SECTION_ITEM_STATUS,
        order: number,
        createdAt: string,
        updatedAt: string,
      } > | null,
      createdAt: string,
      updatedAt: string,
    } > | null,
    content?:  Array< {
      __typename: "PresentableContentMetadata",
      id: string,
      contentId: string,
      token: string,
      key: string,
      expiresAt: string,
      fileName: string,
      associatedFileId?: string | null,
      shareType: SHARE_TYPE,
      fileExtension?: string | null,
      contentURL?: string | null,
      pageGroups?:  Array< {
        __typename: "PageGroup",
        id: string,
        pageIds?: Array< string > | null,
        name: string,
        locked: boolean,
        sourceID?: string | null,
        source: PAGE_GROUP_SOURCE,
      } > | null,
      title?: string | null,
      numPages?: number | null,
      notation?:  Array< {
        __typename: "Notation",
        id: string,
        type: NOTATION_TYPE,
        description?: string | null,
        status: HUB_SECTION_ITEM_STATUS,
        pageId: string,
        coordinate?:  Array< {
          __typename: "Coordinate",
          x: number,
          y: number,
        } > | null,
        createdAt: string,
        createdBy: string,
        updatedAt: string,
        updatedBy: string,
      } > | null,
      coverThumbnailPageNum?: number | null,
      sharedBy: string,
      tenantId: string,
    } > | null,
    tenantLogoUrl?: string | null,
    sharedBy: string,
    featureFlags:  Array< {
      __typename: "FeatureFlags",
      flagId: string,
      value: boolean,
    } >,
  } | null,
};

export type GetHubAndContentDetailsByIdQueryVariables = {
  hubId: string,
};

export type GetHubAndContentDetailsByIdQuery = {
  getHubAndContentDetailsById?:  {
    __typename: "HubAndContentDetails",
    hubId: string,
    tenantId: string,
    title?: string | null,
    status: HUB_STATUS,
    hubSections?:  Array< {
      __typename: "HubSection",
      id: string,
      sectionHeader?: string | null,
      status: HUB_SECTION_STATUS,
      type: HUB_SECTION_TYPE,
      visible: boolean,
      order: number,
      textarea?:  {
        __typename: "TextareaHubSettings",
        content?: string | null,
      } | null,
      sharedFiles?:  Array< {
        __typename: "SharedFileHubSetting",
        id: string,
        contentId: string,
        title: string,
        contentType: HUB_SHARED_FILE_CONTENT_TYPE,
        documentVersionSettings?:  {
          __typename: "DocumentVersionSettings",
          associatedFiles?:  Array< {
            __typename: "HubSharedAssociatedFile",
            associatedFileId: string,
            versionId?: string | null,
            notation?:  Array< {
              __typename: "Notation",
              id: string,
              type: NOTATION_TYPE,
              description?: string | null,
              status: HUB_SECTION_ITEM_STATUS,
              pageId: string,
              coordinate?:  Array< {
                __typename: "Coordinate",
                x: number,
                y: number,
              } > | null,
              createdAt: string,
              createdBy: string,
              updatedAt: string,
              updatedBy: string,
            } > | null,
          } > | null,
        } | null,
        status: HUB_SECTION_ITEM_STATUS,
        createdAt: string,
        updatedAt: string,
        notation?:  Array< {
          __typename: "Notation",
          id: string,
          type: NOTATION_TYPE,
          description?: string | null,
          status: HUB_SECTION_ITEM_STATUS,
          pageId: string,
          coordinate?:  Array< {
            __typename: "Coordinate",
            x: number,
            y: number,
          } > | null,
          createdAt: string,
          createdBy: string,
          updatedAt: string,
          updatedBy: string,
        } > | null,
      } > | null,
      toDos?:  Array< {
        __typename: "ToDo",
        id: string,
        title: string,
        status: TODO_STATUS,
        completedAt?: string | null,
        resolution?: string | null,
        order: number,
        createdAt: string,
        updatedAt: string,
      } > | null,
      links?:  Array< {
        __typename: "Link",
        id: string,
        url: string,
        title?: string | null,
        status: HUB_SECTION_ITEM_STATUS,
        order: number,
        createdAt: string,
        updatedAt: string,
      } > | null,
      createdAt: string,
      updatedAt: string,
    } > | null,
    content?:  Array< {
      __typename: "PresentableContentMetadata",
      id: string,
      contentId: string,
      token: string,
      key: string,
      expiresAt: string,
      fileName: string,
      associatedFileId?: string | null,
      shareType: SHARE_TYPE,
      fileExtension?: string | null,
      contentURL?: string | null,
      pageGroups?:  Array< {
        __typename: "PageGroup",
        id: string,
        pageIds?: Array< string > | null,
        name: string,
        locked: boolean,
        sourceID?: string | null,
        source: PAGE_GROUP_SOURCE,
      } > | null,
      title?: string | null,
      numPages?: number | null,
      notation?:  Array< {
        __typename: "Notation",
        id: string,
        type: NOTATION_TYPE,
        description?: string | null,
        status: HUB_SECTION_ITEM_STATUS,
        pageId: string,
        coordinate?:  Array< {
          __typename: "Coordinate",
          x: number,
          y: number,
        } > | null,
        createdAt: string,
        createdBy: string,
        updatedAt: string,
        updatedBy: string,
      } > | null,
      coverThumbnailPageNum?: number | null,
      sharedBy: string,
      tenantId: string,
    } > | null,
    tenantLogoUrl?: string | null,
    sharedBy: string,
    featureFlags:  Array< {
      __typename: "FeatureFlags",
      flagId: string,
      value: boolean,
    } >,
  } | null,
};

export type GetProviderByUserEmailQueryVariables = {
  idpIdentifier: string,
};

export type GetProviderByUserEmailQuery = {
  getProviderByUserEmail?: boolean | null,
};

export type GetReportListQueryVariables = {
  folderName: string,
  isPublisherMode?: boolean | null,
};

export type GetReportListQuery = {
  getReportList?:  {
    __typename: "AvailableDashboards",
    dashboards:  Array< {
      __typename: "Dashboard",
      reports:  Array< {
        __typename: "ReportItem",
        dashboardId: string,
        name: string,
        group:  {
          __typename: "ReportGroup",
          name: string,
          order: number,
        },
      } >,
      dashboardUrl: string,
      dashboardId: string,
      name: string,
      dashboardSheets:  Array< {
        __typename: "Sheet",
        sheetId: string,
        name: string,
      } >,
    } >,
    isTeamLeader: boolean,
  } | null,
};

export type GetEmbeddedUrlQueryVariables = {
  dashboardId: string,
  folderName: string,
  isPublisherMode?: boolean | null,
};

export type GetEmbeddedUrlQuery = {
  getEmbeddedUrl?: string | null,
};

export type GetSharedFoldersQuery = {
  getSharedFolders?:  {
    __typename: "GetSharedFoldersOutput",
    folders:  Array< {
      __typename: "Folder",
      id: string,
      tenantId: string,
      name: string,
      isPinned?: boolean | null,
      status: FOLDER_STATUS,
      items:  Array< {
        __typename: "FolderItem",
        id: string,
        type: FOLDER_ITEM_TYPE,
        itemId: string,
        status?: FOLDER_ITEM_STATUS | null,
        addedAt: string,
        updateAcknowledgedAt?: string | null,
        itemLastUpdatedAt: string,
        visiblePages?: Array< number > | null,
        customTitle?: string | null,
      } >,
      createdAt: string,
      createdBy: string,
      updatedAt: string,
      updatedBy: string,
      sharePermissions?:  Array< {
        __typename: "SharePermission",
        id: string,
        isDeleted?: boolean | null,
        type: PERMISSION_TYPE,
        targetType: SHARE_TARGET_TYPE,
        targetUsername?: string | null,
        targetCustomValues?:  Array< {
          __typename: "CustomValues",
          fieldId: string,
          objectRecords?:  Array< {
            __typename: "ObjectRecord",
            id: string,
            externalId?: string | null,
            syncStatus?: CRMSyncStatus | null,
            status: OBJECT_RECORD_STATUS,
            values:  Array< {
              __typename: "ObjectRecordValue",
              fieldId: string,
              values: Array< string >,
            } >,
          } > | null,
          values: Array< string >,
        } > | null,
        targetLabels?:  Array< {
          __typename: "LabelValues",
          key: string,
          values: Array< string >,
        } > | null,
        createdAt: string,
        createdBy: string,
        updatedAt: string,
        updatedBy: string,
        deletedBy?: string | null,
        deletedAt?: string | null,
      } > | null,
      shareStatus: SHARE_STATUS,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } >,
    permissions:  Array< {
      __typename: "FolderPermission",
      id: string,
      type: PERMISSION_TYPE,
    } >,
  } | null,
};

export type GetDependenciesForSharedFolderQueryVariables = {
  folderId: string,
  parentFolderId: string,
};

export type GetDependenciesForSharedFolderQuery = {
  getDependenciesForSharedFolder?:  {
    __typename: "GetDependenciesOutput",
    customDecks:  Array< {
      __typename: "CustomDeck",
      id: string,
      createdAt: string,
      createdBy: string,
      autoUpdateAcknowledgedAt?: string | null,
      updatedAt: string,
      updatedBy: string,
      tenantId: string,
      groups:  Array< {
        __typename: "CustomDeckGroup",
        id: string,
        pages:  Array< {
          __typename: "CustomDeckPage",
          pageId: string,
          pageNumber: number,
          documentVersionId: string,
        } >,
        visible: boolean,
        srcId?: string | null,
        docAccessLevel: DOCUMENT_ACCESS_LEVEL,
        name?: string | null,
        locked?: boolean | null,
      } >,
      title: string,
      editMutex?:  {
        __typename: "EditMutex",
        userId: string,
        timeStarted: string,
        lastSeenAt: string,
      } | null,
      sourceCustomDeckId?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } >,
    documents:  Array< {
      __typename: "Document",
      id: string,
      tenantId: string,
      accessLevel: DOCUMENT_ACCESS_LEVEL,
      status: DOCUMENT_STATUS,
      owner: string,
      expiresAt?: string | null,
      integration?:  {
        __typename: "DocumentIntegration",
        integrationId?: string | null,
        externalId?: string | null,
        firstSync?: string | null,
        lastSync?: string | null,
      } | null,
      integrationType?: INTEGRATION_TYPE | null,
      type: FILE_TYPE,
      editPermissions?: Array< string > | null,
      createdAt: string,
      createdBy: string,
      updatedAt: string,
      updatedBy: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } >,
    documentVersions:  Array< {
      __typename: "DocumentVersion",
      id: string,
      tenantId: string,
      documentId: string,
      versionNumber: number,
      srcFilename?: string | null,
      contentURL?: string | null,
      conversionStatus: CONVERSION_STATUS,
      status: DOCUMENT_STATUS,
      srcFile?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
        url: string,
      } | null,
      srcHash?: string | null,
      srcSize?: number | null,
      numPages?: number | null,
      pageSettings:  Array< {
        __typename: "PageSetting",
        pageId: string,
        number: number,
        isRequired?: boolean | null,
        linkedSlides: Array< string >,
      } >,
      pageGroups?:  Array< {
        __typename: "PageGroup",
        id: string,
        pageIds?: Array< string > | null,
        name: string,
        locked: boolean,
        sourceID?: string | null,
        source: PAGE_GROUP_SOURCE,
      } > | null,
      type: FILE_TYPE,
      releaseNotes?: string | null,
      changeType?: DOCUMENT_VERSION_CHANGE_TYPE | null,
      labelValues?:  Array< {
        __typename: "LabelValue",
        key: string,
        value: string,
      } > | null,
      customValues?:  Array< {
        __typename: "CustomValues",
        fieldId: string,
        objectRecords?:  Array< {
          __typename: "ObjectRecord",
          id: string,
          externalId?: string | null,
          syncStatus?: CRMSyncStatus | null,
          status: OBJECT_RECORD_STATUS,
          values:  Array< {
            __typename: "ObjectRecordValue",
            fieldId: string,
            values: Array< string >,
          } >,
        } > | null,
        values: Array< string >,
      } > | null,
      title?: string | null,
      shortDescription?: string | null,
      longDescription?: string | null,
      owner?: string | null,
      expiresAt?: string | null,
      hasCopyright?: boolean | null,
      hasCustomThumbnail?: boolean | null,
      purpose?: string | null,
      canHideSlides?: boolean | null,
      distributable?: boolean | null,
      downloadable?: boolean | null,
      isInternalGenerated?: boolean | null,
      semVer?:  {
        __typename: "SemVer",
        minor: number,
        major: number,
      } | null,
      notificationScope?: NOTIFICATION_SCOPE | null,
      selectedThumbnail?: number | null,
      publishedAt?: string | null,
      uploadedAt?: string | null,
      uploadedBy?: string | null,
      convertedArchiveKey?: string | null,
      convertedArchiveSize?: number | null,
      convertedFolderKey?: string | null,
      associatedFiles?:  Array< {
        __typename: "AssociatedFile",
        id: string,
        isDistributable?: boolean | null,
        isDefault?: boolean | null,
        type: ASSOCIATED_FILE_TYPE,
        attachmentId: string,
        status: ASSOCIATED_FILE_STATUS,
        createdAt: string,
        createdBy: string,
      } > | null,
      editPermissions: Array< string >,
      converterVersion?: number | null,
      createdAt: string,
      createdBy: string,
      updatedAt: string,
      updatedBy: string,
      integration?:  {
        __typename: "DocumentVersionIntegration",
        externalVersionId?: string | null,
        version?: string | null,
        timestamp?: string | null,
        srcFileHash?: string | null,
        srcDocumentHash?: string | null,
      } | null,
      integrationType?: INTEGRATION_TYPE | null,
      conversionWarningCode?: CONVERSION_WARNING_CODE | null,
      conversionErrorCode?: string | null,
      scheduledPublish?: string | null,
      mappingStatus?: MAPPING_STATUS | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } >,
    accessTokens:  Array< {
      __typename: "AccessTokenData",
      documentVersionId: string,
      accessToken: string,
      accessTokenExpire: string,
    } >,
  } | null,
};

export type GetCustomDeckLambdaQueryVariables = {
  customDeckId: string,
  folderId: string,
  rootFolderId: string,
};

export type GetCustomDeckLambdaQuery = {
  getCustomDeckLambda?:  {
    __typename: "CustomDeck",
    id: string,
    createdAt: string,
    createdBy: string,
    autoUpdateAcknowledgedAt?: string | null,
    updatedAt: string,
    updatedBy: string,
    tenantId: string,
    groups:  Array< {
      __typename: "CustomDeckGroup",
      id: string,
      pages:  Array< {
        __typename: "CustomDeckPage",
        pageId: string,
        pageNumber: number,
        documentVersionId: string,
      } >,
      visible: boolean,
      srcId?: string | null,
      docAccessLevel: DOCUMENT_ACCESS_LEVEL,
      name?: string | null,
      locked?: boolean | null,
    } >,
    title: string,
    editMutex?:  {
      __typename: "EditMutex",
      userId: string,
      timeStarted: string,
      lastSeenAt: string,
    } | null,
    sourceCustomDeckId?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type GetAvailableIntegrationsByCognitoUserQuery = {
  getAvailableIntegrationsByCognitoUser?:  Array< {
    __typename: "PublisherIntegration",
    id: string,
    tenantId: string,
    name: string,
    integrationType: string,
    enabled: boolean,
  } > | null,
};

export type GetZendeskJWTQueryVariables = {
  feature: ZENDESK_FEATURE,
};

export type GetZendeskJWTQuery = {
  getZendeskJWT?:  {
    __typename: "ZendeskTokenPayload",
    token: string,
    expiresAt: string,
  } | null,
};

export type GetRefreshedContentAccessTokenQueryVariables = {
  expiredToken: string,
  durationSeconds: number,
};

export type GetRefreshedContentAccessTokenQuery = {
  getRefreshedContentAccessToken?:  {
    __typename: "ContentTokenOutput",
    token: string,
    expiresAt: string,
  } | null,
};

export type GetCRMAuthUrlQuery = {
  getCRMAuthUrl?: string | null,
};

export type GetCRMAuthInformationQueryVariables = {
  code: string,
};

export type GetCRMAuthInformationQuery = {
  getCRMAuthInformation?:  {
    __typename: "AuthInformationPayload",
    accessToken: string,
    instanceUrl: string,
    refreshToken: string,
    userInfo:  {
      __typename: "UserInfoPayload",
      id: string,
      organizationId: string,
      displayName: string,
      thumbnail?: string | null,
    },
  } | null,
};

export type LogOutCRMQueryVariables = {
  accessToken?: string | null,
};

export type LogOutCRMQuery = {
  logOutCRM?: string | null,
};

export type RefreshTokenCRMQueryVariables = {
  accessToken?: string | null,
  refreshToken?: string | null,
};

export type RefreshTokenCRMQuery = {
  refreshTokenCRM?:  {
    __typename: "RefreshTokenCRMPayload",
    accessToken: string,
    issuedAt: string,
    signature: string,
    id: string,
  } | null,
};

export type GetPoolUserSubIdByEmailQueryVariables = {
  email: string,
};

export type GetPoolUserSubIdByEmailQuery = {
  getPoolUserSubIdByEmail?: string | null,
};

export type FullTextSearchQueryVariables = {
  queryText: string,
  filter?: FullTextSearchFilterInput | null,
  configName?: string | null,
};

export type FullTextSearchQuery = {
  fullTextSearch?:  {
    __typename: "FullTextSearchOutput",
    items:  Array< {
      __typename: "FullTextSearchResultItem",
      documentId: string,
      title:  {
        __typename: "TextWithHighlights",
        text?: string | null,
        highlights?:  Array< {
          __typename: "Highlights",
          beginOffset: number,
          endOffset: number,
          topAnswer?: boolean | null,
          type: string,
        } > | null,
      },
      documentExcerpt:  {
        __typename: "TextWithHighlights",
        text?: string | null,
        highlights?:  Array< {
          __typename: "Highlights",
          beginOffset: number,
          endOffset: number,
          topAnswer?: boolean | null,
          type: string,
        } > | null,
      },
      queryId: string,
      resultId: string,
      pageNumber?: number | null,
    } >,
    searchServerTime?: number | null,
    searchClientTime?: number | null,
  } | null,
};

export type OnCreateUserSubscriptionVariables = {
  filter?: ModelSubscriptionUserFilterInput | null,
  tenantId?: string | null,
  id?: string | null,
};

export type OnCreateUserSubscription = {
  onCreateUser?:  {
    __typename: "User",
    id: string,
    cognitoSubId?: string | null,
    tenantId: string,
    email: string,
    givenName: string,
    phoneNumber?: string | null,
    familyName: string,
    meetingId?: string | null,
    defaultFiltersCustomValues:  Array< {
      __typename: "CustomValues",
      fieldId: string,
      objectRecords?:  Array< {
        __typename: "ObjectRecord",
        id: string,
        externalId?: string | null,
        syncStatus?: CRMSyncStatus | null,
        status: OBJECT_RECORD_STATUS,
        values:  Array< {
          __typename: "ObjectRecordValue",
          fieldId: string,
          values: Array< string >,
        } >,
      } > | null,
      values: Array< string >,
    } >,
    lockedFiltersCustomValues:  Array< {
      __typename: "CustomValues",
      fieldId: string,
      objectRecords?:  Array< {
        __typename: "ObjectRecord",
        id: string,
        externalId?: string | null,
        syncStatus?: CRMSyncStatus | null,
        status: OBJECT_RECORD_STATUS,
        values:  Array< {
          __typename: "ObjectRecordValue",
          fieldId: string,
          values: Array< string >,
        } >,
      } > | null,
      values: Array< string >,
    } >,
    defaultFilterValues:  Array< {
      __typename: "LabelValues",
      key: string,
      values: Array< string >,
    } >,
    lockedFilters?:  Array< {
      __typename: "LabelValues",
      key: string,
      values: Array< string >,
    } > | null,
    shareBCC?: Array< string > | null,
    shareCC?: Array< string > | null,
    createdAt: string,
    createdBy: string,
    updatedAt: string,
    status?: USER_STATUS | null,
    role: USER_ROLE,
    bookmarkedDocs?:  Array< {
      __typename: "BookmarkedDoc",
      docID: string,
      createdAt: string,
    } > | null,
    isExcludedFromReporting?: boolean | null,
    additionalRoles?: Array< USER_ROLE > | null,
    altUsername?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateUserSubscriptionVariables = {
  filter?: ModelSubscriptionUserFilterInput | null,
  tenantId?: string | null,
  id?: string | null,
};

export type OnUpdateUserSubscription = {
  onUpdateUser?:  {
    __typename: "User",
    id: string,
    cognitoSubId?: string | null,
    tenantId: string,
    email: string,
    givenName: string,
    phoneNumber?: string | null,
    familyName: string,
    meetingId?: string | null,
    defaultFiltersCustomValues:  Array< {
      __typename: "CustomValues",
      fieldId: string,
      objectRecords?:  Array< {
        __typename: "ObjectRecord",
        id: string,
        externalId?: string | null,
        syncStatus?: CRMSyncStatus | null,
        status: OBJECT_RECORD_STATUS,
        values:  Array< {
          __typename: "ObjectRecordValue",
          fieldId: string,
          values: Array< string >,
        } >,
      } > | null,
      values: Array< string >,
    } >,
    lockedFiltersCustomValues:  Array< {
      __typename: "CustomValues",
      fieldId: string,
      objectRecords?:  Array< {
        __typename: "ObjectRecord",
        id: string,
        externalId?: string | null,
        syncStatus?: CRMSyncStatus | null,
        status: OBJECT_RECORD_STATUS,
        values:  Array< {
          __typename: "ObjectRecordValue",
          fieldId: string,
          values: Array< string >,
        } >,
      } > | null,
      values: Array< string >,
    } >,
    defaultFilterValues:  Array< {
      __typename: "LabelValues",
      key: string,
      values: Array< string >,
    } >,
    lockedFilters?:  Array< {
      __typename: "LabelValues",
      key: string,
      values: Array< string >,
    } > | null,
    shareBCC?: Array< string > | null,
    shareCC?: Array< string > | null,
    createdAt: string,
    createdBy: string,
    updatedAt: string,
    status?: USER_STATUS | null,
    role: USER_ROLE,
    bookmarkedDocs?:  Array< {
      __typename: "BookmarkedDoc",
      docID: string,
      createdAt: string,
    } > | null,
    isExcludedFromReporting?: boolean | null,
    additionalRoles?: Array< USER_ROLE > | null,
    altUsername?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteUserSubscriptionVariables = {
  filter?: ModelSubscriptionUserFilterInput | null,
  tenantId?: string | null,
  id?: string | null,
};

export type OnDeleteUserSubscription = {
  onDeleteUser?:  {
    __typename: "User",
    id: string,
    cognitoSubId?: string | null,
    tenantId: string,
    email: string,
    givenName: string,
    phoneNumber?: string | null,
    familyName: string,
    meetingId?: string | null,
    defaultFiltersCustomValues:  Array< {
      __typename: "CustomValues",
      fieldId: string,
      objectRecords?:  Array< {
        __typename: "ObjectRecord",
        id: string,
        externalId?: string | null,
        syncStatus?: CRMSyncStatus | null,
        status: OBJECT_RECORD_STATUS,
        values:  Array< {
          __typename: "ObjectRecordValue",
          fieldId: string,
          values: Array< string >,
        } >,
      } > | null,
      values: Array< string >,
    } >,
    lockedFiltersCustomValues:  Array< {
      __typename: "CustomValues",
      fieldId: string,
      objectRecords?:  Array< {
        __typename: "ObjectRecord",
        id: string,
        externalId?: string | null,
        syncStatus?: CRMSyncStatus | null,
        status: OBJECT_RECORD_STATUS,
        values:  Array< {
          __typename: "ObjectRecordValue",
          fieldId: string,
          values: Array< string >,
        } >,
      } > | null,
      values: Array< string >,
    } >,
    defaultFilterValues:  Array< {
      __typename: "LabelValues",
      key: string,
      values: Array< string >,
    } >,
    lockedFilters?:  Array< {
      __typename: "LabelValues",
      key: string,
      values: Array< string >,
    } > | null,
    shareBCC?: Array< string > | null,
    shareCC?: Array< string > | null,
    createdAt: string,
    createdBy: string,
    updatedAt: string,
    status?: USER_STATUS | null,
    role: USER_ROLE,
    bookmarkedDocs?:  Array< {
      __typename: "BookmarkedDoc",
      docID: string,
      createdAt: string,
    } > | null,
    isExcludedFromReporting?: boolean | null,
    additionalRoles?: Array< USER_ROLE > | null,
    altUsername?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateTenantSubscriptionVariables = {
  filter?: ModelSubscriptionTenantFilterInput | null,
  id?: string | null,
};

export type OnCreateTenantSubscription = {
  onCreateTenant?:  {
    __typename: "Tenant",
    id: string,
    name: string,
    licensedUsers: number,
    status: TENANT_STATUS,
    fields:  Array< {
      __typename: "FieldConfig",
      fieldName: string,
      description?: string | null,
      required: boolean,
      isEmailTemplateFilter?: boolean | null,
      dataType: FIELD_DATA_TYPE,
      userFilter: boolean,
      defaultSearchFilter: boolean,
      values: Array< string >,
    } >,
    folderUpdateGracePeriodDays?: number | null,
    medInfoURL?: string | null,
    publisherListConfig?:  {
      __typename: "ListConfiguration",
      fields:  Array< {
        __typename: "ListConfigurationField",
        fieldName: string,
        header?: string | null,
        width?: number | null,
      } >,
      sort?:  {
        __typename: "ListConfigurationSort",
        field: string,
        isAsc: boolean,
      } | null,
    } | null,
    mslListConfig?:  {
      __typename: "ListConfiguration",
      fields:  Array< {
        __typename: "ListConfigurationField",
        fieldName: string,
        header?: string | null,
        width?: number | null,
      } >,
      sort?:  {
        __typename: "ListConfigurationSort",
        field: string,
        isAsc: boolean,
      } | null,
    } | null,
    featureFlags:  Array< {
      __typename: "FeatureFlags",
      flagId: string,
      value: boolean,
    } >,
    createdAt: string,
    createdBy: string,
    updatedAt: string,
    statusChangedAt: string,
    statusChangedBy: string,
    ssoDomains?: Array< string | null > | null,
    integrations?: Array< string | null > | null,
    config:  {
      __typename: "TenantConfig",
      requiredSlidesHiddenMessage?: string | null,
      forms?:  Array< {
        __typename: "TenantForm",
        id: string,
        name: string,
        label: string,
        fields:  Array< {
          __typename: "CustomFieldDefinition",
          id: string,
          fieldName: string,
          fieldLabel: string,
          reportingName?: string | null,
          status: FIELD_STATUS,
          description?: string | null,
          required?: boolean | null,
          maxLength?: number | null,
          formatValidation?: FORMAT_VALIDATION | null,
          fieldType: FIELD_DATA_TYPE,
          showByDefault?: boolean | null,
          dependentFieldId?: string | null,
          controlType: CONTROL_TYPE,
          order?: number | null,
          settings?:  {
            __typename: "CommonFieldSettings",
            isBadge?: boolean | null,
            fieldPosition: FIELD_POSITION,
          } | null,
          usage: Array< CUSTOM_FIELD_USAGE >,
          documentSettings?:  {
            __typename: "DocumentFieldSettings",
            fieldPosition: FIELD_POSITION,
          } | null,
          fieldValueDefinitions:  Array< {
            __typename: "CustomFieldValueDefinition",
            id: string,
            value: string,
            label?: string | null,
            disabled?: boolean | null,
            isDefault?: boolean | null,
            documentSettings?:  {
              __typename: "DocumentFieldValueSettings",
              presentationWatermarkText?: string | null,
              permission?:  {
                __typename: "DocumentPermission",
                present?: PERMISSION_EFFECT | null,
                modify?: PERMISSION_EFFECT | null,
                share?: PERMISSION_EFFECT | null,
                download?: PERMISSION_EFFECT | null,
                externalNotation?: PERMISSION_EFFECT | null,
              } | null,
            } | null,
            badgeColor?: string | null,
            badgeLabel?: string | null,
            createdAt: string,
            dependentValueIds: Array< string >,
          } >,
          displayOnParentSelection?: boolean | null,
          displayOnValueSelection?: Array< string > | null,
          objectSetting?:  {
            __typename: "ObjectSetting",
            childrenFieldIds: Array< string >,
            restriction?:  {
              __typename: "ObjectRestriction",
              syncUpdate?: boolean | null,
              syncDelete?: boolean | null,
              update?: boolean | null,
              delete?: boolean | null,
            } | null,
          } | null,
          isChildField?: boolean | null,
          dateRestriction?: DATE_RESTRICTION | null,
          createdAt: string,
          scaleNumber?: number | null,
          precisionNumber?: number | null,
        } >,
        targetRequestTemplate: string,
        targetType: TENANT_FORM_TARGET_TYPE,
        targetAddress: string,
        targetParameters?:  Array< {
          __typename: "TenantFormTargetParameters",
          key: string,
          value: string,
        } > | null,
        status: TENANT_FORM_STATUS,
      } > | null,
      meetingFields?:  Array< {
        __typename: "MeetingFieldDefinition",
        id: string,
        fieldName: string,
        status: FIELD_STATUS,
        description?: string | null,
        required: boolean,
        maxLength?: number | null,
        type: FIELD_DATA_TYPE,
        controlType: CONTROL_TYPE,
        fieldValueDefinitions:  Array< {
          __typename: "MeetingFieldValueDefinition",
          id: string,
          value: string,
          disabled: boolean,
          createdAt: string,
        } >,
        createdAt: string,
      } > | null,
      customFields?:  Array< {
        __typename: "CustomFieldDefinition",
        id: string,
        fieldName: string,
        fieldLabel: string,
        reportingName?: string | null,
        status: FIELD_STATUS,
        description?: string | null,
        required?: boolean | null,
        maxLength?: number | null,
        formatValidation?: FORMAT_VALIDATION | null,
        fieldType: FIELD_DATA_TYPE,
        showByDefault?: boolean | null,
        dependentFieldId?: string | null,
        controlType: CONTROL_TYPE,
        order?: number | null,
        settings?:  {
          __typename: "CommonFieldSettings",
          isBadge?: boolean | null,
          fieldPosition: FIELD_POSITION,
        } | null,
        usage: Array< CUSTOM_FIELD_USAGE >,
        documentSettings?:  {
          __typename: "DocumentFieldSettings",
          fieldPosition: FIELD_POSITION,
        } | null,
        fieldValueDefinitions:  Array< {
          __typename: "CustomFieldValueDefinition",
          id: string,
          value: string,
          label?: string | null,
          disabled?: boolean | null,
          isDefault?: boolean | null,
          documentSettings?:  {
            __typename: "DocumentFieldValueSettings",
            presentationWatermarkText?: string | null,
            permission?:  {
              __typename: "DocumentPermission",
              present?: PERMISSION_EFFECT | null,
              modify?: PERMISSION_EFFECT | null,
              share?: PERMISSION_EFFECT | null,
              download?: PERMISSION_EFFECT | null,
              externalNotation?: PERMISSION_EFFECT | null,
            } | null,
          } | null,
          badgeColor?: string | null,
          badgeLabel?: string | null,
          createdAt: string,
          dependentValueIds: Array< string >,
        } >,
        displayOnParentSelection?: boolean | null,
        displayOnValueSelection?: Array< string > | null,
        objectSetting?:  {
          __typename: "ObjectSetting",
          childrenFieldIds: Array< string >,
          restriction?:  {
            __typename: "ObjectRestriction",
            syncUpdate?: boolean | null,
            syncDelete?: boolean | null,
            update?: boolean | null,
            delete?: boolean | null,
          } | null,
        } | null,
        isChildField?: boolean | null,
        dateRestriction?: DATE_RESTRICTION | null,
        createdAt: string,
        scaleNumber?: number | null,
        precisionNumber?: number | null,
      } > | null,
      defaultDocumentPermissions:  {
        __typename: "DocumentPermission",
        present?: PERMISSION_EFFECT | null,
        modify?: PERMISSION_EFFECT | null,
        share?: PERMISSION_EFFECT | null,
        download?: PERMISSION_EFFECT | null,
        externalNotation?: PERMISSION_EFFECT | null,
      },
      mslListConfig?:  {
        __typename: "ListConfig",
        sort?:  {
          __typename: "ListSort",
          field: string,
          isAsc?: boolean | null,
        } | null,
        fields:  Array< {
          __typename: "FieldListConfig",
          field: string,
          width?: number | null,
        } >,
      } | null,
      publisherListConfig?:  {
        __typename: "ListConfig",
        sort?:  {
          __typename: "ListSort",
          field: string,
          isAsc?: boolean | null,
        } | null,
        fields:  Array< {
          __typename: "FieldListConfig",
          field: string,
          width?: number | null,
        } >,
      } | null,
      crmIntegration?:  {
        __typename: "CRMIntegration",
        id: string,
        crmIntegrationType: CRM_INTEGRATION_TYPE,
        instanceUrl: string,
        clientId: string,
        redirectUri: string,
        name: string,
        accountsSettings:  {
          __typename: "CRMAccountSettings",
          query: string,
          displaySettings:  {
            __typename: "CRMDisplaySettings",
            shortened: string,
            extended?: Array< string > | null,
          },
          addressSettings:  {
            __typename: "CRMAddressSettings",
            alias: string,
            displaySettings:  {
              __typename: "CRMDisplaySettings",
              shortened: string,
              extended?: Array< string > | null,
            },
          },
        },
        meetingSetting?:  {
          __typename: "CRMTableSetting",
          apiName: string,
          presentationsFieldName?: string | null,
          children:  Array< {
            __typename: "CRMChildTableSetting",
            name: string,
            apiName: string,
            relationshipName?: string | null,
            type: CRM_INTEGRATION_TYPE,
            veevaSetting?:  {
              __typename: "VeevaSetting",
              markerFieldName?: string | null,
            } | null,
          } >,
          type: CRM_INTEGRATION_TYPE,
        } | null,
        additionalSettings?:  {
          __typename: "CRMAdditionalSettings",
          allowCommentsOnTasks?: boolean | null,
          detailContentTableSettings?:  {
            __typename: "CRMTableDetailContentPresented",
            name: string,
            apiName: string,
            relationshipName: string,
            prefix?: string | null,
          } | null,
          enableSurveys?: boolean | null,
          crmAccountToObjectSettings?:  Array< {
            __typename: "CRMAccountToObjectSetting",
            accountFieldNames:  Array< {
              __typename: "CRMAccountToObjectFields",
              accountFieldName: string,
              objectFieldName: string,
            } >,
            apiName: string,
          } > | null,
        } | null,
        loginUrl?: string | null,
        crmStandaloneForms?:  Array< {
          __typename: "CRMStandaloneForm",
          id: string,
          apiName: string,
          children:  Array< {
            __typename: "CRMChildTableSetting",
            name: string,
            apiName: string,
            relationshipName?: string | null,
            type: CRM_INTEGRATION_TYPE,
            veevaSetting?:  {
              __typename: "VeevaSetting",
              markerFieldName?: string | null,
            } | null,
          } >,
          type: CRM_INTEGRATION_TYPE,
          parents:  Array< {
            __typename: "CRMStandaloneFormParent",
            apiName: string,
            relationshipName: string,
          } >,
          usage?: Array< STANDALONE_FORM_USAGE > | null,
        } > | null,
      } | null,
      sessionTimeout?: number | null,
      quickFilters?:  Array< {
        __typename: "QuickFilter",
        id: string,
        label: string,
        path: Array< string >,
      } > | null,
      hubsConfig:  {
        __typename: "HubConfig",
        shareExpirationDays?: number | null,
        disabledSections: Array< HUB_SECTION_TYPE >,
        disableNotationDescription: boolean,
      },
      reports:  Array< {
        __typename: "KeyValue",
        key: string,
        value: boolean,
      } >,
      emailDocumentUpdateFrequency?: FREQUENCY | null,
      sharePPTXasPDF?: boolean | null,
    },
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateTenantSubscriptionVariables = {
  filter?: ModelSubscriptionTenantFilterInput | null,
  id?: string | null,
};

export type OnUpdateTenantSubscription = {
  onUpdateTenant?:  {
    __typename: "Tenant",
    id: string,
    name: string,
    licensedUsers: number,
    status: TENANT_STATUS,
    fields:  Array< {
      __typename: "FieldConfig",
      fieldName: string,
      description?: string | null,
      required: boolean,
      isEmailTemplateFilter?: boolean | null,
      dataType: FIELD_DATA_TYPE,
      userFilter: boolean,
      defaultSearchFilter: boolean,
      values: Array< string >,
    } >,
    folderUpdateGracePeriodDays?: number | null,
    medInfoURL?: string | null,
    publisherListConfig?:  {
      __typename: "ListConfiguration",
      fields:  Array< {
        __typename: "ListConfigurationField",
        fieldName: string,
        header?: string | null,
        width?: number | null,
      } >,
      sort?:  {
        __typename: "ListConfigurationSort",
        field: string,
        isAsc: boolean,
      } | null,
    } | null,
    mslListConfig?:  {
      __typename: "ListConfiguration",
      fields:  Array< {
        __typename: "ListConfigurationField",
        fieldName: string,
        header?: string | null,
        width?: number | null,
      } >,
      sort?:  {
        __typename: "ListConfigurationSort",
        field: string,
        isAsc: boolean,
      } | null,
    } | null,
    featureFlags:  Array< {
      __typename: "FeatureFlags",
      flagId: string,
      value: boolean,
    } >,
    createdAt: string,
    createdBy: string,
    updatedAt: string,
    statusChangedAt: string,
    statusChangedBy: string,
    ssoDomains?: Array< string | null > | null,
    integrations?: Array< string | null > | null,
    config:  {
      __typename: "TenantConfig",
      requiredSlidesHiddenMessage?: string | null,
      forms?:  Array< {
        __typename: "TenantForm",
        id: string,
        name: string,
        label: string,
        fields:  Array< {
          __typename: "CustomFieldDefinition",
          id: string,
          fieldName: string,
          fieldLabel: string,
          reportingName?: string | null,
          status: FIELD_STATUS,
          description?: string | null,
          required?: boolean | null,
          maxLength?: number | null,
          formatValidation?: FORMAT_VALIDATION | null,
          fieldType: FIELD_DATA_TYPE,
          showByDefault?: boolean | null,
          dependentFieldId?: string | null,
          controlType: CONTROL_TYPE,
          order?: number | null,
          settings?:  {
            __typename: "CommonFieldSettings",
            isBadge?: boolean | null,
            fieldPosition: FIELD_POSITION,
          } | null,
          usage: Array< CUSTOM_FIELD_USAGE >,
          documentSettings?:  {
            __typename: "DocumentFieldSettings",
            fieldPosition: FIELD_POSITION,
          } | null,
          fieldValueDefinitions:  Array< {
            __typename: "CustomFieldValueDefinition",
            id: string,
            value: string,
            label?: string | null,
            disabled?: boolean | null,
            isDefault?: boolean | null,
            documentSettings?:  {
              __typename: "DocumentFieldValueSettings",
              presentationWatermarkText?: string | null,
              permission?:  {
                __typename: "DocumentPermission",
                present?: PERMISSION_EFFECT | null,
                modify?: PERMISSION_EFFECT | null,
                share?: PERMISSION_EFFECT | null,
                download?: PERMISSION_EFFECT | null,
                externalNotation?: PERMISSION_EFFECT | null,
              } | null,
            } | null,
            badgeColor?: string | null,
            badgeLabel?: string | null,
            createdAt: string,
            dependentValueIds: Array< string >,
          } >,
          displayOnParentSelection?: boolean | null,
          displayOnValueSelection?: Array< string > | null,
          objectSetting?:  {
            __typename: "ObjectSetting",
            childrenFieldIds: Array< string >,
            restriction?:  {
              __typename: "ObjectRestriction",
              syncUpdate?: boolean | null,
              syncDelete?: boolean | null,
              update?: boolean | null,
              delete?: boolean | null,
            } | null,
          } | null,
          isChildField?: boolean | null,
          dateRestriction?: DATE_RESTRICTION | null,
          createdAt: string,
          scaleNumber?: number | null,
          precisionNumber?: number | null,
        } >,
        targetRequestTemplate: string,
        targetType: TENANT_FORM_TARGET_TYPE,
        targetAddress: string,
        targetParameters?:  Array< {
          __typename: "TenantFormTargetParameters",
          key: string,
          value: string,
        } > | null,
        status: TENANT_FORM_STATUS,
      } > | null,
      meetingFields?:  Array< {
        __typename: "MeetingFieldDefinition",
        id: string,
        fieldName: string,
        status: FIELD_STATUS,
        description?: string | null,
        required: boolean,
        maxLength?: number | null,
        type: FIELD_DATA_TYPE,
        controlType: CONTROL_TYPE,
        fieldValueDefinitions:  Array< {
          __typename: "MeetingFieldValueDefinition",
          id: string,
          value: string,
          disabled: boolean,
          createdAt: string,
        } >,
        createdAt: string,
      } > | null,
      customFields?:  Array< {
        __typename: "CustomFieldDefinition",
        id: string,
        fieldName: string,
        fieldLabel: string,
        reportingName?: string | null,
        status: FIELD_STATUS,
        description?: string | null,
        required?: boolean | null,
        maxLength?: number | null,
        formatValidation?: FORMAT_VALIDATION | null,
        fieldType: FIELD_DATA_TYPE,
        showByDefault?: boolean | null,
        dependentFieldId?: string | null,
        controlType: CONTROL_TYPE,
        order?: number | null,
        settings?:  {
          __typename: "CommonFieldSettings",
          isBadge?: boolean | null,
          fieldPosition: FIELD_POSITION,
        } | null,
        usage: Array< CUSTOM_FIELD_USAGE >,
        documentSettings?:  {
          __typename: "DocumentFieldSettings",
          fieldPosition: FIELD_POSITION,
        } | null,
        fieldValueDefinitions:  Array< {
          __typename: "CustomFieldValueDefinition",
          id: string,
          value: string,
          label?: string | null,
          disabled?: boolean | null,
          isDefault?: boolean | null,
          documentSettings?:  {
            __typename: "DocumentFieldValueSettings",
            presentationWatermarkText?: string | null,
            permission?:  {
              __typename: "DocumentPermission",
              present?: PERMISSION_EFFECT | null,
              modify?: PERMISSION_EFFECT | null,
              share?: PERMISSION_EFFECT | null,
              download?: PERMISSION_EFFECT | null,
              externalNotation?: PERMISSION_EFFECT | null,
            } | null,
          } | null,
          badgeColor?: string | null,
          badgeLabel?: string | null,
          createdAt: string,
          dependentValueIds: Array< string >,
        } >,
        displayOnParentSelection?: boolean | null,
        displayOnValueSelection?: Array< string > | null,
        objectSetting?:  {
          __typename: "ObjectSetting",
          childrenFieldIds: Array< string >,
          restriction?:  {
            __typename: "ObjectRestriction",
            syncUpdate?: boolean | null,
            syncDelete?: boolean | null,
            update?: boolean | null,
            delete?: boolean | null,
          } | null,
        } | null,
        isChildField?: boolean | null,
        dateRestriction?: DATE_RESTRICTION | null,
        createdAt: string,
        scaleNumber?: number | null,
        precisionNumber?: number | null,
      } > | null,
      defaultDocumentPermissions:  {
        __typename: "DocumentPermission",
        present?: PERMISSION_EFFECT | null,
        modify?: PERMISSION_EFFECT | null,
        share?: PERMISSION_EFFECT | null,
        download?: PERMISSION_EFFECT | null,
        externalNotation?: PERMISSION_EFFECT | null,
      },
      mslListConfig?:  {
        __typename: "ListConfig",
        sort?:  {
          __typename: "ListSort",
          field: string,
          isAsc?: boolean | null,
        } | null,
        fields:  Array< {
          __typename: "FieldListConfig",
          field: string,
          width?: number | null,
        } >,
      } | null,
      publisherListConfig?:  {
        __typename: "ListConfig",
        sort?:  {
          __typename: "ListSort",
          field: string,
          isAsc?: boolean | null,
        } | null,
        fields:  Array< {
          __typename: "FieldListConfig",
          field: string,
          width?: number | null,
        } >,
      } | null,
      crmIntegration?:  {
        __typename: "CRMIntegration",
        id: string,
        crmIntegrationType: CRM_INTEGRATION_TYPE,
        instanceUrl: string,
        clientId: string,
        redirectUri: string,
        name: string,
        accountsSettings:  {
          __typename: "CRMAccountSettings",
          query: string,
          displaySettings:  {
            __typename: "CRMDisplaySettings",
            shortened: string,
            extended?: Array< string > | null,
          },
          addressSettings:  {
            __typename: "CRMAddressSettings",
            alias: string,
            displaySettings:  {
              __typename: "CRMDisplaySettings",
              shortened: string,
              extended?: Array< string > | null,
            },
          },
        },
        meetingSetting?:  {
          __typename: "CRMTableSetting",
          apiName: string,
          presentationsFieldName?: string | null,
          children:  Array< {
            __typename: "CRMChildTableSetting",
            name: string,
            apiName: string,
            relationshipName?: string | null,
            type: CRM_INTEGRATION_TYPE,
            veevaSetting?:  {
              __typename: "VeevaSetting",
              markerFieldName?: string | null,
            } | null,
          } >,
          type: CRM_INTEGRATION_TYPE,
        } | null,
        additionalSettings?:  {
          __typename: "CRMAdditionalSettings",
          allowCommentsOnTasks?: boolean | null,
          detailContentTableSettings?:  {
            __typename: "CRMTableDetailContentPresented",
            name: string,
            apiName: string,
            relationshipName: string,
            prefix?: string | null,
          } | null,
          enableSurveys?: boolean | null,
          crmAccountToObjectSettings?:  Array< {
            __typename: "CRMAccountToObjectSetting",
            accountFieldNames:  Array< {
              __typename: "CRMAccountToObjectFields",
              accountFieldName: string,
              objectFieldName: string,
            } >,
            apiName: string,
          } > | null,
        } | null,
        loginUrl?: string | null,
        crmStandaloneForms?:  Array< {
          __typename: "CRMStandaloneForm",
          id: string,
          apiName: string,
          children:  Array< {
            __typename: "CRMChildTableSetting",
            name: string,
            apiName: string,
            relationshipName?: string | null,
            type: CRM_INTEGRATION_TYPE,
            veevaSetting?:  {
              __typename: "VeevaSetting",
              markerFieldName?: string | null,
            } | null,
          } >,
          type: CRM_INTEGRATION_TYPE,
          parents:  Array< {
            __typename: "CRMStandaloneFormParent",
            apiName: string,
            relationshipName: string,
          } >,
          usage?: Array< STANDALONE_FORM_USAGE > | null,
        } > | null,
      } | null,
      sessionTimeout?: number | null,
      quickFilters?:  Array< {
        __typename: "QuickFilter",
        id: string,
        label: string,
        path: Array< string >,
      } > | null,
      hubsConfig:  {
        __typename: "HubConfig",
        shareExpirationDays?: number | null,
        disabledSections: Array< HUB_SECTION_TYPE >,
        disableNotationDescription: boolean,
      },
      reports:  Array< {
        __typename: "KeyValue",
        key: string,
        value: boolean,
      } >,
      emailDocumentUpdateFrequency?: FREQUENCY | null,
      sharePPTXasPDF?: boolean | null,
    },
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteTenantSubscriptionVariables = {
  filter?: ModelSubscriptionTenantFilterInput | null,
  id?: string | null,
};

export type OnDeleteTenantSubscription = {
  onDeleteTenant?:  {
    __typename: "Tenant",
    id: string,
    name: string,
    licensedUsers: number,
    status: TENANT_STATUS,
    fields:  Array< {
      __typename: "FieldConfig",
      fieldName: string,
      description?: string | null,
      required: boolean,
      isEmailTemplateFilter?: boolean | null,
      dataType: FIELD_DATA_TYPE,
      userFilter: boolean,
      defaultSearchFilter: boolean,
      values: Array< string >,
    } >,
    folderUpdateGracePeriodDays?: number | null,
    medInfoURL?: string | null,
    publisherListConfig?:  {
      __typename: "ListConfiguration",
      fields:  Array< {
        __typename: "ListConfigurationField",
        fieldName: string,
        header?: string | null,
        width?: number | null,
      } >,
      sort?:  {
        __typename: "ListConfigurationSort",
        field: string,
        isAsc: boolean,
      } | null,
    } | null,
    mslListConfig?:  {
      __typename: "ListConfiguration",
      fields:  Array< {
        __typename: "ListConfigurationField",
        fieldName: string,
        header?: string | null,
        width?: number | null,
      } >,
      sort?:  {
        __typename: "ListConfigurationSort",
        field: string,
        isAsc: boolean,
      } | null,
    } | null,
    featureFlags:  Array< {
      __typename: "FeatureFlags",
      flagId: string,
      value: boolean,
    } >,
    createdAt: string,
    createdBy: string,
    updatedAt: string,
    statusChangedAt: string,
    statusChangedBy: string,
    ssoDomains?: Array< string | null > | null,
    integrations?: Array< string | null > | null,
    config:  {
      __typename: "TenantConfig",
      requiredSlidesHiddenMessage?: string | null,
      forms?:  Array< {
        __typename: "TenantForm",
        id: string,
        name: string,
        label: string,
        fields:  Array< {
          __typename: "CustomFieldDefinition",
          id: string,
          fieldName: string,
          fieldLabel: string,
          reportingName?: string | null,
          status: FIELD_STATUS,
          description?: string | null,
          required?: boolean | null,
          maxLength?: number | null,
          formatValidation?: FORMAT_VALIDATION | null,
          fieldType: FIELD_DATA_TYPE,
          showByDefault?: boolean | null,
          dependentFieldId?: string | null,
          controlType: CONTROL_TYPE,
          order?: number | null,
          settings?:  {
            __typename: "CommonFieldSettings",
            isBadge?: boolean | null,
            fieldPosition: FIELD_POSITION,
          } | null,
          usage: Array< CUSTOM_FIELD_USAGE >,
          documentSettings?:  {
            __typename: "DocumentFieldSettings",
            fieldPosition: FIELD_POSITION,
          } | null,
          fieldValueDefinitions:  Array< {
            __typename: "CustomFieldValueDefinition",
            id: string,
            value: string,
            label?: string | null,
            disabled?: boolean | null,
            isDefault?: boolean | null,
            documentSettings?:  {
              __typename: "DocumentFieldValueSettings",
              presentationWatermarkText?: string | null,
              permission?:  {
                __typename: "DocumentPermission",
                present?: PERMISSION_EFFECT | null,
                modify?: PERMISSION_EFFECT | null,
                share?: PERMISSION_EFFECT | null,
                download?: PERMISSION_EFFECT | null,
                externalNotation?: PERMISSION_EFFECT | null,
              } | null,
            } | null,
            badgeColor?: string | null,
            badgeLabel?: string | null,
            createdAt: string,
            dependentValueIds: Array< string >,
          } >,
          displayOnParentSelection?: boolean | null,
          displayOnValueSelection?: Array< string > | null,
          objectSetting?:  {
            __typename: "ObjectSetting",
            childrenFieldIds: Array< string >,
            restriction?:  {
              __typename: "ObjectRestriction",
              syncUpdate?: boolean | null,
              syncDelete?: boolean | null,
              update?: boolean | null,
              delete?: boolean | null,
            } | null,
          } | null,
          isChildField?: boolean | null,
          dateRestriction?: DATE_RESTRICTION | null,
          createdAt: string,
          scaleNumber?: number | null,
          precisionNumber?: number | null,
        } >,
        targetRequestTemplate: string,
        targetType: TENANT_FORM_TARGET_TYPE,
        targetAddress: string,
        targetParameters?:  Array< {
          __typename: "TenantFormTargetParameters",
          key: string,
          value: string,
        } > | null,
        status: TENANT_FORM_STATUS,
      } > | null,
      meetingFields?:  Array< {
        __typename: "MeetingFieldDefinition",
        id: string,
        fieldName: string,
        status: FIELD_STATUS,
        description?: string | null,
        required: boolean,
        maxLength?: number | null,
        type: FIELD_DATA_TYPE,
        controlType: CONTROL_TYPE,
        fieldValueDefinitions:  Array< {
          __typename: "MeetingFieldValueDefinition",
          id: string,
          value: string,
          disabled: boolean,
          createdAt: string,
        } >,
        createdAt: string,
      } > | null,
      customFields?:  Array< {
        __typename: "CustomFieldDefinition",
        id: string,
        fieldName: string,
        fieldLabel: string,
        reportingName?: string | null,
        status: FIELD_STATUS,
        description?: string | null,
        required?: boolean | null,
        maxLength?: number | null,
        formatValidation?: FORMAT_VALIDATION | null,
        fieldType: FIELD_DATA_TYPE,
        showByDefault?: boolean | null,
        dependentFieldId?: string | null,
        controlType: CONTROL_TYPE,
        order?: number | null,
        settings?:  {
          __typename: "CommonFieldSettings",
          isBadge?: boolean | null,
          fieldPosition: FIELD_POSITION,
        } | null,
        usage: Array< CUSTOM_FIELD_USAGE >,
        documentSettings?:  {
          __typename: "DocumentFieldSettings",
          fieldPosition: FIELD_POSITION,
        } | null,
        fieldValueDefinitions:  Array< {
          __typename: "CustomFieldValueDefinition",
          id: string,
          value: string,
          label?: string | null,
          disabled?: boolean | null,
          isDefault?: boolean | null,
          documentSettings?:  {
            __typename: "DocumentFieldValueSettings",
            presentationWatermarkText?: string | null,
            permission?:  {
              __typename: "DocumentPermission",
              present?: PERMISSION_EFFECT | null,
              modify?: PERMISSION_EFFECT | null,
              share?: PERMISSION_EFFECT | null,
              download?: PERMISSION_EFFECT | null,
              externalNotation?: PERMISSION_EFFECT | null,
            } | null,
          } | null,
          badgeColor?: string | null,
          badgeLabel?: string | null,
          createdAt: string,
          dependentValueIds: Array< string >,
        } >,
        displayOnParentSelection?: boolean | null,
        displayOnValueSelection?: Array< string > | null,
        objectSetting?:  {
          __typename: "ObjectSetting",
          childrenFieldIds: Array< string >,
          restriction?:  {
            __typename: "ObjectRestriction",
            syncUpdate?: boolean | null,
            syncDelete?: boolean | null,
            update?: boolean | null,
            delete?: boolean | null,
          } | null,
        } | null,
        isChildField?: boolean | null,
        dateRestriction?: DATE_RESTRICTION | null,
        createdAt: string,
        scaleNumber?: number | null,
        precisionNumber?: number | null,
      } > | null,
      defaultDocumentPermissions:  {
        __typename: "DocumentPermission",
        present?: PERMISSION_EFFECT | null,
        modify?: PERMISSION_EFFECT | null,
        share?: PERMISSION_EFFECT | null,
        download?: PERMISSION_EFFECT | null,
        externalNotation?: PERMISSION_EFFECT | null,
      },
      mslListConfig?:  {
        __typename: "ListConfig",
        sort?:  {
          __typename: "ListSort",
          field: string,
          isAsc?: boolean | null,
        } | null,
        fields:  Array< {
          __typename: "FieldListConfig",
          field: string,
          width?: number | null,
        } >,
      } | null,
      publisherListConfig?:  {
        __typename: "ListConfig",
        sort?:  {
          __typename: "ListSort",
          field: string,
          isAsc?: boolean | null,
        } | null,
        fields:  Array< {
          __typename: "FieldListConfig",
          field: string,
          width?: number | null,
        } >,
      } | null,
      crmIntegration?:  {
        __typename: "CRMIntegration",
        id: string,
        crmIntegrationType: CRM_INTEGRATION_TYPE,
        instanceUrl: string,
        clientId: string,
        redirectUri: string,
        name: string,
        accountsSettings:  {
          __typename: "CRMAccountSettings",
          query: string,
          displaySettings:  {
            __typename: "CRMDisplaySettings",
            shortened: string,
            extended?: Array< string > | null,
          },
          addressSettings:  {
            __typename: "CRMAddressSettings",
            alias: string,
            displaySettings:  {
              __typename: "CRMDisplaySettings",
              shortened: string,
              extended?: Array< string > | null,
            },
          },
        },
        meetingSetting?:  {
          __typename: "CRMTableSetting",
          apiName: string,
          presentationsFieldName?: string | null,
          children:  Array< {
            __typename: "CRMChildTableSetting",
            name: string,
            apiName: string,
            relationshipName?: string | null,
            type: CRM_INTEGRATION_TYPE,
            veevaSetting?:  {
              __typename: "VeevaSetting",
              markerFieldName?: string | null,
            } | null,
          } >,
          type: CRM_INTEGRATION_TYPE,
        } | null,
        additionalSettings?:  {
          __typename: "CRMAdditionalSettings",
          allowCommentsOnTasks?: boolean | null,
          detailContentTableSettings?:  {
            __typename: "CRMTableDetailContentPresented",
            name: string,
            apiName: string,
            relationshipName: string,
            prefix?: string | null,
          } | null,
          enableSurveys?: boolean | null,
          crmAccountToObjectSettings?:  Array< {
            __typename: "CRMAccountToObjectSetting",
            accountFieldNames:  Array< {
              __typename: "CRMAccountToObjectFields",
              accountFieldName: string,
              objectFieldName: string,
            } >,
            apiName: string,
          } > | null,
        } | null,
        loginUrl?: string | null,
        crmStandaloneForms?:  Array< {
          __typename: "CRMStandaloneForm",
          id: string,
          apiName: string,
          children:  Array< {
            __typename: "CRMChildTableSetting",
            name: string,
            apiName: string,
            relationshipName?: string | null,
            type: CRM_INTEGRATION_TYPE,
            veevaSetting?:  {
              __typename: "VeevaSetting",
              markerFieldName?: string | null,
            } | null,
          } >,
          type: CRM_INTEGRATION_TYPE,
          parents:  Array< {
            __typename: "CRMStandaloneFormParent",
            apiName: string,
            relationshipName: string,
          } >,
          usage?: Array< STANDALONE_FORM_USAGE > | null,
        } > | null,
      } | null,
      sessionTimeout?: number | null,
      quickFilters?:  Array< {
        __typename: "QuickFilter",
        id: string,
        label: string,
        path: Array< string >,
      } > | null,
      hubsConfig:  {
        __typename: "HubConfig",
        shareExpirationDays?: number | null,
        disabledSections: Array< HUB_SECTION_TYPE >,
        disableNotationDescription: boolean,
      },
      reports:  Array< {
        __typename: "KeyValue",
        key: string,
        value: boolean,
      } >,
      emailDocumentUpdateFrequency?: FREQUENCY | null,
      sharePPTXasPDF?: boolean | null,
    },
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateDocumentVersionSubscriptionVariables = {
  filter?: ModelSubscriptionDocumentVersionFilterInput | null,
  tenantId?: string | null,
};

export type OnCreateDocumentVersionSubscription = {
  onCreateDocumentVersion?:  {
    __typename: "DocumentVersion",
    id: string,
    tenantId: string,
    documentId: string,
    versionNumber: number,
    srcFilename?: string | null,
    contentURL?: string | null,
    conversionStatus: CONVERSION_STATUS,
    status: DOCUMENT_STATUS,
    srcFile?:  {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
      url: string,
    } | null,
    srcHash?: string | null,
    srcSize?: number | null,
    numPages?: number | null,
    pageSettings:  Array< {
      __typename: "PageSetting",
      pageId: string,
      number: number,
      isRequired?: boolean | null,
      linkedSlides: Array< string >,
    } >,
    pageGroups?:  Array< {
      __typename: "PageGroup",
      id: string,
      pageIds?: Array< string > | null,
      name: string,
      locked: boolean,
      sourceID?: string | null,
      source: PAGE_GROUP_SOURCE,
    } > | null,
    type: FILE_TYPE,
    releaseNotes?: string | null,
    changeType?: DOCUMENT_VERSION_CHANGE_TYPE | null,
    labelValues?:  Array< {
      __typename: "LabelValue",
      key: string,
      value: string,
    } > | null,
    customValues?:  Array< {
      __typename: "CustomValues",
      fieldId: string,
      objectRecords?:  Array< {
        __typename: "ObjectRecord",
        id: string,
        externalId?: string | null,
        syncStatus?: CRMSyncStatus | null,
        status: OBJECT_RECORD_STATUS,
        values:  Array< {
          __typename: "ObjectRecordValue",
          fieldId: string,
          values: Array< string >,
        } >,
      } > | null,
      values: Array< string >,
    } > | null,
    title?: string | null,
    shortDescription?: string | null,
    longDescription?: string | null,
    owner?: string | null,
    expiresAt?: string | null,
    hasCopyright?: boolean | null,
    hasCustomThumbnail?: boolean | null,
    purpose?: string | null,
    canHideSlides?: boolean | null,
    distributable?: boolean | null,
    downloadable?: boolean | null,
    isInternalGenerated?: boolean | null,
    semVer?:  {
      __typename: "SemVer",
      minor: number,
      major: number,
    } | null,
    notificationScope?: NOTIFICATION_SCOPE | null,
    selectedThumbnail?: number | null,
    publishedAt?: string | null,
    uploadedAt?: string | null,
    uploadedBy?: string | null,
    convertedArchiveKey?: string | null,
    convertedArchiveSize?: number | null,
    convertedFolderKey?: string | null,
    associatedFiles?:  Array< {
      __typename: "AssociatedFile",
      id: string,
      isDistributable?: boolean | null,
      isDefault?: boolean | null,
      type: ASSOCIATED_FILE_TYPE,
      attachmentId: string,
      status: ASSOCIATED_FILE_STATUS,
      createdAt: string,
      createdBy: string,
    } > | null,
    editPermissions: Array< string >,
    converterVersion?: number | null,
    createdAt: string,
    createdBy: string,
    updatedAt: string,
    updatedBy: string,
    integration?:  {
      __typename: "DocumentVersionIntegration",
      externalVersionId?: string | null,
      version?: string | null,
      timestamp?: string | null,
      srcFileHash?: string | null,
      srcDocumentHash?: string | null,
    } | null,
    integrationType?: INTEGRATION_TYPE | null,
    conversionWarningCode?: CONVERSION_WARNING_CODE | null,
    conversionErrorCode?: string | null,
    scheduledPublish?: string | null,
    mappingStatus?: MAPPING_STATUS | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateDocumentVersionSubscriptionVariables = {
  filter?: ModelSubscriptionDocumentVersionFilterInput | null,
  tenantId?: string | null,
};

export type OnUpdateDocumentVersionSubscription = {
  onUpdateDocumentVersion?:  {
    __typename: "DocumentVersion",
    id: string,
    tenantId: string,
    documentId: string,
    versionNumber: number,
    srcFilename?: string | null,
    contentURL?: string | null,
    conversionStatus: CONVERSION_STATUS,
    status: DOCUMENT_STATUS,
    srcFile?:  {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
      url: string,
    } | null,
    srcHash?: string | null,
    srcSize?: number | null,
    numPages?: number | null,
    pageSettings:  Array< {
      __typename: "PageSetting",
      pageId: string,
      number: number,
      isRequired?: boolean | null,
      linkedSlides: Array< string >,
    } >,
    pageGroups?:  Array< {
      __typename: "PageGroup",
      id: string,
      pageIds?: Array< string > | null,
      name: string,
      locked: boolean,
      sourceID?: string | null,
      source: PAGE_GROUP_SOURCE,
    } > | null,
    type: FILE_TYPE,
    releaseNotes?: string | null,
    changeType?: DOCUMENT_VERSION_CHANGE_TYPE | null,
    labelValues?:  Array< {
      __typename: "LabelValue",
      key: string,
      value: string,
    } > | null,
    customValues?:  Array< {
      __typename: "CustomValues",
      fieldId: string,
      objectRecords?:  Array< {
        __typename: "ObjectRecord",
        id: string,
        externalId?: string | null,
        syncStatus?: CRMSyncStatus | null,
        status: OBJECT_RECORD_STATUS,
        values:  Array< {
          __typename: "ObjectRecordValue",
          fieldId: string,
          values: Array< string >,
        } >,
      } > | null,
      values: Array< string >,
    } > | null,
    title?: string | null,
    shortDescription?: string | null,
    longDescription?: string | null,
    owner?: string | null,
    expiresAt?: string | null,
    hasCopyright?: boolean | null,
    hasCustomThumbnail?: boolean | null,
    purpose?: string | null,
    canHideSlides?: boolean | null,
    distributable?: boolean | null,
    downloadable?: boolean | null,
    isInternalGenerated?: boolean | null,
    semVer?:  {
      __typename: "SemVer",
      minor: number,
      major: number,
    } | null,
    notificationScope?: NOTIFICATION_SCOPE | null,
    selectedThumbnail?: number | null,
    publishedAt?: string | null,
    uploadedAt?: string | null,
    uploadedBy?: string | null,
    convertedArchiveKey?: string | null,
    convertedArchiveSize?: number | null,
    convertedFolderKey?: string | null,
    associatedFiles?:  Array< {
      __typename: "AssociatedFile",
      id: string,
      isDistributable?: boolean | null,
      isDefault?: boolean | null,
      type: ASSOCIATED_FILE_TYPE,
      attachmentId: string,
      status: ASSOCIATED_FILE_STATUS,
      createdAt: string,
      createdBy: string,
    } > | null,
    editPermissions: Array< string >,
    converterVersion?: number | null,
    createdAt: string,
    createdBy: string,
    updatedAt: string,
    updatedBy: string,
    integration?:  {
      __typename: "DocumentVersionIntegration",
      externalVersionId?: string | null,
      version?: string | null,
      timestamp?: string | null,
      srcFileHash?: string | null,
      srcDocumentHash?: string | null,
    } | null,
    integrationType?: INTEGRATION_TYPE | null,
    conversionWarningCode?: CONVERSION_WARNING_CODE | null,
    conversionErrorCode?: string | null,
    scheduledPublish?: string | null,
    mappingStatus?: MAPPING_STATUS | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteDocumentVersionSubscriptionVariables = {
  filter?: ModelSubscriptionDocumentVersionFilterInput | null,
  tenantId?: string | null,
};

export type OnDeleteDocumentVersionSubscription = {
  onDeleteDocumentVersion?:  {
    __typename: "DocumentVersion",
    id: string,
    tenantId: string,
    documentId: string,
    versionNumber: number,
    srcFilename?: string | null,
    contentURL?: string | null,
    conversionStatus: CONVERSION_STATUS,
    status: DOCUMENT_STATUS,
    srcFile?:  {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
      url: string,
    } | null,
    srcHash?: string | null,
    srcSize?: number | null,
    numPages?: number | null,
    pageSettings:  Array< {
      __typename: "PageSetting",
      pageId: string,
      number: number,
      isRequired?: boolean | null,
      linkedSlides: Array< string >,
    } >,
    pageGroups?:  Array< {
      __typename: "PageGroup",
      id: string,
      pageIds?: Array< string > | null,
      name: string,
      locked: boolean,
      sourceID?: string | null,
      source: PAGE_GROUP_SOURCE,
    } > | null,
    type: FILE_TYPE,
    releaseNotes?: string | null,
    changeType?: DOCUMENT_VERSION_CHANGE_TYPE | null,
    labelValues?:  Array< {
      __typename: "LabelValue",
      key: string,
      value: string,
    } > | null,
    customValues?:  Array< {
      __typename: "CustomValues",
      fieldId: string,
      objectRecords?:  Array< {
        __typename: "ObjectRecord",
        id: string,
        externalId?: string | null,
        syncStatus?: CRMSyncStatus | null,
        status: OBJECT_RECORD_STATUS,
        values:  Array< {
          __typename: "ObjectRecordValue",
          fieldId: string,
          values: Array< string >,
        } >,
      } > | null,
      values: Array< string >,
    } > | null,
    title?: string | null,
    shortDescription?: string | null,
    longDescription?: string | null,
    owner?: string | null,
    expiresAt?: string | null,
    hasCopyright?: boolean | null,
    hasCustomThumbnail?: boolean | null,
    purpose?: string | null,
    canHideSlides?: boolean | null,
    distributable?: boolean | null,
    downloadable?: boolean | null,
    isInternalGenerated?: boolean | null,
    semVer?:  {
      __typename: "SemVer",
      minor: number,
      major: number,
    } | null,
    notificationScope?: NOTIFICATION_SCOPE | null,
    selectedThumbnail?: number | null,
    publishedAt?: string | null,
    uploadedAt?: string | null,
    uploadedBy?: string | null,
    convertedArchiveKey?: string | null,
    convertedArchiveSize?: number | null,
    convertedFolderKey?: string | null,
    associatedFiles?:  Array< {
      __typename: "AssociatedFile",
      id: string,
      isDistributable?: boolean | null,
      isDefault?: boolean | null,
      type: ASSOCIATED_FILE_TYPE,
      attachmentId: string,
      status: ASSOCIATED_FILE_STATUS,
      createdAt: string,
      createdBy: string,
    } > | null,
    editPermissions: Array< string >,
    converterVersion?: number | null,
    createdAt: string,
    createdBy: string,
    updatedAt: string,
    updatedBy: string,
    integration?:  {
      __typename: "DocumentVersionIntegration",
      externalVersionId?: string | null,
      version?: string | null,
      timestamp?: string | null,
      srcFileHash?: string | null,
      srcDocumentHash?: string | null,
    } | null,
    integrationType?: INTEGRATION_TYPE | null,
    conversionWarningCode?: CONVERSION_WARNING_CODE | null,
    conversionErrorCode?: string | null,
    scheduledPublish?: string | null,
    mappingStatus?: MAPPING_STATUS | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateAttachedFileSubscriptionVariables = {
  filter?: ModelSubscriptionAttachedFileFilterInput | null,
  tenantId?: string | null,
};

export type OnCreateAttachedFileSubscription = {
  onCreateAttachedFile?:  {
    __typename: "AttachedFile",
    id: string,
    title: string,
    tenantId: string,
    documentId?: string | null,
    srcFile:  {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
      url: string,
    },
    srcFileName: string,
    srcHash?: string | null,
    srcSize: number,
    type: FILE_TYPE,
    editPermissions: Array< string >,
    createdAt: string,
    createdBy: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateAttachedFileSubscriptionVariables = {
  filter?: ModelSubscriptionAttachedFileFilterInput | null,
  tenantId?: string | null,
};

export type OnUpdateAttachedFileSubscription = {
  onUpdateAttachedFile?:  {
    __typename: "AttachedFile",
    id: string,
    title: string,
    tenantId: string,
    documentId?: string | null,
    srcFile:  {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
      url: string,
    },
    srcFileName: string,
    srcHash?: string | null,
    srcSize: number,
    type: FILE_TYPE,
    editPermissions: Array< string >,
    createdAt: string,
    createdBy: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteAttachedFileSubscriptionVariables = {
  filter?: ModelSubscriptionAttachedFileFilterInput | null,
  tenantId?: string | null,
};

export type OnDeleteAttachedFileSubscription = {
  onDeleteAttachedFile?:  {
    __typename: "AttachedFile",
    id: string,
    title: string,
    tenantId: string,
    documentId?: string | null,
    srcFile:  {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
      url: string,
    },
    srcFileName: string,
    srcHash?: string | null,
    srcSize: number,
    type: FILE_TYPE,
    editPermissions: Array< string >,
    createdAt: string,
    createdBy: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateDocumentSubscriptionVariables = {
  filter?: ModelSubscriptionDocumentFilterInput | null,
  tenantId?: string | null,
};

export type OnCreateDocumentSubscription = {
  onCreateDocument?:  {
    __typename: "Document",
    id: string,
    tenantId: string,
    accessLevel: DOCUMENT_ACCESS_LEVEL,
    status: DOCUMENT_STATUS,
    owner: string,
    expiresAt?: string | null,
    integration?:  {
      __typename: "DocumentIntegration",
      integrationId?: string | null,
      externalId?: string | null,
      firstSync?: string | null,
      lastSync?: string | null,
    } | null,
    integrationType?: INTEGRATION_TYPE | null,
    type: FILE_TYPE,
    editPermissions?: Array< string > | null,
    createdAt: string,
    createdBy: string,
    updatedAt: string,
    updatedBy: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateDocumentSubscriptionVariables = {
  filter?: ModelSubscriptionDocumentFilterInput | null,
  tenantId?: string | null,
};

export type OnUpdateDocumentSubscription = {
  onUpdateDocument?:  {
    __typename: "Document",
    id: string,
    tenantId: string,
    accessLevel: DOCUMENT_ACCESS_LEVEL,
    status: DOCUMENT_STATUS,
    owner: string,
    expiresAt?: string | null,
    integration?:  {
      __typename: "DocumentIntegration",
      integrationId?: string | null,
      externalId?: string | null,
      firstSync?: string | null,
      lastSync?: string | null,
    } | null,
    integrationType?: INTEGRATION_TYPE | null,
    type: FILE_TYPE,
    editPermissions?: Array< string > | null,
    createdAt: string,
    createdBy: string,
    updatedAt: string,
    updatedBy: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteDocumentSubscriptionVariables = {
  filter?: ModelSubscriptionDocumentFilterInput | null,
  tenantId?: string | null,
};

export type OnDeleteDocumentSubscription = {
  onDeleteDocument?:  {
    __typename: "Document",
    id: string,
    tenantId: string,
    accessLevel: DOCUMENT_ACCESS_LEVEL,
    status: DOCUMENT_STATUS,
    owner: string,
    expiresAt?: string | null,
    integration?:  {
      __typename: "DocumentIntegration",
      integrationId?: string | null,
      externalId?: string | null,
      firstSync?: string | null,
      lastSync?: string | null,
    } | null,
    integrationType?: INTEGRATION_TYPE | null,
    type: FILE_TYPE,
    editPermissions?: Array< string > | null,
    createdAt: string,
    createdBy: string,
    updatedAt: string,
    updatedBy: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateEmailTemplateSubscriptionVariables = {
  filter?: ModelSubscriptionEmailTemplateFilterInput | null,
  tenantId?: string | null,
};

export type OnCreateEmailTemplateSubscription = {
  onCreateEmailTemplate?:  {
    __typename: "EmailTemplate",
    id: string,
    tenantId: string,
    labelValues?:  Array< {
      __typename: "LabelValue",
      key: string,
      value: string,
    } > | null,
    customFilterValues?:  Array< {
      __typename: "CustomValues",
      fieldId: string,
      objectRecords?:  Array< {
        __typename: "ObjectRecord",
        id: string,
        externalId?: string | null,
        syncStatus?: CRMSyncStatus | null,
        status: OBJECT_RECORD_STATUS,
        values:  Array< {
          __typename: "ObjectRecordValue",
          fieldId: string,
          values: Array< string >,
        } >,
      } > | null,
      values: Array< string >,
    } > | null,
    subject?: string | null,
    title?: string | null,
    cc?: Array< string > | null,
    bcc?: Array< string > | null,
    body?: string | null,
    associatedFiles?:  Array< {
      __typename: "AssociatedFile",
      id: string,
      isDistributable?: boolean | null,
      isDefault?: boolean | null,
      type: ASSOCIATED_FILE_TYPE,
      attachmentId: string,
      status: ASSOCIATED_FILE_STATUS,
      createdAt: string,
      createdBy: string,
    } > | null,
    status: EMAIL_TEMPLATE_STATUS,
    editPermissions?: Array< string > | null,
    createdBy: string,
    createdAt: string,
    updatedBy: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateEmailTemplateSubscriptionVariables = {
  filter?: ModelSubscriptionEmailTemplateFilterInput | null,
  tenantId?: string | null,
};

export type OnUpdateEmailTemplateSubscription = {
  onUpdateEmailTemplate?:  {
    __typename: "EmailTemplate",
    id: string,
    tenantId: string,
    labelValues?:  Array< {
      __typename: "LabelValue",
      key: string,
      value: string,
    } > | null,
    customFilterValues?:  Array< {
      __typename: "CustomValues",
      fieldId: string,
      objectRecords?:  Array< {
        __typename: "ObjectRecord",
        id: string,
        externalId?: string | null,
        syncStatus?: CRMSyncStatus | null,
        status: OBJECT_RECORD_STATUS,
        values:  Array< {
          __typename: "ObjectRecordValue",
          fieldId: string,
          values: Array< string >,
        } >,
      } > | null,
      values: Array< string >,
    } > | null,
    subject?: string | null,
    title?: string | null,
    cc?: Array< string > | null,
    bcc?: Array< string > | null,
    body?: string | null,
    associatedFiles?:  Array< {
      __typename: "AssociatedFile",
      id: string,
      isDistributable?: boolean | null,
      isDefault?: boolean | null,
      type: ASSOCIATED_FILE_TYPE,
      attachmentId: string,
      status: ASSOCIATED_FILE_STATUS,
      createdAt: string,
      createdBy: string,
    } > | null,
    status: EMAIL_TEMPLATE_STATUS,
    editPermissions?: Array< string > | null,
    createdBy: string,
    createdAt: string,
    updatedBy: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteEmailTemplateSubscriptionVariables = {
  filter?: ModelSubscriptionEmailTemplateFilterInput | null,
  tenantId?: string | null,
};

export type OnDeleteEmailTemplateSubscription = {
  onDeleteEmailTemplate?:  {
    __typename: "EmailTemplate",
    id: string,
    tenantId: string,
    labelValues?:  Array< {
      __typename: "LabelValue",
      key: string,
      value: string,
    } > | null,
    customFilterValues?:  Array< {
      __typename: "CustomValues",
      fieldId: string,
      objectRecords?:  Array< {
        __typename: "ObjectRecord",
        id: string,
        externalId?: string | null,
        syncStatus?: CRMSyncStatus | null,
        status: OBJECT_RECORD_STATUS,
        values:  Array< {
          __typename: "ObjectRecordValue",
          fieldId: string,
          values: Array< string >,
        } >,
      } > | null,
      values: Array< string >,
    } > | null,
    subject?: string | null,
    title?: string | null,
    cc?: Array< string > | null,
    bcc?: Array< string > | null,
    body?: string | null,
    associatedFiles?:  Array< {
      __typename: "AssociatedFile",
      id: string,
      isDistributable?: boolean | null,
      isDefault?: boolean | null,
      type: ASSOCIATED_FILE_TYPE,
      attachmentId: string,
      status: ASSOCIATED_FILE_STATUS,
      createdAt: string,
      createdBy: string,
    } > | null,
    status: EMAIL_TEMPLATE_STATUS,
    editPermissions?: Array< string > | null,
    createdBy: string,
    createdAt: string,
    updatedBy: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateDocumentSettingSubscriptionVariables = {
  filter?: ModelSubscriptionDocumentSettingFilterInput | null,
  createdBy?: string | null,
};

export type OnCreateDocumentSettingSubscription = {
  onCreateDocumentSetting?:  {
    __typename: "DocumentSetting",
    id: string,
    tenantId: string,
    createdAt: string,
    createdBy: string,
    updatedAt: string,
    updatedBy: string,
    documentId: string,
    documentVersionId: string,
    notation:  Array< {
      __typename: "Notation",
      id: string,
      type: NOTATION_TYPE,
      description?: string | null,
      status: HUB_SECTION_ITEM_STATUS,
      pageId: string,
      coordinate?:  Array< {
        __typename: "Coordinate",
        x: number,
        y: number,
      } > | null,
      createdAt: string,
      createdBy: string,
      updatedAt: string,
      updatedBy: string,
    } >,
    status: USER_NOTATIONS_STATUS,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateDocumentSettingSubscriptionVariables = {
  filter?: ModelSubscriptionDocumentSettingFilterInput | null,
  createdBy?: string | null,
};

export type OnUpdateDocumentSettingSubscription = {
  onUpdateDocumentSetting?:  {
    __typename: "DocumentSetting",
    id: string,
    tenantId: string,
    createdAt: string,
    createdBy: string,
    updatedAt: string,
    updatedBy: string,
    documentId: string,
    documentVersionId: string,
    notation:  Array< {
      __typename: "Notation",
      id: string,
      type: NOTATION_TYPE,
      description?: string | null,
      status: HUB_SECTION_ITEM_STATUS,
      pageId: string,
      coordinate?:  Array< {
        __typename: "Coordinate",
        x: number,
        y: number,
      } > | null,
      createdAt: string,
      createdBy: string,
      updatedAt: string,
      updatedBy: string,
    } >,
    status: USER_NOTATIONS_STATUS,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteDocumentSettingSubscriptionVariables = {
  filter?: ModelSubscriptionDocumentSettingFilterInput | null,
  createdBy?: string | null,
};

export type OnDeleteDocumentSettingSubscription = {
  onDeleteDocumentSetting?:  {
    __typename: "DocumentSetting",
    id: string,
    tenantId: string,
    createdAt: string,
    createdBy: string,
    updatedAt: string,
    updatedBy: string,
    documentId: string,
    documentVersionId: string,
    notation:  Array< {
      __typename: "Notation",
      id: string,
      type: NOTATION_TYPE,
      description?: string | null,
      status: HUB_SECTION_ITEM_STATUS,
      pageId: string,
      coordinate?:  Array< {
        __typename: "Coordinate",
        x: number,
        y: number,
      } > | null,
      createdAt: string,
      createdBy: string,
      updatedAt: string,
      updatedBy: string,
    } >,
    status: USER_NOTATIONS_STATUS,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateUserNotationsSubscriptionVariables = {
  filter?: ModelSubscriptionUserNotationsFilterInput | null,
  createdBy?: string | null,
};

export type OnCreateUserNotationsSubscription = {
  onCreateUserNotations?:  {
    __typename: "UserNotations",
    id: string,
    tenantId: string,
    createdAt: string,
    createdBy: string,
    updatedAt: string,
    updatedBy: string,
    documentId?: string | null,
    documentVersionId?: string | null,
    customDeckId?: string | null,
    notation:  Array< {
      __typename: "Notation",
      id: string,
      type: NOTATION_TYPE,
      description?: string | null,
      status: HUB_SECTION_ITEM_STATUS,
      pageId: string,
      coordinate?:  Array< {
        __typename: "Coordinate",
        x: number,
        y: number,
      } > | null,
      createdAt: string,
      createdBy: string,
      updatedAt: string,
      updatedBy: string,
    } >,
    status: USER_NOTATIONS_STATUS,
    type: USER_NOTATIONS_TYPE,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateUserNotationsSubscriptionVariables = {
  filter?: ModelSubscriptionUserNotationsFilterInput | null,
  createdBy?: string | null,
};

export type OnUpdateUserNotationsSubscription = {
  onUpdateUserNotations?:  {
    __typename: "UserNotations",
    id: string,
    tenantId: string,
    createdAt: string,
    createdBy: string,
    updatedAt: string,
    updatedBy: string,
    documentId?: string | null,
    documentVersionId?: string | null,
    customDeckId?: string | null,
    notation:  Array< {
      __typename: "Notation",
      id: string,
      type: NOTATION_TYPE,
      description?: string | null,
      status: HUB_SECTION_ITEM_STATUS,
      pageId: string,
      coordinate?:  Array< {
        __typename: "Coordinate",
        x: number,
        y: number,
      } > | null,
      createdAt: string,
      createdBy: string,
      updatedAt: string,
      updatedBy: string,
    } >,
    status: USER_NOTATIONS_STATUS,
    type: USER_NOTATIONS_TYPE,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteUserNotationsSubscriptionVariables = {
  filter?: ModelSubscriptionUserNotationsFilterInput | null,
  createdBy?: string | null,
};

export type OnDeleteUserNotationsSubscription = {
  onDeleteUserNotations?:  {
    __typename: "UserNotations",
    id: string,
    tenantId: string,
    createdAt: string,
    createdBy: string,
    updatedAt: string,
    updatedBy: string,
    documentId?: string | null,
    documentVersionId?: string | null,
    customDeckId?: string | null,
    notation:  Array< {
      __typename: "Notation",
      id: string,
      type: NOTATION_TYPE,
      description?: string | null,
      status: HUB_SECTION_ITEM_STATUS,
      pageId: string,
      coordinate?:  Array< {
        __typename: "Coordinate",
        x: number,
        y: number,
      } > | null,
      createdAt: string,
      createdBy: string,
      updatedAt: string,
      updatedBy: string,
    } >,
    status: USER_NOTATIONS_STATUS,
    type: USER_NOTATIONS_TYPE,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateCustomDeckSubscriptionVariables = {
  filter?: ModelSubscriptionCustomDeckFilterInput | null,
  createdBy?: string | null,
};

export type OnCreateCustomDeckSubscription = {
  onCreateCustomDeck?:  {
    __typename: "CustomDeck",
    id: string,
    createdAt: string,
    createdBy: string,
    autoUpdateAcknowledgedAt?: string | null,
    updatedAt: string,
    updatedBy: string,
    tenantId: string,
    groups:  Array< {
      __typename: "CustomDeckGroup",
      id: string,
      pages:  Array< {
        __typename: "CustomDeckPage",
        pageId: string,
        pageNumber: number,
        documentVersionId: string,
      } >,
      visible: boolean,
      srcId?: string | null,
      docAccessLevel: DOCUMENT_ACCESS_LEVEL,
      name?: string | null,
      locked?: boolean | null,
    } >,
    title: string,
    editMutex?:  {
      __typename: "EditMutex",
      userId: string,
      timeStarted: string,
      lastSeenAt: string,
    } | null,
    sourceCustomDeckId?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateCustomDeckSubscriptionVariables = {
  filter?: ModelSubscriptionCustomDeckFilterInput | null,
  createdBy?: string | null,
};

export type OnUpdateCustomDeckSubscription = {
  onUpdateCustomDeck?:  {
    __typename: "CustomDeck",
    id: string,
    createdAt: string,
    createdBy: string,
    autoUpdateAcknowledgedAt?: string | null,
    updatedAt: string,
    updatedBy: string,
    tenantId: string,
    groups:  Array< {
      __typename: "CustomDeckGroup",
      id: string,
      pages:  Array< {
        __typename: "CustomDeckPage",
        pageId: string,
        pageNumber: number,
        documentVersionId: string,
      } >,
      visible: boolean,
      srcId?: string | null,
      docAccessLevel: DOCUMENT_ACCESS_LEVEL,
      name?: string | null,
      locked?: boolean | null,
    } >,
    title: string,
    editMutex?:  {
      __typename: "EditMutex",
      userId: string,
      timeStarted: string,
      lastSeenAt: string,
    } | null,
    sourceCustomDeckId?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteCustomDeckSubscriptionVariables = {
  filter?: ModelSubscriptionCustomDeckFilterInput | null,
  createdBy?: string | null,
};

export type OnDeleteCustomDeckSubscription = {
  onDeleteCustomDeck?:  {
    __typename: "CustomDeck",
    id: string,
    createdAt: string,
    createdBy: string,
    autoUpdateAcknowledgedAt?: string | null,
    updatedAt: string,
    updatedBy: string,
    tenantId: string,
    groups:  Array< {
      __typename: "CustomDeckGroup",
      id: string,
      pages:  Array< {
        __typename: "CustomDeckPage",
        pageId: string,
        pageNumber: number,
        documentVersionId: string,
      } >,
      visible: boolean,
      srcId?: string | null,
      docAccessLevel: DOCUMENT_ACCESS_LEVEL,
      name?: string | null,
      locked?: boolean | null,
    } >,
    title: string,
    editMutex?:  {
      __typename: "EditMutex",
      userId: string,
      timeStarted: string,
      lastSeenAt: string,
    } | null,
    sourceCustomDeckId?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateFolderSubscriptionVariables = {
  filter?: ModelSubscriptionFolderFilterInput | null,
  createdBy?: string | null,
};

export type OnCreateFolderSubscription = {
  onCreateFolder?:  {
    __typename: "Folder",
    id: string,
    tenantId: string,
    name: string,
    isPinned?: boolean | null,
    status: FOLDER_STATUS,
    items:  Array< {
      __typename: "FolderItem",
      id: string,
      type: FOLDER_ITEM_TYPE,
      itemId: string,
      status?: FOLDER_ITEM_STATUS | null,
      addedAt: string,
      updateAcknowledgedAt?: string | null,
      itemLastUpdatedAt: string,
      visiblePages?: Array< number > | null,
      customTitle?: string | null,
    } >,
    createdAt: string,
    createdBy: string,
    updatedAt: string,
    updatedBy: string,
    sharePermissions?:  Array< {
      __typename: "SharePermission",
      id: string,
      isDeleted?: boolean | null,
      type: PERMISSION_TYPE,
      targetType: SHARE_TARGET_TYPE,
      targetUsername?: string | null,
      targetCustomValues?:  Array< {
        __typename: "CustomValues",
        fieldId: string,
        objectRecords?:  Array< {
          __typename: "ObjectRecord",
          id: string,
          externalId?: string | null,
          syncStatus?: CRMSyncStatus | null,
          status: OBJECT_RECORD_STATUS,
          values:  Array< {
            __typename: "ObjectRecordValue",
            fieldId: string,
            values: Array< string >,
          } >,
        } > | null,
        values: Array< string >,
      } > | null,
      targetLabels?:  Array< {
        __typename: "LabelValues",
        key: string,
        values: Array< string >,
      } > | null,
      createdAt: string,
      createdBy: string,
      updatedAt: string,
      updatedBy: string,
      deletedBy?: string | null,
      deletedAt?: string | null,
    } > | null,
    shareStatus: SHARE_STATUS,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateFolderSubscriptionVariables = {
  filter?: ModelSubscriptionFolderFilterInput | null,
  createdBy?: string | null,
};

export type OnUpdateFolderSubscription = {
  onUpdateFolder?:  {
    __typename: "Folder",
    id: string,
    tenantId: string,
    name: string,
    isPinned?: boolean | null,
    status: FOLDER_STATUS,
    items:  Array< {
      __typename: "FolderItem",
      id: string,
      type: FOLDER_ITEM_TYPE,
      itemId: string,
      status?: FOLDER_ITEM_STATUS | null,
      addedAt: string,
      updateAcknowledgedAt?: string | null,
      itemLastUpdatedAt: string,
      visiblePages?: Array< number > | null,
      customTitle?: string | null,
    } >,
    createdAt: string,
    createdBy: string,
    updatedAt: string,
    updatedBy: string,
    sharePermissions?:  Array< {
      __typename: "SharePermission",
      id: string,
      isDeleted?: boolean | null,
      type: PERMISSION_TYPE,
      targetType: SHARE_TARGET_TYPE,
      targetUsername?: string | null,
      targetCustomValues?:  Array< {
        __typename: "CustomValues",
        fieldId: string,
        objectRecords?:  Array< {
          __typename: "ObjectRecord",
          id: string,
          externalId?: string | null,
          syncStatus?: CRMSyncStatus | null,
          status: OBJECT_RECORD_STATUS,
          values:  Array< {
            __typename: "ObjectRecordValue",
            fieldId: string,
            values: Array< string >,
          } >,
        } > | null,
        values: Array< string >,
      } > | null,
      targetLabels?:  Array< {
        __typename: "LabelValues",
        key: string,
        values: Array< string >,
      } > | null,
      createdAt: string,
      createdBy: string,
      updatedAt: string,
      updatedBy: string,
      deletedBy?: string | null,
      deletedAt?: string | null,
    } > | null,
    shareStatus: SHARE_STATUS,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteFolderSubscriptionVariables = {
  filter?: ModelSubscriptionFolderFilterInput | null,
  createdBy?: string | null,
};

export type OnDeleteFolderSubscription = {
  onDeleteFolder?:  {
    __typename: "Folder",
    id: string,
    tenantId: string,
    name: string,
    isPinned?: boolean | null,
    status: FOLDER_STATUS,
    items:  Array< {
      __typename: "FolderItem",
      id: string,
      type: FOLDER_ITEM_TYPE,
      itemId: string,
      status?: FOLDER_ITEM_STATUS | null,
      addedAt: string,
      updateAcknowledgedAt?: string | null,
      itemLastUpdatedAt: string,
      visiblePages?: Array< number > | null,
      customTitle?: string | null,
    } >,
    createdAt: string,
    createdBy: string,
    updatedAt: string,
    updatedBy: string,
    sharePermissions?:  Array< {
      __typename: "SharePermission",
      id: string,
      isDeleted?: boolean | null,
      type: PERMISSION_TYPE,
      targetType: SHARE_TARGET_TYPE,
      targetUsername?: string | null,
      targetCustomValues?:  Array< {
        __typename: "CustomValues",
        fieldId: string,
        objectRecords?:  Array< {
          __typename: "ObjectRecord",
          id: string,
          externalId?: string | null,
          syncStatus?: CRMSyncStatus | null,
          status: OBJECT_RECORD_STATUS,
          values:  Array< {
            __typename: "ObjectRecordValue",
            fieldId: string,
            values: Array< string >,
          } >,
        } > | null,
        values: Array< string >,
      } > | null,
      targetLabels?:  Array< {
        __typename: "LabelValues",
        key: string,
        values: Array< string >,
      } > | null,
      createdAt: string,
      createdBy: string,
      updatedAt: string,
      updatedBy: string,
      deletedBy?: string | null,
      deletedAt?: string | null,
    } > | null,
    shareStatus: SHARE_STATUS,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateContentShareSubscriptionVariables = {
  filter?: ModelSubscriptionContentShareFilterInput | null,
  createdBy?: string | null,
};

export type OnCreateContentShareSubscription = {
  onCreateContentShare?:  {
    __typename: "ContentShare",
    id: string,
    token: string,
    tenantId: string,
    type: SHARE_TYPE,
    contentId: string,
    meetingId?: string | null,
    expiresAt: string,
    createdAt: string,
    updatedAt: string,
    createdBy: string,
    customValues:  Array< {
      __typename: "CustomValues",
      fieldId: string,
      objectRecords?:  Array< {
        __typename: "ObjectRecord",
        id: string,
        externalId?: string | null,
        syncStatus?: CRMSyncStatus | null,
        status: OBJECT_RECORD_STATUS,
        values:  Array< {
          __typename: "ObjectRecordValue",
          fieldId: string,
          values: Array< string >,
        } >,
      } > | null,
      values: Array< string >,
    } >,
    revoked?: boolean | null,
    customDeckDependencies?:  {
      __typename: "CustomDeckDependencies",
      versions: Array< string >,
      folderId: string,
    } | null,
    notificationScope?: SHARE_NOTIFICATION_SCOPE | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateContentShareSubscriptionVariables = {
  filter?: ModelSubscriptionContentShareFilterInput | null,
  createdBy?: string | null,
};

export type OnUpdateContentShareSubscription = {
  onUpdateContentShare?:  {
    __typename: "ContentShare",
    id: string,
    token: string,
    tenantId: string,
    type: SHARE_TYPE,
    contentId: string,
    meetingId?: string | null,
    expiresAt: string,
    createdAt: string,
    updatedAt: string,
    createdBy: string,
    customValues:  Array< {
      __typename: "CustomValues",
      fieldId: string,
      objectRecords?:  Array< {
        __typename: "ObjectRecord",
        id: string,
        externalId?: string | null,
        syncStatus?: CRMSyncStatus | null,
        status: OBJECT_RECORD_STATUS,
        values:  Array< {
          __typename: "ObjectRecordValue",
          fieldId: string,
          values: Array< string >,
        } >,
      } > | null,
      values: Array< string >,
    } >,
    revoked?: boolean | null,
    customDeckDependencies?:  {
      __typename: "CustomDeckDependencies",
      versions: Array< string >,
      folderId: string,
    } | null,
    notificationScope?: SHARE_NOTIFICATION_SCOPE | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteContentShareSubscriptionVariables = {
  filter?: ModelSubscriptionContentShareFilterInput | null,
  createdBy?: string | null,
};

export type OnDeleteContentShareSubscription = {
  onDeleteContentShare?:  {
    __typename: "ContentShare",
    id: string,
    token: string,
    tenantId: string,
    type: SHARE_TYPE,
    contentId: string,
    meetingId?: string | null,
    expiresAt: string,
    createdAt: string,
    updatedAt: string,
    createdBy: string,
    customValues:  Array< {
      __typename: "CustomValues",
      fieldId: string,
      objectRecords?:  Array< {
        __typename: "ObjectRecord",
        id: string,
        externalId?: string | null,
        syncStatus?: CRMSyncStatus | null,
        status: OBJECT_RECORD_STATUS,
        values:  Array< {
          __typename: "ObjectRecordValue",
          fieldId: string,
          values: Array< string >,
        } >,
      } > | null,
      values: Array< string >,
    } >,
    revoked?: boolean | null,
    customDeckDependencies?:  {
      __typename: "CustomDeckDependencies",
      versions: Array< string >,
      folderId: string,
    } | null,
    notificationScope?: SHARE_NOTIFICATION_SCOPE | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateIntegrationLogSubscriptionVariables = {
  filter?: ModelSubscriptionIntegrationLogFilterInput | null,
};

export type OnCreateIntegrationLogSubscription = {
  onCreateIntegrationLog?:  {
    __typename: "IntegrationLog",
    integrationId: string,
    id: string,
    log:  Array< {
      __typename: "IntegrationLogEntry",
      level: LOG_LEVEL,
      timestamp: string,
      message: string,
      srcDocId: string,
      srcDocVersion: string,
    } | null >,
    status: INTEGRATION_RUN_STATUS,
    tenantId: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateIntegrationLogSubscriptionVariables = {
  filter?: ModelSubscriptionIntegrationLogFilterInput | null,
};

export type OnUpdateIntegrationLogSubscription = {
  onUpdateIntegrationLog?:  {
    __typename: "IntegrationLog",
    integrationId: string,
    id: string,
    log:  Array< {
      __typename: "IntegrationLogEntry",
      level: LOG_LEVEL,
      timestamp: string,
      message: string,
      srcDocId: string,
      srcDocVersion: string,
    } | null >,
    status: INTEGRATION_RUN_STATUS,
    tenantId: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteIntegrationLogSubscriptionVariables = {
  filter?: ModelSubscriptionIntegrationLogFilterInput | null,
};

export type OnDeleteIntegrationLogSubscription = {
  onDeleteIntegrationLog?:  {
    __typename: "IntegrationLog",
    integrationId: string,
    id: string,
    log:  Array< {
      __typename: "IntegrationLogEntry",
      level: LOG_LEVEL,
      timestamp: string,
      message: string,
      srcDocId: string,
      srcDocVersion: string,
    } | null >,
    status: INTEGRATION_RUN_STATUS,
    tenantId: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateIntegrationSettingsSubscriptionVariables = {
  filter?: ModelSubscriptionIntegrationSettingsFilterInput | null,
};

export type OnCreateIntegrationSettingsSubscription = {
  onCreateIntegrationSettings?:  {
    __typename: "IntegrationSettings",
    id: string,
    tenantId: string,
    syncContentEvery: number,
    name?: string | null,
    mapping:  Array< {
      __typename: "IntegrationFieldMapping",
      key: string,
      fieldType: string,
      dataType: string,
      targetFieldName: string,
      srcFieldname: string,
      valueMappings?:  Array< {
        __typename: "IntegrationValueMapping",
        srcValue: string,
        targetValue: string,
      } | null > | null,
    } | null >,
    clientConfigurationFields:  Array< {
      __typename: "IntegrationClientConfigurationTuple",
      key: string,
      value: string,
    } | null >,
    integrationType?: INTEGRATION_TYPE | null,
    enabled?: boolean | null,
    notificationEmail?: string | null,
    errorSyncEmail?: string | null,
    createdAt: string,
    createdBy: string,
    updatedAt: string,
    updatedBy: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateIntegrationSettingsSubscriptionVariables = {
  filter?: ModelSubscriptionIntegrationSettingsFilterInput | null,
};

export type OnUpdateIntegrationSettingsSubscription = {
  onUpdateIntegrationSettings?:  {
    __typename: "IntegrationSettings",
    id: string,
    tenantId: string,
    syncContentEvery: number,
    name?: string | null,
    mapping:  Array< {
      __typename: "IntegrationFieldMapping",
      key: string,
      fieldType: string,
      dataType: string,
      targetFieldName: string,
      srcFieldname: string,
      valueMappings?:  Array< {
        __typename: "IntegrationValueMapping",
        srcValue: string,
        targetValue: string,
      } | null > | null,
    } | null >,
    clientConfigurationFields:  Array< {
      __typename: "IntegrationClientConfigurationTuple",
      key: string,
      value: string,
    } | null >,
    integrationType?: INTEGRATION_TYPE | null,
    enabled?: boolean | null,
    notificationEmail?: string | null,
    errorSyncEmail?: string | null,
    createdAt: string,
    createdBy: string,
    updatedAt: string,
    updatedBy: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteIntegrationSettingsSubscriptionVariables = {
  filter?: ModelSubscriptionIntegrationSettingsFilterInput | null,
};

export type OnDeleteIntegrationSettingsSubscription = {
  onDeleteIntegrationSettings?:  {
    __typename: "IntegrationSettings",
    id: string,
    tenantId: string,
    syncContentEvery: number,
    name?: string | null,
    mapping:  Array< {
      __typename: "IntegrationFieldMapping",
      key: string,
      fieldType: string,
      dataType: string,
      targetFieldName: string,
      srcFieldname: string,
      valueMappings?:  Array< {
        __typename: "IntegrationValueMapping",
        srcValue: string,
        targetValue: string,
      } | null > | null,
    } | null >,
    clientConfigurationFields:  Array< {
      __typename: "IntegrationClientConfigurationTuple",
      key: string,
      value: string,
    } | null >,
    integrationType?: INTEGRATION_TYPE | null,
    enabled?: boolean | null,
    notificationEmail?: string | null,
    errorSyncEmail?: string | null,
    createdAt: string,
    createdBy: string,
    updatedAt: string,
    updatedBy: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateMeetingSubscriptionVariables = {
  filter?: ModelSubscriptionMeetingFilterInput | null,
  createdBy?: string | null,
};

export type OnCreateMeetingSubscription = {
  onCreateMeeting?:  {
    __typename: "Meeting",
    id: string,
    tenantId: string,
    title?: string | null,
    startTime: string,
    endTime?: string | null,
    contentPresented:  Array< {
      __typename: "ContentPresented",
      contentId: string,
      groupId?: string | null,
      folderItemId?: string | null,
      title: string,
      groupTitle?: string | null,
      status: CONTENT_PRESENTED_STATUS,
      contentType: MEETING_CONTENT_TYPE,
      events?:  Array< {
        __typename: "PagePresentedEvent",
        pageNumber: number,
        pageId: string,
        timestamp: string,
        end?: string | null,
      } > | null,
      openedAt?: string | null,
      closedAt?: string | null,
      presentedMeta:  Array< {
        __typename: "PresentedMeta",
        pageId: string,
        presented: boolean,
        followUp?: boolean | null,
        sentiment?: SENTIMENT | null,
        note?: string | null,
        title?: string | null,
      } >,
    } >,
    type: MEETING_TYPE,
    fieldValues:  Array< {
      __typename: "FieldValue",
      fieldId: string,
      valueOrId: string,
    } >,
    customValues?:  Array< {
      __typename: "CustomValues",
      fieldId: string,
      objectRecords?:  Array< {
        __typename: "ObjectRecord",
        id: string,
        externalId?: string | null,
        syncStatus?: CRMSyncStatus | null,
        status: OBJECT_RECORD_STATUS,
        values:  Array< {
          __typename: "ObjectRecordValue",
          fieldId: string,
          values: Array< string >,
        } >,
      } > | null,
      values: Array< string >,
    } > | null,
    notes?: string | null,
    status: MEETING_STATUS,
    createdAt: string,
    createdBy: string,
    updatedAt: string,
    updatedBy: string,
    attendees:  Array< {
      __typename: "Attendee",
      id: string,
      name: string,
      status: MEETING_ATTENDEE_STATUS,
      updatedAt: string,
      email?: string | null,
      crmAccountId?: string | null,
      crmAddressId?: string | null,
      attendeeType: ATTENDEE_TYPE,
      crmRecord?:  {
        __typename: "CRMMeetingRecordInfo",
        crmCallId?: string | null,
        crmCustomValues:  Array< {
          __typename: "CustomValues",
          fieldId: string,
          objectRecords?:  Array< {
            __typename: "ObjectRecord",
            id: string,
            externalId?: string | null,
            syncStatus?: CRMSyncStatus | null,
            status: OBJECT_RECORD_STATUS,
            values:  Array< {
              __typename: "ObjectRecordValue",
              fieldId: string,
              values: Array< string >,
            } >,
          } > | null,
          values: Array< string >,
        } >,
        crmSyncStatus?: CRMSyncStatus | null,
        lastSyncedAt?: string | null,
      } | null,
      lastSyncedAt?: string | null,
    } >,
    crmRecord?:  {
      __typename: "CRMMeetingRecordInfo",
      crmCallId?: string | null,
      crmCustomValues:  Array< {
        __typename: "CustomValues",
        fieldId: string,
        objectRecords?:  Array< {
          __typename: "ObjectRecord",
          id: string,
          externalId?: string | null,
          syncStatus?: CRMSyncStatus | null,
          status: OBJECT_RECORD_STATUS,
          values:  Array< {
            __typename: "ObjectRecordValue",
            fieldId: string,
            values: Array< string >,
          } >,
        } > | null,
        values: Array< string >,
      } >,
      crmSyncStatus?: CRMSyncStatus | null,
      lastSyncedAt?: string | null,
    } | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateMeetingSubscriptionVariables = {
  filter?: ModelSubscriptionMeetingFilterInput | null,
  createdBy?: string | null,
};

export type OnUpdateMeetingSubscription = {
  onUpdateMeeting?:  {
    __typename: "Meeting",
    id: string,
    tenantId: string,
    title?: string | null,
    startTime: string,
    endTime?: string | null,
    contentPresented:  Array< {
      __typename: "ContentPresented",
      contentId: string,
      groupId?: string | null,
      folderItemId?: string | null,
      title: string,
      groupTitle?: string | null,
      status: CONTENT_PRESENTED_STATUS,
      contentType: MEETING_CONTENT_TYPE,
      events?:  Array< {
        __typename: "PagePresentedEvent",
        pageNumber: number,
        pageId: string,
        timestamp: string,
        end?: string | null,
      } > | null,
      openedAt?: string | null,
      closedAt?: string | null,
      presentedMeta:  Array< {
        __typename: "PresentedMeta",
        pageId: string,
        presented: boolean,
        followUp?: boolean | null,
        sentiment?: SENTIMENT | null,
        note?: string | null,
        title?: string | null,
      } >,
    } >,
    type: MEETING_TYPE,
    fieldValues:  Array< {
      __typename: "FieldValue",
      fieldId: string,
      valueOrId: string,
    } >,
    customValues?:  Array< {
      __typename: "CustomValues",
      fieldId: string,
      objectRecords?:  Array< {
        __typename: "ObjectRecord",
        id: string,
        externalId?: string | null,
        syncStatus?: CRMSyncStatus | null,
        status: OBJECT_RECORD_STATUS,
        values:  Array< {
          __typename: "ObjectRecordValue",
          fieldId: string,
          values: Array< string >,
        } >,
      } > | null,
      values: Array< string >,
    } > | null,
    notes?: string | null,
    status: MEETING_STATUS,
    createdAt: string,
    createdBy: string,
    updatedAt: string,
    updatedBy: string,
    attendees:  Array< {
      __typename: "Attendee",
      id: string,
      name: string,
      status: MEETING_ATTENDEE_STATUS,
      updatedAt: string,
      email?: string | null,
      crmAccountId?: string | null,
      crmAddressId?: string | null,
      attendeeType: ATTENDEE_TYPE,
      crmRecord?:  {
        __typename: "CRMMeetingRecordInfo",
        crmCallId?: string | null,
        crmCustomValues:  Array< {
          __typename: "CustomValues",
          fieldId: string,
          objectRecords?:  Array< {
            __typename: "ObjectRecord",
            id: string,
            externalId?: string | null,
            syncStatus?: CRMSyncStatus | null,
            status: OBJECT_RECORD_STATUS,
            values:  Array< {
              __typename: "ObjectRecordValue",
              fieldId: string,
              values: Array< string >,
            } >,
          } > | null,
          values: Array< string >,
        } >,
        crmSyncStatus?: CRMSyncStatus | null,
        lastSyncedAt?: string | null,
      } | null,
      lastSyncedAt?: string | null,
    } >,
    crmRecord?:  {
      __typename: "CRMMeetingRecordInfo",
      crmCallId?: string | null,
      crmCustomValues:  Array< {
        __typename: "CustomValues",
        fieldId: string,
        objectRecords?:  Array< {
          __typename: "ObjectRecord",
          id: string,
          externalId?: string | null,
          syncStatus?: CRMSyncStatus | null,
          status: OBJECT_RECORD_STATUS,
          values:  Array< {
            __typename: "ObjectRecordValue",
            fieldId: string,
            values: Array< string >,
          } >,
        } > | null,
        values: Array< string >,
      } >,
      crmSyncStatus?: CRMSyncStatus | null,
      lastSyncedAt?: string | null,
    } | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteMeetingSubscriptionVariables = {
  filter?: ModelSubscriptionMeetingFilterInput | null,
  createdBy?: string | null,
};

export type OnDeleteMeetingSubscription = {
  onDeleteMeeting?:  {
    __typename: "Meeting",
    id: string,
    tenantId: string,
    title?: string | null,
    startTime: string,
    endTime?: string | null,
    contentPresented:  Array< {
      __typename: "ContentPresented",
      contentId: string,
      groupId?: string | null,
      folderItemId?: string | null,
      title: string,
      groupTitle?: string | null,
      status: CONTENT_PRESENTED_STATUS,
      contentType: MEETING_CONTENT_TYPE,
      events?:  Array< {
        __typename: "PagePresentedEvent",
        pageNumber: number,
        pageId: string,
        timestamp: string,
        end?: string | null,
      } > | null,
      openedAt?: string | null,
      closedAt?: string | null,
      presentedMeta:  Array< {
        __typename: "PresentedMeta",
        pageId: string,
        presented: boolean,
        followUp?: boolean | null,
        sentiment?: SENTIMENT | null,
        note?: string | null,
        title?: string | null,
      } >,
    } >,
    type: MEETING_TYPE,
    fieldValues:  Array< {
      __typename: "FieldValue",
      fieldId: string,
      valueOrId: string,
    } >,
    customValues?:  Array< {
      __typename: "CustomValues",
      fieldId: string,
      objectRecords?:  Array< {
        __typename: "ObjectRecord",
        id: string,
        externalId?: string | null,
        syncStatus?: CRMSyncStatus | null,
        status: OBJECT_RECORD_STATUS,
        values:  Array< {
          __typename: "ObjectRecordValue",
          fieldId: string,
          values: Array< string >,
        } >,
      } > | null,
      values: Array< string >,
    } > | null,
    notes?: string | null,
    status: MEETING_STATUS,
    createdAt: string,
    createdBy: string,
    updatedAt: string,
    updatedBy: string,
    attendees:  Array< {
      __typename: "Attendee",
      id: string,
      name: string,
      status: MEETING_ATTENDEE_STATUS,
      updatedAt: string,
      email?: string | null,
      crmAccountId?: string | null,
      crmAddressId?: string | null,
      attendeeType: ATTENDEE_TYPE,
      crmRecord?:  {
        __typename: "CRMMeetingRecordInfo",
        crmCallId?: string | null,
        crmCustomValues:  Array< {
          __typename: "CustomValues",
          fieldId: string,
          objectRecords?:  Array< {
            __typename: "ObjectRecord",
            id: string,
            externalId?: string | null,
            syncStatus?: CRMSyncStatus | null,
            status: OBJECT_RECORD_STATUS,
            values:  Array< {
              __typename: "ObjectRecordValue",
              fieldId: string,
              values: Array< string >,
            } >,
          } > | null,
          values: Array< string >,
        } >,
        crmSyncStatus?: CRMSyncStatus | null,
        lastSyncedAt?: string | null,
      } | null,
      lastSyncedAt?: string | null,
    } >,
    crmRecord?:  {
      __typename: "CRMMeetingRecordInfo",
      crmCallId?: string | null,
      crmCustomValues:  Array< {
        __typename: "CustomValues",
        fieldId: string,
        objectRecords?:  Array< {
          __typename: "ObjectRecord",
          id: string,
          externalId?: string | null,
          syncStatus?: CRMSyncStatus | null,
          status: OBJECT_RECORD_STATUS,
          values:  Array< {
            __typename: "ObjectRecordValue",
            fieldId: string,
            values: Array< string >,
          } >,
        } > | null,
        values: Array< string >,
      } >,
      crmSyncStatus?: CRMSyncStatus | null,
      lastSyncedAt?: string | null,
    } | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateHubSubscriptionVariables = {
  filter?: ModelSubscriptionHubFilterInput | null,
  createdBy?: string | null,
};

export type OnCreateHubSubscription = {
  onCreateHub?:  {
    __typename: "Hub",
    id: string,
    tenantId: string,
    name?: string | null,
    status: HUB_STATUS,
    createdAt: string,
    createdBy: string,
    updatedAt: string,
    updatedBy: string,
    hubSections?:  Array< {
      __typename: "HubSection",
      id: string,
      sectionHeader?: string | null,
      status: HUB_SECTION_STATUS,
      type: HUB_SECTION_TYPE,
      visible: boolean,
      order: number,
      textarea?:  {
        __typename: "TextareaHubSettings",
        content?: string | null,
      } | null,
      sharedFiles?:  Array< {
        __typename: "SharedFileHubSetting",
        id: string,
        contentId: string,
        title: string,
        contentType: HUB_SHARED_FILE_CONTENT_TYPE,
        documentVersionSettings?:  {
          __typename: "DocumentVersionSettings",
          associatedFiles?:  Array< {
            __typename: "HubSharedAssociatedFile",
            associatedFileId: string,
            versionId?: string | null,
            notation?:  Array< {
              __typename: "Notation",
              id: string,
              type: NOTATION_TYPE,
              description?: string | null,
              status: HUB_SECTION_ITEM_STATUS,
              pageId: string,
              coordinate?:  Array< {
                __typename: "Coordinate",
                x: number,
                y: number,
              } > | null,
              createdAt: string,
              createdBy: string,
              updatedAt: string,
              updatedBy: string,
            } > | null,
          } > | null,
        } | null,
        status: HUB_SECTION_ITEM_STATUS,
        createdAt: string,
        updatedAt: string,
        notation?:  Array< {
          __typename: "Notation",
          id: string,
          type: NOTATION_TYPE,
          description?: string | null,
          status: HUB_SECTION_ITEM_STATUS,
          pageId: string,
          coordinate?:  Array< {
            __typename: "Coordinate",
            x: number,
            y: number,
          } > | null,
          createdAt: string,
          createdBy: string,
          updatedAt: string,
          updatedBy: string,
        } > | null,
      } > | null,
      toDos?:  Array< {
        __typename: "ToDo",
        id: string,
        title: string,
        status: TODO_STATUS,
        completedAt?: string | null,
        resolution?: string | null,
        order: number,
        createdAt: string,
        updatedAt: string,
      } > | null,
      links?:  Array< {
        __typename: "Link",
        id: string,
        url: string,
        title?: string | null,
        status: HUB_SECTION_ITEM_STATUS,
        order: number,
        createdAt: string,
        updatedAt: string,
      } > | null,
      createdAt: string,
      updatedAt: string,
    } > | null,
    customValues?:  Array< {
      __typename: "CustomValues",
      fieldId: string,
      objectRecords?:  Array< {
        __typename: "ObjectRecord",
        id: string,
        externalId?: string | null,
        syncStatus?: CRMSyncStatus | null,
        status: OBJECT_RECORD_STATUS,
        values:  Array< {
          __typename: "ObjectRecordValue",
          fieldId: string,
          values: Array< string >,
        } >,
      } > | null,
      values: Array< string >,
    } > | null,
    meetingId?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateHubSubscriptionVariables = {
  filter?: ModelSubscriptionHubFilterInput | null,
  createdBy?: string | null,
};

export type OnUpdateHubSubscription = {
  onUpdateHub?:  {
    __typename: "Hub",
    id: string,
    tenantId: string,
    name?: string | null,
    status: HUB_STATUS,
    createdAt: string,
    createdBy: string,
    updatedAt: string,
    updatedBy: string,
    hubSections?:  Array< {
      __typename: "HubSection",
      id: string,
      sectionHeader?: string | null,
      status: HUB_SECTION_STATUS,
      type: HUB_SECTION_TYPE,
      visible: boolean,
      order: number,
      textarea?:  {
        __typename: "TextareaHubSettings",
        content?: string | null,
      } | null,
      sharedFiles?:  Array< {
        __typename: "SharedFileHubSetting",
        id: string,
        contentId: string,
        title: string,
        contentType: HUB_SHARED_FILE_CONTENT_TYPE,
        documentVersionSettings?:  {
          __typename: "DocumentVersionSettings",
          associatedFiles?:  Array< {
            __typename: "HubSharedAssociatedFile",
            associatedFileId: string,
            versionId?: string | null,
            notation?:  Array< {
              __typename: "Notation",
              id: string,
              type: NOTATION_TYPE,
              description?: string | null,
              status: HUB_SECTION_ITEM_STATUS,
              pageId: string,
              coordinate?:  Array< {
                __typename: "Coordinate",
                x: number,
                y: number,
              } > | null,
              createdAt: string,
              createdBy: string,
              updatedAt: string,
              updatedBy: string,
            } > | null,
          } > | null,
        } | null,
        status: HUB_SECTION_ITEM_STATUS,
        createdAt: string,
        updatedAt: string,
        notation?:  Array< {
          __typename: "Notation",
          id: string,
          type: NOTATION_TYPE,
          description?: string | null,
          status: HUB_SECTION_ITEM_STATUS,
          pageId: string,
          coordinate?:  Array< {
            __typename: "Coordinate",
            x: number,
            y: number,
          } > | null,
          createdAt: string,
          createdBy: string,
          updatedAt: string,
          updatedBy: string,
        } > | null,
      } > | null,
      toDos?:  Array< {
        __typename: "ToDo",
        id: string,
        title: string,
        status: TODO_STATUS,
        completedAt?: string | null,
        resolution?: string | null,
        order: number,
        createdAt: string,
        updatedAt: string,
      } > | null,
      links?:  Array< {
        __typename: "Link",
        id: string,
        url: string,
        title?: string | null,
        status: HUB_SECTION_ITEM_STATUS,
        order: number,
        createdAt: string,
        updatedAt: string,
      } > | null,
      createdAt: string,
      updatedAt: string,
    } > | null,
    customValues?:  Array< {
      __typename: "CustomValues",
      fieldId: string,
      objectRecords?:  Array< {
        __typename: "ObjectRecord",
        id: string,
        externalId?: string | null,
        syncStatus?: CRMSyncStatus | null,
        status: OBJECT_RECORD_STATUS,
        values:  Array< {
          __typename: "ObjectRecordValue",
          fieldId: string,
          values: Array< string >,
        } >,
      } > | null,
      values: Array< string >,
    } > | null,
    meetingId?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteHubSubscriptionVariables = {
  filter?: ModelSubscriptionHubFilterInput | null,
  createdBy?: string | null,
};

export type OnDeleteHubSubscription = {
  onDeleteHub?:  {
    __typename: "Hub",
    id: string,
    tenantId: string,
    name?: string | null,
    status: HUB_STATUS,
    createdAt: string,
    createdBy: string,
    updatedAt: string,
    updatedBy: string,
    hubSections?:  Array< {
      __typename: "HubSection",
      id: string,
      sectionHeader?: string | null,
      status: HUB_SECTION_STATUS,
      type: HUB_SECTION_TYPE,
      visible: boolean,
      order: number,
      textarea?:  {
        __typename: "TextareaHubSettings",
        content?: string | null,
      } | null,
      sharedFiles?:  Array< {
        __typename: "SharedFileHubSetting",
        id: string,
        contentId: string,
        title: string,
        contentType: HUB_SHARED_FILE_CONTENT_TYPE,
        documentVersionSettings?:  {
          __typename: "DocumentVersionSettings",
          associatedFiles?:  Array< {
            __typename: "HubSharedAssociatedFile",
            associatedFileId: string,
            versionId?: string | null,
            notation?:  Array< {
              __typename: "Notation",
              id: string,
              type: NOTATION_TYPE,
              description?: string | null,
              status: HUB_SECTION_ITEM_STATUS,
              pageId: string,
              coordinate?:  Array< {
                __typename: "Coordinate",
                x: number,
                y: number,
              } > | null,
              createdAt: string,
              createdBy: string,
              updatedAt: string,
              updatedBy: string,
            } > | null,
          } > | null,
        } | null,
        status: HUB_SECTION_ITEM_STATUS,
        createdAt: string,
        updatedAt: string,
        notation?:  Array< {
          __typename: "Notation",
          id: string,
          type: NOTATION_TYPE,
          description?: string | null,
          status: HUB_SECTION_ITEM_STATUS,
          pageId: string,
          coordinate?:  Array< {
            __typename: "Coordinate",
            x: number,
            y: number,
          } > | null,
          createdAt: string,
          createdBy: string,
          updatedAt: string,
          updatedBy: string,
        } > | null,
      } > | null,
      toDos?:  Array< {
        __typename: "ToDo",
        id: string,
        title: string,
        status: TODO_STATUS,
        completedAt?: string | null,
        resolution?: string | null,
        order: number,
        createdAt: string,
        updatedAt: string,
      } > | null,
      links?:  Array< {
        __typename: "Link",
        id: string,
        url: string,
        title?: string | null,
        status: HUB_SECTION_ITEM_STATUS,
        order: number,
        createdAt: string,
        updatedAt: string,
      } > | null,
      createdAt: string,
      updatedAt: string,
    } > | null,
    customValues?:  Array< {
      __typename: "CustomValues",
      fieldId: string,
      objectRecords?:  Array< {
        __typename: "ObjectRecord",
        id: string,
        externalId?: string | null,
        syncStatus?: CRMSyncStatus | null,
        status: OBJECT_RECORD_STATUS,
        values:  Array< {
          __typename: "ObjectRecordValue",
          fieldId: string,
          values: Array< string >,
        } >,
      } > | null,
      values: Array< string >,
    } > | null,
    meetingId?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateCustomFormRecordSubscriptionVariables = {
  filter?: ModelSubscriptionCustomFormRecordFilterInput | null,
  createdBy?: string | null,
};

export type OnCreateCustomFormRecordSubscription = {
  onCreateCustomFormRecord?:  {
    __typename: "CustomFormRecord",
    id: string,
    tenantId: string,
    customFormId: string,
    entity: CUSTOM_FORM_RECORD_ENTITY,
    status: CUSTOM_FORM_RECORD_STATUS,
    crmFields?:  {
      __typename: "CustomFormRecordCRMFields",
      accountId?: string | null,
      externalId?: string | null,
      apiName: string,
      recordTypeId?: string | null,
      syncStatus: CRMSyncStatus,
      lastSyncedAt?: string | null,
    } | null,
    name?: string | null,
    parentId?: string | null,
    parentModel?: CUSTOM_FORM_RECORD_RELATIONSHIP_MODEL | null,
    values:  Array< {
      __typename: "CustomValues",
      fieldId: string,
      objectRecords?:  Array< {
        __typename: "ObjectRecord",
        id: string,
        externalId?: string | null,
        syncStatus?: CRMSyncStatus | null,
        status: OBJECT_RECORD_STATUS,
        values:  Array< {
          __typename: "ObjectRecordValue",
          fieldId: string,
          values: Array< string >,
        } >,
      } > | null,
      values: Array< string >,
    } >,
    createdAt: string,
    createdBy: string,
    updatedAt: string,
    updatedBy: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateCustomFormRecordSubscriptionVariables = {
  filter?: ModelSubscriptionCustomFormRecordFilterInput | null,
  createdBy?: string | null,
};

export type OnUpdateCustomFormRecordSubscription = {
  onUpdateCustomFormRecord?:  {
    __typename: "CustomFormRecord",
    id: string,
    tenantId: string,
    customFormId: string,
    entity: CUSTOM_FORM_RECORD_ENTITY,
    status: CUSTOM_FORM_RECORD_STATUS,
    crmFields?:  {
      __typename: "CustomFormRecordCRMFields",
      accountId?: string | null,
      externalId?: string | null,
      apiName: string,
      recordTypeId?: string | null,
      syncStatus: CRMSyncStatus,
      lastSyncedAt?: string | null,
    } | null,
    name?: string | null,
    parentId?: string | null,
    parentModel?: CUSTOM_FORM_RECORD_RELATIONSHIP_MODEL | null,
    values:  Array< {
      __typename: "CustomValues",
      fieldId: string,
      objectRecords?:  Array< {
        __typename: "ObjectRecord",
        id: string,
        externalId?: string | null,
        syncStatus?: CRMSyncStatus | null,
        status: OBJECT_RECORD_STATUS,
        values:  Array< {
          __typename: "ObjectRecordValue",
          fieldId: string,
          values: Array< string >,
        } >,
      } > | null,
      values: Array< string >,
    } >,
    createdAt: string,
    createdBy: string,
    updatedAt: string,
    updatedBy: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteCustomFormRecordSubscriptionVariables = {
  filter?: ModelSubscriptionCustomFormRecordFilterInput | null,
  createdBy?: string | null,
};

export type OnDeleteCustomFormRecordSubscription = {
  onDeleteCustomFormRecord?:  {
    __typename: "CustomFormRecord",
    id: string,
    tenantId: string,
    customFormId: string,
    entity: CUSTOM_FORM_RECORD_ENTITY,
    status: CUSTOM_FORM_RECORD_STATUS,
    crmFields?:  {
      __typename: "CustomFormRecordCRMFields",
      accountId?: string | null,
      externalId?: string | null,
      apiName: string,
      recordTypeId?: string | null,
      syncStatus: CRMSyncStatus,
      lastSyncedAt?: string | null,
    } | null,
    name?: string | null,
    parentId?: string | null,
    parentModel?: CUSTOM_FORM_RECORD_RELATIONSHIP_MODEL | null,
    values:  Array< {
      __typename: "CustomValues",
      fieldId: string,
      objectRecords?:  Array< {
        __typename: "ObjectRecord",
        id: string,
        externalId?: string | null,
        syncStatus?: CRMSyncStatus | null,
        status: OBJECT_RECORD_STATUS,
        values:  Array< {
          __typename: "ObjectRecordValue",
          fieldId: string,
          values: Array< string >,
        } >,
      } > | null,
      values: Array< string >,
    } >,
    createdAt: string,
    createdBy: string,
    updatedAt: string,
    updatedBy: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};
